import { TFunction } from 'i18next';

export enum OccupationTypeEnums {
    MAIN_OCCUPATION = 'MAIN_OCCUPATION',
    SECONDARY_OCCUPATION = 'SECONDARY_OCCUPATION',
}

const createOccupationTypeKey = (id: OccupationTypeEnums) => {
    return `lob.person.occupationType.select.${id}`;
};

export const getOccupationTypeOption = (key: OccupationTypeEnums, t: TFunction) => {
    return {
        value: key,
        label: t(createOccupationTypeKey(key)),
    };
};
