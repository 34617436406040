import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { selectEngineClaimDescription } from '../../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../../sagas/motor';
import {
    EngineClaimDescriptionModel,
    FlowKeys,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { flowActions } from '../../../../sagas/flow';
import { engineClaimDescriptionSchema } from '../../../../validations/schemas/engineClaimDescriptionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { commonActions } from '../../../../sagas/common';
import { claimDescriptionUtil } from '../../../../utils/claimDescriptionUtil';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    ENGINE_CLAIM_DESCRIPTION_LABEL,
    ENGINE_CLAIM_DESCRIPTION_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { ENGINE_CLAIM_DESCRIPTION } = FormFieldNames;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const PEClaimDescriptionPage = () => {
    const dispatch = useDispatch();
    const engineClaimDescription = useSelector(selectEngineClaimDescription);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.claimDescription');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<EngineClaimDescriptionModel>({
        resolver: yupResolver(engineClaimDescriptionSchema(t, ENGINE_CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            engineClaimDescription,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ engineClaimDescription }: EngineClaimDescriptionModel, e?: FormChangeable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { engineClaimDescription, flow: END })
            .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={ENGINE_CLAIM_DESCRIPTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.engineClaimDescription}
                                errorMessage={errors.engineClaimDescription?.message}
                                id={ENGINE_CLAIM_DESCRIPTION}
                                inputFieldWrapper={'col-12'}
                                label={t(ENGINE_CLAIM_DESCRIPTION_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ENGINE_CLAIM_DESCRIPTION_PLACEHOLDER)}
                                reference={ref}
                                rows={10}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
