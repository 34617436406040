import { TFunction } from 'i18next';
import { DamageLocationTypeEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createWorkplaceKey = (id: string) => {
    return `lob.person.workplace.select.${id}`;
};

export const getWorkplaceOption = (t: TFunction, key: DamageLocationTypeEnums, value: string) => {
    return {
        value,
        label: t(createWorkplaceKey(key)),
    };
};
