import { combineReducers } from 'redux';
import { AttachmentModel } from '../../interfaces/models/Attachment.model';
import attachment, { AttachmentActionTypes, attachmentInitState, IAttachmentAction } from './attachment/attachment';
import certificate, {
    CertificateActionTypes,
    certificateInitState,
    ICertificateAction,
} from './certificate/certificate';
import receipt, { IReceiptAction, ReceiptActionTypes, receiptInitState } from './receipt/receipt';
import { PollCertificateActionTypes, pollCertificateWatchers } from './certificate/pollCertificate';
import { pollReceiptWatchers, PollReciptActionTypes } from './receipt/pollReceipt';
import {
    IUploadAttachmentAction,
    UploadAttachmentActionTypes,
    uploadAttachmentWatchers,
} from './attachment/uploadAttachment';
import {
    IUploadCertificateAction,
    UploadCertificateActionTypes,
    uploadCertificateWatchers,
} from './certificate/uploadCertificate';
import { IUploadReceiptAction, UploadReceiptActionTypes, uploadReceiptWatchers } from './receipt/uploadReceipt';
import { DeleteAttachmentActionTypes, deleteAttachmentWatchers } from './attachment/deleteAttachment';
import { DeleteCertificateActionTypes, deleteCertificateWatchers } from './certificate/deleteCertificate';
import { DeleteReceiptActionTypes, deleteReceiptWatchers } from './receipt/deleteReceipt';
import { PollAttachmentActionTypes, pollAttachmentWatchers } from './attachment/pollAttachment';
import safetyStandards, {
    SafetyStandardsAction,
    SafetyStandardsActionTypes,
    safetyStandardsInitState,
} from './safetyStandard/safetyStandards';
import {
    PollSafetyStandardsAction,
    PollSafetyStandardsActionTypes,
    pollSafetyStandardsWatchers,
} from './safetyStandard/pollSafetyStandards';
import { DeleteSafetyStandardsAction, DeleteSafetyStandardsActionTypes } from './safetyStandard/deleteSafetyStandards';
import {
    UploadSafetyStandardsAction,
    UploadSafetyStandardsActionTypes,
    uploadSafetyStandardsWatchers,
} from './safetyStandard/uploadSafetyStandards';
import file, { FileAction, FileActionTypes, fileInitState } from './file/file';
import { PollFileAction, PollFileActionTypes, pollFileWatchers } from './file/pollFile';
import { DeleteFileAction, DeleteFileActionTypes, deleteFileWatchers } from './file/deleteFile';
import { UploadFileAction, UploadFileActionTypes, uploadFileWatchers } from './file/uploadFile';
import report, { ReportAction, ReportActionTypes, reportInitState } from './report/report';
import { DeleteReportAction, DeleteReportActionTypes, deleteReportWatchers } from './report/deleteReport';
import { PollReportAction, PollReportActionTypes, pollReportWatchers } from './report/pollReport';
import { UploadReportAction, UploadReportActionTypes, uploadReportWatchers } from './report/uploadReport';
import descriptionFile, {
    DescriptionFileAction,
    DescriptionFileActionTypes,
    descriptionFileInitState,
} from './descriptionFile/descriptionFile';
import {
    UploadDescriptionFileAction,
    UploadDescriptionFileActionTypes,
    uploadDescriptionFileWatchers,
} from './descriptionFile/uploadDescriptionFile';
import {
    DeleteDescriptionFileActionTypes,
    deleteDescriptionFileWatchers,
} from './descriptionFile/deleteDescriptionFile';
import { PollDescriptionFileActionTypes, pollDescriptionFileWatchers } from './descriptionFile/pollDescriptionFile';

export type UploadActionTypes =
    | CertificateActionTypes
    | DeleteCertificateActionTypes
    | PollCertificateActionTypes
    | UploadCertificateActionTypes
    | AttachmentActionTypes
    | DeleteAttachmentActionTypes
    | PollAttachmentActionTypes
    | UploadAttachmentActionTypes
    | ReceiptActionTypes
    | DeleteReceiptActionTypes
    | PollReciptActionTypes
    | UploadReceiptActionTypes
    | SafetyStandardsActionTypes
    | PollSafetyStandardsActionTypes
    | DeleteSafetyStandardsActionTypes
    | UploadSafetyStandardsActionTypes
    | FileActionTypes
    | PollFileActionTypes
    | DeleteFileActionTypes
    | UploadFileActionTypes
    | ReportActionTypes
    | DeleteReportActionTypes
    | PollReportActionTypes
    | UploadReportActionTypes
    | DescriptionFileActionTypes
    | PollDescriptionFileActionTypes
    | DeleteDescriptionFileActionTypes
    | UploadDescriptionFileActionTypes;

export type IUploadActions =
    | ICertificateAction
    | IUploadCertificateAction
    | IAttachmentAction
    | IUploadAttachmentAction
    | IReceiptAction
    | IUploadReceiptAction
    | SafetyStandardsAction
    | PollSafetyStandardsAction
    | DeleteSafetyStandardsAction
    | UploadSafetyStandardsAction
    | FileAction
    | PollFileAction
    | DeleteFileAction
    | UploadFileAction
    | ReportAction
    | PollReportAction
    | DeleteReportAction
    | UploadReportAction
    | DescriptionFileAction
    | UploadDescriptionFileAction;

export const uploadInitState = {
    attachment: attachmentInitState,
    certificate: certificateInitState,
    descriptionFile: descriptionFileInitState,
    file: fileInitState,
    receipt: receiptInitState,
    report: reportInitState,
    safetyStandards: safetyStandardsInitState,
};

/**
 * Export namespace state
 */
export interface UploadState {
    attachment: AttachmentModel;
    certificate: AttachmentModel;
    descriptionFile: AttachmentModel;
    file: AttachmentModel;
    receipt: AttachmentModel;
    report: AttachmentModel;
    safetyStandards: AttachmentModel;
}

/**
 * Export namespace watchers
 */
export const uploadWatchers = [
    uploadAttachmentWatchers,
    pollAttachmentWatchers,
    deleteAttachmentWatchers,
    uploadReceiptWatchers,
    pollReceiptWatchers,
    deleteReceiptWatchers,
    uploadCertificateWatchers,
    pollCertificateWatchers,
    deleteCertificateWatchers,
    uploadSafetyStandardsWatchers,
    pollSafetyStandardsWatchers,
    uploadFileWatchers,
    pollFileWatchers,
    deleteFileWatchers,
    uploadReportWatchers,
    pollReportWatchers,
    deleteReportWatchers,
    uploadDescriptionFileWatchers,
    pollDescriptionFileWatchers,
    deleteDescriptionFileWatchers,
];

/**
 * Export combined reducers as default
 */
export default combineReducers({
    certificate,
    attachment,
    receipt,
    safetyStandards,
    file,
    report,
    descriptionFile,
});
