import {
    CategoryKeys,
    IncapacityPeriodEnums,
    ObjectWithDynamicKey,
    TypeOfCauseEnums,
    TypeOfStudiesEnums,
    TypeOfVehicleEnums,
    YesNoKeys,
} from '@protectorinsurance/ds-can';

export enum PersonRoutePaths {
    INDEX = '/fin/:language/5',
    ERROR = '/error',
    STOP = '/fin/:language/5/stop',

    // Dyn
    DYN_BASE = '/fin/:language/5/dyn/',
    DYN_CAROUSEL = '/fin/:language/5/dyn/:flow?/:slug',
    // Start
    DYN_CAROUSEL_START_HAS_EXISTING_CLAIM = '/fin/:language/5/dyn/start/has-existing-claim',
    // Health
    DYN_CAROUSEL_HEALTH_CATEGORY = '/fin/:language/5/dyn/health/category',
    // Health / Accident
    DYN_CAROUSEL_HEALTH_ACCIDENT_CLAIM_REPORTER_ROLE = '/fin/:language/5/dyn/health/accident-claim-reporter-role',
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_APPLIED_KELA = '/fin/:language/5/dyn/health/accident-has-applied-kela',
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_OTHER_INSURANCE = '/fin/:language/5/dyn/health/accident-has-other-insurance',
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_EXPENSES = '/fin/:language/5/dyn/health/accident-has-expenses',
    DYN_CAROUSEL_HEALTH_ACCIDENT_WHO_PAID_EXPENSES = '/fin/:language/5/dyn/health/accident-who-paid-expenses',
    DYN_CAROUSEL_HEALTH_ACCIDENT_TYPE_OF_DOCTOR = '/fin/:language/5/dyn/health/accident-type-of-doctor',
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_ATTACHMENTS = '/fin/:language/5/dyn/health/accident-has-attachments',
    // Health / Illness
    DYN_CAROUSEL_HEALTH_ILLNESS_CLAIM_REPORTER_ROLE = '/fin/:language/5/dyn/health/illness-claim-reporter-role',
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_DIAGNOSIS_CODE = '/fin/:language/5/dyn/health/illness-has-diagnosis-code',
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_APPLIED_KELA = '/fin/:language/5/dyn/health/illness-has-applied-kela',
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_OTHER_INSURANCE = '/fin/:language/5/dyn/health/illness-has-other-insurance',
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_EXPENSES = '/fin/:language/5/dyn/health/illness-has-expenses',
    DYN_CAROUSEL_HEALTH_ILLNESS_WHO_PAID_EXPENSES = '/fin/:language/5/dyn/health/illness-who-paid-expenses',
    DYN_CAROUSEL_HEALTH_ILLNESS_TYPE_OF_DOCTOR = '/fin/:language/5/dyn/health/illness-type-of-doctor',
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_ATTACHMENTS = '/fin/:language/5/dyn/health/illness-has-attachments',
    // Group Accident
    DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY = '/fin/:language/5/dyn/group-accident/claimant-age-category',
    DYN_CAROUSEL_GROUP_ACCIDENT_CLAIM_LOCATION = '/fin/:language/5/dyn/group-accident/claim-location',
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE = '/fin/:language/5/dyn/group-accident/has-applied-medical-care',
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_EXPENSES = '/fin/:language/5/dyn/group-accident/has-expenses',
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_KELA = '/fin/:language/5/dyn/group-accident/has-applied-kela',
    DYN_CAROUSEL_GROUP_ACCIDENT_WHO_PAID_EXPENSES = '/fin/:language/5/dyn/group-accident/who-paid-expenses',
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE = '/fin/:language/5/dyn/group-accident/has-other-insurance',
    // Workers Comp
    DYN_CAROUSEL_WORKERS_COMP_CATEGORY = '/fin/:language/5/dyn/workers-comp/category',
    // Workers Comp / Illness
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CLAIMANT_ROLE = '/fin/:language/5/dyn/workers-comp-illness/claimant-role',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_CAUSE = '/fin/:language/5/dyn/workers-comp-illness/type-of-cause',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_PHYSICAL_FACTOR = '/fin/:language/5/dyn/workers-comp-illness/type-of-physical-factor',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_BIOLOGICAL_FACTOR = '/fin/:language/5/dyn/workers-comp-illness/type-of-biological-factor',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY = '/fin/:language/5/dyn/workers-comp-illness/caused-incapacity',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE = '/fin/:language/5/dyn/workers-comp-illness/has-applied-medical-care',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_EXPENSES = '/fin/:language/5/dyn/workers-comp-illness/has-expenses',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_WHO_PAID_EXPENSES = '/fin/:language/5/dyn/workers-comp-illness/who-paid-expenses',
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_COMPANY_SHARES = '/fin/:language/5/dyn/workers-comp-illness/has-company-shares',
    // Workers Comp / Accident
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CLAIMANT_ROLE = '/fin/:language/5/dyn/workers-comp-accident/claimant-role',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION = '/fin/:language/5/dyn/workers-comp-accident/study-location',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_STUDIES = '/fin/:language/5/dyn/workers-comp-accident/type-of-studies',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION = '/fin/:language/5/dyn/workers-comp-accident/occupational-condition',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OUTSIDE_OCCUPATIONAL_CONDITION = '/fin/:language/5/dyn/workers-comp-accident/outside-occupational-condition',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OTHER_OCCUPATIONAL_CONDITION = '/fin/:language/5/dyn/workers-comp-accident/other-occupational-condition',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_VEHICLE_INVOLVED = '/fin/:language/5/dyn/workers-comp-accident/is-vehicle-involved',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_VEHICLE = '/fin/:language/5/dyn/workers-comp-accident/type-of-vehicle',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED = '/fin/:language/5/dyn/workers-comp-accident/is-police-contacted',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE = '/fin/:language/5/dyn/workers-comp-accident/under-influence',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CAUSED_INCAPACITY = '/fin/:language/5/dyn/workers-comp-accident/caused-incapacity',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_MORE_THAN_SEVEN_DAYS = '/fin/:language/5/dyn/workers-comp-accident/incapacity-more-than-seven-days',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_PERIOD = '/fin/:language/5/dyn/workers-comp-accident/incapacity-period',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE = '/fin/:language/5/dyn/workers-comp-accident/has-applied-medical-care',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES = '/fin/:language/5/dyn/workers-comp-accident/has-expenses',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_WHO_PAID_EXPENSES = '/fin/:language/5/dyn/workers-comp-accident/who-paid-expenses',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE = '/fin/:language/5/dyn/workers-comp-accident/has-other-insurance',
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_COMPANY_SHARES = '/fin/:language/5/dyn/workers-comp-accident/has-company-shares',

    // Start
    START_WELCOME = '/fin/:language/5/start/welcome',
    START_CLAIM_NUMBER_INFORMATION = '/fin/:language/5/start/claim-number-information',

    // Health / Accident
    H_A_ONBOARDING = '/fin/:language/5/health/accident/onboarding',
    H_A_PRIVACY = '/fin/:language/5/health/accident/privacy',
    H_A_CLAIM_DATE = '/fin/:language/5/health/accident/claim-date',
    H_A_NATURE_TYPE_ID = '/fin/:language/5/health/accident/type-of-accident',
    H_A_BODY_PARTS = '/fin/:language/5/health/accident/body-parts',
    H_A_OTHER_INSURANCE_COMPANY = '/fin/:language/5/health/accident/other-insurance-company',
    H_A_UPLOAD_RECEIPT = '/fin/:language/5/health/accident/upload-receipt',
    H_A_UPLOAD_ATTACHMENT = '/fin/:language/5/health/accident/upload-attachment',
    H_A_SITUATION_SUMMARY = '/fin/:language/5/health/accident/situation-summary',
    H_A_COMPANY_INFORMATION = '/fin/:language/5/health/accident/company-information',
    H_A_CLAIMANT_INFORMATION = '/fin/:language/5/health/accident/claimant-information',
    H_A_REPORTER_INFORMATION = '/fin/:language/5/health/accident/reporter-information',
    H_A_BANK_ACCOUNT_INFORMATION = '/fin/:language/5/health/accident/bank-account-information',
    H_A_BANK_ACCOUNT_CONTACT_INFORMATION = '/fin/:language/5/health/accident/bank-account-contact-information',
    H_A_FINAL_SUMMARY = '/fin/:language/5/health/accident/final-summary',
    H_A_REPORT_COMPLETED = '/fin/:language/5/health/accident/report-completed',

    // Health / Illness
    H_I_ONBOARDING = '/fin/:language/5/health/illness/onboarding',
    H_I_PRIVACY = '/fin/:language/5/health/illness/privacy',
    H_I_CLAIM_DATE = '/fin/:language/5/health/illness/claim-date',
    H_I_DIAGNOSIS_CODE_INFORMATION = '/fin/:language/5/health/illness/diagnosis-code-information',
    H_I_OTHER_INSURANCE_COMPANY = '/fin/:language/5/health/illness/other-insurance-company',
    H_I_UPLOAD_RECEIPT = '/fin/:language/5/health/illness/upload-receipt',
    H_I_UPLOAD_ATTACHMENT = '/fin/:language/5/health/illness/upload-attachment',
    H_I_SITUATION_SUMMARY = '/fin/:language/5/health/illness/situation-summary',
    H_I_COMPANY_INFORMATION = '/fin/:language/5/health/illness/company-information',
    H_I_CLAIMANT_INFORMATION = '/fin/:language/5/health/illness/claimant-information',
    H_I_REPORTER_INFORMATION = '/fin/:language/5/health/illness/reporter-information',
    H_I_BANK_ACCOUNT_INFORMATION = '/fin/:language/5/health/illness/bank-account-information',
    H_I_BANK_ACCOUNT_CONTACT_INFORMATION = '/fin/:language/5/health/illness/bank-account-contact-information',
    H_I_FINAL_SUMMARY = '/fin/:language/5/health/illness/final-summary',
    H_I_REPORT_COMPLETED = '/fin/:language/5/health/illness/report-completed',

    // Group Accident
    GROUP_ACCIDENT_ONBOARDING = '/fin/:language/5/group-accident/onboarding',
    GROUP_ACCIDENT_PRIVACY = '/fin/:language/5/group-accident/privacy',
    GROUP_ACCIDENT_CLAIM_DATE = '/fin/:language/5/group-accident/claim-date',
    GROUP_ACCIDENT_NATURE_TYPE_ID = '/fin/:language/5/group-accident/nature-type-id',
    GROUP_ACCIDENT_CLAIM_CAUSE = '/fin/:language/5/group-accident/claim-cause',
    GROUP_ACCIDENT_BODY_PARTS = '/fin/:language/5/group-accident/body-parts',
    GROUP_ACCIDENT_UPLOAD_RECEIPT = '/fin/:language/5/group-accident/upload-receipt',
    GROUP_ACCIDENT_OTHER_INSURANCE_COMPANY = '/fin/:language/5/group-accident/other-insurance-company',
    GROUP_ACCIDENT_SITUATION_SUMMARY = '/fin/:language/5/group-accident/situation-summary',
    GROUP_ACCIDENT_COMPANY_INFORMATION = '/fin/:language/5/group-accident/company-information',
    GROUP_ACCIDENT_CLAIMANT_INFORMATION = '/fin/:language/5/group-accident/claimant-information',
    GROUP_ACCIDENT_REPORTER_INFORMATION = '/fin/:language/5/group-accident/reporter-information',
    GROUP_ACCIDENT_BANK_ACCOUNT_INFORMATION = '/fin/:language/5/group-accident/bank-account-information',
    GROUP_ACCIDENT_BANK_ACCOUNT_CONTACT_INFORMATION = '/fin/:language/5/group-accident/bank-account-contact-information',
    GROUP_ACCIDENT_FINAL_SUMMARY = '/fin/:language/5/group-accident/final-summary',
    GROUP_ACCIDENT_REPORT_COMPLETED = '/fin/:language/5/group-accident/report-completed',

    // Workers Comp / Illness
    WORKERS_COMP_ILLNESS_ONBOARDING = '/fin/:language/5/workers-comp/illness/onboarding',
    WORKERS_COMP_ILLNESS_CLAIM_DATE = '/fin/:language/5/workers-comp/illness/claim-date',
    WORKERS_COMP_ILLNESS_REPORTED_TO_EMPLOYER_DATE = '/fin/:language/5/workers-comp/illness/reported-to-employer-date',
    WORKERS_COMP_ILLNESS_DAMAGE_ADDRESS = '/fin/:language/5/workers-comp/illness/damage-address',
    WORKERS_COMP_ILLNESS_TYPE_OF_CHEMICAL_FACTOR = '/fin/:language/5/workers-comp/illness/type-of-chemical-factor',
    WORKERS_COMP_ILLNESS_INCAPACITY_PERIODS = '/fin/:language/5/workers-comp/illness/incapacity-periods',
    WORKERS_COMP_ILLNESS_DATE_OF_APPLIED_MEDICAL_CARE = '/fin/:language/5/workers-comp/illness/date-of-applied-medical-care',
    WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION = '/fin/:language/5/workers-comp/illness/medical-institution',
    WORKERS_COMP_ILLNESS_UPLOAD_RECEIPT = '/fin/:language/5/workers-comp/illness/upload-receipt',
    WORKERS_COMP_ILLNESS_SITUATION_SUMMARY = '/fin/:language/5/workers-comp/illness/situation-summary',
    WORKERS_COMP_ILLNESS_COMPANY_INFORMATION = '/fin/:language/5/workers-comp/illness/company-information',
    WORKERS_COMP_ILLNESS_CLAIMANT_INFORMATION = '/fin/:language/5/workers-comp/illness/claimant-information',
    WORKERS_COMP_ILLNESS_INJURED_PROFESSION = '/fin/:language/5/workers-comp/illness/injured-profession',
    WORKERS_COMP_ILLNESS_WORKPLACE = '/fin/:language/5/workers-comp/illness/workplace',
    WORKERS_COMP_ILLNESS_ENTREPRENEUR_OWNERSHIP = '/fin/:language/5/workers-comp/illness/entrepreneur-ownership',
    WORKERS_COMP_ILLNESS_CLAIMANT_POSITION = '/fin/:language/5/workers-comp/illness/claimant-position',
    WORKERS_COMP_ILLNESS_REPORTER_INFORMATION = '/fin/:language/5/workers-comp/illness/reporter-information',
    WORKERS_COMP_ILLNESS_POLICY_HOLDERS_CONTACT = '/fin/:language/5/workers-comp/illness/policy-holders-contact',
    WORKERS_COMP_ILLNESS_BANK_ACCOUNT_INFORMATION = '/fin/:language/5/workers-comp/illness/bank-account-information',
    WORKERS_COMP_ILLNESS_BANK_ACCOUNT_CONTACT_INFORMATION = '/fin/:language/5/workers-comp/illness/bank-account-contact-information',
    WORKERS_COMP_ILLNESS_FINAL_SUMMARY = '/fin/:language/5/workers-comp/illness/final-summary',
    WORKERS_COMP_ILLNESS_REPORT_COMPLETED = '/fin/:language/5/workers-comp/illness/report-completed',

    // Workers Comp / Accident
    WORKERS_COMP_ACCIDENT_ONBOARDING = '/fin/:language/5/workers-comp/accident/onboarding',
    WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS = '/fin/:language/5/workers-comp/accident/damage-address',
    WORKERS_COMP_ACCIDENT_WORKPLACE = '/fin/:language/5/workers-comp/accident/workplace',
    WORKERS_COMP_ACCIDENT_CLAIM_DATE = '/fin/:language/5/workers-comp/accident/claim-date',
    WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE = '/fin/:language/5/workers-comp/accident/reported-to-employer-date',
    WORKERS_COMP_ACCIDENT_CLAIMANT_WORK_DAY = '/fin/:language/5/workers-comp/accident/claimant-work-day',
    WORKERS_COMP_ACCIDENT_NATURE_TYPE_ID = '/fin/:language/5/workers-comp/accident/nature-type-id',
    WORKERS_COMP_ACCIDENT_BODY_PARTS_LIST = '/fin/:language/5/workers-comp/accident/body-parts-list',
    WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER = '/fin/:language/5/workers-comp/accident/vehicle-registration-number',
    WORKERS_COMP_ACCIDENT_POLICE_DISTRICT = '/fin/:language/5/workers-comp/accident/police-district',
    WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE_CLAIM_DESCRIPTION = '/fin/:language/5/workers-comp/accident/under-influence-claim-description',
    WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS = '/fin/:language/5/workers-comp/accident/incapacity-periods',
    WORKERS_COMP_ACCIDENT_DATE_OF_APPLIED_MEDICAL_CARE = '/fin/:language/5/workers-comp/accident/date-of-applied-medical-care',
    WORKERS_COMP_ACCIDENT_MEDICAL_INSTITUTION = '/fin/:language/5/workers-comp/accident/medical-institution',
    WORKERS_COMP_ACCIDENT_UPLOAD_RECEIPT = '/fin/:language/5/workers-comp/accident/upload-receipt',
    WORKERS_COMP_ACCIDENT_OTHER_INSURANCE_COMPANY = '/fin/:language/5/workers-comp/accident/other-insurance-company',
    WORKERS_COMP_ACCIDENT_SITUATION_SUMMARY = '/fin/:language/5/workers-comp/accident/situation-summary',
    WORKERS_COMP_ACCIDENT_COMPANY_INFORMATION = '/fin/:language/5/workers-comp/accident/company-information',
    WORKERS_COMP_ACCIDENT_CLAIMANT_INFORMATION = '/fin/:language/5/workers-comp/accident/claimant-information',
    WORKERS_COMP_ACCIDENT_INJURED_PROFESSION = '/fin/:language/5/workers-comp/accident/injured-profession',
    WORKERS_COMP_ACCIDENT_ENTREPRENEUR_OWNERSHIP = '/fin/:language/5/workers-comp/accident/entrepreneur-ownership',
    WORKERS_COMP_ACCIDENT_CLAIMANT_POSITION = '/fin/:language/5/workers-comp/accident/claimant-position',
    WORKERS_COMP_ACCIDENT_REPORTER_INFORMATION = '/fin/:language/5/workers-comp/accident/reporter-information',
    WORKERS_COMP_ACCIDENT_POLICY_HOLDERS_CONTACT = '/fin/:language/5/workers-comp/accident/policy-holders-contact',
    WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_INFORMATION = '/fin/:language/5/workers-comp/accident/bank-account-information',
    WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_CONTACT_INFORMATION = '/fin/:language/5/workers-comp/accident/bank-account-contact-information',
    WORKERS_COMP_ACCIDENT_FINAL_SUMMARY = '/fin/:language/5/workers-comp/accident/final-summary',
    WORKERS_COMP_ACCIDENT_REPORT_COMPLETED = '/fin/:language/5/workers-comp/accident/report-completed',
}

export const personWizardRoutes: ObjectWithDynamicKey = {
    //Start routes
    [PersonRoutePaths.INDEX]: PersonRoutePaths.DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
    [PersonRoutePaths.DYN_CAROUSEL_START_HAS_EXISTING_CLAIM]: {
        [YesNoKeys.YES]: PersonRoutePaths.START_CLAIM_NUMBER_INFORMATION,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_CATEGORY,
    },
    [PersonRoutePaths.START_CLAIM_NUMBER_INFORMATION]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_CATEGORY,

    //Health routes
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_CATEGORY]: {
        [CategoryKeys.ACCIDENT]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_CLAIM_REPORTER_ROLE, //Accident
        [CategoryKeys.ILLNESS]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_CLAIM_REPORTER_ROLE, //Illness
    },

    //Health - Accident routes
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_CLAIM_REPORTER_ROLE]: PersonRoutePaths.H_A_ONBOARDING,
    [PersonRoutePaths.H_A_ONBOARDING]: PersonRoutePaths.H_A_PRIVACY,
    [PersonRoutePaths.H_A_PRIVACY]: PersonRoutePaths.H_A_CLAIM_DATE,
    [PersonRoutePaths.H_A_CLAIM_DATE]: PersonRoutePaths.H_A_NATURE_TYPE_ID,
    [PersonRoutePaths.H_A_NATURE_TYPE_ID]: PersonRoutePaths.H_A_BODY_PARTS,
    [PersonRoutePaths.H_A_BODY_PARTS]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_APPLIED_KELA,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_APPLIED_KELA]:
        PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_OTHER_INSURANCE,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: PersonRoutePaths.H_A_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_EXPENSES,
    },
    [PersonRoutePaths.H_A_OTHER_INSURANCE_COMPANY]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_EXPENSES]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_TYPE_OF_DOCTOR,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_ATTACHMENTS,
    },
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_TYPE_OF_DOCTOR]:
        PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_WHO_PAID_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_WHO_PAID_EXPENSES]: PersonRoutePaths.H_A_UPLOAD_RECEIPT,
    [PersonRoutePaths.H_A_UPLOAD_RECEIPT]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_ATTACHMENTS,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_ATTACHMENTS]: {
        [YesNoKeys.YES]: PersonRoutePaths.H_A_UPLOAD_ATTACHMENT,
        [YesNoKeys.NO]: PersonRoutePaths.H_A_SITUATION_SUMMARY,
    },
    [PersonRoutePaths.H_A_UPLOAD_ATTACHMENT]: PersonRoutePaths.H_A_SITUATION_SUMMARY,
    [PersonRoutePaths.H_A_SITUATION_SUMMARY]: PersonRoutePaths.H_A_COMPANY_INFORMATION,
    [PersonRoutePaths.H_A_COMPANY_INFORMATION]: PersonRoutePaths.H_A_CLAIMANT_INFORMATION,
    [PersonRoutePaths.H_A_CLAIMANT_INFORMATION]: PersonRoutePaths.H_A_REPORTER_INFORMATION,
    [PersonRoutePaths.H_A_REPORTER_INFORMATION]: PersonRoutePaths.H_A_BANK_ACCOUNT_INFORMATION,
    [PersonRoutePaths.H_A_BANK_ACCOUNT_INFORMATION]: PersonRoutePaths.H_A_FINAL_SUMMARY,
    [PersonRoutePaths.H_A_BANK_ACCOUNT_CONTACT_INFORMATION]: PersonRoutePaths.H_A_FINAL_SUMMARY,
    [PersonRoutePaths.H_A_FINAL_SUMMARY]: PersonRoutePaths.H_A_REPORT_COMPLETED,

    //Health - Illness routes
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_CLAIM_REPORTER_ROLE]: PersonRoutePaths.H_I_ONBOARDING,
    [PersonRoutePaths.H_I_ONBOARDING]: PersonRoutePaths.H_I_PRIVACY,
    [PersonRoutePaths.H_I_PRIVACY]: PersonRoutePaths.H_I_CLAIM_DATE,
    [PersonRoutePaths.H_I_CLAIM_DATE]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_DIAGNOSIS_CODE,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_DIAGNOSIS_CODE]: {
        [YesNoKeys.YES]: PersonRoutePaths.H_I_DIAGNOSIS_CODE_INFORMATION,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_APPLIED_KELA,
    },
    [PersonRoutePaths.H_I_DIAGNOSIS_CODE_INFORMATION]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_APPLIED_KELA,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_APPLIED_KELA]:
        PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_OTHER_INSURANCE,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: PersonRoutePaths.H_I_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_EXPENSES,
    },
    [PersonRoutePaths.H_I_OTHER_INSURANCE_COMPANY]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_EXPENSES]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_TYPE_OF_DOCTOR,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_ATTACHMENTS,
    },
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_TYPE_OF_DOCTOR]:
        PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_WHO_PAID_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_WHO_PAID_EXPENSES]: PersonRoutePaths.H_I_UPLOAD_RECEIPT,
    [PersonRoutePaths.H_I_UPLOAD_RECEIPT]: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_ATTACHMENTS,
    [PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_ATTACHMENTS]: {
        [YesNoKeys.YES]: PersonRoutePaths.H_I_UPLOAD_ATTACHMENT,
        [YesNoKeys.NO]: PersonRoutePaths.H_I_SITUATION_SUMMARY,
    },
    [PersonRoutePaths.H_I_UPLOAD_ATTACHMENT]: PersonRoutePaths.H_I_SITUATION_SUMMARY,
    [PersonRoutePaths.H_I_SITUATION_SUMMARY]: PersonRoutePaths.H_I_COMPANY_INFORMATION,
    [PersonRoutePaths.H_I_COMPANY_INFORMATION]: PersonRoutePaths.H_I_CLAIMANT_INFORMATION,
    [PersonRoutePaths.H_I_CLAIMANT_INFORMATION]: PersonRoutePaths.H_I_REPORTER_INFORMATION,
    [PersonRoutePaths.H_I_REPORTER_INFORMATION]: PersonRoutePaths.H_I_BANK_ACCOUNT_INFORMATION,
    [PersonRoutePaths.H_I_BANK_ACCOUNT_INFORMATION]: PersonRoutePaths.H_I_FINAL_SUMMARY,
    [PersonRoutePaths.H_I_BANK_ACCOUNT_CONTACT_INFORMATION]: PersonRoutePaths.H_I_FINAL_SUMMARY,
    [PersonRoutePaths.H_I_FINAL_SUMMARY]: PersonRoutePaths.H_I_REPORT_COMPLETED,

    // Group Accident Routes
    [PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY]: PersonRoutePaths.GROUP_ACCIDENT_ONBOARDING,
    [PersonRoutePaths.GROUP_ACCIDENT_ONBOARDING]: PersonRoutePaths.GROUP_ACCIDENT_PRIVACY,
    [PersonRoutePaths.GROUP_ACCIDENT_PRIVACY]: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_CLAIM_LOCATION,
    [PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_CLAIM_LOCATION]: PersonRoutePaths.GROUP_ACCIDENT_CLAIM_DATE,
    [PersonRoutePaths.GROUP_ACCIDENT_CLAIM_DATE]: PersonRoutePaths.GROUP_ACCIDENT_NATURE_TYPE_ID,
    [PersonRoutePaths.GROUP_ACCIDENT_NATURE_TYPE_ID]: PersonRoutePaths.GROUP_ACCIDENT_CLAIM_CAUSE,
    [PersonRoutePaths.GROUP_ACCIDENT_CLAIM_CAUSE]: PersonRoutePaths.GROUP_ACCIDENT_BODY_PARTS,
    [PersonRoutePaths.GROUP_ACCIDENT_BODY_PARTS]: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
    [PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE]:
        PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_EXPENSES]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_KELA,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE,
    },
    [PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_KELA]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_WHO_PAID_EXPENSES,
        [YesNoKeys.NO]: PersonRoutePaths.STOP,
    },
    [PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_WHO_PAID_EXPENSES]: PersonRoutePaths.GROUP_ACCIDENT_UPLOAD_RECEIPT,
    [PersonRoutePaths.GROUP_ACCIDENT_UPLOAD_RECEIPT]: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE,
    [PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: PersonRoutePaths.GROUP_ACCIDENT_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: PersonRoutePaths.GROUP_ACCIDENT_SITUATION_SUMMARY,
    },
    [PersonRoutePaths.GROUP_ACCIDENT_OTHER_INSURANCE_COMPANY]: PersonRoutePaths.GROUP_ACCIDENT_SITUATION_SUMMARY,
    [PersonRoutePaths.GROUP_ACCIDENT_SITUATION_SUMMARY]: PersonRoutePaths.GROUP_ACCIDENT_COMPANY_INFORMATION,
    [PersonRoutePaths.GROUP_ACCIDENT_COMPANY_INFORMATION]: PersonRoutePaths.GROUP_ACCIDENT_CLAIMANT_INFORMATION,
    [PersonRoutePaths.GROUP_ACCIDENT_CLAIMANT_INFORMATION]: PersonRoutePaths.GROUP_ACCIDENT_REPORTER_INFORMATION,
    [PersonRoutePaths.GROUP_ACCIDENT_REPORTER_INFORMATION]: PersonRoutePaths.GROUP_ACCIDENT_BANK_ACCOUNT_INFORMATION,
    [PersonRoutePaths.GROUP_ACCIDENT_BANK_ACCOUNT_INFORMATION]: PersonRoutePaths.GROUP_ACCIDENT_FINAL_SUMMARY,
    [PersonRoutePaths.GROUP_ACCIDENT_BANK_ACCOUNT_CONTACT_INFORMATION]: PersonRoutePaths.GROUP_ACCIDENT_FINAL_SUMMARY,
    [PersonRoutePaths.GROUP_ACCIDENT_FINAL_SUMMARY]: PersonRoutePaths.GROUP_ACCIDENT_REPORT_COMPLETED,

    // Workers Comp Routes
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_CATEGORY]: {
        [CategoryKeys.ACCIDENT]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CLAIMANT_ROLE,
        [CategoryKeys.ILLNESS]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CLAIMANT_ROLE,
    },

    // Workers Comp - Illness Routes
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CLAIMANT_ROLE]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_ONBOARDING,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_ONBOARDING]: PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIM_DATE,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIM_DATE]: PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTED_TO_EMPLOYER_DATE,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTED_TO_EMPLOYER_DATE]: PersonRoutePaths.WORKERS_COMP_ILLNESS_WORKPLACE,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_WORKPLACE]: PersonRoutePaths.WORKERS_COMP_ILLNESS_DAMAGE_ADDRESS,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_DAMAGE_ADDRESS]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_CAUSE,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_CAUSE]: {
        [TypeOfCauseEnums.PHYSICAL_FACTOR]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_PHYSICAL_FACTOR,
        [TypeOfCauseEnums.BIOLOGICAL_FACTOR]:
            PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_BIOLOGICAL_FACTOR,
        [TypeOfCauseEnums.CHEMICAL_FACTOR]: PersonRoutePaths.WORKERS_COMP_ILLNESS_TYPE_OF_CHEMICAL_FACTOR,
    },
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_PHYSICAL_FACTOR]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_TYPE_OF_CHEMICAL_FACTOR]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_BIOLOGICAL_FACTOR]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ILLNESS_INCAPACITY_PERIODS,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE,
        [YesNoKeys.DONT_KNOW]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE,
    },
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_INCAPACITY_PERIODS]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_DATE_OF_APPLIED_MEDICAL_CARE]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_EXPENSES]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_WHO_PAID_EXPENSES,
        [YesNoKeys.NO]: PersonRoutePaths.WORKERS_COMP_ILLNESS_SITUATION_SUMMARY,
        [YesNoKeys.DONT_KNOW]: PersonRoutePaths.WORKERS_COMP_ILLNESS_SITUATION_SUMMARY,
    },
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_WHO_PAID_EXPENSES]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_UPLOAD_RECEIPT,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_UPLOAD_RECEIPT]: PersonRoutePaths.WORKERS_COMP_ILLNESS_SITUATION_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_SITUATION_SUMMARY]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_COMPANY_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_COMPANY_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIMANT_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIMANT_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_INJURED_PROFESSION,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_INJURED_PROFESSION]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTER_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIMANT_POSITION]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_COMPANY_SHARES,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_COMPANY_SHARES]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ILLNESS_ENTREPRENEUR_OWNERSHIP,
        [YesNoKeys.NO]: PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTER_INFORMATION,
    },
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_ENTREPRENEUR_OWNERSHIP]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTER_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTER_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_POLICY_HOLDERS_CONTACT,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_POLICY_HOLDERS_CONTACT]: PersonRoutePaths.WORKERS_COMP_ILLNESS_FINAL_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_BANK_ACCOUNT_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_FINAL_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_BANK_ACCOUNT_CONTACT_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ILLNESS_FINAL_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ILLNESS_FINAL_SUMMARY]: PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORT_COMPLETED,

    // Workers Comp - Accident Routes
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CLAIMANT_ROLE]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_ONBOARDING,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_ONBOARDING]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_STUDIES,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_STUDIES]: {
        [TypeOfStudiesEnums.PRACTICAL]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION,
        [TypeOfStudiesEnums.THEORETICAL]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIM_DATE,
    },
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OUTSIDE_OCCUPATIONAL_CONDITION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OTHER_OCCUPATIONAL_CONDITION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_WORKPLACE,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_WORKPLACE]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIM_DATE,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIM_DATE]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_WORK_DAY,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_WORK_DAY]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_NATURE_TYPE_ID,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_NATURE_TYPE_ID]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_BODY_PARTS_LIST,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_BODY_PARTS_LIST]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_VEHICLE_INVOLVED,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_VEHICLE_INVOLVED]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_VEHICLE,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED,
    },
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_VEHICLE]: {
        [TypeOfVehicleEnums.CAR]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER,
        [TypeOfVehicleEnums.MOTORCYCLE]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER,
        [TypeOfVehicleEnums.BUS_TRAM_TRAIN]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED,
        [TypeOfVehicleEnums.OTHER]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED,
    },
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICE_DISTRICT,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE,
    },
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICE_DISTRICT]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE_CLAIM_DESCRIPTION,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CAUSED_INCAPACITY,
    },
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE_CLAIM_DESCRIPTION]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CAUSED_INCAPACITY,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CAUSED_INCAPACITY]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_PERIOD,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
        [YesNoKeys.DONT_KNOW]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
    },
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_MORE_THAN_SEVEN_DAYS]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
    },
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_PERIOD]: {
        [IncapacityPeriodEnums.INJURY_DAY]:
            PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
        [IncapacityPeriodEnums.ONE_OR_TWO_DAYS]:
            PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
        [IncapacityPeriodEnums.AT_LEAST_THREE_DAYS]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS,
    },
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_DATE_OF_APPLIED_MEDICAL_CARE,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES,
        [YesNoKeys.DONT_KNOW]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES,
    },
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_DATE_OF_APPLIED_MEDICAL_CARE]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_MEDICAL_INSTITUTION,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_MEDICAL_INSTITUTION]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES]: {
        [YesNoKeys.YES]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_WHO_PAID_EXPENSES,
        [YesNoKeys.NO]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE,
        [YesNoKeys.DONT_KNOW]: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE,
    },
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_WHO_PAID_EXPENSES]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_UPLOAD_RECEIPT,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_UPLOAD_RECEIPT]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_SITUATION_SUMMARY,
    },
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_OTHER_INSURANCE_COMPANY]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_SITUATION_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_SITUATION_SUMMARY]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_COMPANY_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_COMPANY_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_INJURED_PROFESSION,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_INJURED_PROFESSION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTER_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_POSITION]:
        PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_COMPANY_SHARES,
    [PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_COMPANY_SHARES]: {
        [YesNoKeys.YES]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_ENTREPRENEUR_OWNERSHIP,
        [YesNoKeys.NO]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTER_INFORMATION,
    },
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_ENTREPRENEUR_OWNERSHIP]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTER_INFORMATION,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTER_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICY_HOLDERS_CONTACT,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICY_HOLDERS_CONTACT]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_FINAL_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_FINAL_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_CONTACT_INFORMATION]:
        PersonRoutePaths.WORKERS_COMP_ACCIDENT_FINAL_SUMMARY,
    [PersonRoutePaths.WORKERS_COMP_ACCIDENT_FINAL_SUMMARY]: PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORT_COMPLETED,
};
