import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { CLAIM_NUMBER_REGEX } from '../../utils/validation/stringFormats';

export function claimNumber(this: StringSchema, message: string = yupCustomLocale.string.claimNumber) {
    // @ts-ignore
    return this.test('claimNumber', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return !!value.match(CLAIM_NUMBER_REGEX);
    });
}
