import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectClaimCause, selectPoliceCaseNumber } from '../../../sagas/selectors/motorSelectors';
import {
    emptyFn,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MotorClaimCauseKeys,
    MuiTextInput,
    PageLayout,
    PoliceCaseNumberModel,
} from '@protectorinsurance/ds-can';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import { FormFieldNames } from '../../../config/formFieldNames';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    POLICE_CASE_NUMBER_LABEL,
    POLICE_CASE_NUMBER_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { POLICE_CASE_NUMBER } = FormFieldNames;
const { PARKING } = MotorClaimCauseKeys;
const { END_UPLOAD_ATTACHMENT } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const PoliceCaseNumberPage = () => {
    const dispatch = useDispatch();
    const policeCaseNumber = useSelector(selectPoliceCaseNumber);
    const claimCause = useSelector(selectClaimCause);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.policeCaseNumber');
    const { control, handleSubmit, setValue } = useForm<PoliceCaseNumberModel>({
        defaultValues: {
            policeCaseNumber,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = emptyFn;

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ policeCaseNumber }: PoliceCaseNumberModel) => {
        let nextAction = wizardActions.goToNext();
        if (claimCause === PARKING) {
            nextAction = wizardActions.goTo(END_UPLOAD_ATTACHMENT);
        }

        dispatcherWithPromise(dispatch, motorActions.update, { policeCaseNumber })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={POLICE_CASE_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                id={POLICE_CASE_NUMBER}
                                inputFieldWrapper={'col-12'}
                                label={t(POLICE_CASE_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(POLICE_CASE_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
