import { TFunction } from 'i18next';
import { MissingItemsKeys, OptionType } from '@protectorinsurance/ds-can';

const { EXTRA_LIGHTS, LICENSE_PLATE, STEERING_WHEEL, WHEELS, OTHER } = MissingItemsKeys;
const createMissingItem = (id: string) => {
    return `motor.parked.missingItems.select.${id}`;
};

export const getMissingItemsOptions = (t: TFunction): OptionType[] => {
    return [
        { value: EXTRA_LIGHTS, label: t(createMissingItem(EXTRA_LIGHTS)) },
        { value: LICENSE_PLATE, label: t(createMissingItem(LICENSE_PLATE)) },
        { value: STEERING_WHEEL, label: t(createMissingItem(STEERING_WHEEL)) },
        { value: WHEELS, label: t(createMissingItem(WHEELS)) },
        { value: OTHER, label: t(createMissingItem(OTHER)) },
    ];
};
