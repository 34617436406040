import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { certificateActions } from './certificate';
import {
    actionWithPromise,
    emptyFn,
    FileModel,
    FileStatusEnums,
    PFRequestError,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { api } from 'utils/api';
import { select } from 'typed-redux-saga';
import { selectRequestId } from '../../selectors/commonSelectors';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum PollCertificateActionTypes {
    REQUEST = '@app/upload/certificate/poll/REQUEST',
    SUCCESS = '@app/upload/certificate/poll/SUCCESS',
    FAILURE = '@app/upload/certificate/poll/FAILURE',
}

/**
 * Action Definitions
 */
export interface IPollCertificateAction {
    type: PollCertificateActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const pollCertificateActions = {
    request: actionWithPromise(PollCertificateActionTypes.REQUEST),
    success: actionWithPromise(PollCertificateActionTypes.SUCCESS),
    failure: actionWithPromise(PollCertificateActionTypes.FAILURE),
};

/**
 * Saga functions
 */
export const pollCertificateSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: IPollCertificateAction) {
        let i = 0;
        const maxIterations = 20;
        const requestId = yield* select(selectRequestId);
        for (i; i < maxIterations; i++) {
            yield delay(1000);
            try {
                if (data.externalId) {
                    const res: AxiosResponse = yield call(api.get, `upload/${data.externalId}/meta`, {
                        baseURL: NODE_API_BASE_URL,
                        headers: { 'X-Request-Id': `${requestId}` },
                    });
                    const status: string = res.data.data.scanResult.status;

                    if (status === FileStatusEnums.PASSED) {
                        yield put(certificateActions.update({ ...data, status }));
                        yield put(pollCertificateActions.success());
                        resolve();
                        break;
                    }
                }
            } catch (e) {
                yield put(pollCertificateActions.failure((e as any).response.data));
                reject();
            }

            yield delay(1000);
        }

        // Handle if file has not passed after max iterations
        if (i === maxIterations && data) {
            const error: PFRequestError = {
                message: '500',
                name: '500',
                status: 500,
                statusText: '500',
                url: data && data.externalId,
            };

            const file = {
                ...data,
                status: FileStatusEnums.ERROR,
                errors: [
                    ...data.errors,
                    {
                        type: PollCertificateActionTypes.FAILURE,
                        error,
                    },
                ],
            };

            yield put(certificateActions.update(file));
            yield put(pollCertificateActions.failure(error));
        }
    },
};

/**
 * Saga watchers
 */
export const pollCertificateWatchers = function* () {
    yield takeEvery(PollCertificateActionTypes.REQUEST, pollCertificateSagas.request);
};
