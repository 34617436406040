import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';

// Dynamic Imports
import { DynCarouselPage } from '../pages/DynCarouselPage';

// Start Imports
import { StartOnboardingPage } from '../pages/lpo/start/Onboarding';
import { StartPrivacyPage } from '../pages/lpo/start/Privacy';
import { StartClaimDatePage } from '../pages/lpo/start/ClaimDate';
import { StartAccidentLocationPage } from '../pages/lpo/start/AccidentLocation';
import { StartPoliceCaseNumberPage } from '../pages/lpo/start/PoliceCaseNumber';
import { StartClaimDescriptionPage } from '../pages/lpo/start/ClaimDescription';

// Property Imports
import { PropertyPropertyAddressPage } from '../pages/lpo/property/PropertyAddress';

// Travel Imports
import { TravelTravelInterruptionPage } from '../pages/lpo/travel/TravelInterruption';
import { TravelClaimantInformationPage } from '../pages/lpo/travel/ClaimantInformation';

// End Imports
import { EndUploadAttachmentPage } from '../pages/lpo/end/UploadAttachment';
import { EndOtherInsuranceCompanyPage } from '../pages/lpo/end/OtherInsuranceCompany';
import { EndPolicyHolderPage } from '../pages/lpo/end/PolicyHolder';
import { EndReporterInformationPage } from '../pages/lpo/end/ReporterInformation';
import { EndPolicyHoldersContactPage } from '../pages/lpo/end/PolicyHoldersContact';
import { EndBankAccountInformationPage } from '../pages/lpo/end/BankAccountInformation';
import { EndFinalSummaryPage } from '../pages/lpo/end/FinalSummary';
import { EndReportCompletedPage } from '../pages/lpo/end/ReportCompleted';
import { TravelTravelDatesPage } from '../pages/lpo/travel/TravelDates';

export const Lpo: FC = () => {
    return (
        <Switch>
            {/* DYN */}
            <Route exact path={LpoRoutePaths.DYN_CAROUSEL} component={DynCarouselPage} />

            {/* START */}
            <Route exact path={LpoRoutePaths.INDEX} component={StartOnboardingPage} />
            <Route exact path={LpoRoutePaths.START_ONBOARDING} component={StartOnboardingPage} />
            <Route exact path={LpoRoutePaths.START_PRIVACY} component={StartPrivacyPage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_DATE} component={StartClaimDatePage} />
            <Route exact path={LpoRoutePaths.START_ACCIDENT_LOCATION} component={StartAccidentLocationPage} />
            <Route exact path={LpoRoutePaths.START_POLICE_CASE_NUMBER} component={StartPoliceCaseNumberPage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_DESCRIPTION} component={StartClaimDescriptionPage} />

            {/* PROPERTY */}
            <Route exact path={LpoRoutePaths.PROPERTY_PROPERTY_ADDRESS} component={PropertyPropertyAddressPage} />

            {/* TRAVEL */}
            <Route exact path={LpoRoutePaths.TRAVEL_TRAVEL_DATES} component={TravelTravelDatesPage} />
            <Route exact path={LpoRoutePaths.TRAVEL_TRAVEL_INTERRUPTION} component={TravelTravelInterruptionPage} />
            <Route exact path={LpoRoutePaths.TRAVEL_CLAIMANT_INFORMATION} component={TravelClaimantInformationPage} />

            {/* END */}
            <Route exact path={LpoRoutePaths.END_UPLOAD_ATTACHMENT} component={EndUploadAttachmentPage} />
            <Route exact path={LpoRoutePaths.END_OTHER_INSURANCE_COMPANY} component={EndOtherInsuranceCompanyPage} />
            <Route exact path={LpoRoutePaths.END_POLICY_HOLDER} component={EndPolicyHolderPage} />
            <Route exact path={LpoRoutePaths.END_REPORTER_INFORMATION} component={EndReporterInformationPage} />
            <Route exact path={LpoRoutePaths.END_POLICY_HOLDERS_CONTACT} component={EndPolicyHoldersContactPage} />
            <Route exact path={LpoRoutePaths.END_BANK_ACCOUNT_INFORMATION} component={EndBankAccountInformationPage} />
            <Route exact path={LpoRoutePaths.END_FINAL_SUMMARY} component={EndFinalSummaryPage} />
            <Route exact path={LpoRoutePaths.END_REPORT_COMPLETED} component={EndReportCompletedPage} />
        </Switch>
    );
};
