import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    HeadOnCollisionIcon,
    LaneChangeIcon,
    MotorClaimCauseKeys,
    ParkedIcon,
    RearEndedIcon,
    ReversingIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { selectClaimCause, selectClaimCauseList } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../../sagas/common';
import { claimCauseListUtil } from '../../../../../../utils/claimCauseListUtils';
import { flowActions } from '../../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { CYCLIST_COLLISION, HEAD_ON, LANE_CHANGE, PARKED, REAR_ENDED, REVERSING } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const parkingOtherClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        {
            id: REAR_ENDED,
            name: t(`motor.driving.parkingOtherClaimCause.select.${REAR_ENDED}`),
            icon: <RearEndedIcon />,
        },
        {
            id: HEAD_ON,
            name: t(`motor.driving.parkingOtherClaimCause.select.${HEAD_ON}`),
            icon: <HeadOnCollisionIcon />,
        },
        { id: PARKED, name: t(`motor.driving.parkingOtherClaimCause.select.${PARKED}`), icon: <ParkedIcon /> },
        {
            id: LANE_CHANGE,
            name: t(`motor.driving.parkingOtherClaimCause.select.${LANE_CHANGE}`),
            icon: <LaneChangeIcon />,
        },
        { id: REVERSING, name: t(`motor.driving.parkingOtherClaimCause.select.${REVERSING}`), icon: <ReversingIcon /> },
    ];

    const claimCause = selectClaimCause(state);
    const claimCauseList = selectClaimCauseList(state);

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_PARKING_OTHER_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.driving.parkingOtherClaimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (option.id !== claimCause) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                const claimCausesList = [...claimCauseList];
                dispatcherWithPromise(dispatch, motorActions.update, {
                    claimCauseList: claimCauseListUtil(
                        claimCausesList,
                        MotorRoutePaths.DYN_CAROUSEL_PARKING_OTHER_CLAIM_CAUSE,
                        option.id
                    ),
                    flow: option.id,
                    ...(claimCause !== CYCLIST_COLLISION && { claimCause: option.id }),
                });

                dispatcherWithPromise(dispatch, flowActions.update, option.id)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
        isSelected: (id) => {
            if (id === CYCLIST_COLLISION) {
                return !!claimCauseList.find((x) => x.claimCause === id);
            }

            return id === claimCause;
        },
        testInitState: (key) => ({
            claimCauseList: [{ claimCause: key }],
            claimCause: key,
            flow: key,
        }),
    };
};
