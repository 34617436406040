import React from 'react';
import { useI18n } from '../../hooks/useI18n';
import { useHistory, useLocation } from 'react-router-dom';
import './LanguageSwitcher.scss';
import {
    CountryCodeISOEnums,
    LanguageCodeEnums,
    LocaleServiceInstance,
    MomentLocaleEnums,
} from '@protectorinsurance/ds-can';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../sagas/common';

export const LanguageSwitcher = () => {
    /**
     * Hooks
     */
    const { t, i18n } = useI18n();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    /**
     * Action handlers
     */
    const handleSwitchLanguage = (language: LanguageCodeEnums) => {
        const [slash, country, , ...rest] = location.pathname.split('/');
        const path = [slash, country, language, ...rest].join('/');

        let i18nLanguage: LanguageCodeEnums | string = language;

        if (language === LanguageCodeEnums.SE) {
            i18nLanguage = 'sv';
        }

        // Change language in i18n
        i18n.changeLanguage(i18nLanguage);

        // Change language on LocaleServiceInstance
        LocaleServiceInstance.setLanguage(language).setMoment(
            language === LanguageCodeEnums.FI ? MomentLocaleEnums.FI : MomentLocaleEnums.EN
        );

        // Update in store
        dispatch(
            commonActions.update({
                locale: {
                    language: language,
                    country: CountryCodeISOEnums.FINLAND,
                },
            })
        );

        // Change url
        history.push(path);
    };

    const currentLocale = LocaleServiceInstance.getLocale();
    const toggleEnglishLocale = currentLocale === LanguageCodeEnums.EN ? LanguageCodeEnums.FI : LanguageCodeEnums.EN;
    const toggleSwedishLocale = currentLocale === LanguageCodeEnums.SE ? LanguageCodeEnums.FI : LanguageCodeEnums.SE;

    /**
     * Render functions
     */
    return (
        <div className={'link-container'}>
            <button onClick={() => handleSwitchLanguage(toggleEnglishLocale)} lang={toggleEnglishLocale}>
                {t(`footer.languageSwitcher.${toggleEnglishLocale.toUpperCase()}`)}
            </button>
            <button onClick={() => handleSwitchLanguage(toggleSwedishLocale)} lang={toggleSwedishLocale}>
                {t(`footer.languageSwitcher.${toggleSwedishLocale.toUpperCase()}`)}
            </button>
        </div>
    );
};
