import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { registrationNumberSchema } from '../fieldSchemas/registrationNumberSchema';

/**
 * Validation logic
 */
export const vehicleRegistrationNumberSchema = (t: TFunction): Yup.ObjectSchema => {
    return Yup.object().shape({
        ...registrationNumberSchema(t),
    });
};
