import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { BANK_ACCOUNT_NUMBER_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const bankAccountNumberSchema = (t: TFunction, required: boolean) => {
    return {
        bankAccountNumber: Yup.string().label(t(BANK_ACCOUNT_NUMBER_LABEL)).bankAccountNumber(required).nullable(),
    };
};
