import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    CrushInjuryIcon,
    HearingAidIcon,
    OdCauseEnums,
    OtherInjuriesIcon,
    PressureIcon,
    RadiationIcon,
    TemperatureInjuryIcon,
    VibrationIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectOdCause } from '../../../../../sagas/selectors/personSelectors';
import { createOdCauseKey, getOdCauseOption } from '../../../../../utils/person/odCauseUtils';

/**
 * Destructure necessary imports
 */
const {
    COMPRESSION,
    NOISE,
    OTHER_PHYSICAL_FACTOR,
    PRESSURE,
    RADIATION,
    REPEATED_ABNORMAL_THERMAL_LOAD,
    VIBRATION,
} = OdCauseEnums;

/**
 * Page view and page logic
 */
export const workersCompIllnessPhysicalFactorPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: COMPRESSION,
            name: t(createOdCauseKey(COMPRESSION)),
            icon: <CrushInjuryIcon />,
        },
        {
            id: VIBRATION,
            name: t(createOdCauseKey(VIBRATION)),
            icon: <VibrationIcon />,
        },
        {
            id: NOISE,
            name: t(createOdCauseKey(NOISE)),
            icon: <HearingAidIcon />,
        },
        {
            id: PRESSURE,
            name: t(createOdCauseKey(PRESSURE)),
            icon: <PressureIcon />,
        },
        {
            id: RADIATION,
            name: t(createOdCauseKey(RADIATION)),
            icon: <RadiationIcon />,
        },
        {
            id: REPEATED_ABNORMAL_THERMAL_LOAD,
            name: t(createOdCauseKey(REPEATED_ABNORMAL_THERMAL_LOAD)),
            icon: <TemperatureInjuryIcon />,
        },
        {
            id: OTHER_PHYSICAL_FACTOR,
            name: t(createOdCauseKey(OTHER_PHYSICAL_FACTOR)),
            icon: <OtherInjuriesIcon />,
        },
    ];

    // Numbers are set based on what Backend needs for mapping
    const compressionOption = getOdCauseOption(t, COMPRESSION, '548130');
    const noiseOption = getOdCauseOption(t, NOISE, '548125');
    const otherPhysicalFactorOption = getOdCauseOption(t, OTHER_PHYSICAL_FACTOR, '548132');
    const pressureOption = getOdCauseOption(t, PRESSURE, '548126');
    const radiationOption = getOdCauseOption(t, RADIATION, '548129');
    const repeatedAbnormalThermalLoadOption = getOdCauseOption(t, REPEATED_ABNORMAL_THERMAL_LOAD, '548131');
    const vibrationOption = getOdCauseOption(t, VIBRATION, '548124');

    const values = [
        compressionOption,
        vibrationOption,
        noiseOption,
        pressureOption,
        radiationOption,
        repeatedAbnormalThermalLoadOption,
        otherPhysicalFactorOption,
    ];

    const getValues = (option: string) => {
        const selected = values.find((x) => x.label === option);
        if (selected) {
            return { id: Number(selected.value), propertyId: 46, key: selected.label };
        }
        return null;
    };

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_PHYSICAL_FACTOR,
        stateKey: 'odCause',
        i18n: {
            ns: 'lob.person.workersComp.illness.physicalFactor',
        },
        selectorValue: selectOdCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { odCause: getValues(option.name) })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
