import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { ICD_REGEX } from '../../utils/validation/stringFormats';

export function icd(this: StringSchema, key: string, message: string = yupCustomLocale.string.icd) {
    // @ts-ignore
    return this.test('icd', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return !!value.match(ICD_REGEX);
    });
}
