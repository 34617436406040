import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    EmployeesHomeRemoteIcon,
    is,
    OccupationalConditionEnums,
    WorkingAreaOtherPremisesIcon,
    WorkingAreaWorkingProcessMeetingIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { selectOccupationalCondition } from '../../../../../sagas/selectors/personSelectors';
import { createOccupationalConditionKey } from '../../../../../utils/person/occupationalConditionUtils';

/**
 * Destructure necessary imports
 */
const { EMPLOYEE_HOUSE, OTHER_PREMISES, WORKING_AREA } = OccupationalConditionEnums;
const { WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const workersCompAccidentOutsideOccupationalConditionPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: WORKING_AREA,
            name: t(createOccupationalConditionKey(WORKING_AREA)),
            icon: <WorkingAreaWorkingProcessMeetingIcon />,
        },
        {
            id: EMPLOYEE_HOUSE,
            name: t(createOccupationalConditionKey(EMPLOYEE_HOUSE)),
            icon: <EmployeesHomeRemoteIcon />,
        },
        {
            id: OTHER_PREMISES,
            name: t(createOccupationalConditionKey(OTHER_PREMISES)),
            icon: <WorkingAreaOtherPremisesIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OUTSIDE_OCCUPATIONAL_CONDITION,
        stateKey: 'occupationalCondition',
        i18n: {
            ns: 'lob.person.workersComp.accident.occupationalCondition',
        },
        selectorValue: selectOccupationalCondition(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext();
                if (is(option.id, OTHER_PREMISES)) {
                    nextAction = wizardActions.goTo(WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS);
                }
                dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
            }
        },
    };
};
