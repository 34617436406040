import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { AppState } from './index';
import flow, { FlowAction, FlowActionTypes } from '../sagas/flow';
import wizardRouter, { WizardRouterAction, WizardRouterActionTypes } from '../sagas/wizardRouter';
import person, { IPersonAction, PersonActionTypes } from '../sagas/person';
import common, { CommonActionTypes, ICommonAction } from '../sagas/common';
import upload, { IUploadActions, UploadActionTypes } from '../sagas/upload/index';
import errors, { IErrorsAction, ErrorsActionTypes } from '../sagas/errors';
import { ILoadersAction } from '../sagas/loaders';
import motor, { IMotorAction, MotorActionTypes } from '../sagas/motor';
import services, { ServicesActions, ServicesActionTypes } from '../sagas/services';
import lpo, { LpoAction, LpoActionTypes } from '../sagas/lpo';

export type AppActions =
    | FlowAction
    | WizardRouterAction
    | IMotorAction
    | IPersonAction
    | LpoAction
    | ICommonAction
    | IUploadActions
    | ServicesActions;

export type AppActionTypes =
    | FlowActionTypes
    | WizardRouterActionTypes
    | MotorActionTypes
    | PersonActionTypes
    | LpoActionTypes
    | UploadActionTypes
    | CommonActionTypes
    | ServicesActionTypes
    | ErrorsActionTypes;

export type AppActionsWithPromise = ICommonAction | ILoadersAction | IErrorsAction;

export default (state: AppState, action: AppActions, history: History): Reducer<AppState, AppActions> => {
    return combineReducers<any, any>({
        router: connectRouter(history),
        flow,
        errors,
        wizardRouter,
        upload,
        motor,
        person,
        lpo,
        common,
        services,
    })(state, action);
};
