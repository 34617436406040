import React from 'react';
import {
    CarouselConfigLookupResponse,
    CirculatingRoundaboutIcon,
    EnteringRoundaboutIcon,
    ExitingRoundaboutIcon,
    RoundaboutPositionKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { selectClaimantRoundaboutPositioning } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { CIRCULATING, ENTERING, EXITING } = RoundaboutPositionKeys;

/**
 * Page view and page logic
 */
export const claimantRoundaboutPositioningPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        {
            id: ENTERING,
            name: t(`motor.driving.claimantRoundaboutPositioning.select.${ENTERING}`),
            icon: <EnteringRoundaboutIcon />,
        },
        {
            id: CIRCULATING,
            name: t(`motor.driving.claimantRoundaboutPositioning.select.${CIRCULATING}`),
            icon: <CirculatingRoundaboutIcon />,
        },
        {
            id: EXITING,
            name: t(`motor.driving.claimantRoundaboutPositioning.select.${EXITING}`),
            icon: <ExitingRoundaboutIcon />,
        },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING,
        stateKey: 'claimantRoundaboutPositioning',
        i18n: {
            ns: 'motor.driving.claimantRoundaboutPositioning',
        },
        selectorValue: selectClaimantRoundaboutPositioning(state),
        options,
    };
};
