import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectBodyPartsList } from '../../../sagas/selectors/personSelectors';
import { personActions } from '../../../sagas/person';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    BodyPartListBackImage,
    BodyPartListFrontImage,
    bodyPartListIds,
    BodyPartListKeys,
    BodyPartListModel,
    BodyPartListTypeModel,
    Clickable,
    emptyFn,
    Formable,
    isBodyPartChecked,
    PageLayout,
    PartCheckboxGroup,
    SVGClickable,
    updateSelectablePartsList,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import Tabs from '../../../components/tabs/Tabs';
import TabPanel from '../../../components/tabs/TabPanel';
import { CheckboxInput } from '../../../components/inputs/CheckboxInput';
import { selectLocaleLanguageCode } from 'sagas/selectors/commonSelectors';
import { getLabelDirection } from '../../../utils/person/bodyPartsListUtils';

enum TabKeys {
    FRONT = 'FRONT',
    BACK = 'BACK',
}

/**
 * Destructure necessary imports
 */
const {
    ARM_LEFT,
    ARM_RIGHT,
    FOOT_LEFT_LEGACY,
    FOOT_RIGHT_LEGACY,
    HAND_LEFT_LEGACY,
    HAND_RIGHT_LEGACY,
    HEAD_BACK,
    HEAD_FRONT,
    LEG_LEFT,
    LEG_RIGHT,
    TORSO_FRONT_LEGACY,
    TORSO_BACK_LEGACY,
    WHOLE_BODY,
} = BodyPartListKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const GroupAccidentBodyPartsListPage = () => {
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.groupAccident.bodyParts');
    const dispatch = useDispatch();
    const bodyPartsList = useSelector(selectBodyPartsList);
    const language = useSelector(selectLocaleLanguageCode);
    const { register } = useForm<BodyPartListTypeModel>({});
    const partsFootLabel = 'parts.FOOT.label';
    const partsLegLabel = 'parts.LEG.label';
    const partsHandLabel = 'parts.HAND.label';
    const partsArmLabel = 'parts.ARM.label';
    const labels = getLabelDirection(language);

    /**
     * Internal helper functions
     */
    const updateBodyParts = (key: BodyPartListKeys, id: number, selected: boolean) => {
        const parts = updateSelectablePartsList(key, id, bodyPartsList, selected);

        dispatch(personActions.update({ bodyPartList: parts }));
    };

    const isDisabled = isBodyPartChecked(WHOLE_BODY, bodyPartsList);
    const tCheckboxLabel = (direction: string, label: string) => `${t(direction)} ${tWithNS.t(label)}`;

    /**
     * Handle functions
     */
    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleChange = (e: Formable) => {
        const { name, checked } = e.currentTarget;
        const bodyPart = bodyPartListIds.find((bp: { id: number; key: BodyPartListKeys }) => bp.key === name);

        if (bodyPart) {
            updateBodyParts(name as BodyPartListKeys, bodyPart.id, checked);
        }
    };

    const handleBlur = emptyFn;

    const handleSvgClick = (e: SVGClickable) => {
        const name = e.currentTarget.id as BodyPartListKeys;
        const bodyPart = bodyPartListIds.find((bp: { id: number; key: BodyPartListKeys }) => bp.key === name);

        if (bodyPart) {
            const checked = bodyPartsList.some((bp: BodyPartListModel) => bp.key === name);
            updateBodyParts(name, bodyPart.id, !checked);
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={'page__body-parts'}
            {...{ handleBackButton, handleContinueButton }}
        >
            <div className={'tabs__container mt-5'}>
                <Tabs
                    label={tWithNS.t('tabs.label')}
                    tabs={[
                        { id: TabKeys.FRONT, label: tWithNS.t('tabs.tab.front.label') },
                        { id: TabKeys.BACK, label: tWithNS.t('tabs.tab.back.label') },
                    ]}
                >
                    <TabPanel tabId={TabKeys.FRONT}>
                        <div className="body-parts space-x-5 mt-5">
                            <div className={'body-parts__image'}>
                                <div className={'wrapper'}>
                                    <BodyPartListFrontImage onClick={handleSvgClick} selectedParts={bodyPartsList} />
                                    <div className="footer">
                                        <span>{tWithNS.t('image.footer.right')}</span>
                                        <span>{tWithNS.t('image.footer.left')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'body-parts__content'}>
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.WHOLE_BODY.description')}
                                    label={tWithNS.t('parts.WHOLE_BODY.label')}
                                    labelFor={WHOLE_BODY}
                                >
                                    <CheckboxInput
                                        id={WHOLE_BODY}
                                        isChecked={isBodyPartChecked(WHOLE_BODY, bodyPartsList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                <h2>{tWithNS.t('parts.heading.upperBody')}</h2>

                                {/* HEAD_FRONT */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HEAD_FRONT.description')}
                                    label={tWithNS.t('parts.HEAD_FRONT.label')}
                                    labelFor={HEAD_FRONT}
                                >
                                    <CheckboxInput
                                        id={HEAD_FRONT}
                                        isChecked={isBodyPartChecked(HEAD_FRONT, bodyPartsList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* TORSO_FRONT */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TORSO_FRONT.description')}
                                    label={tWithNS.t('parts.TORSO_FRONT.label')}
                                    labelFor={TORSO_FRONT_LEGACY}
                                >
                                    <CheckboxInput
                                        id={TORSO_FRONT_LEGACY}
                                        isChecked={isBodyPartChecked(TORSO_FRONT_LEGACY, bodyPartsList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* ARM */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.ARM.description')}
                                    label={tWithNS.t(partsArmLabel)}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={ARM_LEFT}
                                        isChecked={isBodyPartChecked(ARM_LEFT, bodyPartsList)}
                                        label={tCheckboxLabel(labels.left, partsArmLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={ARM_RIGHT}
                                        isChecked={isBodyPartChecked(ARM_RIGHT, bodyPartsList)}
                                        label={tCheckboxLabel(labels.right, partsArmLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* HAND */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HAND.description')}
                                    label={tWithNS.t(partsHandLabel)}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={HAND_LEFT_LEGACY}
                                        isChecked={isBodyPartChecked(HAND_LEFT_LEGACY, bodyPartsList)}
                                        label={tCheckboxLabel(labels.left, partsHandLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={HAND_RIGHT_LEGACY}
                                        isChecked={isBodyPartChecked(HAND_RIGHT_LEGACY, bodyPartsList)}
                                        label={tCheckboxLabel(labels.right, partsHandLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                <h2>{tWithNS.t('parts.heading.lowerBody')}</h2>

                                {/* LEG */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.LEG.description')}
                                    label={tWithNS.t(partsLegLabel)}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={LEG_LEFT}
                                        isChecked={isBodyPartChecked(LEG_LEFT, bodyPartsList)}
                                        label={tCheckboxLabel(labels.left, partsLegLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={LEG_RIGHT}
                                        isChecked={isBodyPartChecked(LEG_RIGHT, bodyPartsList)}
                                        label={tCheckboxLabel(labels.right, partsLegLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>

                                {/* FOOT */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.FOOT.description')}
                                    label={tWithNS.t(partsFootLabel)}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={FOOT_LEFT_LEGACY}
                                        isChecked={isBodyPartChecked(FOOT_LEFT_LEGACY, bodyPartsList)}
                                        label={tCheckboxLabel(labels.left, partsFootLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />

                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={FOOT_RIGHT_LEGACY}
                                        isChecked={isBodyPartChecked(FOOT_RIGHT_LEGACY, bodyPartsList)}
                                        label={tCheckboxLabel(labels.right, partsFootLabel)}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange, isDisabled }}
                                    />
                                </PartCheckboxGroup>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel tabId={TabKeys.BACK}>
                        <div className="body-parts space-x-5 mt-5">
                            <div className={'body-parts__image'}>
                                <div className={'wrapper'}>
                                    <BodyPartListBackImage onClick={handleSvgClick} selectedParts={bodyPartsList} />

                                    <div className="footer">
                                        <span>{tWithNS.t('image.footer.left')}</span>
                                        <span>{tWithNS.t('image.footer.right')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'body-parts__content'}>
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HEAD_BACK.description')}
                                    label={tWithNS.t('parts.HEAD_BACK.label')}
                                    labelFor={HEAD_BACK}
                                >
                                    <CheckboxInput
                                        id={HEAD_BACK}
                                        isChecked={isBodyPartChecked(HEAD_BACK, bodyPartsList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TORSO_BACK.description')}
                                    label={tWithNS.t('parts.TORSO_BACK.label')}
                                    labelFor={TORSO_BACK_LEGACY}
                                >
                                    <CheckboxInput
                                        id={TORSO_BACK_LEGACY}
                                        isChecked={isBodyPartChecked(TORSO_BACK_LEGACY, bodyPartsList)}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </PageLayout>
    );
};
