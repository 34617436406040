import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    BiologicalFactorIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ChemicalFactorIcon,
    Clickable,
    PhysicalFactorIcon,
    TypeOfCauseEnums,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectTypeOfCause } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { BIOLOGICAL_FACTOR, CHEMICAL_FACTOR, PHYSICAL_FACTOR } = TypeOfCauseEnums;

/**
 * Page view and page logic
 */
export const workersCompIllnessTypeOfCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: PHYSICAL_FACTOR,
            name: t(`lob.person.workersComp.illness.typeOfCause.select.${PHYSICAL_FACTOR}`),
            icon: <PhysicalFactorIcon />,
        },
        {
            id: CHEMICAL_FACTOR,
            name: t(`lob.person.workersComp.illness.typeOfCause.select.${CHEMICAL_FACTOR}`),
            icon: <ChemicalFactorIcon />,
        },
        {
            id: BIOLOGICAL_FACTOR,
            name: t(`lob.person.workersComp.illness.typeOfCause.select.${BIOLOGICAL_FACTOR}`),
            icon: <BiologicalFactorIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_CAUSE,
        stateKey: 'typeOfCause',
        i18n: {
            ns: 'lob.person.workersComp.illness.typeOfCause',
        },
        selectorValue: selectTypeOfCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { typeOfCause: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
