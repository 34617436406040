import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    CyclistIcon,
    DitchIcon,
    EngineDamageIcon,
    FlowKeys,
    MotorClaimCauseKeys,
    OtherInjuriesIcon,
    PedestrianIcon,
    StationaryObjectIcon,
    WildAnimalIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { selectClaimCause, selectClaimCauseList } from '../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../sagas/common';
import { claimCauseListUtil } from '../../../../../utils/claimCauseListUtils';
import { flowActions } from '../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const {
    ANIMAL_COLLISION,
    CYCLIST_COLLISION,
    DITCH_COLLISION,
    OTHER,
    PEDESTRIAN_COLLISION,
    STATIONARY_OBJECT_COLLISION,
    ENGINE,
} = MotorClaimCauseKeys;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const noVehicleClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        {
            id: ANIMAL_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${ANIMAL_COLLISION}`),
            icon: <WildAnimalIcon />,
        },
        {
            id: STATIONARY_OBJECT_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${STATIONARY_OBJECT_COLLISION}`),
            icon: <StationaryObjectIcon />,
        },
        {
            id: CYCLIST_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${CYCLIST_COLLISION}`),
            icon: <CyclistIcon />,
        },
        {
            id: DITCH_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${DITCH_COLLISION}`),
            icon: <DitchIcon />,
        },
        { id: ENGINE, name: t(`motor.driving.noVehicleClaimCause.select.${ENGINE}`), icon: <EngineDamageIcon /> },
        {
            id: PEDESTRIAN_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${PEDESTRIAN_COLLISION}`),
            icon: <PedestrianIcon />,
        },
        { id: OTHER, name: t(`motor.driving.noVehicleClaimCause.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    const claimCause = selectClaimCause(state);

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_NO_VEHICLE_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.driving.noVehicleClaimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let flowOption: MotorClaimCauseKeys | FlowKeys = option.id;
                if (option.id !== claimCause) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (
                    option.id === STATIONARY_OBJECT_COLLISION ||
                    option.id === DITCH_COLLISION ||
                    option.id === PEDESTRIAN_COLLISION ||
                    option.id === OTHER
                ) {
                    flowOption = END;
                }

                const claimCausesList = [...selectClaimCauseList(state)];
                dispatcherWithPromise(dispatch, motorActions.update, {
                    claimCauseList: claimCauseListUtil(
                        claimCausesList,
                        MotorRoutePaths.DYN_CAROUSEL_NO_VEHICLE_CLAIM_CAUSE,
                        option.id
                    ),
                });

                dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id, flow: flowOption })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, flowOption))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
        testInitState: (key) => ({
            claimCauseList: [{ claimCause: key }],
            claimCause: key,
            flow: key,
        }),
    };
};
