import React from 'react';
import { is, LobKeys, LocationModel, Nullable, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { PROPERTY } = LobKeys;

/**
 * Component view and component logic
 */
export const renderUnit = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    accidentLocation: LocationModel,
    lob: Nullable<LobKeys>
) => {
    if (!is(accidentLocation.unit, null) && is(lob, PROPERTY)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
