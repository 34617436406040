import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    OtherMissingItemsClaimDescriptionModel,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { motorActions } from '../../../../sagas/motor';
import { Controller, useForm } from 'react-hook-form';
import { selectOtherMissingItemsClaimDescription } from '../../../../sagas/selectors/motorSelectors';
import { otherMissingItemsClaimDescriptionSchema } from '../../../../validations/schemas/otherMissingItemsClaimDescriptionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    OTHER_MISSING_ITEMS_LABEL,
    OTHER_MISSING_ITEMS_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { OTHER_MISSING_ITEMS_CLAIM_DESCRIPTION } = FormFieldNames;

/**
 * Page view and page logic
 */
export const PTOtherMissingItemsPage = () => {
    const dispatch = useDispatch();
    const otherMissingItemsClaimDescription = useSelector(selectOtherMissingItemsClaimDescription);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.otherMissingItems');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<OtherMissingItemsClaimDescriptionModel>({
        resolver: yupResolver(otherMissingItemsClaimDescriptionSchema(t, OTHER_MISSING_ITEMS_LABEL)),
        defaultValues: {
            otherMissingItemsClaimDescription,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (
        { otherMissingItemsClaimDescription }: OtherMissingItemsClaimDescriptionModel,
        e?: FormChangeable
    ) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { otherMissingItemsClaimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={OTHER_MISSING_ITEMS_CLAIM_DESCRIPTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.otherMissingItemsClaimDescription}
                                errorMessage={errors.otherMissingItemsClaimDescription?.message}
                                id={OTHER_MISSING_ITEMS_CLAIM_DESCRIPTION}
                                inputFieldWrapper={'col-12'}
                                label={t(OTHER_MISSING_ITEMS_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(OTHER_MISSING_ITEMS_PLACEHOLDER)}
                                reference={ref}
                                rows={10}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
