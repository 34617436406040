import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { companySchema } from '../fieldSchemas/companySchema';
import { addressSchema } from '../fieldSchemas/addressSchema';

export const companyInformationSchema = (t: TFunction, required: boolean) => {
    return Yup.object().shape({
        ...companySchema(t, required),
        ...addressSchema(t),
    });
};
