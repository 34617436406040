import React, { MouseEvent } from 'react';
import {
    CalendarIcon,
    CircleGarbageIcon,
    CircleIconButton,
    Grid,
    IncapacityPeriodsModel,
    IncapacityPeriodsTypeModel,
} from '@protectorinsurance/ds-can';
import { FIN_DATE_FORMAT } from '../../config/locale';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';

export interface IncapacityListProps {
    incapacityPeriods: IncapacityPeriodsTypeModel;
    handleDelete: (e: MouseEvent, period: IncapacityPeriodsModel) => void;
    fromText: string;
    toText: string;
    label: string;
    displayFormat?: string;
}

export const IncapacityPeriodList = ({
    incapacityPeriods,
    handleDelete,
    fromText,
    toText,
    label,
    displayFormat = FIN_DATE_FORMAT,
}: IncapacityListProps) => {
    const periodRender = incapacityPeriods.map((period: IncapacityPeriodsModel, idx: number) => {
        return (
            <li key={idx} data-testid={TEST_ID_PREFIX.incapacityPeriod}>
                <Grid className={'sm:my-8 align-center'}>
                    <div className={'col-10 sick-leave-divider'}>
                        <CalendarIcon wh={40} />
                        <div>
                            {fromText} {period.from && period.from.format(displayFormat)} {toText}{' '}
                            {period.to && period.to.format(displayFormat)} ({period.rate}%)
                        </div>
                    </div>
                    <CircleIconButton
                        dataTestId={'btn-delete'}
                        id={`delete-${idx}`}
                        label={label}
                        ariaLabel={label}
                        icon={<CircleGarbageIcon wh={60} />}
                        handleClick={(e: MouseEvent) => handleDelete(e, period)}
                    />
                </Grid>
            </li>
        );
    });
    return <ul data-testid={TEST_ID_PREFIX.incapacityPeriodList}>{periodRender}</ul>;
};
