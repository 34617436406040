import React from 'react';
import { useI18n } from '../../../../../hooks/useI18n';
import './FinalSummary.scss';
import { BankAccountInformationModel, I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayBankAccountInformationProps {
    model: Partial<BankAccountInformationModel>;
    i18n?: I18NOptions;
}

export const DisplayBankAccountInformation = ({ model, i18n }: DisplayBankAccountInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));
    const { ownerGivenName, ownerFamilyName, accountNumber, dontKnowBankInformation, isCompany, companyName } = model;

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix('text.bankAccountInformation')}
                </p>
            </div>
            {!dontKnowBankInformation && (
                <>
                    {isCompany && companyName ? (
                        <SummaryItemText
                            label={tWithPrefix('text.companyName')}
                            text={`${companyName}`}
                            className={'col-8 divider text-align-left'}
                        />
                    ) : (
                        ''
                    )}
                    {ownerGivenName || ownerFamilyName ? (
                        <SummaryItemText
                            label={tWithPrefix('text.fullName')}
                            text={`${ownerGivenName} ${ownerFamilyName}`}
                            className={'col-8 divider text-align-left'}
                        />
                    ) : (
                        ''
                    )}
                    <SummaryItemText
                        label={tWithPrefix('text.accountNumber')}
                        text={`${accountNumber}`}
                        className={'col-4 divider text-align-left'}
                    />
                </>
            )}
            <SummaryItemText
                label={tWithPrefix('text.dontKnowBankInformation')}
                text={t(`dontKnowBankInformation.select.${dontKnowBankInformation}`)}
                className={'col-12 divider text-align-left'}
            />
        </>
    );
};
