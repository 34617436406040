import React from 'react';
import {
    CarouselConfigLookupResponse,
    IAmIllIcon,
    OnBehalfOfSbIllIcon,
    WhoReportKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { selectClaimReporterRole } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { MYSELF, ON_BEHALF } = WhoReportKeys;

/**
 * Page view and page logic
 */
export const healthIllnessClaimReporterRolePage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: MYSELF, name: t(`lob.person.health.illness.claimReporterRole.select.${MYSELF}`), icon: <IAmIllIcon /> },
        {
            id: ON_BEHALF,
            name: t(`lob.person.health.illness.claimReporterRole.select.${ON_BEHALF}`),
            icon: <OnBehalfOfSbIllIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_CLAIM_REPORTER_ROLE,
        stateKey: 'claimReporterRole',
        i18n: {
            ns: 'lob.person.health.illness.claimReporterRole',
        },
        selectorValue: selectClaimReporterRole(state),
        options,
    };
};
