import React, { ReactNode, useEffect, useState } from 'react';
import { isDev, isTest } from '../../utils/env';
import './Layout.scss';
import { useSelector } from 'react-redux';
import { selectFlow } from '../../sagas/selectors/flowSelectors';
import { selectRouterPathname } from '../../sagas/selectors/routerSelectors';
import { progressBarUtil } from '../../utils/flow/progressBarUtil';
import { getLobProgressBarRoutes } from '../../utils/flow/getLobProgressBarRoutes';
import { setHTMLLang } from '../../utils/locale/setHTMLLang';
import { useI18n } from '../../hooks/useI18n';
import { NavBar, ProgressBar } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../config/phraseKeys';
import {
    selectCustomCAN,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
} from 'sagas/selectors/commonSelectors';
import { selectInsuranceType } from '../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { PAGE_NAME } = PhraseKeys;

/**
 * Interfaces
 */
interface LayoutProps {
    children: ReactNode;
}

/**
 * Component view and component logic
 */
export const Layout = ({ children }: LayoutProps) => {
    const flow = useSelector(selectFlow);
    const path = useSelector(selectRouterPathname);
    const lob = useSelector(selectLob);
    const insuranceType = useSelector(selectInsuranceType);
    const [progress, setProgress] = useState(0);
    const progressBarRoutes = getLobProgressBarRoutes(lob, insuranceType);
    const language = useSelector(selectLocaleLanguageCode);
    const country = useSelector(selectLocaleCountryCode);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();

    useEffect(() => {
        setProgress(progressBarUtil(flow, path, progressBarRoutes));
    }, [flow, path, progressBarRoutes]);

    const envClassName = isDev() ? 'is-dev' : isTest() ? 'is-test' : '';
    const titlePostfix = isDev() ? 'DEV' : isTest() ? 'TEST' : '';
    const logoText = 'logo.link';
    document.documentElement.lang = setHTMLLang(language);

    return (
        <>
            <header className={`header ${envClassName} no-print`}>
                <NavBar
                    domain={t(PAGE_NAME)}
                    environment={titlePostfix}
                    label={t(logoText)}
                    {...{ country, customCAN, envClassName, language }}
                />
            </header>
            <section role="main" className={'main content'}>
                {children}
            </section>
            <footer className={'footer no-print'}>
                <ProgressBar width={progress} {...{ customCAN }} />
            </footer>
        </>
    );
};
