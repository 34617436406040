import { NumberSchema } from 'yup';
import { yupCustomLocale } from '../../config/yup';

export function minNumber(this: NumberSchema, length: number, message: string = yupCustomLocale.number.minNumber) {
    return this.test({
        name: 'minNumber',
        message,
        params: { length },
        // @ts-ignore
        test: (value: number) => {
            return value ? value.toString().length >= length : false;
        },
    });
}
