import React, { useRef } from 'react';
import { DamageMarkers } from '../DamageMarker';
import { useMarkers } from '../../../hooks/useMarkers';
import { createOnClickAddDamagePoint } from '../../../utils/svgUtils';
import { BodyKeys, SvgImg } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { FRONT_LEFT, CENTER_LEFT, BACK_LEFT } = BodyKeys;

/**
 * Component view and component logic
 */
export const HeavyTruckLeftImage = ({
    addDamagePoint,
    removeDamagePoint,
    selectedDamagePoints,
    title,
    id = 'heavy-truck-left-image-svg',
}: SvgImg) => {
    const refSVG = useRef<SVGSVGElement>(null);
    const thisMarkers = useMarkers(selectedDamagePoints, id);
    const onClick = createOnClickAddDamagePoint(refSVG, addDamagePoint);

    return (
        <svg
            id={id}
            data-testid={id}
            className={'vehicle-img-svg left-image-svg'}
            ref={refSVG}
            width="2075"
            height="595"
            viewBox="0 0 2075 595"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id={`${id}-title`}>{title}</title>
            <g className={'left-image-svg__selectable'}>
                <path
                    id={BACK_LEFT}
                    onClick={onClick}
                    fill="#FFFFFF"
                    stroke="#7D8890"
                    d="M2070 594.5C2072.49 594.5 2074.5 592.485 2074.5 590L2074.5 0.5L1471.5 0.499993L1471.5 594.5L2070 594.5Z"
                />
                <path
                    id={FRONT_LEFT}
                    onClick={onClick}
                    fill="#FFFFFF"
                    stroke="#7D8890"
                    d="M4.99999 594.5C2.51471 594.5 0.5 592.485 0.5 590L0.500007 0.5L603.5 0.500007L603.5 594.5L4.99999 594.5Z"
                />
                <path
                    id={CENTER_LEFT}
                    onClick={onClick}
                    fill="#FFFFFF"
                    stroke="#7D8890"
                    d="M609 594.5C606.515 594.5 604.5 592.485 604.5 590L604.5 0.5L1470.5 0.50001L1470.5 594.5L609 594.5Z"
                />
            </g>
            <g className={'left-image-svg__content'}>
                <path
                    d="M418.597 403.343V61H2035.55V403.343M418.597 403.343V447.224H339.564V197.181C346.618 191.886 349.128 187.976 348.262 177.51L328.598 78.4009H281.708L218.179 113.581H171.289C120.995 113.581 88.8527 126.442 65.4076 177.51L32.5088 338.657L26.0802 349.249V400.695L32.5088 403.343L14.7358 437.767C11.5077 502.717 11.9319 530.981 23.4332 542.172H123.642C100.575 485.5 146.709 437.767 199.65 437.767C252.591 437.767 295.5 490.726 267 553.899H671.956C654.5 490.726 686.703 437.767 746.826 437.767L2020.05 447.224V524.771L2035.55 545.198H2059V524.771L2035.55 511.91V403.343M418.597 403.343H2035.55M1518.63 517.206C1518.63 551.886 1490.52 580 1455.86 580C1421.19 580 1393.08 551.886 1393.08 517.206C1393.08 482.525 1421.19 454.411 1455.86 454.411C1490.52 454.411 1518.63 482.525 1518.63 517.206ZM1662.32 517.206C1662.32 551.886 1634.22 580 1599.55 580C1564.88 580 1536.78 551.886 1536.78 517.206C1536.78 482.525 1564.88 454.411 1599.55 454.411C1634.22 454.411 1662.32 482.525 1662.32 517.206ZM1809.8 517.206C1809.8 551.886 1781.7 580 1747.03 580C1712.36 580 1684.26 551.886 1684.26 517.206C1684.26 482.525 1712.36 454.411 1747.03 454.411C1781.7 454.411 1809.8 482.525 1809.8 517.206ZM812.248 517.206C812.248 551.886 784.144 580 749.476 580C714.808 580 686.703 551.886 686.703 517.206C686.703 482.525 714.808 454.411 749.476 454.411C784.144 454.411 812.248 482.525 812.248 517.206ZM260.153 517.206C260.153 551.886 232.049 580 197.381 580C162.713 580 134.609 551.886 134.609 517.206C134.609 482.525 162.713 454.411 197.381 454.411C232.049 454.411 260.153 482.525 260.153 517.206ZM185.658 206.638H208.725C220.07 206.638 220.07 215.338 220.07 221.012V269.811C220.07 278.511 220.07 283.429 208.725 283.429H185.658V206.638ZM179.23 206.638V283.429H120.995L98.3064 299.695H40.4498L59.7354 206.638H179.23ZM179.608 297.481V406.748C120.968 420.823 103.018 438.774 100.575 490.726H46.8783V314.125H100.575L123.642 297.481H179.608Z"
                    stroke="black"
                    strokeWidth="3.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <DamageMarkers removeDamagePoint={removeDamagePoint} selectedDamagePoints={thisMarkers} />
        </svg>
    );
};
