import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { bankCompanyInformationSchema } from '../fieldSchemas/bankCompanyInformationSchema';

/**
 * Validation logic
 */
export const bankAccountCompanyInformationSchema = (t: TFunction, optional: boolean) => {
    return Yup.object().shape({
        ...bankCompanyInformationSchema(t, optional),
    });
};
