import * as Yup from 'yup';
import { TFunction } from 'i18next';

/**
 * Validation logic
 */
export const driverSchema = (t: TFunction) => {
    return {
        isDriver: Yup.boolean(),
    };
};
