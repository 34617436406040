import { Moment } from 'moment';
import { FIN_DATE_FORMAT, FIN_DATE_TIME_FORMAT, FIN_TIME_FORMAT } from '../../config/locale';
import { dtParse } from '@protectorinsurance/ds-can';

export const dtFormat = (date: Moment, withTime?: boolean) => {
    return date.format(withTime ? FIN_DATE_TIME_FORMAT : FIN_DATE_FORMAT);
};

export const dtParseAndFormat = (date: Date | Moment | string) => dtFormat(dtParse(date));

export const dtTimeParseAndFormat = (date: Date | Moment | string) => dtFormat(dtParse(date), true);

export const timeFormat = (date: Moment) => {
    return date.format(FIN_TIME_FORMAT);
};

export const timeParseAndFormat = (date: Date | Moment | string) => timeFormat(dtParse(date));
