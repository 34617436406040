import { UploadActionTypes } from '../../sagas/upload';
import { AxiosError } from 'axios';
import { FileModel, FileStatusEnums, HttpStatusCodeEnums } from '@protectorinsurance/ds-can';

export const createFileError = (data: FileModel, type: UploadActionTypes, error: AxiosError | Error): FileModel => {
    let e;
    if ((error as AxiosError).response) {
        // @ts-ignore
        e = { ...(error as AxiosError).response?.data.error };
    } else if (error instanceof TypeError) {
        e = {
            message: error.message,
            status: HttpStatusCodeEnums.BAD_REQUEST,
            statusText: HttpStatusCodeEnums.BAD_REQUEST,
        };
    } else {
        e = { ...error };
    }
    return {
        ...data,
        status: FileStatusEnums.ERROR,
        errors: [
            ...data.errors,
            {
                type,
                error: e,
            },
        ],
    };
};
