import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectIncapacityMoreThanSevenDays } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const workersCompAccidentIncapacityMoreThanSevenDaysPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: YES,
            name: t(`lob.person.workersComp.accident.incapacityMoreThanSevenDays.select.${YES}`),
            icon: <YesIcon />,
        },
        {
            id: NO,
            name: t(`lob.person.workersComp.accident.incapacityMoreThanSevenDays.select.${NO}`),
            icon: <NoIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_MORE_THAN_SEVEN_DAYS,
        stateKey: 'incapacityMoreThanSevenDays',
        i18n: {
            ns: 'lob.person.workersComp.accident.incapacityMoreThanSevenDays',
        },
        selectorValue: selectIncapacityMoreThanSevenDays(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { incapacityMoreThanSevenDays: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
