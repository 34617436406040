import React from 'react';
import {
    IncapacityPeriodsModel,
    IncapacityPeriodsTypeModel,
    ListProps,
    SummaryNavButton,
    SummaryText,
} from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../utils/testIdUtil';

interface IncapacityPeriodListSummaryProps extends ListProps {
    list: IncapacityPeriodsTypeModel;
    getText: (model: IncapacityPeriodsModel) => string;
    textKey: string;
}

/**
 * Component view and component logic
 */
export const IncapacityPeriodListSummary = ({
    list,
    show,
    handleClick,
    path,
    getText,
    textKey,
}: IncapacityPeriodListSummaryProps) => {
    return (
        <>
            {show && <SummaryText text={textKey} />}
            {show &&
                list.map((period: IncapacityPeriodsModel, idx: number) => {
                    return (
                        <div key={`incapacity-period-${idx}`} className={'incapacity-period-list'}>
                            <SummaryNavButton
                                className={'summary'}
                                key={`incapacityPeriod-${idx}`}
                                dataTestId={`${TEST_ID_PREFIX.incapacityPeriodList}-${idx}`}
                                path={path}
                                buttonText={getText(period)}
                                handleClick={handleClick}
                            />
                        </div>
                    );
                })}
        </>
    );
};
