import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectReporterInformation } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { contactPersonInformationSchema } from '../../../../validations/schemas/contactPersonInformationSchema';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    AutocompleteEnums,
    ClaimReporterInformationModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { crossContactInfoFieldValidation } from '../../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { EMAIL, FIRST_NAME, LAST_NAME, PHONE, ROLE } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    ROLE_LABEL,
    ROLE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteEnums;

/**
 * Page view and page logic
 */
export const AccidentReporterInformationPage = () => {
    const dispatch = useDispatch();
    const reporterInformation = useSelector(selectReporterInformation);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.health.accident.reporterInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimReporterInformationModel>({
        resolver: yupResolver(contactPersonInformationSchema(t)),
        defaultValues: {
            ...reporterInformation,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await crossContactInfoFieldValidation(id, trigger);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: ClaimReporterInformationModel) => {
        dispatcherWithPromise(dispatch, personActions.update, { reporterInformation: values })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={ROLE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.role}
                                errorMessage={errors.role?.message}
                                id={ROLE}
                                inputFieldWrapper={'col-4'}
                                label={t(ROLE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ROLE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
