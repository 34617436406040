import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    FlowKeys,
    MotorClaimCauseKeys,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { selectClaimCauseList, selectIsLaneChange } from '../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../sagas/common';
import { flowActions, FlowState } from '../../../../../sagas/flow';
import { claimCauseListUtil } from '../../../../../utils/claimCauseListUtils';
import { selectFlow } from '../../../../../sagas/selectors/flowSelectors';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;
const { END } = FlowKeys;
const { LANE_CHANGE } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const isLaneChangePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.driving.isLaneChange.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.isLaneChange.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE,
        stateKey: 'isLaneChange',
        i18n: {
            ns: 'motor.driving.isLaneChange',
        },
        selectorValue: selectIsLaneChange(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let flow: FlowState | MotorClaimCauseKeys = END;
                if (option.id === YES) {
                    flow = selectFlow(state);
                    dispatch(wizardActions.skipBackToPrev(true));

                    const claimCausesList = [...selectClaimCauseList(state)];
                    dispatcherWithPromise(dispatch, motorActions.update, {
                        claimCauseList: claimCauseListUtil(
                            claimCausesList,
                            MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE,
                            LANE_CHANGE
                        ),
                    });
                }

                dispatcherWithPromise(dispatch, motorActions.update, { isLaneChange: option.id, flow })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, flow))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
