import React from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import {
    ClaimTypeEnums,
    Clickable,
    FlowKeys,
    is,
    LobKeys,
    LpoClaimCauseEnums,
    PageLayout,
    StartSummary,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { flowActions } from '../../../sagas/flow';
import { goBack } from 'connected-react-router';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { lpoActions } from '../../../sagas/lpo';
import { commonActions } from '../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { START } = FlowKeys;
const { LIABILITY, PROPERTY } = LobKeys;
const { PROPERTY: PROPERTY_CLAIM_TYPE, PRODUCT_LIABILITY } = ClaimTypeEnums;
const { OTHER } = LpoClaimCauseEnums;

/**
 * Page view and page logic
 */
export const StartOnboardingPage = () => {
    const dispatch = useDispatch();
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.start.onboarding');

    const handleBackButton = () => {
        dispatcherWithPromise(dispatch, flowActions.update, null).then(() => dispatch(goBack()));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(lob, LIABILITY)) {
            dispatch(lpoActions.update({ claimCause: OTHER, claimType: PRODUCT_LIABILITY }));
        }
        if (is(lob, PROPERTY)) {
            dispatch(lpoActions.update({ claimCause: OTHER, claimType: PROPERTY_CLAIM_TYPE }));
        }

        dispatcherWithPromise(dispatch, flowActions.update, START)
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardRouterActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <StartSummary
                avgMin={tWithNS.t('body.avgMin')}
                description={tWithNS.t('body.paragraph.one')}
                sessionText={tWithNS.t('body.paragraph.two')}
                subTitle={tWithNS.t('body.subTitle')}
                title={tWithNS.t('body.title')}
                {...{ customCAN }}
            />
        </PageLayout>
    );
};
