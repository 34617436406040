import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useGoBack } from '../../../../hooks/useGoBack';
import { useI18n } from '../../../../hooks/useI18n';
import {
    selectClaimCause,
    selectCollisionSituation,
    selectTimeSinceAction,
} from '../../../../sagas/selectors/motorSelectors';
import {
    Clickable,
    CollisionModel,
    CollisionSituationKeys,
    FlowKeys,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MotorClaimCauseKeys,
    MuiTextInput,
    PageLayout,
    SecondaryButton,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import { vehicleCollisionSchema } from '../../../../validations/schemas/vehicleCollisionSchema';
import { flowActions } from '../../../../sagas/flow';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TIME_SINCE_ACTION_LABEL,
    TIME_SINCE_ACTION_PLACEHOLDER,
    TITLE,
} = PhraseKeys;
const { AFTER, DURING } = CollisionSituationKeys;
const { TIME_SINCE_ACTION } = FormFieldNames;
const { REVERSING } = MotorClaimCauseKeys;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const DVCollisionPage = () => {
    const dispatch = useDispatch();
    const collisionSituation = useSelector(selectCollisionSituation);
    const timeSinceAction = useSelector(selectTimeSinceAction);
    const claimCause = useSelector(selectClaimCause);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n(
        claimCause === REVERSING ? 'motor.driving.reversingCollision' : 'motor.driving.laneChangeCollision'
    );
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<CollisionModel>({
        resolver: yupResolver(vehicleCollisionSchema(t)),
        defaultValues: {
            timeSinceAction,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ timeSinceAction }: CollisionModel, e?: FormChangeable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { timeSinceAction })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    const handleButtonAction = (collisionSituation: CollisionSituationKeys, e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { collisionSituation: collisionSituation }).then(() =>
            dispatcherWithPromise(dispatch, commonActions.send)
        );
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { flow: END })
            .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={collisionSituation === null}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={collisionSituation === AFTER ? handleSubmit(onSubmit) : handleContinueButton}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <div className={'col-6 justify-self-end'}>
                        <SecondaryButton
                            ariaLabel={tWithNS.t('option.one')}
                            className={`${collisionSituation === DURING ? 'active' : ''}`}
                            onClick={() => handleButtonAction(DURING)}
                            value={tWithNS.t('option.one')}
                            {...{ customCAN }}
                        />
                    </div>
                    <div className={'col-6'}>
                        <SecondaryButton
                            ariaLabel={tWithNS.t('option.two')}
                            className={`${collisionSituation === AFTER ? 'active' : ''}`}
                            onClick={() => handleButtonAction(AFTER)}
                            value={tWithNS.t('option.two')}
                            {...{ customCAN }}
                        />
                    </div>
                    {collisionSituation === AFTER ? (
                        <Controller
                            control={control}
                            name={TIME_SINCE_ACTION}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    error={!!errors.timeSinceAction}
                                    errorMessage={errors.timeSinceAction?.message}
                                    id={TIME_SINCE_ACTION}
                                    inputFieldWrapper={'col-12 padding-top'}
                                    label={tWithNS.t(TIME_SINCE_ACTION_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={tWithNS.t(TIME_SINCE_ACTION_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    ) : (
                        <div className={'col-12'}>
                            <span />
                        </div>
                    )}
                </Grid>
            </form>
        </PageLayout>
    );
};
