import React from 'react';
import classNames from 'classnames';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { isYes } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow, trueFalseToYesNo } from 'utils/displayUtils';
import { PersonSummaryProps } from '../../../interfaces/types/SummaryTypes';
import { renderHasExistingClaim } from './components/RenderHasExistingClaim';
import { renderClaimNumberInformation } from './components/RenderClaimNumberInformation';
import { renderInsuranceType } from './components/RenderInsuranceType';
import { renderClaimantAgeCategory } from './components/RenderClaimantAgeCategory';
import { renderPrivacy } from './components/RenderPrivacy';
import { renderClaimLocation } from './components/RenderClaimLocation';
import { renderClaimDate } from './components/RenderClaimDate';
import { renderNatureTypeId } from './components/RenderNatureTypeId';
import { renderBody } from './components/RenderBody';
import { renderHasAppliedKela } from './components/RenderHasAppliedKela';
import { renderClaimCause } from './components/RenderClaimCause';
import { renderHasAppliedMedicalCare } from './components/RenderHasAppliedMedicalCare';
import { renderHasExpenses } from './components/RenderHasExpenses';
import { renderWhoPaidExpenses } from './components/RenderWhoPaidExpenses';
import { renderReceipt } from './components/RenderReceipt';
import { renderHasOtherInsurance } from './components/RenderHasOtherInsurance';
import { renderOtherInsuranceCompany } from './components/RenderOtherInsuranceCompany';
import { dtTimeParseAndFormat } from 'utils/date/dateUtils';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_GROUP_ACCIDENT_CLAIM_LOCATION,
    DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY,
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_KELA,
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_EXPENSES,
    DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_GROUP_ACCIDENT_WHO_PAID_EXPENSES,
    DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
    GROUP_ACCIDENT_BODY_PARTS,
    GROUP_ACCIDENT_CLAIM_CAUSE,
    GROUP_ACCIDENT_CLAIM_DATE,
    GROUP_ACCIDENT_NATURE_TYPE_ID,
    GROUP_ACCIDENT_OTHER_INSURANCE_COMPANY,
    GROUP_ACCIDENT_PRIVACY,
    GROUP_ACCIDENT_UPLOAD_RECEIPT,
    INDEX,
    START_CLAIM_NUMBER_INFORMATION,
} = PersonRoutePaths;

export const DisplayGroupAccidentSituationSummary = ({
    model,
    upload,
    t,
    tWithNS,
    handleClick,
}: PersonSummaryProps) => {
    /**
     * Helpers
     */
    const {
        acceptedPrivacy,
        bodyPartList,
        claimCause,
        claimDate,
        claimLocation,
        claimNumber,
        claimantAgeCategory,
        hasAppliedKela,
        hasAppliedMedicalCare,
        hasExistingClaim,
        hasExpenses,
        insuranceType,
        natureTypeId,
        otherInsurance,
        otherInsuranceCompany,
        whoPaidExpenses,
    } = model;

    // Classes
    const hasExistingClaimClass = classNames(
        {
            'col-6': isYes(hasExistingClaim),
            'col-12': !isYes(hasExistingClaim),
        },
        'divider align-button-start text-align-left'
    );

    const hasExpensesClass = classNames(
        {
            'col-4 spacing': isYes(hasExpenses),
            'col-12 divider': !isYes(hasExpenses),
        },
        'align-button-start text-align-left'
    );

    const hasOtherInsuranceClass = classNames(
        {
            'col-4': isYes(otherInsurance),
            'col-12': !isYes(otherInsurance),
        },
        'divider align-button-start text-align-left'
    );

    return (
        <>
            {renderHasExistingClaim({
                inputWrapperClass: hasExistingClaimClass,
                textKey: tWithNS('hasExistingClaim.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasExistingClaim,
                buttonText: t(createTextYesNoDontKnow(hasExistingClaim)),
                path: DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
                handleClick,
            })}

            {renderClaimNumberInformation(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('claimNumberInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimNumber,
                    buttonText: claimNumber ? claimNumber : '-',
                    path: START_CLAIM_NUMBER_INFORMATION,
                    handleClick,
                },
                hasExistingClaim
            )}

            {renderInsuranceType({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('insuranceType.heading.title'),
                dataTestId: TEST_ID_PREFIX.insuranceType,
                buttonText: insuranceType ? t(`lob.person.start.insuranceType.select.${insuranceType}`) : '-',
                path: INDEX,
                handleClick,
            })}

            {renderClaimantAgeCategory({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('claimantAgeCategory.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimantAgeCategory,
                buttonText: claimantAgeCategory
                    ? t(`lob.person.groupAccident.claimantAgeCategory.select.${claimantAgeCategory}`)
                    : '-',
                path: DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY,
                handleClick,
            })}

            {renderPrivacy({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('privacy.heading.title'),
                dataTestId: TEST_ID_PREFIX.acceptedPrivacy,
                buttonText: t(trueFalseToYesNo(acceptedPrivacy)),
                path: GROUP_ACCIDENT_PRIVACY,
                handleClick,
            })}

            {renderClaimLocation({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('claimLocation.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimLocation,
                buttonText: claimLocation ? t(`lob.person.groupAccident.claimLocation.select.${claimLocation}`) : '-',
                path: DYN_CAROUSEL_GROUP_ACCIDENT_CLAIM_LOCATION,
                handleClick,
            })}

            {renderClaimDate({
                inputWrapperClass: 'col-4 divider align-button-start text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtTimeParseAndFormat(claimDate) : '-',
                path: GROUP_ACCIDENT_CLAIM_DATE,
                handleClick,
            })}

            {renderNatureTypeId({
                inputWrapperClass: 'col-8 divider align-button-start text-align-left',
                textKey: tWithNS('natureTypeId.heading.title'),
                dataTestId: TEST_ID_PREFIX.natureTypeId,
                buttonText: natureTypeId ? t(`lob.person.health.accident.natureTypeId.select.${natureTypeId}`) : '-',
                path: GROUP_ACCIDENT_NATURE_TYPE_ID,
                handleClick,
            })}

            {renderClaimCause({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('claimCause.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimCause,
                buttonText: claimCause ? t(`lob.person.accident.claimCause.select.${claimCause}`) : '',
                path: GROUP_ACCIDENT_CLAIM_CAUSE,
                handleClick,
            })}

            {renderBody(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('bodyParts.heading.title'),
                    dataTestId: TEST_ID_PREFIX.bodyParts,
                    buttonText: '',
                    path: GROUP_ACCIDENT_BODY_PARTS,
                    handleClick,
                },
                bodyPartList
            )}

            {renderHasAppliedMedicalCare({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('hasAppliedMedicalCare.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasAppliedMedicalCare,
                buttonText: t(createTextYesNoDontKnow(hasAppliedMedicalCare)),
                path: DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
                handleClick,
            })}

            {renderHasExpenses({
                inputWrapperClass: hasExpensesClass,
                textKey: tWithNS('hasExpenses.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasExpenses,
                buttonText: t(createTextYesNoDontKnow(hasExpenses)),
                path: DYN_CAROUSEL_GROUP_ACCIDENT_HAS_EXPENSES,
                handleClick,
            })}

            {renderHasAppliedKela(
                {
                    inputWrapperClass: 'col-4 spacing align-button-start text-align-left',
                    textKey: tWithNS('hasAppliedKela.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasAppliedKela,
                    buttonText: t(createTextYesNoDontKnow(hasAppliedKela)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_KELA,
                    handleClick,
                },
                hasExpenses
            )}

            {renderWhoPaidExpenses(
                {
                    inputWrapperClass: 'col-4 spacing align-button-start text-align-left',
                    textKey: tWithNS('whoPaidExpenses.heading.title'),
                    dataTestId: TEST_ID_PREFIX.whoPaidExpenses,
                    buttonText: whoPaidExpenses
                        ? t(`lob.person.groupAccident.whoPaidExpenses.select.${whoPaidExpenses}`)
                        : '-',
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_WHO_PAID_EXPENSES,
                    handleClick,
                },
                hasExpenses
            )}

            {renderReceipt(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('uploadReceipt.heading.title'),
                    dataTestId: TEST_ID_PREFIX.uploadReceipt,
                    buttonText: '',
                    path: GROUP_ACCIDENT_UPLOAD_RECEIPT,
                    handleClick,
                },
                hasExpenses,
                upload.receipt
            )}

            {renderHasOtherInsurance({
                inputWrapperClass: hasOtherInsuranceClass,
                textKey: tWithNS('hasOtherInsurance.heading.title'),
                dataTestId: TEST_ID_PREFIX.otherInsurance,
                buttonText: t(createTextYesNoDontKnow(otherInsurance)),
                path: DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE,
                handleClick,
            })}

            {renderOtherInsuranceCompany(
                {
                    inputWrapperClass: 'col-8 divider align-button-start text-align-left',
                    textKey: tWithNS('otherInsuranceCompany.heading.title'),
                    dataTestId: TEST_ID_PREFIX.otherInsuranceCompany,
                    buttonText: otherInsuranceCompany ? otherInsuranceCompany : '-',
                    path: GROUP_ACCIDENT_OTHER_INSURANCE_COMPANY,
                    handleClick,
                },
                otherInsurance
            )}
        </>
    );
};
