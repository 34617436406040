import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    DamageToCompartmentIcon,
    FlowKeys,
    LostKeysIcon,
    MotorClaimCauseKeys,
    NaturalDisasterIcon,
    OtherInjuriesIcon,
    WrongFuelIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { selectClaimCause, selectClaimCauseList } from '../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../sagas/common';
import { claimCauseListUtil } from '../../../../../utils/claimCauseListUtils';
import { flowActions } from '../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { WRONG_FUEL, LOST_KEY, COMPARTMENT_DAMAGE, NATURAL_DISASTER, OTHER } = MotorClaimCauseKeys;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const claimCauseDetailsPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: WRONG_FUEL, name: t(`motor.parked.claimCauseDetails.select.${WRONG_FUEL}`), icon: <WrongFuelIcon /> },
        { id: LOST_KEY, name: t(`motor.parked.claimCauseDetails.select.${LOST_KEY}`), icon: <LostKeysIcon /> },
        {
            id: COMPARTMENT_DAMAGE,
            name: t(`motor.parked.claimCauseDetails.select.${COMPARTMENT_DAMAGE}`),
            icon: <DamageToCompartmentIcon />,
        },
        {
            id: NATURAL_DISASTER,
            name: t(`motor.parked.claimCauseDetails.select.${NATURAL_DISASTER}`),
            icon: <NaturalDisasterIcon />,
        },
        { id: OTHER, name: t(`motor.parked.claimCauseDetails.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_OTHER_CLAIM_CAUSE_DETAILS,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.parked.claimCauseDetails',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                const claimCausesList = [...selectClaimCauseList(state)];
                dispatcherWithPromise(dispatch, motorActions.update, {
                    claimCauseList: claimCauseListUtil(
                        claimCausesList,
                        MotorRoutePaths.DYN_CAROUSEL_OTHER_CLAIM_CAUSE_DETAILS,
                        option.id
                    ),
                });

                dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id, flow: END })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
        testInitState: (key) => ({ claimCause: key }),
    };
};
