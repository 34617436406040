import React from 'react';
import { useI18n } from '../../../../../hooks/useI18n';
import './FinalSummary.scss';
import {
    ClaimantPositionModel,
    EntrepreneurOwnershipTypeModel,
    I18NOptions,
    InjuredProfessionTypeModel,
    OccupationModel,
    SummaryItemText,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from '../../../../../utils/displayUtils';
import { dtParseAndFormat } from '../../../../../utils/date/dateUtils';

interface DisplayOccupationInformationProps {
    model: {
        claimantPosition: ClaimantPositionModel;
        entrepreneurOwnership: EntrepreneurOwnershipTypeModel;
        hasCompanyShares: YesNoModel;
        injuredProfession: InjuredProfessionTypeModel;
        occupation: OccupationModel;
    };
    i18n?: I18NOptions;
}

export const DisplayOccupationInformation = ({ model, i18n }: DisplayOccupationInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));
    const { claimantPosition, entrepreneurOwnership, hasCompanyShares, injuredProfession, occupation } = model;

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix('text.occupationInformation')}
                </p>
            </div>
            {claimantPosition.ownership || claimantPosition.position ? (
                <SummaryItemText
                    label={tWithPrefix('entrepreneurOwnership.heading.title')}
                    text={`${claimantPosition.position} (${claimantPosition.ownership}%)`}
                    className={'col-12 spacing text-align-left'}
                />
            ) : (
                ''
            )}
            {hasCompanyShares ? (
                <SummaryItemText
                    label={tWithPrefix('hasCompanyShares.heading.title')}
                    text={t(createTextYesNoDontKnow(hasCompanyShares))}
                    className={'col-12 spacing text-align-left'}
                />
            ) : (
                ''
            )}
            {entrepreneurOwnership.length > 0 ? (
                <>
                    {entrepreneurOwnership.map((item) => (
                        <SummaryItemText
                            label={tWithPrefix('entrepreneurOwnership.heading.title')}
                            text={`${item.position} (${item.ownership}%)`}
                            className={'col-12 spacing text-align-left'}
                        />
                    ))}
                </>
            ) : (
                ''
            )}
            {injuredProfession ? (
                <SummaryItemText
                    label={tWithPrefix('injuredProfession.heading.title')}
                    text={`${injuredProfession}`}
                    className={'col-12 spacing text-align-left'}
                />
            ) : (
                ''
            )}
            {occupation.employmentRelationship ? (
                <SummaryItemText
                    label={tWithPrefix('employmentRelationship.heading.title')}
                    text={t(`lob.person.employmentRelationship.select.${occupation.employmentRelationship}`)}
                    className={'col-6 spacing text-align-left'}
                />
            ) : (
                ''
            )}
            {occupation.occupationType ? (
                <SummaryItemText
                    label={tWithPrefix('occupationType.heading.title')}
                    text={t(`lob.person.occupationType.select.${occupation.occupationType}`)}
                    className={'col-6 spacing text-align-left'}
                />
            ) : (
                ''
            )}
            {occupation.dateOfEmployment ? (
                <SummaryItemText
                    label={tWithPrefix('dateOfEmployment.heading.title')}
                    text={dtParseAndFormat(occupation.dateOfEmployment)}
                    className={`${occupation.dateOfTermination ? 'col-6' : 'col-12'} divider text-align-left`}
                />
            ) : (
                ''
            )}
            {occupation.dateOfTermination ? (
                <SummaryItemText
                    label={tWithPrefix('dateOfTermination.heading.title')}
                    text={dtParseAndFormat(occupation.dateOfTermination)}
                    className={'col-6 divider text-align-left'}
                />
            ) : (
                ''
            )}
        </>
    );
};
