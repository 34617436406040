import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from 'hooks/useI18n';
import { wizardRouterActions as wizardActions } from '../sagas/wizardRouter';
import {
    BodyIcon,
    CarIcon,
    Carousel,
    CarouselItem,
    CarouselModel,
    CategoryKeys,
    Clickable,
    GroupAccidentIcon,
    InsuranceTypeKeys,
    is,
    LiabilityIcon,
    LobKeys,
    PageLayout,
    PropertyDamageIcon,
    TravelIcon,
    WorkCompIcon,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../config/phraseKeys';
import { useGoBack } from '../hooks/useGoBack';
import { selectCustomCAN, selectLob } from '../sagas/selectors/commonSelectors';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { commonActions } from '../sagas/common';
import { LanguageSwitcher } from '../components/languageSwitcher/LanguageSwitcher';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import { personActions } from '../sagas/person';
import dispatcherWithPromise from '../utils/dispatcherWithPromise';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { AUTO, LIABILITY, PERSON, PROPERTY, TRAVEL } = LobKeys;
const { INDEX: PERSON_INDEX } = PersonRoutePaths;
const { INDEX: MOTOR_INDEX } = MotorRoutePaths;
const { DYN_CAROUSEL_TRAVEL_CLAIM_TYPE, INDEX: LPO_INDEX } = LpoRoutePaths;
const { GROUP_ACCIDENT, HEALTH, WORK_COMP } = InsuranceTypeKeys;
const { ACCIDENT } = CategoryKeys;

/**
 * Page view and page logic
 */
export const ClaimPage = () => {
    const dispatch = useDispatch();
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob');

    // Options
    const optGroupAccident = {
        id: PERSON,
        name: tWithNS.t(`select.groupAccident.${PERSON}`),
        icon: <GroupAccidentIcon />,
        description: tWithNS.t(`description.groupAccident.${PERSON}`),
    };
    const optLiability = {
        id: LIABILITY,
        name: tWithNS.t(`select.${LIABILITY}`),
        icon: <LiabilityIcon />,
        description: tWithNS.t(`description.${LIABILITY}`),
    };
    const optMotor = {
        id: AUTO,
        name: tWithNS.t(`select.${AUTO}`),
        icon: <CarIcon />,
        description: tWithNS.t(`description.${AUTO}`),
    };
    const optPerson = {
        id: PERSON,
        name: tWithNS.t(`select.${PERSON}`),
        icon: <BodyIcon />,
        description: tWithNS.t(`description.${PERSON}`),
    };
    const optProperty = {
        id: PROPERTY,
        name: tWithNS.t(`select.${PROPERTY}`),
        icon: <PropertyDamageIcon />,
        description: tWithNS.t(`description.${PROPERTY}`),
    };
    const optTravel = {
        id: TRAVEL,
        name: tWithNS.t(`select.${TRAVEL}`),
        icon: <TravelIcon />,
        description: tWithNS.t(`description.${TRAVEL}`),
    };
    const optWorkersComp = {
        id: PERSON,
        name: tWithNS.t(`select.workersComp.${PERSON}`),
        icon: <WorkCompIcon />,
        description: tWithNS.t(`description.workersComp.${PERSON}`),
    };

    const options = [optMotor, optPerson, optLiability, optTravel, optProperty, optWorkersComp, optGroupAccident];

    const handleBackButton = useGoBack();

    const handleSelect = (id: LobKeys, e: Clickable, name: string) => {
        e.preventDefault();

        const option = options.find((x: CarouselModel) => x.id === id);

        if (option) {
            let nextAction = wizardActions.goTo(PERSON_INDEX);
            if (is(option.id, PERSON)) {
                dispatch(personActions.update({ insuranceType: HEALTH }));
            }

            const lobs = [LIABILITY, PROPERTY];
            if (is(option.id, AUTO)) {
                nextAction = wizardActions.goTo(MOTOR_INDEX);
            }

            if (lobs.includes(option.id)) {
                nextAction = wizardActions.goTo(LPO_INDEX);
            }

            if (is(option.id, TRAVEL)) {
                nextAction = wizardActions.goTo(DYN_CAROUSEL_TRAVEL_CLAIM_TYPE);
            }

            if (is(option.id, PERSON) && is(name, tWithNS.t(`select.groupAccident.${PERSON}`))) {
                dispatch(personActions.update({ insuranceType: GROUP_ACCIDENT, category: ACCIDENT }));
            }

            if (is(option.id, PERSON) && is(name, tWithNS.t(`select.workersComp.${PERSON}`))) {
                dispatch(personActions.update({ insuranceType: WORK_COMP }));
            }

            dispatch(commonActions.update({ lob: option.id }));
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    const renderSelection = options.map((option: CarouselModel, idx: number) => {
        return (
            <CarouselItem
                key={idx}
                {...option}
                isSelected={option.id === lob}
                handleClick={handleSelect}
                {...{ customCAN }}
            />
        );
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerComponent={<LanguageSwitcher />}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={'landing-page'}
            showBackButton={false}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <Carousel>{renderSelection}</Carousel>
        </PageLayout>
    );
};
