import { TFunction } from 'i18next';
import { OccupationalConditionEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createOccupationalConditionKey = (id: string) => {
    return `lob.person.occupationalCondition.select.${id}`;
};

export const getOccupationalConditionOption = (t: TFunction, key: OccupationalConditionEnums, value: string) => {
    return {
        value,
        label: t(createOccupationalConditionKey(key)),
    };
};
