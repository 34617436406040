import React from 'react';
import classNames from 'classnames';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { is, isYes, TypeOfCauseEnums } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from 'utils/displayUtils';
import { PersonSummaryProps } from '../../../../interfaces/types/SummaryTypes';
import { dtParseAndFormat, dtTimeParseAndFormat } from 'utils/date/dateUtils';
import { renderHasExistingClaim } from './components/RenderHasExistingClaim';
import { renderClaimNumberInformation } from './components/RenderClaimNumberInformation';
import { renderInsuranceType } from './components/RenderInsuranceType';
import { renderClaimantRole } from './components/RenderClaimantRole';
import { renderClaimDate } from './components/RenderClaimDate';
import { renderReportedToEmployerDate } from './components/RenderReportedToEmployerDate';
import { renderTypeOfCause } from './components/RenderTypeOfCause';
import { renderOdCause } from './components/RenderOdCause';
import { renderCausedIncapacity } from './components/RenderCausedIncapacity';
import { renderIncapacityPeriods } from './components/RenderIncapacityPeriods';
import { renderHasAppliedMedicalCare } from './components/RenderHasAppliedMedicalCare';
import { renderMedicalInstitution } from './components/RenderMedicalInstitution';
import { renderHasExpenses } from './components/RenderHasExpenses';
import { renderWhoPaidExpenses } from './components/RenderWhoPaidExpenses';
import { renderReceipt } from './components/RenderReceipt';
import { renderWorkplace } from './components/RenderWorkplace';
import { renderDamageAddress } from '../accident/components/RenderDamageAddress';
import { renderLifeDamageMunicipality } from '../accident/components/RenderLifeDamageMunicipality';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CLAIMANT_ROLE,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_EXPENSES,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_BIOLOGICAL_FACTOR,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_CAUSE,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_PHYSICAL_FACTOR,
    DYN_CAROUSEL_WORKERS_COMP_ILLNESS_WHO_PAID_EXPENSES,
    INDEX,
    START_CLAIM_NUMBER_INFORMATION,
    WORKERS_COMP_ILLNESS_CLAIM_DATE,
    WORKERS_COMP_ILLNESS_DAMAGE_ADDRESS,
    WORKERS_COMP_ILLNESS_INCAPACITY_PERIODS,
    WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION,
    WORKERS_COMP_ILLNESS_REPORTED_TO_EMPLOYER_DATE,
    WORKERS_COMP_ILLNESS_TYPE_OF_CHEMICAL_FACTOR,
    WORKERS_COMP_ILLNESS_UPLOAD_RECEIPT,
    WORKERS_COMP_ILLNESS_WORKPLACE,
} = PersonRoutePaths;
const { BIOLOGICAL_FACTOR, CHEMICAL_FACTOR } = TypeOfCauseEnums;

/**
 * Component view and component logic
 */
export const DisplayWorkersCompIllnessSituationSummary = ({
    model,
    upload,
    t,
    tWithNS,
    handleClick,
}: PersonSummaryProps) => {
    /**
     * Helpers
     */
    const {
        causedIncapacity,
        claimDate,
        claimNumber,
        claimantRole,
        damageAddress,
        hasAppliedMedicalCare,
        hasExistingClaim,
        hasExpenses,
        incapacityPeriods,
        insuranceType,
        lifeDamageMunicipality,
        locationType,
        medicalInstitution,
        odCause,
        reportedToEmployerDate,
        typeOfCause,
        whoPaidExpenses,
    } = model;

    // Classes
    const hasExistingClaimClass = classNames(
        {
            'col-6': isYes(hasExistingClaim),
            'col-12': !isYes(hasExistingClaim),
        },
        'divider align-button-start text-align-left'
    );

    const causedIncapacityClass = classNames(
        {
            'col-6': isYes(causedIncapacity),
            'col-12': !isYes(causedIncapacity),
        },
        'divider align-button-start text-align-left'
    );

    const hasAppliedMedicalCareClass = classNames(
        {
            'col-4': isYes(hasAppliedMedicalCare),
            'col-12': !isYes(hasAppliedMedicalCare),
        },
        'divider align-button-start text-align-left'
    );

    const hasExpensesClass = classNames(
        {
            'col-6 spacing': isYes(hasExpenses),
            'col-12 divider': !isYes(hasExpenses),
        },
        'divider align-button-start text-align-left'
    );

    let factorRoute = DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_PHYSICAL_FACTOR;
    if (is(typeOfCause, BIOLOGICAL_FACTOR)) {
        factorRoute = DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_BIOLOGICAL_FACTOR;
    }

    if (is(typeOfCause, CHEMICAL_FACTOR)) {
        factorRoute = WORKERS_COMP_ILLNESS_TYPE_OF_CHEMICAL_FACTOR;
    }

    return (
        <>
            {renderHasExistingClaim({
                inputWrapperClass: hasExistingClaimClass,
                textKey: tWithNS('hasExistingClaim.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasExistingClaim,
                buttonText: t(createTextYesNoDontKnow(hasExistingClaim)),
                path: DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
                handleClick,
            })}

            {renderClaimNumberInformation(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('claimNumberInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimNumber,
                    buttonText: claimNumber ? claimNumber : '-',
                    path: START_CLAIM_NUMBER_INFORMATION,
                    handleClick,
                },
                hasExistingClaim
            )}

            {renderInsuranceType({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('insuranceType.heading.title'),
                dataTestId: TEST_ID_PREFIX.insuranceType,
                buttonText: insuranceType ? t(`lob.person.start.insuranceType.select.${insuranceType}`) : '-',
                path: INDEX,
                handleClick,
            })}

            {renderClaimantRole({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('claimantRole.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimantRole,
                buttonText: claimantRole
                    ? t(`lob.person.workersComp.illness.claimantRole.select.${claimantRole}`)
                    : '-',
                path: DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CLAIMANT_ROLE,
                handleClick,
            })}

            {renderDamageAddress({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('damageAddress.heading.title'),
                dataTestId: TEST_ID_PREFIX.damageAddress,
                buttonText: damageAddress ? damageAddress : '-',
                path: WORKERS_COMP_ILLNESS_DAMAGE_ADDRESS,
                handleClick,
            })}

            {renderLifeDamageMunicipality({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('lifeDamageMunicipality.heading.title'),
                dataTestId: TEST_ID_PREFIX.damageAddress,
                buttonText: lifeDamageMunicipality && lifeDamageMunicipality.key ? lifeDamageMunicipality.key : '-',
                path: WORKERS_COMP_ILLNESS_DAMAGE_ADDRESS,
                handleClick,
            })}

            {renderWorkplace({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('workplace.heading.title'),
                dataTestId: TEST_ID_PREFIX.locationType,
                buttonText: locationType && locationType.key ? locationType.key : '-',
                path: WORKERS_COMP_ILLNESS_WORKPLACE,
                handleClick,
            })}

            {renderClaimDate({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtTimeParseAndFormat(claimDate) : '-',
                path: WORKERS_COMP_ILLNESS_CLAIM_DATE,
                handleClick,
            })}

            {renderReportedToEmployerDate({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('reportedToEmployerDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.reportedToEmployerDate,
                buttonText: reportedToEmployerDate ? dtParseAndFormat(reportedToEmployerDate) : '-',
                path: WORKERS_COMP_ILLNESS_REPORTED_TO_EMPLOYER_DATE,
                handleClick,
            })}

            {/* TODO: Add Workplace render here */}

            {renderTypeOfCause({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('typeOfCause.heading.title'),
                dataTestId: TEST_ID_PREFIX.typeOfCause,
                buttonText: typeOfCause ? t(`lob.person.workersComp.illness.typeOfCause.select.${typeOfCause}`) : '-',
                path: DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_CAUSE,
                handleClick,
            })}

            {renderOdCause({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('odCause.heading.title'),
                dataTestId: TEST_ID_PREFIX.odCause,
                buttonText: odCause && odCause.key ? odCause.key : '-',
                path: factorRoute,
                handleClick,
            })}

            {renderCausedIncapacity({
                inputWrapperClass: causedIncapacityClass,
                textKey: tWithNS('causedIncapacity.heading.title'),
                dataTestId: TEST_ID_PREFIX.causedIncapacity,
                buttonText: t(createTextYesNoDontKnow(causedIncapacity)),
                path: DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY,
                handleClick,
            })}

            {renderIncapacityPeriods(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('incapacityPeriods.heading.title'),
                    dataTestId: TEST_ID_PREFIX.incapacityPeriod,
                    buttonText: '',
                    path: WORKERS_COMP_ILLNESS_INCAPACITY_PERIODS,
                    handleClick,
                },
                causedIncapacity,
                incapacityPeriods,
                t
            )}

            {renderHasAppliedMedicalCare({
                inputWrapperClass: hasAppliedMedicalCareClass,
                textKey: tWithNS('hasAppliedMedicalCare.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasAppliedMedicalCare,
                buttonText: t(createTextYesNoDontKnow(hasAppliedMedicalCare)),
                path: DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE,
                handleClick,
            })}

            {renderMedicalInstitution(
                {
                    inputWrapperClass: 'col-4 divider align-button-start text-align-left',
                    textKey: tWithNS('medicalInstitution.heading.title'),
                    dataTestId: TEST_ID_PREFIX.medicalInstitution,
                    buttonText: medicalInstitution ? medicalInstitution : '-',
                    path: WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION,
                    handleClick,
                },
                hasAppliedMedicalCare
            )}

            {renderHasExpenses({
                inputWrapperClass: hasExpensesClass,
                textKey: tWithNS('hasExpenses.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasExpenses,
                buttonText: t(createTextYesNoDontKnow(hasExpenses)),
                path: DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_EXPENSES,
                handleClick,
            })}

            {renderWhoPaidExpenses(
                {
                    inputWrapperClass: 'col-6 spacing align-button-start text-align-left',
                    textKey: tWithNS('whoPaidExpenses.heading.title'),
                    dataTestId: TEST_ID_PREFIX.whoPaidExpenses,
                    buttonText: whoPaidExpenses
                        ? t(`lob.person.workersComp.illness.whoPaidExpenses.select.${whoPaidExpenses}`)
                        : '-',
                    path: DYN_CAROUSEL_WORKERS_COMP_ILLNESS_WHO_PAID_EXPENSES,
                    handleClick,
                },
                hasExpenses
            )}

            {renderReceipt(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('uploadReceipt.heading.title'),
                    dataTestId: TEST_ID_PREFIX.uploadReceipt,
                    buttonText: '',
                    path: WORKERS_COMP_ILLNESS_UPLOAD_RECEIPT,
                    handleClick,
                },
                hasExpenses,
                upload.receipt
            )}
        </>
    );
};
