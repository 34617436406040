import React from 'react';
import {
    is,
    SummaryItem,
    SummaryItemProps,
    TypeOfVehicleEnums,
    TypeOfVehicleTypeModel,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { CAR, MOTORCYCLE } = TypeOfVehicleEnums;

/**
 * Component view and component logic
 */
export const renderRegistrationNumber = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    typeOfVehicle: TypeOfVehicleTypeModel
) => {
    if (is(typeOfVehicle, CAR) || is(typeOfVehicle, MOTORCYCLE)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
