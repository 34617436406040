import React from 'react';
import { useI18n } from '../../../hooks/useI18n';
import { Clickable, PageLayout, StartSummary } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useSelector } from 'react-redux';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const StopPage = () => {
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.stop');

    const handleBackButton = (e: Clickable) => {
        e.preventDefault();
        window.location.href = tWithNS.t('body.link');
    };

    return (
        <PageLayout
            backBtnText={tWithNS.t('button.end')}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <StartSummary
                description={tWithNS.t('body.paragraph.one')}
                title={tWithNS.t('body.title')}
                {...{ customCAN }}
            />
            <p tabIndex={0}>
                {tWithNS.t('body.paragraph.two')}{' '}
                <a href={`mailto:${tWithNS.t('body.email')}`}>{tWithNS.t('body.email')}</a>
            </p>
        </PageLayout>
    );
};
