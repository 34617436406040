import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectClaimDate } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { useGoBack } from '../../../../hooks/useGoBack';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import {
    Clickable,
    Datable,
    getLocaleFromUrl,
    MuiDatePicker,
    PageLayout,
    updateDateByDate,
} from '@protectorinsurance/ds-can';
import { getMomentLocale } from '../../../../utils/locale/getMomentLocale';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { CLAIM_DATE } = FormFieldNames;
const { MANDATORY_DATE_FIELD } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const AccidentClaimDatePage = () => {
    const dispatch = useDispatch();
    const claimDate = useSelector(selectClaimDate);
    const customCAN = useSelector(selectCustomCAN);
    const [date, setDate] = useState<Datable>(claimDate || null);
    const [error, setError] = useState<string>();
    const { t } = useI18n();
    const tWithNS = useI18n(`lob.person.health.accident.claimDate`);
    const handleBackButton = useGoBack();

    useEffect(() => {
        setDate(claimDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (date === null) {
            setError(t(MANDATORY_DATE_FIELD));
        } else {
            dispatcherWithPromise(dispatch, personActions.update, { claimDate })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const selectedDate = (date: Datable) => {
        if (date) {
            const newFullDate = updateDateByDate(claimDate, date);
            setDate(date);
            setError('');
            dispatch(personActions.update({ claimDate: newFullDate }));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <MuiDatePicker
                disableFuture={true}
                locale={getMomentLocale(getLocaleFromUrl())}
                name={CLAIM_DATE}
                onChange={selectedDate}
                {...{ customCAN, date, error }}
            />
        </PageLayout>
    );
};
