import React from 'react';
import { FileList, is, isOneOf, isYes, MotorClaimCauseKeys, SummaryItem } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { MotorRoutePaths } from '../../../../config/wizardRouter/motorWizardRoutes';
import { createTextYesNoDontKnow } from '../../../../utils/displayUtils';
import { claimCauseSummary } from '../../../../utils/claimCauseUtils';
import { MotorSummaryProps } from 'interfaces/types/SummaryTypes';

/**
 * Destructure necessary imports
 */
const {
    END_FIND_WORKSHOP,
    DYN_CAROUSEL_END_HAS_LICENSE,
    DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    DYN_CAROUSEL_END_HAS_PERSON_INJURIES,
    DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES,
    DYN_CAROUSEL_END_HAS_WITNESSES,
    DYN_CAROUSEL_END_IS_POLICE_CONTACTED,
    END_POLICE_CASE_NUMBER,
    END_POLICE_DISTRICT,
    END_UPLOAD_ATTACHMENT,
    END_UPLOAD_POLICE_REPORT,
    END_WHO_UNDER_INFLUENCE,
} = MotorRoutePaths;
const { ENGINE, OTHER, PARKING, THEFT_AND_DAMAGE } = MotorClaimCauseKeys;

/**
 * Page view and logic
 */
export const DisplayEndSummary = ({ model, upload, t, tWithNS }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const {
        claimCause,
        hasLicense,
        hasNonVehicleDamages,
        hasParkedWitnesses,
        hasPersonInjuries,
        hasVehicleDamages,
        hasWitnesses,
        isPoliceContacted,
        policeCaseNumber,
        policeDistrict,
        underInfluence,
        whoUnderInfluence,
        workshopInformation,
    } = model;
    const { report, file } = upload;

    const renderVehicleDamages = () => {
        return (
            <>
                {/* Damages */}
                {!is(claimCause, MotorClaimCauseKeys.ENGINE) && (
                    <SummaryItem
                        textKey={tWithNS('hasVehicleDamages.heading.title')}
                        dataTestId={TEST_ID_PREFIX.hasVehicleDamages}
                        buttonText={t(createTextYesNoDontKnow(hasVehicleDamages))}
                        path={DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES}
                        inputWrapperClass={'col-12 divider'}
                    />
                )}
            </>
        );
    };

    const renderWorkshopInformation = () => {
        return (
            <>
                {/* Find Workshop */}
                <SummaryItem
                    textKey={tWithNS('findWorkshop.heading.title')}
                    dataTestId={TEST_ID_PREFIX.workshopInformation}
                    buttonText={workshopInformation ? workshopInformation : '-'}
                    path={END_FIND_WORKSHOP}
                    inputWrapperClass={'col-12 divider'}
                />
            </>
        );
    };

    const renderOtherDamages = () => {
        return (
            <>
                {/* Other Damages */}
                {!isOneOf(claimCause, [ENGINE, THEFT_AND_DAMAGE, PARKING]) && (
                    <SummaryItem
                        textKey={tWithNS('hasNonVehicleDamages.heading.title')}
                        dataTestId={TEST_ID_PREFIX.hasNonVehicleDamages}
                        buttonText={t(createTextYesNoDontKnow(hasNonVehicleDamages))}
                        path={DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES}
                        inputWrapperClass={'col-12 divider'}
                    />
                )}
            </>
        );
    };

    const renderInjuries = () => {
        return (
            <>
                {/* Injuries */}
                {!isOneOf(claimCause, [ENGINE, THEFT_AND_DAMAGE, PARKING]) && (
                    <SummaryItem
                        textKey={tWithNS('hasPersonInjuries.heading.title')}
                        dataTestId={TEST_ID_PREFIX.hasPersonInjuries}
                        buttonText={t(createTextYesNoDontKnow(hasPersonInjuries))}
                        path={DYN_CAROUSEL_END_HAS_PERSON_INJURIES}
                        inputWrapperClass={'col-12 divider'}
                    />
                )}
            </>
        );
    };

    const renderPolice = () => {
        return (
            <>
                {/* Police */}
                <SummaryItem
                    textKey={tWithNS('isPoliceContacted.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isPoliceContacted}
                    buttonText={t(createTextYesNoDontKnow(isPoliceContacted))}
                    path={DYN_CAROUSEL_END_IS_POLICE_CONTACTED}
                    inputWrapperClass={`${isYes(isPoliceContacted) ? 'spacing' : 'divider'} col-12 text-align-left`}
                />

                {/* Reference */}
                {isYes(isPoliceContacted) && (
                    <SummaryItem
                        textKey={tWithNS('policeCaseNumber.heading.title')}
                        dataTestId={TEST_ID_PREFIX.policeCaseNumber}
                        buttonText={policeCaseNumber ? policeCaseNumber : '-'}
                        path={END_POLICE_CASE_NUMBER}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />
                )}

                {/* Police Report */}
                {isYes(isPoliceContacted) && (
                    <div className={'col-12 divider text-align-left'}>
                        <FileList
                            list={report.files}
                            path={END_UPLOAD_POLICE_REPORT}
                            show={report.files.length > 0}
                            dataTestId={TEST_ID_PREFIX.uploadPoliceReport}
                            textKey={`${tWithNS('uploadPoliceReport.heading.title')}`}
                        />
                    </div>
                )}

                {isYes(isPoliceContacted) && (
                    <SummaryItem
                        textKey={tWithNS('policeDistrict.heading.title')}
                        dataTestId={TEST_ID_PREFIX.policeDistrict}
                        buttonText={policeDistrict ? policeDistrict : '-'}
                        path={END_POLICE_DISTRICT}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />
                )}
            </>
        );
    };

    const renderUnderInfluence = () => {
        return (
            <>
                {/* Under Influence */}
                <SummaryItem
                    textKey={tWithNS('isUnderInfluence.heading.title')}
                    dataTestId={TEST_ID_PREFIX.underInfluence}
                    buttonText={t(createTextYesNoDontKnow(underInfluence))}
                    path={END_WHO_UNDER_INFLUENCE}
                    inputWrapperClass={'col-12 spacing text-align-left'}
                />

                {/* Who Under Influence */}
                {isYes(underInfluence) && (
                    <SummaryItem
                        textKey={tWithNS('whoUnderInfluence.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoUnderInfluence}
                        buttonText={whoUnderInfluence ? t(`motor.whoUnderInfluence.select.${whoUnderInfluence}`) : '-'}
                        path={END_WHO_UNDER_INFLUENCE}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />
                )}
            </>
        );
    };

    const renderWitnesses = () => {
        return (
            <>
                {/* Has Witnesses */}
                {!isYes(hasParkedWitnesses) && (
                    <SummaryItem
                        textKey={tWithNS('hasWitnesses.heading.title')}
                        dataTestId={TEST_ID_PREFIX.hasWitnesses}
                        buttonText={t(createTextYesNoDontKnow(hasWitnesses))}
                        path={DYN_CAROUSEL_END_HAS_WITNESSES}
                        inputWrapperClass={'col-12 divider'}
                    />
                )}
            </>
        );
    };

    const renderUpload = () => {
        return (
            <>
                {/* Upload */}
                {file && file.files.length > 0 && (
                    <div className={'col-12 divider text-align-left'}>
                        <FileList
                            list={file.files}
                            path={END_UPLOAD_ATTACHMENT}
                            show={file.files.length > 0}
                            dataTestId={TEST_ID_PREFIX.uploadAttachments}
                            textKey={`${tWithNS('uploadAttachment.heading.title')}`}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderHasLicense = () => {
        return (
            <SummaryItem
                textKey={tWithNS('hasLicense.heading.title')}
                dataTestId={TEST_ID_PREFIX.hasLicense}
                buttonText={t(createTextYesNoDontKnow(hasLicense))}
                path={DYN_CAROUSEL_END_HAS_LICENSE}
                inputWrapperClass={'col-12 spacing text-align-left'}
            />
        );
    };

    return (
        <>
            {!is(claimCause, OTHER) && !claimCauseSummary(claimCause) && (
                <>
                    {renderVehicleDamages()}

                    {renderWorkshopInformation()}

                    {renderOtherDamages()}

                    {renderInjuries()}

                    {renderPolice()}

                    {renderUnderInfluence()}

                    {renderWitnesses()}

                    {renderUpload()}

                    {renderHasLicense()}
                </>
            )}
        </>
    );
};
