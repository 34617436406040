import { TFunction } from 'i18next';

/**
 * Utility logic
 */
export enum WhoUnderInfluenceTypeKeys {
    CLAIMANT = 'CLAIMANT',
    COUNTERPARTY = 'COUNTERPARTY',
    PEDESTRIAN = 'PEDESTRIAN',
    OTHER = 'OTHER',
}

const createWhoUnderInfluenceKeys = (id: string) => {
    return `motor.whoUnderInfluence.select.${id}`;
};

export const getWhoUnderInfluenceOption = (t: TFunction, key: WhoUnderInfluenceTypeKeys) => {
    return {
        value: key,
        label: t(createWhoUnderInfluenceKeys(key)),
    };
};
