import React from 'react';
import { CarouselConfigLookupResponse, NoIcon, YesIcon, YesNoKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { selectHasAnimalEscaped } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const hasAnimalEscapedPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.driving.hasAnimalEscaped.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.hasAnimalEscaped.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_ANIMAL_HAS_ANIMAL_ESCAPED,
        stateKey: 'hasAnimalEscaped',
        i18n: {
            ns: 'motor.driving.hasAnimalEscaped',
        },
        selectorValue: selectHasAnimalEscaped(state),
        options,
    };
};
