import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

const { ROLE_LABEL } = PhraseKeys;

export const employmentInformationSchema = (t: TFunction) => {
    return {
        role: Yup.string().label(t(ROLE_LABEL)).required().noSpecialChars().nullable(),
    };
};
