import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { policyNumberSchema } from './policyNumberSchema';

/**
 * Destructure necessary imports
 */
const { COMPANY_NAME_LABEL, COMPANY_BUSINESS_NUMBER_LABEL, COMPANY_DEPARTMENT_NAME_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const companySchema = (t: TFunction, required: boolean) => {
    return {
        name: Yup.string().label(t(COMPANY_NAME_LABEL)).required().noSpecialChars().nullable(),
        businessNumber: Yup.string().label(t(COMPANY_BUSINESS_NUMBER_LABEL)).businessNumber(true).nullable(),
        departmentName: Yup.string().label(t(COMPANY_DEPARTMENT_NAME_LABEL)).noSpecialChars().nullable(),
        ...policyNumberSchema(t, required),
    };
};
