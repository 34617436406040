import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    DamageLocationTypeEnums,
    FormChangeable,
    Grid,
    is,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectLocationType } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { useGoBack } from '../../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { wizardRouterActions } from '../../../../sagas/wizardRouter';
import { commonActions } from '../../../../sagas/common';
import { personActions } from '../../../../sagas/person';
import { getWorkplaceOption } from '../../../../utils/person/workplaceUtils';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    LOCATION_TYPE_LABEL,
    LOCATION_TYPE_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { LOCATION_TYPE } = FormFieldNames;
const {
    BREEDING_AREA,
    CONSTRUCTION_SITE,
    CONSTRUCTION_SITE_MAINTENANCE,
    CULTURAL_AREA,
    FARMING_AREA_GROUND_CROP,
    FARMING_AREA_TREE_BUSH_CROP,
    FORESTRY_ZONE,
    HEALTH_ESTABLISHMENT,
    HOME_AREA,
    MAINTENANCE_AREA,
    NO_INFO,
    OFFICE_AREA,
    OTHER_GROUP_010,
    OTHER_GROUP_020,
    OTHER_GROUP_030,
    OTHER_GROUP_090,
    PRODUCTION_AREA,
    PUBLIC_AREA,
    QUARRIES_AND_MINES,
    SALES_AREA,
    SCHOOL,
    STORAGE,
} = DamageLocationTypeEnums;

/**
 * Page view and page logic
 */
export const WorkersCompAccidentWorkplacePage = () => {
    const dispatch = useDispatch();
    const [workplace, setWorkplace] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const locationType = useSelector(selectLocationType);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.accident.workplace');

    // Options
    // Numbers are set based on what Backend needs for mapping
    const productionAreaOption = getWorkplaceOption(t, PRODUCTION_AREA, '23');
    const maintenanceAreaOption = getWorkplaceOption(t, MAINTENANCE_AREA, '24');
    const storageOption = getWorkplaceOption(t, STORAGE, '25');
    const otherGroup010Option = getWorkplaceOption(t, OTHER_GROUP_010, '26');
    const constructionSiteOption = getWorkplaceOption(t, CONSTRUCTION_SITE, '27');
    const constructionSiteMaintenanceOption = getWorkplaceOption(t, CONSTRUCTION_SITE_MAINTENANCE, '28');
    const quarriesAndMinesOption = getWorkplaceOption(t, QUARRIES_AND_MINES, '29');
    const otherGroup020Option = getWorkplaceOption(t, OTHER_GROUP_020, '30');
    const breedingAreaOption = getWorkplaceOption(t, BREEDING_AREA, '31');
    const farmingAreaGroundCropOption = getWorkplaceOption(t, FARMING_AREA_GROUND_CROP, '32');
    const farmingAreaTreeBushCropOption = getWorkplaceOption(t, FARMING_AREA_TREE_BUSH_CROP, '33');
    const forestryZoneOption = getWorkplaceOption(t, FORESTRY_ZONE, '34');
    const otherGroup030Option = getWorkplaceOption(t, OTHER_GROUP_030, '35');
    const officeAreaOption = getWorkplaceOption(t, OFFICE_AREA, '36');
    const schoolOption = getWorkplaceOption(t, SCHOOL, '37');
    const salesAreaOption = getWorkplaceOption(t, SALES_AREA, '38');
    const culturalAreaOption = getWorkplaceOption(t, CULTURAL_AREA, '39');
    const healthEstablishmentOption = getWorkplaceOption(t, HEALTH_ESTABLISHMENT, '40');
    const publicAreaOption = getWorkplaceOption(t, PUBLIC_AREA, '41');
    const homeAreaOption = getWorkplaceOption(t, HOME_AREA, '42');
    const otherGroup090Option = getWorkplaceOption(t, OTHER_GROUP_090, '43');
    const noInfoOption = getWorkplaceOption(t, NO_INFO, '44');

    const options = [
        productionAreaOption,
        maintenanceAreaOption,
        storageOption,
        otherGroup010Option,
        constructionSiteOption,
        constructionSiteMaintenanceOption,
        quarriesAndMinesOption,
        otherGroup020Option,
        breedingAreaOption,
        farmingAreaGroundCropOption,
        farmingAreaTreeBushCropOption,
        forestryZoneOption,
        otherGroup030Option,
        officeAreaOption,
        schoolOption,
        salesAreaOption,
        culturalAreaOption,
        healthEstablishmentOption,
        publicAreaOption,
        homeAreaOption,
        otherGroup090Option,
        noInfoOption,
    ];

    useEffect(() => {
        const selected = options.find((x) => Number(x.value) === locationType?.id);
        if (selected) {
            setWorkplace(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationType, setWorkplace]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(locationType?.id, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        const key = object ? object.label : null;
        setError('');
        setWorkplace(object);
        dispatch(personActions.update({ locationType: { id: Number(value), key } }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(locationType?.id, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={LOCATION_TYPE}
                    inputFieldWrapper={'col-12'}
                    label={t(LOCATION_TYPE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(LOCATION_TYPE_PLACEHOLDER)}
                    value={workplace}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
