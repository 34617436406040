import React from 'react';
import { CarouselConfigLookupResponse, NoIcon, YesIcon, YesNoKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { selectIsDriving } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const isDrivingPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.start.isDriving.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.start.isDriving.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING,
        stateKey: 'isDriving',
        i18n: {
            ns: 'motor.start.isDriving',
        },
        selectorValue: selectIsDriving(state),
        options,
    };
};
