import { PersonRoutePaths } from './personWizardRoutes';
import { FlowKeys, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { strReplace } from '../../utils/strings/strReplace';

const personProgressBar = (path: PersonRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const find = [':language'];
    const replace = [language ? language : ''];
    return strReplace(path, find, replace);
};

export const personGroupAccidentProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.ACCIDENT]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_ONBOARDING),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_PRIVACY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_CLAIM_LOCATION),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_CLAIM_DATE),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_NATURE_TYPE_ID),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_CLAIM_CAUSE),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_BODY_PARTS),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_APPLIED_KELA),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_WHO_PAID_EXPENSES),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_UPLOAD_RECEIPT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_OTHER_INSURANCE_COMPANY),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_COMPANY_INFORMATION),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.GROUP_ACCIDENT_REPORTER_INFORMATION),
    ],
};
