import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

const { NATIONALITY_LABEL } = PhraseKeys;

export const nationalitySchema = (t: TFunction) => {
    return {
        nationality: Yup.string().label(t(NATIONALITY_LABEL)).required().noSpecialChars().nullable(),
    };
};
