import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { selectOtherInsuranceCompany } from '../../../../sagas/selectors/personSelectors';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { otherInsuranceCompanySchema } from '../../../../validations/schemas/otherInsuranceCompanySchema';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    OtherInsuranceCompanyModel,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { personActions } from '../../../../sagas/person';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { OTHER_INSURANCE_COMPANY } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    OTHER_INSURANCE_COMPANY_LABEL,
    OTHER_INSURANCE_COMPANY_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const IllnessOtherInsuranceCompanyPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.health.illness.otherInsuranceCompany');
    const otherInsuranceCompany = useSelector(selectOtherInsuranceCompany);
    const customCAN = useSelector(selectCustomCAN);

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<OtherInsuranceCompanyModel>({
        resolver: yupResolver(otherInsuranceCompanySchema(t)),
        defaultValues: {
            otherInsuranceCompany,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: OtherInsuranceCompanyModel) => {
        dispatcherWithPromise(dispatch, personActions.update, { otherInsuranceCompany: values.otherInsuranceCompany })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={OTHER_INSURANCE_COMPANY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.otherInsuranceCompany}
                                errorMessage={errors.otherInsuranceCompany?.message}
                                id={OTHER_INSURANCE_COMPANY}
                                inputFieldWrapper={'col-12'}
                                label={t(OTHER_INSURANCE_COMPANY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(OTHER_INSURANCE_COMPANY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
