import React, { ReactNode } from 'react';
import './Tabs.scss';
import { Clickable } from '@protectorinsurance/ds-can';

interface TabProps {
    id: string;
    children: ReactNode;
    isActive: boolean;
    onClick: (e: Clickable) => void;
    className?: string;
}

const Tab = ({ id, children, isActive, onClick, className }: TabProps) => {
    return (
        <button
            id={id}
            role="tab"
            className={`tabs_tab ${isActive ? 'active' : ''} ${className}`}
            onClick={onClick}
            aria-selected={isActive}
            aria-controls={`${id}-tab`}
            data-testid={id}
        >
            {children}
        </button>
    );
};

export default Tab;
