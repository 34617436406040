import { TFunction } from 'i18next';

/**
 * Utility logic
 */
export enum PlaceOfAccidentKeys {
    BEND = 'BEND',
    BRIDGE = 'BRIDGE',
    EQUAL_PRIORITY_JUNCTION = 'EQUAL_PRIORITY_JUNCTION',
    LEVEL_CROSSING = 'LEVEL_CROSSING',
    OTHER = 'OTHER',
    PARKING_AREA = 'PARKING_AREA',
    PRIVATE_ROAD_JUNCTION = 'PRIVATE_ROAD_JUNCTION',
    RIGHT_OF_WAY_JUNCTION = 'RIGHT_OF_WAY_JUNCTION',
    STRAIGHT_ROAD = 'STRAIGHT_ROAD',
}

const createPlaceOfAccidentKey = (id: string) => {
    return `motor.placeOfAccident.select.${id}`;
};

export const getPlaceOfAccidentOption = (t: TFunction, key: PlaceOfAccidentKeys) => {
    return {
        value: key,
        label: t(createPlaceOfAccidentKey(key)),
    };
};
