import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { NOTE_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const locationSchema = (t: TFunction, optional: boolean) => {
    if (optional) {
        return {
            note: Yup.string().label(t(NOTE_LABEL)).ensure().nullable(),
        };
    } else {
        return {
            note: Yup.string().label(t(NOTE_LABEL)).ensure().required().nullable(),
        };
    }
};
