import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { Carousel, CarouselItem, Clickable, PageLayout, StartSummary, YesIcon } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { flowActions } from '../../../sagas/flow';
import { goBack } from 'connected-react-router';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const WelcomePage = () => {
    const dispatch = useDispatch();
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.start.welcome');

    const handleBackButton = () => {
        dispatcherWithPromise(dispatch, flowActions.update, null).then(() => dispatch(goBack()));
    };

    const options = [{ id: 'language', name: tWithNS.t('select.CONFIRM'), icon: <YesIcon /> }];

    const selected = (id: 'language', e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const renderSelection = options.map((option, idx) => {
        return (
            <CarouselItem
                {...option}
                handleClick={selected}
                isSelected={option.id === ''}
                key={idx}
                {...{ customCAN }}
            />
        );
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <StartSummary
                description={tWithNS.t('body.paragraph.one')}
                sessionText={tWithNS.t('body.paragraph.two')}
                title={tWithNS.t('body.title')}
                {...{ customCAN }}
            />
            <Carousel>{renderSelection}</Carousel>
        </PageLayout>
    );
};
