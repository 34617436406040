import React from 'react';
import { MotorRoutePaths } from '../../../../config/wizardRouter/motorWizardRoutes';
import {
    ClaimLocationKeys,
    CollisionSituationKeys,
    initVehicleModel,
    is,
    isYes,
    MotorClaimCauseKeys,
    SummaryItem,
    SummaryVehicleList,
    TypeOfAnimalCollisionKeys,
    TypeOfIntersectionKeys,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { createTextYesNoDontKnow } from '../../../../utils/displayUtils';
import { MotorSummaryProps } from '../../../../interfaces/types/SummaryTypes';

/**
 * Destructure necessary imports
 */
const {
    DRIVING_NO_VEHICLE_PLACE_OF_ACCIDENT,
    DRIVING_VEHICLE_COLLISION,
    DRIVING_VEHICLE_PLACE_OF_ACCIDENT,
    DRIVING_VEHICLE_ROAD_INFORMATION,
    DRIVING_VEHICLE_SPEED_INFORMATION,
    END_VEHICLE_WHO_RESPONSIBLE,
    DYN_CAROUSEL_ANIMAL_HAS_ANIMAL_ESCAPED,
    DYN_CAROUSEL_ANIMAL_TYPE_OF_ANIMAL,
    DYN_CAROUSEL_DRIVING_CLAIM_LOCATION_TYPE,
    DYN_CAROUSEL_DRIVING_LIGHT_OF_DAY,
    DYN_CAROUSEL_DRIVING_ROAD_CONDITION,
    DYN_CAROUSEL_INTERSECTION_IS_TRAFFIC_LIGHTS,
    DYN_CAROUSEL_DRIVING_IS_URBAN_AREA,
    DYN_CAROUSEL_INTERSECTION_TYPE_OF_INTERSECTION,
    DYN_CAROUSEL_INTERSECTION_TYPE_OF_ROAD,
    DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT,
    DYN_CAROUSEL_NO_VEHICLE_TYPE_OF_ROAD,
    DYN_CAROUSEL_OVERTAKING_WHO_AT_FAULT,
    DYN_CAROUSEL_PARKED_WHO_AT_FAULT,
    DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT,
    DYN_CAROUSEL_REVERSING_WHO_AT_FAULT,
    DYN_CAROUSEL_ROAD_TYPE_OF_ROAD,
    DYN_CAROUSEL_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING,
    DYN_CAROUSEL_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING,
    DYN_CAROUSEL_ROUNDABOUT_TYPE_OF_ROAD,
    DYN_CAROUSEL_HEAD_ON_IS_COUNTERPARTY_STATIONARY,
    DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE,
    DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED,
    DYN_CAROUSEL_PARKED_IS_REVERSING,
} = MotorRoutePaths;
const {
    ANIMAL_COLLISION,
    CYCLIST_COLLISION,
    DITCH_COLLISION,
    HEAD_ON,
    LANE_CHANGE,
    OTHER,
    OVERTAKING,
    PARKED,
    PEDESTRIAN_COLLISION,
    REAR_ENDED,
    REVERSING,
    STATIONARY_OBJECT_COLLISION,
} = MotorClaimCauseKeys;
const { GAME } = TypeOfAnimalCollisionKeys;
const { INTERSECTION, ROAD, ROUNDABOUT } = ClaimLocationKeys;
const { AFTER } = CollisionSituationKeys;
const { LIGHT_CONTROLLED } = TypeOfIntersectionKeys;

/**
 * Page view and logic
 */
export const DisplayMotorDrivingSummary = ({ model, upload, t, tWithNS }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const {
        claimCause,
        claimLocationType,
        collisionSituation,
        claimantRoundaboutPositioning,
        counterpartyRoundaboutPositioning,
        distanceFromRoadSide,
        drivingSpeed,
        hasAnimalEscaped,
        isCounterpartyStationary,
        isCyclistResponsible,
        isLaneChange,
        isOtherVehicleInvolved,
        isReversing,
        isUrbanArea,
        lightOfDay,
        isTrafficLights,
        placeOfAccident,
        roadCondition,
        roadWidth,
        speedLimit,
        speedOnImpact,
        timeSinceAction,
        typeOfAnimalCollision,
        typeOfIntersection,
        typeOfRoad,
        vehicles,
        whoAtFault,
    } = model;

    const renderClaimCause = () => {
        return (
            <>
                {/* Is Other Vehicle Involved */}
                <SummaryItem
                    textKey={tWithNS('isOtherVehicleInvolved.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isOtherVehicleInvolved}
                    buttonText={t(createTextYesNoDontKnow(isOtherVehicleInvolved))}
                    path={DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />
            </>
        );
    };

    const renderAnimal = () => {
        return (
            is(claimCause, ANIMAL_COLLISION) && (
                <>
                    {/* Type Of Animal */}
                    <SummaryItem
                        textKey={tWithNS('typeOfAnimal.heading.title')}
                        dataTestId={TEST_ID_PREFIX.typeOfAnimal}
                        buttonText={t(`motor.driving.typeOfAnimal.select.${typeOfAnimalCollision}`)}
                        path={DYN_CAROUSEL_ANIMAL_TYPE_OF_ANIMAL}
                        inputWrapperClass={`${
                            !is(typeOfAnimalCollision, GAME) ? 'spacing' : 'divider'
                        } col-12 text-align-left`}
                    />

                    {/* Has Animal Escaped */}
                    {!is(typeOfAnimalCollision, GAME) && (
                        <SummaryItem
                            textKey={tWithNS('hasAnimalEscaped.heading.title')}
                            dataTestId={TEST_ID_PREFIX.hasAnimalEscaped}
                            buttonText={t(createTextYesNoDontKnow(hasAnimalEscaped))}
                            path={DYN_CAROUSEL_ANIMAL_HAS_ANIMAL_ESCAPED}
                            inputWrapperClass={'col-12 divider text-align-left'}
                        />
                    )}
                </>
            )
        );
    };

    const renderNoVehicle = () => {
        return (
            (is(claimCause, ANIMAL_COLLISION) ||
                is(claimCause, DITCH_COLLISION) ||
                is(claimCause, OTHER) ||
                is(claimCause, PEDESTRIAN_COLLISION) ||
                is(claimCause, STATIONARY_OBJECT_COLLISION)) && (
                <>
                    {/* Type Of Road */}
                    <SummaryItem
                        textKey={tWithNS('noVehicleTypeOfRoad.heading.title')}
                        dataTestId={TEST_ID_PREFIX.typeOfRoad}
                        buttonText={typeOfRoad ? t(`motor.driving.noVehicleTypeOfRoad.select.${typeOfRoad}`) : '-'}
                        path={DYN_CAROUSEL_NO_VEHICLE_TYPE_OF_ROAD}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />

                    {/* Place Of Accident */}
                    <SummaryItem
                        textKey={tWithNS('noVehiclePlaceOfAccident.heading.title')}
                        dataTestId={TEST_ID_PREFIX.placeOfAccident}
                        buttonText={placeOfAccident ? t(`motor.placeOfAccident.select.${placeOfAccident}`) : '-'}
                        path={DRIVING_NO_VEHICLE_PLACE_OF_ACCIDENT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderClaimSituation = () => {
        let responsibleVehicles = vehicles.filter((v: VehicleModel) => v.isResponsible);
        if (isCyclistResponsible) {
            responsibleVehicles = [
                ...responsibleVehicles,
                { ...initVehicleModel, make: t('motor.driving.whoResponsible.text.cyclist') },
            ];
        }
        return (
            (isYes(isOtherVehicleInvolved) ||
                (!isYes(isOtherVehicleInvolved) && is(claimCause, CYCLIST_COLLISION))) && (
                <>
                    {/* Speed Limit */}
                    <SummaryItem
                        textKey={tWithNS('speedLimit.heading.title')}
                        dataTestId={TEST_ID_PREFIX.speedLimit}
                        buttonText={speedLimit || speedLimit === 0 ? speedLimit.toString() : '-'}
                        path={DRIVING_VEHICLE_SPEED_INFORMATION}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Driving Speed */}
                    <SummaryItem
                        textKey={tWithNS('drivingSpeed.heading.title')}
                        dataTestId={TEST_ID_PREFIX.drivingSpeed}
                        buttonText={drivingSpeed || drivingSpeed === 0 ? drivingSpeed.toString() : '-'}
                        path={DRIVING_VEHICLE_SPEED_INFORMATION}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Speed On Impact */}
                    <SummaryItem
                        textKey={tWithNS('speedOnImpact.heading.title')}
                        dataTestId={TEST_ID_PREFIX.speedOnImpact}
                        buttonText={speedOnImpact || speedOnImpact === 0 ? speedOnImpact.toString() : '-'}
                        path={DRIVING_VEHICLE_SPEED_INFORMATION}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Who Responsible */}
                    {responsibleVehicles && responsibleVehicles.length > 0 && (
                        <SummaryVehicleList
                            className={'col-12 spacing text-align-left'}
                            list={responsibleVehicles}
                            path={END_VEHICLE_WHO_RESPONSIBLE}
                            textKey={tWithNS('whoResponsible.heading.title')}
                            dataTestId={TEST_ID_PREFIX.vehicleList}
                        />
                    )}

                    {/* Claim Location Type */}
                    <SummaryItem
                        textKey={tWithNS('claimLocationType.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimLocationType}
                        buttonText={
                            claimLocationType ? t(`motor.driving.claimLocationType.select.${claimLocationType}`) : '-'
                        }
                        path={DYN_CAROUSEL_DRIVING_CLAIM_LOCATION_TYPE}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />

                    {/* Place Of Accident */}
                    <SummaryItem
                        textKey={tWithNS('placeOfAccident.heading.title')}
                        dataTestId={TEST_ID_PREFIX.placeOfAccident}
                        buttonText={placeOfAccident ? t(`motor.placeOfAccident.select.${placeOfAccident}`) : '-'}
                        path={DRIVING_VEHICLE_PLACE_OF_ACCIDENT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderRoad = () => {
        return (
            is(claimLocationType, ROAD) && (
                <>
                    {/* Type Of Road */}
                    <SummaryItem
                        textKey={tWithNS('typeOfRoad.heading.title')}
                        dataTestId={TEST_ID_PREFIX.typeOfRoad}
                        buttonText={typeOfRoad ? t(`motor.driving.typeOfRoad.select.${typeOfRoad}`) : '-'}
                        path={DYN_CAROUSEL_ROAD_TYPE_OF_ROAD}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderRoundabout = () => {
        return (
            is(claimLocationType, ROUNDABOUT) && (
                <>
                    {/* Type Of Road */}
                    <SummaryItem
                        textKey={tWithNS('roundaboutTypeOfRoad.heading.title')}
                        dataTestId={TEST_ID_PREFIX.typeOfRoad}
                        buttonText={typeOfRoad ? t(`motor.driving.roundaboutTypeOfRoad.select.${typeOfRoad}`) : '-'}
                        path={DYN_CAROUSEL_ROUNDABOUT_TYPE_OF_ROAD}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />

                    {/* Claimant Roundabout Positioning */}
                    <SummaryItem
                        textKey={tWithNS('claimantRoundaboutPositioning.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimantRoundaboutPositioning}
                        buttonText={
                            claimantRoundaboutPositioning
                                ? t(
                                      `motor.driving.claimantRoundaboutPositioning.select.${claimantRoundaboutPositioning}`
                                  )
                                : '-'
                        }
                        path={DYN_CAROUSEL_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Counterparty Roundabout Positioning */}
                    <SummaryItem
                        textKey={tWithNS('counterpartyRoundaboutPositioning.heading.title')}
                        dataTestId={TEST_ID_PREFIX.counterpartyRoundaboutPositioning}
                        buttonText={
                            counterpartyRoundaboutPositioning
                                ? t(
                                      `motor.driving.counterpartyRoundaboutPositioning.select.${counterpartyRoundaboutPositioning}`
                                  )
                                : '-'
                        }
                        path={DYN_CAROUSEL_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderIntersection = () => {
        return (
            is(claimLocationType, INTERSECTION) && (
                <>
                    {/* Type Of Road */}
                    <SummaryItem
                        textKey={tWithNS('intersectionTypeOfRoad.heading.title')}
                        dataTestId={TEST_ID_PREFIX.typeOfRoad}
                        buttonText={typeOfRoad ? t(`motor.driving.intersectionTypeOfRoad.select.${typeOfRoad}`) : '-'}
                        path={DYN_CAROUSEL_INTERSECTION_TYPE_OF_ROAD}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />

                    {/* Type Of Intersection */}
                    <SummaryItem
                        textKey={tWithNS('typeOfIntersection.heading.title')}
                        dataTestId={TEST_ID_PREFIX.typeOfIntersection}
                        buttonText={
                            typeOfIntersection
                                ? t(`motor.driving.typeOfIntersection.select.${typeOfIntersection}`)
                                : '-'
                        }
                        path={DYN_CAROUSEL_INTERSECTION_TYPE_OF_INTERSECTION}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />

                    {/* Is Traffic Lights */}
                    {is(typeOfIntersection, LIGHT_CONTROLLED) && (
                        <SummaryItem
                            textKey={tWithNS('isTrafficLights.heading.title')}
                            dataTestId={TEST_ID_PREFIX.isTrafficLights}
                            buttonText={t(createTextYesNoDontKnow(isTrafficLights))}
                            path={DYN_CAROUSEL_INTERSECTION_IS_TRAFFIC_LIGHTS}
                            inputWrapperClass={'col-12 divider text-align-left'}
                        />
                    )}
                </>
            )
        );
    };

    const renderReversing = () => {
        return (
            is(claimCause, REVERSING) && (
                <>
                    {/* Reversing Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('reversingWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.reversingWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_REVERSING_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderLaneChange = () => {
        return (
            is(claimCause, LANE_CHANGE) && (
                <>
                    {/* Lane Change Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('laneChangeWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.laneChangeWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderOvertaking = () => {
        return (
            is(claimCause, OVERTAKING) && (
                <>
                    {/* Overtaking Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('overtakingWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.overtakingWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_OVERTAKING_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderHeadOn = () => {
        return (
            is(claimCause, HEAD_ON) && (
                <>
                    {/* Road Width */}
                    <SummaryItem
                        textKey={tWithNS('roadWidth.heading.title')}
                        dataTestId={TEST_ID_PREFIX.roadWidth}
                        buttonText={roadWidth || roadWidth === 0 ? roadWidth.toString() : '-'}
                        path={DRIVING_VEHICLE_ROAD_INFORMATION}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Distance From Road Side */}
                    <SummaryItem
                        textKey={tWithNS('distanceFromRoadSide.heading.title')}
                        dataTestId={TEST_ID_PREFIX.distanceFromRoadSide}
                        buttonText={
                            distanceFromRoadSide || distanceFromRoadSide === 0 ? distanceFromRoadSide.toString() : '-'
                        }
                        path={DRIVING_VEHICLE_ROAD_INFORMATION}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Is Counterparty Stationary */}
                    <SummaryItem
                        textKey={tWithNS('isCounterpartyStationary.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isCounterpartyStationary}
                        buttonText={t(createTextYesNoDontKnow(isCounterpartyStationary))}
                        path={DYN_CAROUSEL_HEAD_ON_IS_COUNTERPARTY_STATIONARY}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderParked = () => {
        return (
            is(claimCause, PARKED) && (
                <>
                    {/* Parked Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('parkedWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.parkedWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_PARKED_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Is Reversing */}
                    <SummaryItem
                        textKey={tWithNS('isReversing.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isReversing}
                        buttonText={t(createTextYesNoDontKnow(isReversing))}
                        path={DYN_CAROUSEL_PARKED_IS_REVERSING}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderRearEnded = () => {
        return (
            is(claimCause, REAR_ENDED) && (
                <>
                    {/* Rear Ended Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('rearEndedWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.rearEndedWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderCollision = () => {
        return (
            (is(claimCause, REVERSING) || is(claimCause, LANE_CHANGE)) && (
                <>
                    {/* Collision Situation */}
                    <SummaryItem
                        textKey={tWithNS(
                            claimCause === REVERSING
                                ? 'reversingCollision.heading.title'
                                : 'laneChangeCollision.heading.title'
                        )}
                        dataTestId={TEST_ID_PREFIX.collisionSituation}
                        buttonText={
                            collisionSituation
                                ? t(
                                      claimCause === REVERSING
                                          ? `motor.driving.reversingCollision.${collisionSituation}`
                                          : `motor.driving.laneChangeCollision.${collisionSituation}`
                                  )
                                : '-'
                        }
                        path={DRIVING_VEHICLE_COLLISION}
                        inputWrapperClass={`${
                            is(collisionSituation, AFTER) ? 'spacing' : 'divider'
                        } col-12 text-align-left`}
                    />

                    {/* Time Since Action */}
                    {is(collisionSituation, AFTER) && (
                        <SummaryItem
                            textKey={tWithNS(
                                claimCause === REVERSING
                                    ? 'reversingTimeSinceAction.heading.title'
                                    : 'laneChangeTimeSinceAction.heading.title'
                            )}
                            dataTestId={TEST_ID_PREFIX.timeSinceAction}
                            buttonText={timeSinceAction ? timeSinceAction.toString() : '-'}
                            path={DRIVING_VEHICLE_COLLISION}
                            inputWrapperClass={'col-12 divider text-align-left'}
                        />
                    )}
                </>
            )
        );
    };

    const renderIsLaneChange = () => {
        return (
            (is(claimCause, HEAD_ON) || is(claimCause, OVERTAKING) || is(claimCause, REAR_ENDED)) && (
                <>
                    {/* Is Lane Change */}
                    <SummaryItem
                        textKey={tWithNS('isLaneChange.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isLaneChange}
                        buttonText={t(createTextYesNoDontKnow(isLaneChange))}
                        path={DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderDrivingEnd = () => {
        return (
            <>
                {/* Road Condition */}
                <SummaryItem
                    textKey={tWithNS('roadCondition.heading.title')}
                    dataTestId={TEST_ID_PREFIX.roadCondition}
                    buttonText={roadCondition ? t(`motor.driving.roadCondition.select.${roadCondition}`) : '-'}
                    path={DYN_CAROUSEL_DRIVING_ROAD_CONDITION}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />

                {/* Light Of Day */}
                <SummaryItem
                    textKey={tWithNS('lightOfDay.heading.title')}
                    dataTestId={TEST_ID_PREFIX.lightOfDay}
                    buttonText={lightOfDay ? t(`motor.driving.lightOfDay.select.${lightOfDay}`) : '-'}
                    path={DYN_CAROUSEL_DRIVING_LIGHT_OF_DAY}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />

                {/* Is Urban Area */}
                <SummaryItem
                    textKey={tWithNS('isUrbanArea.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isUrbanArea}
                    buttonText={t(createTextYesNoDontKnow(isUrbanArea))}
                    path={DYN_CAROUSEL_DRIVING_IS_URBAN_AREA}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />
            </>
        );
    };

    return (
        <>
            {renderClaimCause()}

            {renderAnimal()}

            {renderNoVehicle()}

            {renderClaimSituation()}

            {renderRoad()}

            {renderRoundabout()}

            {renderIntersection()}

            {renderHeadOn()}

            {renderOvertaking()}

            {renderLaneChange()}

            {renderReversing()}

            {renderRearEnded()}

            {renderParked()}

            {renderCollision()}

            {renderIsLaneChange()}

            {renderDrivingEnd()}
        </>
    );
};
