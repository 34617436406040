import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    Datable,
    Grid,
    InputLabel,
    PageLayout,
    TimePicker,
    updateDateByHour,
    updateDateByMinutes,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import { commonActions } from '../../../../sagas/common';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { personActions } from '../../../../sagas/person';
import { selectClaimantWorkDay } from 'sagas/selectors/personSelectors';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure models, phrases etc.
 */
const { BACK_BUTTON, CONTINUE_BUTTON, FROM_TIME_LABEL, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE, TO_TIME_LABEL } =
    PhraseKeys;
const { FROM_TIME, TO_TIME } = FormFieldNames;
const { MANDATORY_TIME_FIELD } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const WorkersCompAccidentClaimantWorkDayPage = () => {
    const dispatch = useDispatch();
    const claimantWorkDay = useSelector(selectClaimantWorkDay);
    const customCAN = useSelector(selectCustomCAN);
    const [fromTime, setFromTime] = useState<Datable>(null);
    const [toTime, setToTime] = useState<Datable>(null);
    const [fromError, setFromError] = useState<string>('');
    const [toError, setToError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.accident.claimantWorkDay');
    const handleBackButton = useGoBack();

    useEffect(() => {
        setFromTime(claimantWorkDay.from);
        setToTime(claimantWorkDay.to);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (fromTime === null && toTime === null) {
            setFromError(t(MANDATORY_TIME_FIELD));
            setToError(t(MANDATORY_TIME_FIELD));
        } else if (fromTime === null) {
            setToError('');
            setFromError(t(MANDATORY_TIME_FIELD));
        } else if (toTime === null) {
            setFromError('');
            setToError(t(MANDATORY_TIME_FIELD));
        } else {
            setFromError('');
            setToError('');
            dispatcherWithPromise(dispatch, personActions.update, { claimantWorkDay })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const onFromHourSelect = (newHour: number) => {
        const newFullDate = updateDateByHour(claimantWorkDay.from, newHour);
        setFromTime(newFullDate);
        setFromError('');
        dispatch(personActions.update({ claimantWorkDay: { ...claimantWorkDay, from: newFullDate } }));
    };

    const onFromMinSelect = (newMin: number) => {
        const newFullDate = updateDateByMinutes(claimantWorkDay.from, newMin);
        setFromTime(newFullDate);
        setFromError('');
        dispatch(personActions.update({ claimantWorkDay: { ...claimantWorkDay, from: newFullDate } }));
    };

    const onToHourSelect = (newHour: number) => {
        const newFullDate = updateDateByHour(claimantWorkDay.to, newHour);
        setToTime(newFullDate);
        setToError('');
        dispatch(personActions.update({ claimantWorkDay: { ...claimantWorkDay, to: newFullDate } }));
    };

    const onToMinSelect = (newMin: number) => {
        const newFullDate = updateDateByMinutes(claimantWorkDay.to, newMin);
        setToTime(newFullDate);
        setToError('');
        dispatch(personActions.update({ claimantWorkDay: { ...claimantWorkDay, to: newFullDate } }));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid>
                <div className={'col-6'}>
                    <InputLabel label={t(FROM_TIME_LABEL)} name={FROM_TIME} />
                    <TimePicker
                        className={'mb-1 mt-5 self-center'}
                        error={fromError}
                        hoursLabel={t('timePicker.label.from.hours')}
                        minutesLabel={t('timePicker.label.from.minutes')}
                        name={FROM_TIME}
                        onHourSelect={onFromHourSelect}
                        onMinSelect={onFromMinSelect}
                        time={fromTime}
                        {...{ customCAN }}
                    />
                </div>
                <div className={'col-6'}>
                    <InputLabel label={t(TO_TIME_LABEL)} name={TO_TIME} />
                    <TimePicker
                        className={'mb-1 mt-5 self-center'}
                        error={toError}
                        hoursLabel={t('timePicker.label.to.hours')}
                        minutesLabel={t('timePicker.label.to.minutes')}
                        name={TO_TIME}
                        onHourSelect={onToHourSelect}
                        onMinSelect={onToMinSelect}
                        time={toTime}
                        {...{ customCAN }}
                    />
                </div>
            </Grid>
        </PageLayout>
    );
};
