import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';
import { accountNumberSchema } from './accountNumberSchema';
import { dontKnowBankInformationSchema } from './dontKnowBankInformationSchema';

/**
 * Destructure necessary imports
 */
const { OWNER_FAMILY_NAME_LABEL, OWNER_GIVEN_NAME_LABEL } = PhraseKeys;
const { MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation logic
 */
export const bankInformationSchema = (t: TFunction, optional: boolean) => {
    if (optional) {
        return {
            ownerGivenName: Yup.string()
                .label(t(OWNER_GIVEN_NAME_LABEL))
                .max(
                    MAX_LENGTH_250,
                    t(MAX_LENGTH_ERROR_MESSAGE, {
                        field: t(OWNER_GIVEN_NAME_LABEL),
                        max: MAX_LENGTH_250,
                    })
                )
                .noSpecialChars()
                .nullable(),
            ownerFamilyName: Yup.string()
                .label(t(OWNER_FAMILY_NAME_LABEL))
                .max(
                    MAX_LENGTH_250,
                    t(MAX_LENGTH_ERROR_MESSAGE, {
                        field: t(OWNER_FAMILY_NAME_LABEL),
                        max: MAX_LENGTH_250,
                    })
                )
                .noSpecialChars()
                .nullable(),
            ...accountNumberSchema(t, optional),
            ...dontKnowBankInformationSchema(t),
        };
    } else {
        return {
            ownerGivenName: Yup.string()
                .label(t(OWNER_GIVEN_NAME_LABEL))
                .max(
                    MAX_LENGTH_250,
                    t(MAX_LENGTH_ERROR_MESSAGE, {
                        field: t(OWNER_GIVEN_NAME_LABEL),
                        max: MAX_LENGTH_250,
                    })
                )
                .noSpecialChars()
                .nullable()
                .required(),
            ownerFamilyName: Yup.string()
                .label(t(OWNER_FAMILY_NAME_LABEL))
                .max(
                    MAX_LENGTH_250,
                    t(MAX_LENGTH_ERROR_MESSAGE, {
                        field: t(OWNER_FAMILY_NAME_LABEL),
                        max: MAX_LENGTH_250,
                    })
                )
                .noSpecialChars()
                .nullable()
                .required(),
            ...accountNumberSchema(t, optional),
            ...dontKnowBankInformationSchema(t),
        };
    }
};
