import { TFunction } from 'i18next';
import { FileCategoryEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
const createFileCategoryKey = (id: string) => {
    return `file.category.select.${id}`;
};

export const getFileCategoryOption = (t: TFunction, key: FileCategoryEnums) => {
    return {
        value: key,
        label: t(createFileCategoryKey(key)),
    };
};
