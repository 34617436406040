import React from 'react';
import { BusArialImage } from './BusArialImage';
import { BusLeftImage } from './BusLeftImage';
import { BusRightImage } from './BusRightImage';
import '../VehicleImageSvg.scss';
import Tabs from '../../tabs/Tabs';
import { useI18n } from '../../../hooks/useI18n';
import TabPanel from 'components/tabs/TabPanel';
import { createSvgTabs, MainImageSVGProps, SVGImageView } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { TOP, LEFT, RIGHT } = SVGImageView;

/**
 * Component view and component logic
 */
export const BusImage = (props: MainImageSVGProps) => {
    const { t } = useI18n();
    const tabs = createSvgTabs(t);

    return (
        <div className={'vehicle-body-damages'}>
            <Tabs label={t('tabs.label.bus')} tabs={tabs}>
                <TabPanel tabId={TOP}>
                    <BusArialImage title={props.titles.top} {...props} />
                </TabPanel>
                <TabPanel tabId={RIGHT}>
                    <BusRightImage title={props.titles.right} {...props} />
                </TabPanel>
                <TabPanel tabId={LEFT}>
                    <BusLeftImage title={props.titles.left} {...props} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
