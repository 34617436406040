import React from 'react';
import { CarouselConfigLookupResponse, NoIcon, YesIcon, YesNoKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { selectIsTrafficLights } from '../../../../../../sagas/selectors/motorSelectors';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const isTrafficLightsPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.driving.isTrafficLights.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.isTrafficLights.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_IS_TRAFFIC_LIGHTS,
        stateKey: 'isTrafficLights',
        i18n: {
            ns: 'motor.driving.isTrafficLights',
        },
        selectorValue: selectIsTrafficLights(state),
        options,
    };
};
