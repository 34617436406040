import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectAcceptedPrivacy } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { HistoryListener } from '../../../../interfaces/types/HistoryListener';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { useHistory } from 'react-router-dom';
import { useHistoryListen } from '../../../../hooks/useHistoryListen';
import { personActions } from '../../../../sagas/person';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { useGoBack } from '../../../../hooks/useGoBack';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { Clickable, Grid, Link, PageLayout, SingleCheckbox } from '@protectorinsurance/ds-can';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { H_A_CLAIM_DATE, H_A_PRIVACY } = PersonRoutePaths;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const AccidentPrivacyPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { listen, unlisten } = useHistoryListen();
    const hasAcceptedPrivacy = useSelector(selectAcceptedPrivacy);
    const customCAN = useSelector(selectCustomCAN);
    const pathPrivacy = H_A_PRIVACY;
    const pathNextRoute = H_A_CLAIM_DATE;
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.health.accident.privacy');

    const listener: HistoryListener = useCallback(
        (hLocation, hAction) => {
            if (hAction === 'POP' && hLocation.pathname === pathNextRoute && !hasAcceptedPrivacy) {
                history.push(pathPrivacy);
            }
        },
        [history, hasAcceptedPrivacy, pathNextRoute, pathPrivacy]
    );

    useEffect(() => {
        hasAcceptedPrivacy ? unlisten() : listen(listener);
    }, [hasAcceptedPrivacy, listen, unlisten, listener]);

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (hasAcceptedPrivacy) {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(personActions.update({ acceptedPrivacy: e.target.checked }));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={!hasAcceptedPrivacy}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <p className={'info-container'} tabIndex={0}>
                {tWithNS.t(`text.info`)}
            </p>
            <Grid>
                <SingleCheckbox
                    checked={hasAcceptedPrivacy}
                    handleChange={handleCheckbox}
                    name={'acceptedPrivacy'}
                    wrapperClass={'col-12'}
                    {...{ customCAN }}
                >
                    {tWithNS.t(`text.accept`)}
                    <Link
                        linkText={tWithNS.t('privacyLinkText')}
                        url={tWithNS.t('privacyLinkURL')}
                        {...{ customCAN }}
                    />
                </SingleCheckbox>
            </Grid>
        </PageLayout>
    );
};
