import React, { useEffect } from 'react';
import { infoWindowContent } from './InfoWindowContent';
import { DEFAULT_MAP_LOCATION } from 'config/maps';
import { isEmpty } from '@protectorinsurance/ds-can';

interface CreateMarkerProps {
    lat?: number;
    lng?: number;
    title?: string;
    icon?: string;
}

interface MarkerProps extends CreateMarkerProps {
    map: google.maps.Map | null;
    marker: google.maps.Marker | null;
    infoWindow: google.maps.InfoWindow | null;
    infoWindowLabel: string;
    infoWindowLatText: string;
    infoWindowLngText: string;
    setMarker: (m: google.maps.Marker) => void;
    onLoad?: (marker: google.maps.Marker) => void;
    onMarkerDrop: (e: google.maps.MouseEvent) => void;
}
/**
 * Marker must be used as a child of LoadMapScript (or otherwise make sure to load map script before using Marker)
 **/
export const Marker = ({
    lat = DEFAULT_MAP_LOCATION.lat,
    lng = DEFAULT_MAP_LOCATION.lng,
    title,
    onMarkerDrop,
    map,
    infoWindow,
    infoWindowLabel,
    infoWindowLatText,
    infoWindowLngText,
    setMarker,
    marker,
}: MarkerProps) => {
    useEffect(() => {
        if (map && isEmpty(marker)) {
            const m = createMarker({ lat, lng, title });
            m.addListener('dragend', (e) => {
                if (infoWindow) {
                    const p = m.getPosition();
                    infoWindow.setContent(
                        infoWindowContent({
                            position: p,
                            label: infoWindowLabel,
                            latText: infoWindowLatText,
                            lngText: infoWindowLngText,
                        })
                    );
                }
                onMarkerDrop(e);
            });
            m.addListener('click', function clickMapHandler() {
                if (infoWindow) {
                    const p = m.getPosition();
                    infoWindow.setContent(
                        infoWindowContent({
                            position: p,
                            label: infoWindowLabel,
                            latText: infoWindowLatText,
                            lngText: infoWindowLngText,
                        })
                    );
                    infoWindow.open(map, m);
                }
            });
            m.setMap(map);

            setMarker(m);
        }
        return () => {
            marker && marker.setMap(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    return <></>;
};

const createMarker = ({
    lat = DEFAULT_MAP_LOCATION.lat,
    lng = DEFAULT_MAP_LOCATION.lng,
    title = 'marker',
    icon,
}: CreateMarkerProps) => {
    return new google.maps.Marker({
        position: {
            lat,
            lng,
        },
        draggable: true,
        animation: google.maps.Animation.DROP,
        icon,
        title,
    });
};
