import React, { createContext, ReactNode, useState } from 'react';
import Tab from './Tab';
import './Tabs.scss';
import { Clickable } from '@protectorinsurance/ds-can';

interface ITab {
    id: string;
    label: string;
}

interface TabsProps {
    label: string;
    tabs: ITab[];
    children: ReactNode;
    selected?: string;
}

// Tab context
export interface ITabContext {
    activeTab?: string;
}

export const TabContext = createContext<ITabContext>({});

const Tabs = ({ label, children, tabs = [], selected = '' }: TabsProps) => {
    const [activeTab, setActiveTab] = useState<string>(selected || tabs[0].id);

    const handleClick = (e: Clickable) => {
        const { id } = e.currentTarget;

        setActiveTab(id);
    };

    /**
     * Render functions
     */
    const renderTabs = () =>
        tabs.map(({ id, label }: ITab, idx) => (
            <Tab key={idx} id={id} isActive={activeTab === id} onClick={handleClick}>
                {label}
            </Tab>
        ));

    return (
        <TabContext.Provider value={{ activeTab }}>
            <div className={'tabs'}>
                <div role={'tablist'} className={'tab__list'} aria-label={label}>
                    {renderTabs()}
                </div>

                <div className={'tab__content'}>{children}</div>
            </div>
        </TabContext.Provider>
    );
};

export default Tabs;
