import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimantRoleEnums,
    Clickable,
    EmployeeIcon,
    EntrepreneurIcon,
    StudentIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectClaimantRole } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { EMPLOYEE, ENTREPRENEUR, STUDENT } = ClaimantRoleEnums;

/**
 * Page view and page logic
 */
export const workersCompAccidentClaimantRolePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: STUDENT,
            name: t(`lob.person.workersComp.accident.claimantRole.select.${STUDENT}`),
            icon: <StudentIcon />,
        },
        {
            id: EMPLOYEE,
            name: t(`lob.person.workersComp.accident.claimantRole.select.${EMPLOYEE}`),
            icon: <EmployeeIcon />,
        },
        {
            id: ENTREPRENEUR,
            name: t(`lob.person.workersComp.accident.claimantRole.select.${ENTREPRENEUR}`),
            icon: <EntrepreneurIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CLAIMANT_ROLE,
        stateKey: 'claimantRole',
        i18n: {
            ns: 'lob.person.workersComp.accident.claimantRole',
        },
        selectorValue: selectClaimantRole(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { claimantRole: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
