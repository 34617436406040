import React from 'react';
import {
    CarouselConfigLookupResponse,
    DarkIcon,
    DawnDuskIcon,
    DaylightIcon,
    LightOfDayKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { selectLightOfDay } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { DARK_LIT, DARK_UNLIT, DAWN_DUSK, DAYLIGHT } = LightOfDayKeys;

/**
 * Page view and page logic
 */
export const lightOfDayPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: DAYLIGHT, name: t(`motor.driving.lightOfDay.select.${DAYLIGHT}`), icon: <DaylightIcon /> },
        { id: DAWN_DUSK, name: t(`motor.driving.lightOfDay.select.${DAWN_DUSK}`), icon: <DawnDuskIcon /> },
        { id: DARK_LIT, name: t(`motor.driving.lightOfDay.select.${DARK_LIT}`), icon: <DarkIcon /> },
        { id: DARK_UNLIT, name: t(`motor.driving.lightOfDay.select.${DARK_UNLIT}`), icon: <DarkIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_LIGHT_OF_DAY,
        stateKey: 'lightOfDay',
        i18n: {
            ns: 'motor.driving.lightOfDay',
        },
        selectorValue: selectLightOfDay(state),
        options,
    };
};
