/**
 * Help functions
 */

/**
 * Find modulo of iban
 */
export const moduloIban97 = (dividend: string) => {
    const IBAN_MODULO_DIVISOR = 97;
    return moduloIban(dividend, IBAN_MODULO_DIVISOR);
};

export const moduloIban = (dividend: string, divisor: number) => {
    const ibanChars = dividend.split('');
    return ibanChars.reduce(calculateModulo(divisor), 0);
};

export const calculateModulo = (divisor: number) => {
    return (sum: number, curr: string) => {
        return (10 * sum + parseInt(curr)) % divisor;
    };
};

/**
 * Move first 4 characters of iban to the end of the string.
 */
export const reorderIban = (iban: string) => {
    return iban.substring(4) + iban.substring(0, 4);
};

/**
 * Replace country code with the character codes minus 55.
 */
export const replaceIbanCountryCode = (iban: string) => {
    const ENCODING_DIFF = 55;
    return iban.replace(/[A-Z]/g, (match) => `${match.charCodeAt(0) - ENCODING_DIFF}`);
};

/**
 * Check Finnish account number. Matches backend.
 * Control example: FI2112345600000785
 * @return true if correct, false if incorrect
 */
export default function checkAccountNumber(iban: string) {
    let newIban = iban.toUpperCase();

    //Check for Country code and correct length
    if (newIban.search(/^[A-Z]{2}/gi) < 0 || newIban.length !== 18) {
        return false;
    }

    newIban = reorderIban(newIban);

    newIban = replaceIbanCountryCode(newIban);

    //Checks that modulo equals 1.
    return moduloIban97(newIban) === 1;
}

/**
 * Main Function
 */
export const accountNumberUtils = {
    isValid: (iban: string) => {
        return iban.length > 0 ? checkAccountNumber(iban) : true;
    },
};
