import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';

const { STREET_LABEL, ZIP_LABEL, CITY_LABEL } = PhraseKeys;
const { NUMBER_OF_DIGITS_ERROR } = FormFieldErrors;

export const addressSchema = (t: TFunction) => {
    return {
        street: Yup.string().label(t(STREET_LABEL)).required().nullable(),
        zip: Yup.string()
            .label(t(ZIP_LABEL))
            .required()
            .digits(5, t(NUMBER_OF_DIGITS_ERROR, { path: t(ZIP_LABEL), count: 5 }))
            .nullable(),
        city: Yup.string().required().label(t(CITY_LABEL)).city().nullable(),
    };
};
