import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { Clickable, FormChangeable, Grid, MuiAutocomplete, OptionType, PageLayout } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import { selectWhoUnderInfluence } from '../../../sagas/selectors/motorSelectors';
import { getWhoUnderInfluenceOption, WhoUnderInfluenceTypeKeys } from '../../../utils/flow/whoUnderInfluenceUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    WHO_UNDER_INFLUENCE_LABEL,
    WHO_UNDER_INFLUENCE_PLACEHOLDER,
} = PhraseKeys;
const { WHO_UNDER_INFLUENCE } = FormFieldNames;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { CLAIMANT, COUNTERPARTY, OTHER, PEDESTRIAN } = WhoUnderInfluenceTypeKeys;

/**
 * Page view and page logic
 */
export const EndWhoUnderInfluencePage = () => {
    const dispatch = useDispatch();
    const [underInfluence, setUnderInfluence] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const whoUnderInfluence = useSelector(selectWhoUnderInfluence);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.whoUnderInfluence');

    // Options
    const claimantOption = getWhoUnderInfluenceOption(t, CLAIMANT);
    const counterpartyOption = getWhoUnderInfluenceOption(t, COUNTERPARTY);
    const otherOption = getWhoUnderInfluenceOption(t, OTHER);
    const pedestrianOption = getWhoUnderInfluenceOption(t, PEDESTRIAN);

    const options = [claimantOption, counterpartyOption, pedestrianOption, otherOption];

    useEffect(() => {
        const selected = options.find((x) => x.value === whoUnderInfluence);
        if (selected) {
            setUnderInfluence(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whoUnderInfluence, setUnderInfluence]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (underInfluence === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setUnderInfluence(object);
        dispatcherWithPromise(dispatch, motorActions.update, { whoUnderInfluence: value }).then(() =>
            dispatch(commonActions.send())
        );
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (underInfluence === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={WHO_UNDER_INFLUENCE}
                    inputFieldWrapper={'col-12'}
                    label={t(WHO_UNDER_INFLUENCE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(WHO_UNDER_INFLUENCE_PLACEHOLDER)}
                    value={underInfluence}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
