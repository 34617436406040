import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { DAMAGE_ADDRESS_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const damageSchema = (t: TFunction) => {
    return {
        damageAddress: Yup.string().label(t(DAMAGE_ADDRESS_LABEL)).required().noSpecialChars().nullable(),
    };
};
