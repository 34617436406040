import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { fireDescriptionSchema } from '../fieldSchemas/fireDescriptionSchema';

/**
 * Validation logic
 */
export const fireClaimDescriptionSchema = (t: TFunction, label: string): Yup.ObjectSchema => {
    return Yup.object().shape({
        ...fireDescriptionSchema(t, label),
    });
};
