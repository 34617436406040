import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';

// Dyn imports
import { DynCarouselPage } from '../pages/DynCarouselPage';

// Start section imports
import { WelcomePage } from '../pages/motor/start/Welcome';
import { WindshieldDamagePage } from '../pages/motor/start/WindshieldDamage';
import { StartSummaryPage } from '../pages/motor/start/StartSummary';
import { LoginPage } from '../pages/motor/start/Login';
import { ClaimDatePage } from '../pages/motor/start/ClaimDate';
import { StartVehicleRegistrationNumberPage } from '../pages/motor/start/VehicleRegistrationNumber';
import { StartVehicleInformationPage } from '../pages/motor/start/VehicleInformation';
import { StartAddExtrasPage } from '../pages/motor/start/AddExtras';
import { StopPage } from '../pages/motor/start/Stop';
import { CompanyInformationPage } from '../pages/motor/start/CompanyInformation';

// Parked / Theft And Damages imports
import { PTClaimDiscovererInformationPage } from '../pages/motor/parked/theftAndDamages/ClaimDiscovererInformation';
import { PTParkingDatePage } from '../pages/motor/parked/theftAndDamages/ParkingDate';
import { PTParkingInformationPage } from '../pages/motor/parked/theftAndDamages/ParkingInformation';
import { PTClaimDescriptionPage } from '../pages/motor/parked/theftAndDamages/TheftAndDamagesClaimDescription';
import { PTRecoveryDatePage } from '../pages/motor/parked/theftAndDamages/RecoveryDate';
import { PTRecovererInformationPage } from '../pages/motor/parked/theftAndDamages/RecovererInformation';
import { PTMissingItemsPage } from '../pages/motor/parked/theftAndDamages/MissingItems';
import { PTOtherMissingItemsPage } from '../pages/motor/parked/theftAndDamages/OtherMissingItems';
import { PTUploadReceiptPage } from '../pages/motor/parked/theftAndDamages/UploadReceipt';

// Parked / Engine imports
import { PEClaimDescriptionPage } from '../pages/motor/parked/engine/EngineClaimDescription';

// Parked / Fire imports
import { PFClaimDescriptionPage } from '../pages/motor/parked/fire/FireClaimDescription';

// Parked / Parking imports
import { PPCounterpartyRegistrationNumberPage } from '../pages/motor/parked/parking/CounterpartyRegistrationNumber';
import { PPVehicleInformationPage } from '../pages/motor/parked/parking/VehicleInformation';
import { PPCounterpartyInformationPage } from '../pages/motor/parked/parking/CounterpartyInformation';

// Driving / No Vehicle imports
import { DrivingNoVehicleSpeedInformationPage } from '../pages/motor/driving/noVehicle/SpeedInformation';
import { DrivingNoVehiclePlaceOfAccidentPage } from '../pages/motor/driving/noVehicle/PlaceOfAccident';

// Driving / No Vehicle / Animal imports
import { DNAnimalOwnerInformationListPage } from '../pages/motor/driving/noVehicle/animal/OwnerInformationList';

// Driving / Vehicle imports
import { DVDamagesPage } from '../pages/motor/driving/vehicles/Damages';
import { DVVehicleRegistrationNumberPage } from '../pages/motor/driving/vehicles/VehicleRegistrationNumber';
import { DVVehicleInformationPage } from '../pages/motor/driving/vehicles/VehicleInformation';
import { DVAddExtrasPage } from '../pages/motor/driving/vehicles/AddExtras';
import { DVOwnerInformationPage } from '../pages/motor/driving/vehicles/OwnerInformation';
import { DVSpeedInformationPage } from '../pages/motor/driving/vehicles/SpeedInformation';
import { DrivingPlaceOfAccidentPage } from '../pages/motor/driving/vehicles/PlaceOfAccident';
import { DVCollisionPage } from '../pages/motor/driving/vehicles/Collision';

// Driving / Vehicle / Reversing imports
import { DVReversingClaimDescriptionPage } from '../pages/motor/driving/vehicles/reversing/ReversingClaimDescription';

// Driving / Vehicle / Head On imports
import { DVHeadOnRoadInformationPage } from '../pages/motor/driving/vehicles/headOn/RoadInformation';

// End imports
import { AccidentLocationPage } from '../pages/motor/end/AccidentLocation';
import { EndVehicleWhoResponsiblePage } from '../pages/motor/end/WhoResponsible';
import { DamageOverviewPage } from '../pages/motor/end/DamageOverview';
import { EndVehicleRegistrationNumberPage } from '../pages/motor/end/VehicleRegistrationNumber';
import { EndVehicleInformationPage } from '../pages/motor/end/VehicleInformation';
import { BodyDamagesPage } from '../pages/motor/end/BodyDamages';
import { ContactTowingServicePage } from '../pages/motor/end/ContactTowingService';
import { EndFindWorkshop } from '../pages/motor/end/FindWorkshop';
import { OwnerInformationListPage } from '../pages/motor/end/OwnerInformationList';
import { InjuredPersonInformationListPage } from '../pages/motor/end/InjuredPersonInformationList';
import { PoliceCaseNumberPage } from '../pages/motor/end/PoliceCaseNumber';
import { UploadPoliceReportPage } from '../pages/motor/end/UploadPoliceReport';
import { EndPoliceDistrictPage } from '../pages/motor/end/PoliceDistrict';
import { ReporterInformationPage } from '../pages/motor/end/ReporterInformation';
import { DriverInformationPage } from '../pages/motor/end/DriverInformation';
import { EndWhoUnderInfluencePage } from '../pages/motor/end/WhoUnderInfluence';
import { WitnessInformationPage } from '../pages/motor/end/WitnessInformation';
import { UploadAttachmentPage } from '../pages/motor/end/UploadAttachment';
import { PolicyHoldersContactPage } from '../pages/motor/end/PolicyHoldersContact';
import { FinalSummaryPage } from '../pages/motor/end/FinalSummary';
import { ReportCompletedPage } from '../pages/motor/end/ReportCompleted';
import { AccidentDescriptionPage } from '../pages/motor/end/AccidentDescription';
import { StartContinueClaimPage } from '../pages/motor/start/ContinueClaim';

export const Motor: React.FC = () => {
    return (
        <Switch>
            //DYN
            <Route exact path={MotorRoutePaths.DYN_CAROUSEL} component={DynCarouselPage} />
            //START
            <Route exact path={MotorRoutePaths.INDEX} component={WelcomePage} />
            <Route exact path={MotorRoutePaths.START_WINDSHIELD_DAMAGE} component={WindshieldDamagePage} />
            <Route exact path={MotorRoutePaths.START_START_SUMMARY} component={StartSummaryPage} />
            <Route exact path={MotorRoutePaths.START_LOGIN} component={LoginPage} />
            <Route exact path={MotorRoutePaths.START_CONTINUE_CLAIM} component={StartContinueClaimPage} />
            <Route exact path={MotorRoutePaths.START_CLAIM_DATE} component={ClaimDatePage} />
            <Route
                exact
                path={MotorRoutePaths.START_VEHICLE_REGISTRATION_NUMBER}
                component={StartVehicleRegistrationNumberPage}
            />
            <Route exact path={MotorRoutePaths.START_VEHICLE_INFORMATION} component={StartVehicleInformationPage} />
            <Route exact path={MotorRoutePaths.START_ADD_EXTRAS} component={StartAddExtrasPage} />
            <Route exact path={MotorRoutePaths.START_STOP} component={StopPage} />
            <Route exact path={MotorRoutePaths.START_COMPANY_INFORMATION} component={CompanyInformationPage} />
            //PARKED/THEFT_AND_DAMAGES
            <Route
                exact
                path={MotorRoutePaths.PARKED_CLAIM_DISCOVERER_INFORMATION}
                component={PTClaimDiscovererInformationPage}
            />
            <Route exact path={MotorRoutePaths.PARKED_PARKING_DATE} component={PTParkingDatePage} />
            <Route exact path={MotorRoutePaths.PARKED_PARKING_INFORMATION} component={PTParkingInformationPage} />
            <Route
                exact
                path={MotorRoutePaths.PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION}
                component={PTClaimDescriptionPage}
            />
            <Route exact path={MotorRoutePaths.PARKED_RECOVERY_DATE} component={PTRecoveryDatePage} />
            <Route exact path={MotorRoutePaths.PARKED_RECOVERER_INFORMATION} component={PTRecovererInformationPage} />
            <Route exact path={MotorRoutePaths.PARKED_MISSING_ITEMS} component={PTMissingItemsPage} />
            <Route exact path={MotorRoutePaths.PARKED_OTHER_MISSING_ITEMS} component={PTOtherMissingItemsPage} />
            <Route exact path={MotorRoutePaths.PARKED_UPLOAD_RECEIPT} component={PTUploadReceiptPage} />
            //PARKED/ENGINE
            <Route exact path={MotorRoutePaths.PARKED_ENGINE_CLAIM_DESCRIPTION} component={PEClaimDescriptionPage} />
            //PARKED/FIRE
            <Route exact path={MotorRoutePaths.PARKED_FIRE_CLAIM_DESCRIPTION} component={PFClaimDescriptionPage} />
            //PARKED/PARKING
            <Route
                exact
                path={MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER}
                component={PPCounterpartyRegistrationNumberPage}
            />
            <Route exact path={MotorRoutePaths.PARKED_VEHICLE_INFORMATION} component={PPVehicleInformationPage} />
            <Route
                exact
                path={MotorRoutePaths.PARKED_COUNTERPARTY_INFORMATION}
                component={PPCounterpartyInformationPage}
            />
            //DRIVING/NO_VEHICLE
            <Route
                exact
                path={MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION}
                component={DrivingNoVehicleSpeedInformationPage}
            />
            <Route
                exact
                path={MotorRoutePaths.DRIVING_NO_VEHICLE_PLACE_OF_ACCIDENT}
                component={DrivingNoVehiclePlaceOfAccidentPage}
            />
            //DRIVING/NO_VEHICLE/ANIMAL
            <Route
                exact
                path={MotorRoutePaths.DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST}
                component={DNAnimalOwnerInformationListPage}
            />
            //DRIVING/NO_VEHICLE/ENGINE
            <Route
                exact
                path={MotorRoutePaths.DRIVING_NO_VEHICLE_ENGINE_CLAIM_DESCRIPTION}
                component={PEClaimDescriptionPage}
            />
            //DRIVING/VEHICLE
            <Route exact path={MotorRoutePaths.DRIVING_VEHICLE_DAMAGES} component={DVDamagesPage} />
            <Route
                exact
                path={MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER}
                component={DVVehicleRegistrationNumberPage}
            />
            <Route
                exact
                path={MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_INFORMATION}
                component={DVVehicleInformationPage}
            />
            <Route exact path={MotorRoutePaths.DRIVING_ADD_EXTRAS} component={DVAddExtrasPage} />
            <Route exact path={MotorRoutePaths.DRIVING_VEHICLE_OWNER_INFORMATION} component={DVOwnerInformationPage} />
            <Route exact path={MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION} component={DVSpeedInformationPage} />
            <Route
                exact
                path={MotorRoutePaths.DRIVING_VEHICLE_PLACE_OF_ACCIDENT}
                component={DrivingPlaceOfAccidentPage}
            />
            //DRIVING/VEHICLE/REVERSING
            <Route
                exact
                path={MotorRoutePaths.DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION}
                component={DVReversingClaimDescriptionPage}
            />
            //DRIVING/VEHICLE/HEAD_ON
            <Route
                exact
                path={MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION}
                component={DVHeadOnRoadInformationPage}
            />
            //DRIVING/VEHICLE
            <Route exact path={MotorRoutePaths.DRIVING_VEHICLE_COLLISION} component={DVCollisionPage} />
            //END
            <Route exact path={MotorRoutePaths.END_ACCIDENT_LOCATION} component={AccidentLocationPage} />
            <Route exact path={MotorRoutePaths.END_ACCIDENT_DESCRIPTION} component={AccidentDescriptionPage} />
            <Route exact path={MotorRoutePaths.END_VEHICLE_WHO_RESPONSIBLE} component={EndVehicleWhoResponsiblePage} />
            <Route exact path={MotorRoutePaths.END_DAMAGE_OVERVIEW} component={DamageOverviewPage} />
            <Route
                exact
                path={MotorRoutePaths.END_VEHICLE_REGISTRATION_NUMBER}
                component={EndVehicleRegistrationNumberPage}
            />
            <Route exact path={MotorRoutePaths.END_VEHICLE_INFORMATION} component={EndVehicleInformationPage} />
            <Route exact path={MotorRoutePaths.END_BODY} component={BodyDamagesPage} />
            <Route exact path={MotorRoutePaths.END_CONTACT_TOWING_SERVICE} component={ContactTowingServicePage} />
            <Route exact path={MotorRoutePaths.END_FIND_WORKSHOP} component={EndFindWorkshop} />
            <Route exact path={MotorRoutePaths.END_OWNER_INFORMATION_LIST} component={OwnerInformationListPage} />
            <Route
                exact
                path={MotorRoutePaths.END_INJURED_PERSON_INFORMATION}
                component={InjuredPersonInformationListPage}
            />
            <Route exact path={MotorRoutePaths.END_POLICE_CASE_NUMBER} component={PoliceCaseNumberPage} />
            <Route exact path={MotorRoutePaths.END_UPLOAD_POLICE_REPORT} component={UploadPoliceReportPage} />
            <Route exact path={MotorRoutePaths.END_POLICE_DISTRICT} component={EndPoliceDistrictPage} />
            <Route exact path={MotorRoutePaths.END_REPORTER_INFORMATION} component={ReporterInformationPage} />
            <Route exact path={MotorRoutePaths.END_DRIVER_INFORMATION} component={DriverInformationPage} />
            <Route exact path={MotorRoutePaths.END_WHO_UNDER_INFLUENCE} component={EndWhoUnderInfluencePage} />
            <Route exact path={MotorRoutePaths.END_WITNESS_INFORMATION} component={WitnessInformationPage} />
            <Route exact path={MotorRoutePaths.END_UPLOAD_ATTACHMENT} component={UploadAttachmentPage} />
            <Route exact path={MotorRoutePaths.END_POLICY_HOLDERS_CONTACT} component={PolicyHoldersContactPage} />
            <Route exact path={MotorRoutePaths.END_FINAL_SUMMARY} component={FinalSummaryPage} />
            <Route exact path={MotorRoutePaths.END_REPORT_COMPLETED} component={ReportCompletedPage} />
            //Not found
            <Route path={`${MotorRoutePaths.INDEX}*`} component={WelcomePage} />
        </Switch>
    );
};
