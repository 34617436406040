import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectBankAccountInformation } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { bankAccountInformationSchema } from '../../../validations/schemas/bankAccountInformationSchema';
import { useGoBack } from '../../../hooks/useGoBack';
import { bankAccountCompanyInformationSchema } from '../../../validations/schemas/bankAccountCompanyInformationSchema';
import {
    AutocompleteEnums,
    BankAccountInformationModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { yupResolver } from '@hookform/resolvers/yup';
import { lpoActions } from '../../../sagas/lpo';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ACCOUNT_OWNER_GIVEN_NAME,
    ACCOUNT_OWNER_FAMILY_NAME,
    ACCOUNT_NUMBER,
    DONT_KNOW_BANK_INFO,
    COMPANY_NAME,
    IS_COMPANY,
} = FormFieldNames;
const {
    ACCOUNT_NUMBER_LABEL,
    ACCOUNT_NUMBER_PLACEHOLDER,
    BACK_BUTTON,
    COMPANY_NAME_LABEL,
    COMPANY_NAME_PLACEHOLDER,
    CONTINUE_BUTTON,
    DONT_KNOW_BANK_INFO_LABEL,
    HELP_TEXT,
    IS_COMPANY_LABEL,
    OWNER_GIVEN_NAME_LABEL,
    OWNER_GIVEN_NAME_PLACEHOLDER,
    OWNER_FAMILY_NAME_LABEL,
    OWNER_FAMILY_NAME_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { FAMILY_NAME, GIVEN_NAME } = AutocompleteEnums;

/**
 * Page view and page logic
 */
export const EndBankAccountInformationPage = () => {
    const dispatch = useDispatch();
    const bankAccountInformation = useSelector(selectBankAccountInformation);
    const customCAN = useSelector(selectCustomCAN);
    const dontKnow = bankAccountInformation.dontKnowBankInformation;
    const isCompany = bankAccountInformation.isCompany;
    const { t } = useI18n();
    const tWithNS = useI18n(`lpo.end.bankAccountInformation`);
    const schema = bankAccountInformation.isCompany
        ? bankAccountCompanyInformationSchema(t, dontKnow)
        : bankAccountInformationSchema(t, dontKnow);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        trigger,
    } = useForm<BankAccountInformationModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...bankAccountInformation,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: BankAccountInformationModel) => {
        dispatcherWithPromise(dispatch, lpoActions.update, { bankAccountInformation: values })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        reset({
            accountNumber: '',
            companyName: '',
            ownerFamilyName: '',
            ownerGivenName: '',
        });
        setValue('dontKnowBankInformation', checked);
        dispatch(
            lpoActions.update({
                bankAccountInformation: { ...bankAccountInformation, dontKnowBankInformation: checked },
            })
        );
    };

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setValue('isCompany', checked);
        dispatch(lpoActions.update({ bankAccountInformation: { ...bankAccountInformation, isCompany: checked } }));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <SingleCheckbox
                        checked={isCompany}
                        handleChange={handleChecked}
                        name={IS_COMPANY}
                        wrapperClass={'col-6 multiple'}
                        {...{ customCAN }}
                    >
                        {t(IS_COMPANY_LABEL)}
                    </SingleCheckbox>
                    <SingleCheckbox
                        checked={dontKnow}
                        handleChange={handleCheckbox}
                        name={DONT_KNOW_BANK_INFO}
                        wrapperClass={'col-6 multiple'}
                        {...{ customCAN }}
                    >
                        {t(DONT_KNOW_BANK_INFO_LABEL)}
                    </SingleCheckbox>
                    {isCompany ? (
                        <Controller
                            control={control}
                            name={COMPANY_NAME}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    disabled={dontKnow}
                                    error={!!errors.companyName}
                                    errorMessage={errors.companyName?.message}
                                    id={COMPANY_NAME}
                                    inputFieldWrapper={'col-4'}
                                    label={t(COMPANY_NAME_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(COMPANY_NAME_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    ) : (
                        <>
                            <Controller
                                control={control}
                                name={ACCOUNT_OWNER_GIVEN_NAME}
                                render={({ field: { ref, ...field } }) => (
                                    <MuiTextInput
                                        {...field}
                                        autoComplete={GIVEN_NAME}
                                        disabled={dontKnow}
                                        error={!!errors.ownerGivenName}
                                        errorMessage={errors.ownerGivenName?.message}
                                        id={ACCOUNT_OWNER_GIVEN_NAME}
                                        inputFieldWrapper={'col-4'}
                                        label={t(OWNER_GIVEN_NAME_LABEL)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t(OWNER_GIVEN_NAME_PLACEHOLDER)}
                                        reference={ref}
                                        {...{ customCAN }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={ACCOUNT_OWNER_FAMILY_NAME}
                                render={({ field: { ref, ...field } }) => (
                                    <MuiTextInput
                                        {...field}
                                        autoComplete={FAMILY_NAME}
                                        disabled={dontKnow}
                                        error={!!errors.ownerFamilyName}
                                        errorMessage={errors.ownerFamilyName?.message}
                                        id={ACCOUNT_OWNER_FAMILY_NAME}
                                        inputFieldWrapper={'col-4'}
                                        label={t(OWNER_FAMILY_NAME_LABEL)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t(OWNER_FAMILY_NAME_PLACEHOLDER)}
                                        reference={ref}
                                        {...{ customCAN }}
                                    />
                                )}
                            />
                        </>
                    )}
                    <Controller
                        control={control}
                        name={ACCOUNT_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                disabled={dontKnow}
                                error={!!errors.accountNumber}
                                errorMessage={errors.accountNumber?.message}
                                id={ACCOUNT_NUMBER}
                                inputFieldWrapper={'col-4'}
                                label={t(ACCOUNT_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ACCOUNT_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
