import React from 'react';
import { ListProps } from '../../interfaces/types/SummaryTypes';
import { useI18n } from '../../hooks/useI18n';
import { prefixPostFixUtil } from '../../utils/array/PrefixPostfixUtil';
import { BodyPartListModel, BodyPartListTypeModel, SummaryNavButton, SummaryText } from '@protectorinsurance/ds-can';

interface SummaryArrayItemProps extends ListProps {
    list: BodyPartListTypeModel;
    name: string;
    labelTextKey: string;
    textItemKeyPrefix?: string;
    textItemKeyPostfix?: string;
}

export const SummaryArrayItem = ({
    list,
    show,
    handleClick,
    path,
    labelTextKey,
    name,
    textItemKeyPrefix,
    textItemKeyPostfix,
}: SummaryArrayItemProps) => {
    const { t } = useI18n();

    return (
        <>
            {show && <SummaryText text={labelTextKey} />}
            {show &&
                list.map((item: BodyPartListModel, idx: number) => {
                    const tKey = prefixPostFixUtil(item.key, textItemKeyPrefix, textItemKeyPostfix);

                    return (
                        <div key={`${name}-${idx}`}>
                            <SummaryNavButton
                                className={`full`}
                                dataTestId={`${name}-${idx}`}
                                {...{ path, handleClick }}
                                buttonText={t(tKey)}
                            />
                        </div>
                    );
                })}
        </>
    );
};
