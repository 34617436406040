import { PersonRoutePaths } from './personWizardRoutes';
import { FlowKeys, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { strReplace } from '../../utils/strings/strReplace';

const personProgressBar = (path: PersonRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const find = [':language'];
    const replace = [language ? language : ''];
    return strReplace(path, find, replace);
};

export const personHealthProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.ACCIDENT]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_CATEGORY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_CLAIM_REPORTER_ROLE),
        personProgressBar(PersonRoutePaths.H_A_ONBOARDING),
        personProgressBar(PersonRoutePaths.H_A_PRIVACY),
        personProgressBar(PersonRoutePaths.H_A_CLAIM_DATE),
        personProgressBar(PersonRoutePaths.H_A_NATURE_TYPE_ID),
        personProgressBar(PersonRoutePaths.H_A_BODY_PARTS),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_APPLIED_KELA),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_OTHER_INSURANCE),
        personProgressBar(PersonRoutePaths.H_A_OTHER_INSURANCE_COMPANY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_WHO_PAID_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_TYPE_OF_DOCTOR),
        personProgressBar(PersonRoutePaths.H_A_UPLOAD_RECEIPT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_ATTACHMENTS),
        personProgressBar(PersonRoutePaths.H_A_UPLOAD_ATTACHMENT),
        personProgressBar(PersonRoutePaths.H_A_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.H_A_COMPANY_INFORMATION),
        personProgressBar(PersonRoutePaths.H_A_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.H_A_REPORTER_INFORMATION),
        personProgressBar(PersonRoutePaths.H_A_BANK_ACCOUNT_INFORMATION),
        personProgressBar(PersonRoutePaths.H_A_BANK_ACCOUNT_CONTACT_INFORMATION),
        personProgressBar(PersonRoutePaths.H_A_FINAL_SUMMARY),
        personProgressBar(PersonRoutePaths.H_A_REPORT_COMPLETED),
    ],
    [FlowKeys.ILLNESS]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_CATEGORY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_CLAIM_REPORTER_ROLE),
        personProgressBar(PersonRoutePaths.H_I_ONBOARDING),
        personProgressBar(PersonRoutePaths.H_I_PRIVACY),
        personProgressBar(PersonRoutePaths.H_I_CLAIM_DATE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_DIAGNOSIS_CODE),
        personProgressBar(PersonRoutePaths.H_I_DIAGNOSIS_CODE_INFORMATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_APPLIED_KELA),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_OTHER_INSURANCE),
        personProgressBar(PersonRoutePaths.H_I_OTHER_INSURANCE_COMPANY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_WHO_PAID_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_TYPE_OF_DOCTOR),
        personProgressBar(PersonRoutePaths.H_I_UPLOAD_RECEIPT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_HEALTH_ILLNESS_HAS_ATTACHMENTS),
        personProgressBar(PersonRoutePaths.H_I_UPLOAD_ATTACHMENT),
        personProgressBar(PersonRoutePaths.H_I_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.H_I_COMPANY_INFORMATION),
        personProgressBar(PersonRoutePaths.H_I_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.H_I_REPORTER_INFORMATION),
        personProgressBar(PersonRoutePaths.H_I_BANK_ACCOUNT_INFORMATION),
        personProgressBar(PersonRoutePaths.H_I_BANK_ACCOUNT_CONTACT_INFORMATION),
        personProgressBar(PersonRoutePaths.H_I_FINAL_SUMMARY),
        personProgressBar(PersonRoutePaths.H_I_REPORT_COMPLETED),
    ],
    [FlowKeys.DEATH]: [],
    [FlowKeys.CHILD]: [],
};
