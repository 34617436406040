import React from 'react';
import {
    IncapacityPeriodsModel,
    IncapacityPeriodsTypeModel,
    isYes,
    SummaryItemProps,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { IncapacityPeriodListSummary } from '../../../../incapacityPeriod/IncapacityPeriodListSummary';
import { dtParseAndFormat } from 'utils/date/dateUtils';
import { TFunction } from 'i18next';

/**
 * Component view and component logic
 */
export const renderIncapacityPeriods = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    causedIncapacity: YesNoModel,
    incapacityPeriods: IncapacityPeriodsTypeModel,
    t: TFunction
) => {
    if (isYes(causedIncapacity)) {
        return (
            <div className={inputWrapperClass}>
                <IncapacityPeriodListSummary
                    list={incapacityPeriods}
                    getText={(model: IncapacityPeriodsModel) =>
                        `${t('lob.person.workersComp.accident.situationSummary.incapacityPeriodsItem', {
                            from: model.from && dtParseAndFormat(model.from),
                            to: model.to && dtParseAndFormat(model.to),
                            rate: model.rate,
                        })}`
                    }
                    show={incapacityPeriods.length > 0}
                    textKey={textKey || ''}
                    {...{ path, handleClick }}
                />
            </div>
        );
    }
    return <></>;
};
