export enum Domains {
    NOR = 'protectorforsikring.no',
    SWE = 'protectorforsakring.se',
    FIN = 'protectorvakuutus.fi',
}

export enum LanguageCodes {
    FI = 'fi',
    EN = 'en',
    SE = 'se',
}

export type AllCountryCodes = 'fin';
export const COUNTRY_CODE_FINLAND = 'fin';
export const LANG_CODE_FINLAND = 'fi';
export const LANG_CODE_ENGLAND = 'en';
export const LANG_CODE_SWEDEN = 'se';
export const CountryCodeArray = [COUNTRY_CODE_FINLAND];
export const LanguageCodeArray = [LANG_CODE_FINLAND, LANG_CODE_SWEDEN, LANG_CODE_ENGLAND];
export const FIN_DATE_FORMAT = 'DD.MM.YYYY';
export const FIN_TIME_FORMAT = 'HH:mm';
export const FIN_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

export enum LanguageAttributeCodes {
    FI = 'fi',
    EN = 'en',
    SE = 'sv',
}
