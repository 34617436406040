import { AppState } from '../../store';
import { cleanStateBeforeSubmit } from '../../utils/cleanStateBeforeSubmit';
import { is, LobKeys } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { AUTO, PERSON } = LobKeys;

/**
 * Saga logic
 */
export const selectClaimReportId = (state: AppState) => state.common.id;
export const selectClaimReport = (state: AppState) => {
    const { id, lob, country, requestId, timeoutWarning } = state.common;
    const { bodyPartList, occupationalCondition } = state.person;
    let claimState;
    if (is(lob, AUTO)) {
        claimState = state.motor;
    } else if (is(lob, PERSON)) {
        claimState = state.person;
    } else {
        claimState = state.lpo;
    }

    const sessionObject = {
        id,
        lob,
        country,
        requestId,
        timeoutWarning,
        ...claimState,
        ...(occupationalCondition && is(occupationalCondition.id, 0) && { occupationalCondition: null }),
        ...(bodyPartList && bodyPartList.length === 0 && { bodyPartList: [{ key: 'No info', id: 117 }] }),
    };

    return {
        ...cleanStateBeforeSubmit({ ...sessionObject }),
    };
};
export const selectErrors = (state: AppState) => state.common.errors;

export const selectLoggedInClaimReport = (state: AppState) => state.common.loggedIn;
