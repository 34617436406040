import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    AccidentIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    CategoryKeys,
    Clickable,
    IllnessIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectCategory } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';
import { flowActions } from '../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { ACCIDENT, ILLNESS } = CategoryKeys;

/**
 * Page view and page logic
 */
export const healthCategoryPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: ACCIDENT, name: t(`lob.person.health.category.select.${ACCIDENT}`), icon: <AccidentIcon /> },
        { id: ILLNESS, name: t(`lob.person.health.category.select.${ILLNESS}`), icon: <IllnessIcon /> },
    ];
    const category = selectCategory(state);

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_HEALTH_CATEGORY,
        stateKey: 'category',
        i18n: {
            ns: 'lob.person.health.category',
        },
        selectorValue: selectCategory(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (category && option.id !== category) {
                    dispatch(personActions.resetFromCategory({ category: option.id }));
                    dispatch(wizardActions.update({ backToPrev: false }));
                }

                dispatcherWithPromise(dispatch, personActions.update, { category: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
