import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { accountNumberUtils } from '../../utils/validation/accountNumberUtils';
import { isDev } from '../../utils/env';

export function accountNumber(this: StringSchema, message: string = yupCustomLocale.string.accountNumber) {
    // @ts-ignore
    return this.test('accountNumber', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return isDev() ? true : accountNumberUtils.isValid(value);
    });
}
