export enum PhraseKeys {
    // Buttons
    ADD_BUTTON = 'form.buttons.add',
    BACK_BUTTON = 'form.buttons.back',
    BACK_TO_HOMEPAGE_BUTTON = 'button.backToHomepage',
    CANCEL_BUTTON = 'form.buttons.cancel',
    COMPLETED_BUTTON = 'buttons.completed',
    CONFIRM_BUTTON = 'form.buttons.confirm',
    CONTINUE_BUTTON = 'form.buttons.continue',
    DELETE_BUTTON = 'button.delete',
    DONT_KNOW = 'generic.select.dontKnow',
    EDIT_BUTTON = 'button.edit',
    LOOKUP_BUTTON = 'button.lookup',
    SELECT_BUTTON = 'button.select',
    SELECT_NO = 'generic.select.no',
    SELECT_YES = 'generic.select.yes',
    SEND_BUTTON = 'form.buttons.send',
    SUBMITTING_BUTTON = 'form.buttons.submitting',
    REMOVE_BUTTON = 'form.buttons.remove',
    RESET_BUTTON = 'generic.buttons.reset',
    RETRY_BUTTON = 'error.buttons.retry',
    UNDO_BUTTON = 'generic.buttons.undo',

    // Page layout standards
    HELP_TEXT = 'help.bottomHelpText',
    LEFT_ARROW = 'generic.arrow.left',
    PAGE_NAME = 'pageName',
    RIGHT_ARROW = 'generic.arrow.right',
    SUB_TITLE = 'heading.sub',
    TITLE = 'heading.title',

    // Date phrases
    FROM_DATE_TEXT = 'date.text.from',
    TO_DATE_TEXT = 'date.text.to',

    // Upload phrases
    DROPZONE_DESCRIPTION = 'dropzone.description',
    DROPZONE_TITLE = 'dropzone.title',
    UPLOAD_LABEL = 'upload.label',
    FILE_AMOUNT_LABEL = 'file.amount.label',
    FILE_AMOUNT_PLACEHOLDER = 'file.amount.placeholder',
    FILE_CATEGORY_LABEL = 'file.category.label',
    FILE_CATEGORY_PLACEHOLDER = 'file.category.placeholder',
    FILE_DEFINITION_LABEL = 'file.definition.label',
    FILE_DEFINITION_PLACEHOLDER = 'file.definition.placeholder',

    // Body part phrases
    BOYD_PARTS_REQUIRED = 'bodyParts.required',

    // Autocomplete phrases
    NO_OPTIONS_MESSAGE = 'autoComplete.noOptionsMessage',

    // Field phrases
    ACCIDENT_DESCRIPTION_LABEL = 'form.fields.accidentDescription',
    ACCIDENT_SKETCH_LABEL = 'form.fields.accidentSketch',
    ACCIDENT_DESCRIPTION_PLACEHOLDER = 'form.fields.accidentDescriptionPlaceholder',
    ACCOUNT_NUMBER_LABEL = 'form.fields.accountNumber',
    ACCOUNT_NUMBER_PLACEHOLDER = 'form.fields.accountNumberPlaceholder',
    BANK_ACCOUNT_NUMBER_LABEL = 'form.fields.bankAccountNumber',
    BANK_ACCOUNT_NUMBER_PLACEHOLDER = 'form.fields.bankAccountNumberPlaceholder',
    CHASSIS_NUMBER_LABEL = 'form.fields.chassisNumber',
    CHASSIS_NUMBER_PLACEHOLDER = 'form.fields.chassisNumberPlaceholder',
    CITY_LABEL = 'form.fields.city',
    CITY_PLACEHOLDER = 'form.fields.cityPlaceholder',
    CLAIM_CAUSE_LABEL = 'form.fields.claimCause',
    CLAIM_CAUSE_PLACEHOLDER = 'form.fields.claimCausePlaceholder',
    CLAIM_DESCRIPTION_LABEL = 'form.fields.claimDescription',
    CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.claimDescriptionPlaceholder',
    CLAIM_NUMBER_LABEL = 'form.fields.claimNumber',
    CLAIM_NUMBER_PLACEHOLDER = 'form.fields.claimNumberPlaceholder',
    COMPANY_BUSINESS_NUMBER_LABEL = 'form.fields.companyInformation.businessNumber',
    COMPANY_BUSINESS_NUMBER_PLACEHOLDER = 'form.fields.companyInformation.businessNumberPlaceholder',
    COMPANY_DEPARTMENT_NAME_LABEL = 'form.fields.companyInformation.departmentName',
    COMPANY_DEPARTMENT_NAME_PLACEHOLDER = 'form.fields.companyInformation.departmentNamePlaceholder',
    COMPANY_INSURANCE_NUMBER_LABEL = 'form.fields.companyInformation.insuranceNumber',
    COMPANY_INSURANCE_NUMBER_PLACEHOLDER = 'form.fields.companyInformation.insuranceNumberPlaceholder',
    COMPANY_NAME_LABEL = 'form.fields.companyInformation.name',
    COMPANY_NAME_PLACEHOLDER = 'form.fields.companyInformation.namePlaceholder',
    COMPANY_POLICY_NUMBER_LABEL = 'form.fields.policyNumber',
    COMPANY_POLICY_NUMBER_PLACEHOLDER = 'form.fields.policyNumberPlaceholder',
    COUNTRY_LABEL = 'form.fields.country',
    COUNTRY_PLACEHOLDER = 'form.fields.countryPlaceholder',
    DAMAGE_ADDRESS_LABEL = 'form.fields.damageAddress',
    DAMAGE_ADDRESS_PLACEHOLDER = 'form.fields.damageAddressPlaceholder',
    DAMAGE_DESCRIPTION_LABEL = 'form.fields.damageDescriptionLabel',
    DAMAGE_DESCRIPTION_PLACEHOLDER = 'form.fields.damageDescriptionPlaceholder',
    DAMAGED_ITEM_LABEL = 'form.fields.damagedItem',
    DAMAGED_ITEM_PLACEHOLDER = 'form.fields.damagedItemPlaceholder',
    DATE_OF_EMPLOYMENT_LABEL = 'form.fields.dateOfEmployment',
    DATE_OF_TERMINATION_LABEL = 'form.fields.dateOfTermination',
    DIAGNOSIS_CODE_LABEL = 'form.fields.diagnosisCode',
    DIAGNOSIS_CODE_PLACEHOLDER = 'form.fields.diagnosisCodePlaceholder',
    DISTANCE_FROM_ROAD_SIDE_LABEL = 'form.fields.distanceFromRoadSide',
    DISTANCE_FROM_ROAD_SIDE_PLACEHOLDER = 'form.fields.distanceFromRoadSidePlaceholder',
    DITCH_CLAIM_DESCRIPTION_LABEL = 'form.fields.ditchClaimDescription',
    DITCH_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.ditchClaimDescriptionPlaceholder',
    DONT_KNOW_BANK_INFO_LABEL = 'form.fields.dontKnowBankInformation',
    DRIVING_SPEED_LABEL = 'form.fields.drivingSpeed',
    DRIVING_SPEED_PLACEHOLDER = 'form.fields.drivingSpeedPlaceholder',
    EMAIL_LABEL = 'form.fields.email',
    EMAIL_PLACEHOLDER = 'form.fields.emailPlaceholder',
    EMPLOYMENT_RELATIONSHIP_LABEL = 'form.fields.employmentRelationship',
    EMPLOYMENT_RELATIONSHIP_PLACEHOLDER = 'form.fields.employmentRelationshipPlaceholder',
    ENGINE_CLAIM_DESCRIPTION_LABEL = 'form.fields.engineClaimDescription',
    ENGINE_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.engineClaimDescriptionPlaceholder',
    ENTREPRENEUR_POSITION_LABEL = 'form.fields.entrepreneurPosition',
    ENTREPRENEUR_POSITION_PLACEHOLDER = 'form.fields.entrepreneurPositionPlaceholder',
    EXTERNAL_REFERENCE_LABEL = 'form.fields.externalReference',
    EXTERNAL_REFERENCE_PLACEHOLDER = 'form.fields.externalReferencePlaceholder',
    FIRE_CLAIM_DESCRIPTION_LABEL = 'form.fields.fireClaimDescription',
    FIRE_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.fireClaimDescriptionPlaceholder',
    FIRST_NAME_LABEL = 'form.fields.firstName',
    FIRST_NAME_PLACEHOLDER = 'form.fields.firstNamePlaceholder',
    FREIGHT_WEIGHT_LABEL = 'form.fields.freightWeight',
    FREIGHT_WEIGHT_PLACEHOLDER = 'form.fields.freightWeightPlaceholder',
    FROM_DATE_LABEL = 'form.fields.fromDate',
    FROM_TIME_LABEL = 'form.fields.fromTime',
    INJURED_PROFESSION_LABEL = 'form.fields.injuredProfession',
    INJURED_PROFESSION_PLACEHOLDER = 'form.fields.injuredProfessionPlaceholder',
    IS_COMPANY_LABEL = 'form.fields.isCompany',
    IS_DRIVER_LABEL = 'form.fields.isDriver',
    IS_REPORTER_LABEL = 'form.fields.isReporter',
    LANGUAGE_LABEL = 'form.fields.language',
    LANGUAGE_PLACEHOLDER = 'form.fields.languagePlaceholder',
    LAST_NAME_LABEL = 'form.fields.lastName',
    LAST_NAME_PLACEHOLDER = 'form.fields.lastNamePlaceholder',
    LIFE_DAMAGE_MUNICIPALITY_LABEL = 'form.fields.lifeDamageMunicipality',
    LIFE_DAMAGE_MUNICIPALITY_PLACEHOLDER = 'form.fields.lifeDamageMunicipalityPlaceholder',
    LOCATION_TYPE_LABEL = 'form.fields.locationType',
    LOCATION_TYPE_PLACEHOLDER = 'form.fields.locationTypePlaceholder',
    MAKE_LABEL = 'form.fields.makeLabel',
    MAKE_PLACEHOLDER = 'form.fields.makePlaceholder',
    MEDICAL_INSTITUTION_LABEL = 'form.fields.medicalInstitution',
    MEDICAL_INSTITUTION_PLACEHOLDER = 'form.fields.medicalInstitutionPlaceholder',
    MILEAGE_LABEL = 'form.fields.mileage',
    MILEAGE_PLACEHOLDER = 'form.fields.mileagePlaceholder',
    MISSING_ITEMS_LABEL = 'form.fields.missingItems',
    MISSING_ITEMS_PLACEHOLDER = 'form.fields.missingItemsPlaceholder',
    MISSING_SSN_LABEL = 'form.fields.missingSSN',
    MODEL_LABEL = 'form.fields.model',
    MODEL_PLACEHOLDER = 'form.fields.modelPlaceholder',
    NATIONAL_IDENTITY_LABEL = 'form.fields.nationalIdentity',
    NATIONAL_IDENTITY_PLACEHOLDER = 'form.fields.nationalIdentityPlaceholder',
    NATIONALITY_LABEL = 'form.fields.nationality',
    NATIONALITY_PLACEHOLDER = 'form.fields.nationalityPlaceholder',
    NATURE_TYPE_ID_LABEL = 'form.fields.natureTypeId',
    NATURE_TYPE_ID_PLACEHOLDER = 'form.fields.natureTypeIdPlaceholder',
    NOTE_LABEL = 'form.fields.note',
    OCCUPATION_TYPE_LABEL = 'form.fields.occupationType',
    OCCUPATION_TYPE_PLACEHOLDER = 'form.fields.occupationTypePlaceholder',
    OD_CAUSE_LABEL = 'form.fields.odCause',
    OD_CAUSE_PLACEHOLDER = 'form.fields.odCausePlaceholder',
    OTHER_ID_LABEL = 'form.fields.otherId',
    OTHER_ID_PLACEHOLDER = 'form.fields.otherIdPlaceholder',
    OTHER_INSURANCE_COMPANY_LABEL = 'form.fields.otherInsuranceCompany',
    OTHER_INSURANCE_COMPANY_PLACEHOLDER = 'form.fields.otherInsuranceCompanyPlaceholder',
    OTHER_MISSING_ITEMS_LABEL = 'form.fields.otherMissingItems',
    OTHER_MISSING_ITEMS_PLACEHOLDER = 'form.fields.otherMissingItemsPlaceholder',
    OWNER_GIVEN_NAME_LABEL = 'form.fields.ownerGivenName',
    OWNER_GIVEN_NAME_PLACEHOLDER = 'form.fields.ownerGivenNamePlaceholder',
    OWNER_FAMILY_NAME_LABEL = 'form.fields.ownerFamilyName',
    OWNER_FAMILY_NAME_PLACEHOLDER = 'form.fields.ownerFamilyNamePlaceholder',
    OWNERSHIP_LABEL = 'form.fields.ownership',
    OWNERSHIP_PLACEHOLDER = 'form.fields.ownershipPlaceholder',
    PHONE_LABEL = 'form.fields.phone',
    PHONE_PLACEHOLDER = 'form.fields.phonePlaceholder',
    PLACE_OF_ACCIDENT_LABEL = 'form.fields.placeOfAccident',
    PLACE_OF_ACCIDENT_PLACEHOLDER = 'form.fields.placeOfAccidentPlaceholder',
    POLICE_CASE_NUMBER_LABEL = 'form.fields.policeCaseNumber',
    POLICE_CASE_NUMBER_PLACEHOLDER = 'form.fields.policeCaseNumberPlaceholder',
    POLICE_DISTRICT_LABEL = 'form.fields.policeDistrict',
    POLICE_DISTRICT_PLACEHOLDER = 'form.fields.policeDistrictPlaceholder',
    POSITION_LABEL = 'form.fields.position',
    POSITION_PLACEHOLDER = 'form.fields.positionPlaceholder',
    PROPERTY_ADDRESS_LABEL = 'form.fields.propertyAddress',
    PROPERTY_ADDRESS_PLACEHOLDER = 'form.fields.propertyAddressPlaceholder',
    PROPERTY_UNIT_LABEL = 'form.fields.propertyUnit',
    PROPERTY_UNIT_PLACEHOLDER = 'form.fields.propertyUnitPlaceholder',
    RATE_LABEL = 'form.fields.rate',
    RATE_PLACEHOLDER = 'form.fields.ratePlaceholder',
    RECEIPT_TYPE_LABEL = 'form.fields.receiptType',
    RECEIPT_TYPE_PLACEHOLDER = 'form.fields.receiptTypePlaceholder',
    REG_NR_LABEL = 'form.fields.registrationNumber',
    REG_NR_PLACEHOLDER = 'form.fields.registrationNumberPlaceholder',
    REVERSING_CLAIM_DESCRIPTION_LABEL = 'form.fields.reversingClaimDescription',
    REVERSING_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.reversingClaimDescriptionPlaceholder',
    ROAD_CONDITION_DESCRIPTION_LABEL = 'form.fields.roadConditionDescription',
    ROAD_CONDITION_DESCRIPTION_PLACEHOLDER = 'form.fields.roadConditionDescriptionPlaceholder',
    ROAD_WIDTH_LABEL = 'form.fields.roadWidth',
    ROAD_WIDTH_PLACEHOLDER = 'form.fields.roadWidthPlaceholder',
    ROLE_LABEL = 'form.fields.role',
    ROLE_PLACEHOLDER = 'form.fields.rolePlaceholder',
    SPEED_LIMIT_LABEL = 'form.fields.speedLimit',
    SPEED_LIMIT_PLACEHOLDER = 'form.fields.speedLimitPlaceholder',
    SPEED_ON_IMPACT_LABEL = 'form.fields.speedOnImpact',
    SPEED_ON_IMPACT_PLACEHOLDER = 'form.fields.speedOnImpactPlaceholder',
    STREET_LABEL = 'form.fields.street',
    STREET_PLACEHOLDER = 'form.fields.streetPlaceholder',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION_LABEL = 'form.fields.theftAndDamagesClaimDescription',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.theftAndDamagesClaimDescriptionPlaceholder',
    TIME_SINCE_ACTION_LABEL = 'form.fields.timeSinceAction',
    TIME_SINCE_ACTION_PLACEHOLDER = 'form.fields.timeSinceActionPlaceholder',
    TO_DATE_LABEL = 'form.fields.toDate',
    TO_TIME_LABEL = 'form.fields.toTime',
    TYPE_LABEL = 'form.fields.type',
    TYPE_PLACEHOLDER = 'form.fields.typePlaceholder',
    VEHICLE_TYPE_LABEL = 'form.fields.vehicleType',
    VEHICLE_TYPE_PLACEHOLDER = 'form.fields.vehicleTypePlaceholder',
    VIN_NUMBER_LABEL = 'form.fields.VINNumber',
    VIN_NUMBER_PLACEHOLDER = 'form.fields.VINNumberPlaceholder',
    WHO_RESPONSIBLE_LABEL = 'form.fields.whoResponsible',
    WHO_RESPONSIBLE_PLACEHOLDER = 'form.fields.whoResponsiblePlaceholder',
    WHO_UNDER_INFLUENCE_LABEL = 'form.fields.whoUnderInfluence',
    WHO_UNDER_INFLUENCE_PLACEHOLDER = 'form.fields.whoUnderInfluencePlaceholder',
    WORKSHOP_INFORMATION_LABEL = 'form.fields.workshopInformation',
    WORKSHOP_INFORMATION_PLACEHOLDER = 'form.fields.workshopInformationPlaceholder',
    ZIP_LABEL = 'form.fields.zip',
    ZIP_PLACEHOLDER = 'form.fields.zipPlaceholder',

    // Status text
    BAD_REQUEST = 'statusText.badRequest',
    INTERNAL_SERVER_ERROR = 'statusText.internalServerError',
}
