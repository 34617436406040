import React, { BaseSyntheticEvent } from 'react';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { useI18n } from '../../../../hooks/useI18n';
import { useDispatch, useSelector } from 'react-redux';
import { selectClaimDescription, selectPersonState } from '../../../../sagas/selectors/personSelectors';
import { DisplayCompanyInformation } from './components/DisplayCompanyInformation';
import { DisplayClaimantInformation } from './components/DisplayClaimantInformation';
import { DisplayReporterInformation } from './components/DisplayReporterInformation';
import { DisplayBankAccountInformation } from './components/DisplayBankAccountInformation';
import { DisplayBankAccountContactInformation } from './components/DisplayBankAccountContactInformation';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import { claimDescriptionSchema } from '../../../../validations/schemas/claimDescriptionSchema';
import { selectUpload } from '../../../../sagas/selectors/uploadSelectors';
import { useGoBack } from '../../../../hooks/useGoBack';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import moment from 'moment';
import { FIN_DATE_FORMAT } from '../../../../config/locale';
import {
    ClaimDescriptionModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    pdfServiceActions,
    Printable,
    SummaryItemText,
} from '@protectorinsurance/ds-can';
import { personActions } from '../../../../sagas/person';
import {
    selectApiId,
    selectCustomCAN,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
    selectRequestId,
    selectSubmitted,
} from '../../../../sagas/selectors/commonSelectors';
import { commonActions } from '../../../../sagas/common';
import { DisplayOccupationInformation } from './components/DisplayOccupationInformation';
import { DisplayPolicyHoldersContact } from './components/DisplayPolicyHoldersContact';
import { DisplayWorkersCompAccidentSituationSummary } from 'components/situationSummary/workersComp/accident/DisplayWorkersCompAccidentSituationSummary';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_LABEL,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    HELP_TEXT,
    PAGE_NAME,
    SEND_BUTTON,
    SUB_TITLE,
    SUBMITTING_BUTTON,
    TITLE,
} = PhraseKeys;
const { CLAIM_DESCRIPTION } = FormFieldNames;
const reportDate = moment().format(FIN_DATE_FORMAT);

/**
 * Interfaces
 */
interface DisplayWorkersCompAccidentFinalSummaryProps {
    editDescription: boolean;
    showSummary?: boolean;
}

/**
 * Page view and page logic
 */
export const DisplayWorkersCompAccidentFinalSummary = ({
    editDescription,
    showSummary = true,
}: DisplayWorkersCompAccidentFinalSummaryProps) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const uuid = useSelector(selectApiId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const lob = useSelector(selectLob);
    const requestId = useSelector(selectRequestId);
    const tWithSituationSummary = useI18n('lob.person.workersComp.accident.situationSummary');
    const tWithNS = useI18n('lob.person.workersComp.accident.finalSummary');
    const model = useSelector(selectPersonState);
    const upload = useSelector(selectUpload);
    const claimDescription = useSelector(selectClaimDescription);
    const customCAN = useSelector(selectCustomCAN);
    const handleBackButton = useGoBack();
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimDescriptionModel>({
        resolver: yupResolver(claimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            claimDescription,
        },
    });
    const submitted = useSelector(selectSubmitted);

    const {
        bankAccountInformation,
        bankAccountContactInformation,
        claimantInformation,
        claimantPosition,
        companyInformation,
        entrepreneurOwnership,
        hasCompanyShares,
        injuredProfession,
        occupation,
        policyHoldersContact,
        reporterInformation,
    } = model;

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleContinueButton = ({ claimDescription }: ClaimDescriptionModel, e?: BaseSyntheticEvent) => {
        const data = { uuid, requestId, locale: { country, language }, lob, model };
        e?.preventDefault();
        dispatch(commonActions.update({ submitted: true }));
        dispatcherWithPromise(dispatch, personActions.update, { claimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatcherWithPromise(dispatch, pdfServiceActions.request, data))
            .then(() => dispatch(commonActions.submit()))
            .catch(() => dispatch(commonActions.update({ submitted: false })));
    };

    return (
        <PageLayout
            backBtnAriaLabel={t(BACK_BUTTON)}
            backBtnText={t(BACK_BUTTON)}
            continueBtnAriaLabel={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            continueBtnText={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            disableBackButton={submitted}
            disableContinueButton={submitted}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(handleContinueButton)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={!showSummary ? 'hide-content' : ''}
            {...{ handleBackButton }}
        >
            <Printable title={tWithNS.t('print.reportDate', { date: reportDate })}>
                <Grid>
                    <DisplayWorkersCompAccidentSituationSummary
                        tWithNS={tWithSituationSummary.t}
                        {...{ model, t, upload }}
                    />

                    {/* Claim Description */}
                    {editDescription ? (
                        <div className={'col-12 divider text-align-left'}>
                            <form onSubmit={handleSubmit(handleContinueButton)}>
                                <HiddenInputSubmit />
                                <Grid className={'align-center'}>
                                    <Controller
                                        control={control}
                                        name={CLAIM_DESCRIPTION}
                                        render={({ field: { ref, ...field } }) => (
                                            <MuiTextInput
                                                {...field}
                                                error={!!errors.claimDescription}
                                                errorMessage={errors.claimDescription?.message}
                                                id={CLAIM_DESCRIPTION}
                                                inputFieldWrapper={'col-12'}
                                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                                multiline={true}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                                reference={ref}
                                                rows={10}
                                                {...{ customCAN }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </form>
                        </div>
                    ) : (
                        <SummaryItemText
                            className={'col-12 divider text-align-left'}
                            label={tWithNS.t('text.description')}
                            text={claimDescription}
                        />
                    )}

                    {/* Company Information */}
                    <DisplayCompanyInformation i18n={{ t: tWithNS.t }} model={companyInformation} />

                    {/* Claimant Information */}
                    <DisplayClaimantInformation i18n={{ t: tWithNS.t }} model={claimantInformation} />

                    {/* Occupation Information */}
                    <DisplayOccupationInformation
                        i18n={{ t: tWithNS.t }}
                        model={{
                            claimantPosition,
                            entrepreneurOwnership,
                            hasCompanyShares,
                            injuredProfession,
                            occupation,
                        }}
                    />

                    {/* Reporter Information */}
                    <DisplayReporterInformation i18n={{ t: tWithNS.t }} model={reporterInformation} />

                    {/* Policy Holders Contact */}
                    <DisplayPolicyHoldersContact i18n={{ t: tWithNS.t }} model={policyHoldersContact} />

                    {/* Bank Information */}
                    <DisplayBankAccountInformation i18n={{ t: tWithNS.t }} model={bankAccountInformation} />

                    {/* Bank Contact Information */}
                    {bankAccountInformation.dontKnowBankInformation && (
                        <DisplayBankAccountContactInformation
                            i18n={{ t: tWithNS.t }}
                            model={bankAccountContactInformation}
                        />
                    )}
                </Grid>
            </Printable>
        </PageLayout>
    );
};
