import { TFunction } from 'i18next';
import { Nullable, OptionType } from '@protectorinsurance/ds-can';

export enum PoliceDistricts {
    HELSINKI_PD = 'helsinkiPoliceDepartment',
    HAME_PD = 'hämePoliceDepartment',
    EASTERN_FINLAND_PD = 'easternFinlandPoliceDepartment',
    EASTERN_UUSIMAA_PD = 'easternUusimaaPoliceDepartment',
    SOUTHEASTERN_FINLAND_PD = 'southeasternFinlandPoliceDepartment',
    LAPLAND_PD = 'laplandPoliceDepartment',
    SOUTHWESTERN_FINLAND_PD = 'southwesternFinlandPoliceDepartment',
    WESTERN_UUSIMAA_PD = 'westernUusimaaPoliceDepartment',
    OULU_PD = 'ouluPoliceDepartment',
    OSTROBOTHNIA_PD = 'ostrobothniaPoliceDepartment',
    CENTRAL_FINLAND_PD = 'centralFinlandPoliceDepartment',
}

const createPoliceDistrictsKey = (id: string) => {
    return `motor.policeDistrict.select.${id}`;
};

export const getPoliceDistrictOptions = (t: TFunction): OptionType[] => {
    return [
        {
            value: PoliceDistricts.HELSINKI_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.HELSINKI_PD)),
        },
        {
            value: PoliceDistricts.HAME_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.HAME_PD)),
        },
        {
            value: PoliceDistricts.EASTERN_FINLAND_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.EASTERN_FINLAND_PD)),
        },
        {
            value: PoliceDistricts.EASTERN_UUSIMAA_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.EASTERN_UUSIMAA_PD)),
        },
        {
            value: PoliceDistricts.SOUTHEASTERN_FINLAND_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.SOUTHEASTERN_FINLAND_PD)),
        },
        {
            value: PoliceDistricts.LAPLAND_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.LAPLAND_PD)),
        },
        {
            value: PoliceDistricts.SOUTHWESTERN_FINLAND_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.SOUTHWESTERN_FINLAND_PD)),
        },
        {
            value: PoliceDistricts.WESTERN_UUSIMAA_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.WESTERN_UUSIMAA_PD)),
        },
        {
            value: PoliceDistricts.OULU_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.OULU_PD)),
        },
        {
            value: PoliceDistricts.OSTROBOTHNIA_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.OSTROBOTHNIA_PD)),
        },
        {
            value: PoliceDistricts.CENTRAL_FINLAND_PD,
            label: t(createPoliceDistrictsKey(PoliceDistricts.CENTRAL_FINLAND_PD)),
        },
    ];
};

/**
 * Police District Lists
 */
export const easternFinlandPD: string[] = [
    '50100',
    '57130',
    '70100',
    '73500',
    '74100',
    '75500',
    '76100',
    '77600',
    '78200',
    '80100',
    '81700',
    '82500',
    '82900',
    '83500',
];
export const easternUusimaaPD: string[] = ['01300', '01900', '04200', '04400', '04600', '05800', '06100', '07900'];
export const helsinkiPD: string = '00240';
export const hamePD = ['11100', '13130', '15700', '16300', '18100', '30420'];
export const laplandPD = [
    '95400',
    '95600',
    '95700',
    '95900',
    '96100',
    '97700',
    '97900',
    '98100',
    '98900',
    '99300',
    '99400',
    '99600',
    '99800',
    '99980',
];
export const ostrobothniaPD = [
    '60320',
    '61800',
    '62100',
    '62900',
    '63300',
    '64100',
    '65100',
    '66400',
    '67100',
    '68600',
    '69600',
];
export const ouluPD = [
    '84100',
    '85100',
    '85800',
    '86600',
    '87100',
    '88600',
    '88900',
    '89200',
    '89600',
    '90100',
    '90830',
    '91500',
    '91700',
    '92100',
    '93400',
    '93600',
];
export const southeasternFinlandPD = ['45100', '48100', '49400', '53100', '55120'];
export const southwesternFinlandPD = [
    '20100',
    '21200',
    '21600',
    '23500',
    '24100',
    '25700',
    '26100',
    '28100',
    '32200',
    '32700',
    '32800',
    '38700',
];
export const westernUusimaaPD = ['02400', '02630', '03100', '08100', '10600'];

export const policeDistrictFromLocation = (location: Nullable<string>) => {
    if (location) {
        if (easternFinlandPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.EASTERN_FINLAND_PD;
        } else if (easternUusimaaPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.EASTERN_UUSIMAA_PD;
        } else if (location.includes(helsinkiPD)) {
            return PoliceDistricts.HELSINKI_PD;
        } else if (hamePD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.HAME_PD;
        } else if (laplandPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.LAPLAND_PD;
        } else if (ostrobothniaPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.OSTROBOTHNIA_PD;
        } else if (ouluPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.OULU_PD;
        } else if (southeasternFinlandPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.SOUTHEASTERN_FINLAND_PD;
        } else if (southwesternFinlandPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.SOUTHWESTERN_FINLAND_PD;
        } else if (westernUusimaaPD.some((zip: string) => location.includes(zip))) {
            return PoliceDistricts.WESTERN_UUSIMAA_PD;
        }
    }
};
