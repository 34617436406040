import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';
import { addressSchema } from '../fieldSchemas/addressSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { nationalitySchema } from '../fieldSchemas/nationalitySchema';

export const claimantInformationHealthSchema = (t: TFunction, missingSSN: boolean = false) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...(!missingSSN && nationalIdentitySchema(t, false)),
        ...nationalitySchema(t),
        ...contactDetailsSchema(t),
        ...addressSchema(t),
    });
};
