import React from 'react';
import { FileList, isYes, SummaryItemProps } from '@protectorinsurance/ds-can';
import { AttachmentModel } from 'interfaces/models/Attachment.model';

/**
 * Component view and component logic
 */
export const renderAttachment = (
    { inputWrapperClass, buttonText, path, handleClick }: SummaryItemProps,
    textKey: string,
    dataTestId: string,
    attachment: AttachmentModel
) => {
    if (isYes(attachment.hasAttachment)) {
        return (
            <div className={'col-12 divider text-align-left'}>
                <FileList
                    list={attachment.files}
                    show={attachment.files.length > 0}
                    {...{ dataTestId, path, textKey }}
                />
            </div>
        );
    }
    return <></>;
};
