import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

/*
 * Destructure label
 */
const { CLAIM_NUMBER_LABEL } = PhraseKeys;

export const claimNumberSchema = (t: TFunction) => {
    return { claimNumber: Yup.string().label(t(CLAIM_NUMBER_LABEL)).required().claimNumber().nullable() };
};
