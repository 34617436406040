import React, { MouseEvent } from 'react';
import {
    EntrepreneurOwnershipModel,
    EntrepreneurOwnershipTypeModel,
    SmallPersonIcon,
} from '@protectorinsurance/ds-can';
import './DisplayEntrepreneurOwnershipList.scss';

/**
 * Component logic and component view
 */
interface EntrepreneurOwnershipListProps {
    entrepreneurs: EntrepreneurOwnershipTypeModel;
    handleDelete(e: MouseEvent, entrepreneur: EntrepreneurOwnershipModel): void;
    buttonText: string;
}

export const DisplayEntrepreneurOwnershipList = ({
    entrepreneurs,
    handleDelete,
    buttonText,
}: EntrepreneurOwnershipListProps) => {
    const renderEntrepreneur = (entrepreneur: EntrepreneurOwnershipModel) => (
        <>
            {entrepreneur.position} ({entrepreneur.ownership}%)
        </>
    );

    const renderEntrepreneurList = entrepreneurs.map((entrepreneur: EntrepreneurOwnershipModel, idx: number) => {
        return (
            <li key={idx} className={'entrepreneur-list-item'}>
                <SmallPersonIcon />
                <span className={'list-text'} data-hj-suppress>
                    {renderEntrepreneur(entrepreneur)}
                </span>
                <button
                    type={'button'}
                    data-testid={'delete-btn'}
                    className={'delete-btn'}
                    onClick={(e) => handleDelete(e, entrepreneur)}
                >
                    {buttonText}
                </button>
            </li>
        );
    });

    return (
        <ul className={'list-container'} data-testid={'entrepreneur-list'}>
            {renderEntrepreneurList}
        </ul>
    );
};
