import React from 'react';
import {
    CarouselConfigLookupResponse,
    OtherDriverIcon,
    SelfOwnDriverIcon,
    WhoAtFaultKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { selectWhoAtFault } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { CLAIMANT, COUNTERPARTY } = WhoAtFaultKeys;

/**
 * Page view and page logic
 */
export const rearEndedWhoAtFaultPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: CLAIMANT, name: t(`motor.driving.rearEndedWhoAtFault.select.${CLAIMANT}`), icon: <SelfOwnDriverIcon /> },
        {
            id: COUNTERPARTY,
            name: t(`motor.driving.rearEndedWhoAtFault.select.${COUNTERPARTY}`),
            icon: <OtherDriverIcon />,
        },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT,
        stateKey: 'whoAtFault',
        i18n: {
            ns: 'motor.driving.rearEndedWhoAtFault',
        },
        selectorValue: selectWhoAtFault(state),
        options,
    };
};
