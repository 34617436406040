import { call, takeEvery } from 'redux-saga/effects';
import { selectClaimReportId } from '../../selectors/reportSelectors';
import {
    actionWithPromise,
    api,
    createFormData,
    emptyFn,
    FileErrorTypes,
    FileModel,
    LogServiceModel,
    MULTI_PART_HEADER,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { safetyStandardsActions } from './safetyStandards';
import { pollSafetyStandardsActions } from './pollSafetyStandards';
import { selectRequestId } from '../../selectors/commonSelectors';
import { createFileError } from '../../../interfaces/models/File.model';
import { put, select } from 'typed-redux-saga';
import { logServiceActions } from '../../services/logService';
import { NODE_API_BASE_URL } from '../../../config/api';
import { AxiosResponse } from 'axios';

/**
 * Action Types
 */
export enum UploadSafetyStandardsActionTypes {
    REQUEST = '@app/upload/safetyStandards/upload/REQUEST',
    SUCCESS = '@app/upload/safetyStandards/upload/SUCCESS',
    FAILURE = '@app/upload/safetyStandards/upload/FAILURE',
}

/**
 * Action Definitions
 */
export interface UploadSafetyStandardsAction {
    type: UploadSafetyStandardsActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const uploadSafetyStandardsActions = {
    request: actionWithPromise(UploadSafetyStandardsActionTypes.REQUEST),
    success: actionWithPromise(UploadSafetyStandardsActionTypes.SUCCESS),
    failure: actionWithPromise(UploadSafetyStandardsActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const uploadSafetyStandardsSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: UploadSafetyStandardsAction) {
        try {
            yield* put(safetyStandardsActions.add(data));

            const requestId = yield* select(selectRequestId);
            const submissionId = yield* select(selectClaimReportId);
            const formData = createFormData(data);
            const config = {
                baseURL: NODE_API_BASE_URL,
                headers: {
                    ...MULTI_PART_HEADER,
                    'X-Request-Id': `${requestId}`,
                },
            };

            if (data.errors.length === 0) {
                const res: AxiosResponse = yield call(
                    api.post,
                    `upload/submission/${submissionId}/files`,
                    formData,
                    config
                );
                const externalId = res.headers.location.replace('/api/', '').replace('/meta', '');
                const file = { ...data, externalId };

                yield* put(safetyStandardsActions.update(file));
                yield* put(pollSafetyStandardsActions.request(file));
                yield* put(uploadSafetyStandardsActions.success());
                resolve();
            } else {
                const file = createFileError(data, UploadSafetyStandardsActionTypes.FAILURE, {
                    name: 'FileValidationError',
                    message: FileErrorTypes.UNSUPPORTED,
                });
                yield* put(safetyStandardsActions.update(file));
                yield* put(uploadSafetyStandardsActions.failure(file));
                reject();
            }
        } catch (e) {
            const file = createFileError(data, UploadSafetyStandardsActionTypes.FAILURE, e as any);
            if ((e as any).url?.includes('null')) {
                const requestId = yield* select(selectRequestId);
                const errorPayload: LogServiceModel = {
                    level: 'error',
                    message: `Missing UUID: Tried to upload file by name of ${file.name} in uploadSafetyStandards (FI). X-Request-Id=${requestId}`,
                };
                yield* put(logServiceActions.request(errorPayload));
            }
            yield* put(safetyStandardsActions.update(file));
            yield* put(uploadSafetyStandardsActions.failure(file));
            reject();
        }
    },
};

/**
 * Saga Watchers
 */
export const uploadSafetyStandardsWatchers = function* () {
    yield takeEvery(UploadSafetyStandardsActionTypes.REQUEST, uploadSafetyStandardsSagas.request);
};
