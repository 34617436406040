import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    InsuranceTypeKeys,
    is,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectHasExistingClaim, selectInsuranceType } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;
const { GROUP_ACCIDENT, WORK_COMP } = InsuranceTypeKeys;
const { DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY, DYN_CAROUSEL_WORKERS_COMP_CATEGORY } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const hasExistingClaimPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const insuranceType = selectInsuranceType(state);
    const options = [
        { id: YES, name: t(`lob.person.start.hasExistingClaim.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lob.person.start.hasExistingClaim.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
        stateKey: 'hasExistingClaim',
        i18n: {
            ns: 'lob.person.start.hasExistingClaim',
        },
        selectorValue: selectHasExistingClaim(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext(option.id);
                if (option.id === YES) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (is(option.id, NO) && is(insuranceType, GROUP_ACCIDENT)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY);
                }

                if (is(option.id, NO) && is(insuranceType, WORK_COMP)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_WORKERS_COMP_CATEGORY);
                }

                dispatcherWithPromise(dispatch, personActions.update, {
                    hasExistingClaim: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
