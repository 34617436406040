import { Datable } from '@protectorinsurance/ds-can';

export interface TravelDatesModel {
    from: Datable;
    to: Datable;
}

export const initTravelDates: TravelDatesModel = {
    from: null,
    to: null,
};
