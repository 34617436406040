import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    PersonClaimCauseKeys,
} from '@protectorinsurance/ds-can';
import { useDispatch, useSelector } from 'react-redux';
import { selectClaimCause } from '../../../sagas/selectors/personSelectors';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { personActions } from '../../../sagas/person';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { getClaimCauseOption } from '../../../utils/person/claimCauseUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_CAUSE_LABEL,
    CLAIM_CAUSE_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { CLAIM_CAUSE } = FormFieldNames;
const {
    CHEMICALS,
    CRASH_INJURY,
    CRUSH_INJURY,
    CUT,
    DEATH,
    FALL,
    FIRE,
    LIFTING_INJURY,
    OTHER,
    SLIPPING,
    TEETH_INJURY,
    TEMPERATURE_INJURY,
    TRAFFIC,
    VIOLENCE,
} = PersonClaimCauseKeys;

/**
 * Page view and page logic
 */
export const GroupAccidentClaimCausePage = () => {
    const dispatch = useDispatch();
    const claimCause = useSelector(selectClaimCause);
    const customCAN = useSelector(selectCustomCAN);
    const [cause, setCause] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.groupAccident.claimCause');

    // Options
    const chemicalOption = getClaimCauseOption(t, CHEMICALS);
    const crashInjuryOption = getClaimCauseOption(t, CRASH_INJURY);
    const crushInjuryOption = getClaimCauseOption(t, CRUSH_INJURY);
    const cutOption = getClaimCauseOption(t, CUT);
    const deathOption = getClaimCauseOption(t, DEATH);
    const fallOption = getClaimCauseOption(t, FALL);
    const fireOption = getClaimCauseOption(t, FIRE);
    const liftingInjuryOption = getClaimCauseOption(t, LIFTING_INJURY);
    const otherOption = getClaimCauseOption(t, OTHER);
    const slippingOption = getClaimCauseOption(t, SLIPPING);
    const teethInjuryOption = getClaimCauseOption(t, TEETH_INJURY);
    const temperatureInjuryOption = getClaimCauseOption(t, TEMPERATURE_INJURY);
    const trafficOption = getClaimCauseOption(t, TRAFFIC);
    const violenceOption = getClaimCauseOption(t, VIOLENCE);

    const options = [
        fallOption,
        liftingInjuryOption,
        crashInjuryOption,
        crushInjuryOption,
        cutOption,
        violenceOption,
        temperatureInjuryOption,
        chemicalOption,
        fireOption,
        slippingOption,
        teethInjuryOption,
        trafficOption,
        deathOption,
        otherOption,
    ];

    useEffect(() => {
        const selected = options.find((x) => x.value === claimCause);
        if (selected) {
            setCause(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimCause, setCause]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(claimCause, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setCause(object);
        dispatch(personActions.update({ claimCause: value as PersonClaimCauseKeys }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(claimCause, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={CLAIM_CAUSE}
                    inputFieldWrapper={'col-12'}
                    label={t(CLAIM_CAUSE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(CLAIM_CAUSE_PLACEHOLDER)}
                    value={cause}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
