import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { startIsPoliceContactedPage } from './start/isPoliceContacted';
import { endHasOtherInsurancePage } from './end/hasOtherInsurance';
import { travelClaimTypePage } from './travel/claimType';

/**
 * Config logic
 */
export const lpoCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    startIsPoliceContactedPage(props),

    // Travel
    travelClaimTypePage(props),

    // End
    endHasOtherInsurancePage(props),
];
