import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { FormFieldErrors } from '../../config/formFieldNames';

/*
 * Destructure
 */
const { OTHER_INSURANCE_COMPANY_LABEL } = PhraseKeys;
const { MAX_LENGTH_ERROR_MESSAGE, MIN_LENGTH_ERROR_MESSAGE } = FormFieldErrors;
const MIN_LENGTH = 4;
const MAX_LENGTH = 256;

export const insuranceCompanyNameSchema = (t: TFunction) => {
    return {
        otherInsuranceCompany: Yup.string()
            .label(t(OTHER_INSURANCE_COMPANY_LABEL))
            .min(
                MIN_LENGTH,
                t(MIN_LENGTH_ERROR_MESSAGE, {
                    field: t(OTHER_INSURANCE_COMPANY_LABEL),
                    min: MIN_LENGTH,
                })
            )
            .max(
                MAX_LENGTH,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(OTHER_INSURANCE_COMPANY_LABEL),
                    max: MAX_LENGTH,
                })
            )

            .noSpecialChars()
            .required()
            .nullable(),
    };
};
