import React from 'react';
import { CarouselConfigLookupResponse, OtherInjuriesIcon, RoadIcon, TypeOfRoadKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { selectTypeOfRoad } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { FREEWAY, HIGHWAY, OTHER, ROAD, STREET } = TypeOfRoadKeys;

/**
 * Page view and page logic
 */
export const typeOfRoadPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: STREET, name: t(`motor.driving.typeOfRoad.select.${STREET}`), icon: <RoadIcon /> },
        { id: ROAD, name: t(`motor.driving.typeOfRoad.select.${ROAD}`), icon: <RoadIcon /> },
        { id: HIGHWAY, name: t(`motor.driving.typeOfRoad.select.${HIGHWAY}`), icon: <RoadIcon /> },
        { id: FREEWAY, name: t(`motor.driving.typeOfRoad.select.${FREEWAY}`), icon: <RoadIcon /> },
        { id: OTHER, name: t(`motor.driving.typeOfRoad.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_ROAD_TYPE_OF_ROAD,
        stateKey: 'typeOfRoad',
        i18n: {
            ns: 'motor.driving.typeOfRoad',
        },
        selectorValue: selectTypeOfRoad(state),
        options,
    };
};
