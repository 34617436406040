import React from 'react';
import { BodyIcon, CarouselConfigLookupResponse, CompanyIcon, WhoPaidExpensesKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { selectWhoPaidExpenses } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { POLICY_HOLDER, CLAIMANT } = WhoPaidExpensesKeys;

/**
 * Page view and page logic
 */
export const healthAccidentWhoPaidExpensesPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: CLAIMANT, name: t(`lob.person.health.accident.whoPaidExpenses.select.${CLAIMANT}`), icon: <BodyIcon /> },
        {
            id: POLICY_HOLDER,
            name: t(`lob.person.health.accident.whoPaidExpenses.select.${POLICY_HOLDER}`),
            icon: <CompanyIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_WHO_PAID_EXPENSES,
        stateKey: 'whoPaidExpenses',
        i18n: {
            ns: 'lob.person.health.accident.whoPaidExpenses',
        },
        selectorValue: selectWhoPaidExpenses(state),
        options,
    };
};
