import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { whoResponsibleDescriptionSchema } from '../fieldSchemas/whoResponsibleDescriptionSchema';

/**
 * Validation logic
 */
export const whoResponsibleClaimDescriptionSchema = (t: TFunction, label: string): Yup.ObjectSchema => {
    return Yup.object().shape({
        ...whoResponsibleDescriptionSchema(t, label),
    });
};
