import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { WORKSHOP_INFORMATION_LABEL } = PhraseKeys;
const { MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation logic
 */
export const workshopSchema = (t: TFunction) => {
    return {
        workshopInformation: Yup.string()
            .label(t(WORKSHOP_INFORMATION_LABEL))
            .required()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(WORKSHOP_INFORMATION_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .nullable(),
    };
};
