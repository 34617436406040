import { TFunction } from 'i18next';
import { PersonClaimCauseKeys } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
const createClaimCauseKey = (id: string) => {
    return `lob.person.accident.claimCause.select.${id}`;
};

export const getClaimCauseOption = (t: TFunction, key: PersonClaimCauseKeys) => {
    return {
        value: key,
        label: t(createClaimCauseKey(key)),
    };
};
