import React from 'react';
import classNames from 'classnames';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { isYes } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from 'utils/displayUtils';
import { PersonSummaryProps } from '../../../../interfaces/types/SummaryTypes';
import { dtParseAndFormat, dtTimeParseAndFormat, timeParseAndFormat } from 'utils/date/dateUtils';
import { renderHasExistingClaim } from './components/RenderHasExistingClaim';
import { renderClaimNumberInformation } from './components/RenderClaimNumberInformation';
import { renderInsuranceType } from './components/RenderInsuranceType';
import { renderCategory } from './components/RenderCategory';
import { renderClaimantRole } from './components/RenderClaimantRole';
import { renderStudyLocation } from './components/RenderStudyLocation';
import { renderTypeOfStudies } from './components/RenderTypeOfStudies';
import { renderReceipt } from './components/RenderReceipt';
import { renderHasOtherInsurance } from './components/RenderHasOtherInsurance';
import { renderOtherInsuranceCompany } from '../../groupAccident/components/RenderOtherInsuranceCompany';
import { renderWhoPaidExpenses } from './components/RenderWhoPaidExpenses';
import { renderHasExpenses } from './components/RenderHasExpenses';
import { renderMedicalInstitution } from './components/RenderMedicalInstitution';
import { renderDateOfAppliedMedicalCare } from './components/RenderDateOfAppliedMedicalCare';
import { renderHasAppliedMedicalCare } from './components/RenderHasAppliedMedicalCare';
import { renderCausedIncapacity } from './components/RenderCausedIncapacity';
import { renderIncapacityPeriods } from './components/RenderIncapacityPeriods';
import { renderIncapacityMoreThanSevenDays } from './components/RenderIncapacityMoreThanSevenDays';
import { renderIncapacityPeriod } from './components/RenderIncapacityPeriod';
import { renderUnderInfluence } from './components/RenderUnderInfluence';
import { renderUnderInfluenceClaimDescription } from './components/RenderUnderInfluenceClaimDescription';
import { renderIsPoliceContacted } from './components/RenderIsPoliceContacted';
import { renderPoliceDistrict } from './components/RenderPoliceDistrict';
import { renderIsVehicleInvolved } from './components/RenderIsVehicleInvolved';
import { renderTypeOfVehicle } from './components/RenderTypeOfVehicle';
import { renderRegistrationNumber } from './components/RenderRegistrationNumber';
import { renderOccupationalCondition } from './components/RenderOccupationalCondition';
import { renderWorkplace } from './components/RenderWorkplace';
import { renderDamageAddress } from './components/RenderDamageAddress';
import { renderLifeDamageMunicipality } from './components/RenderLifeDamageMunicipality';
import { renderClaimDate } from './components/RenderClaimDate';
import { renderReportedToEmployerDate } from './components/RenderReportedToEmployerDate';
import { renderBodyPartList } from './components/RenderBody';
import { renderNatureTypeId } from './components/RenderNatureTypeId';
import { renderClaimantWorkDay } from './components/RenderClaimantWorkDay';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CLAIMANT_ROLE,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CAUSED_INCAPACITY,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_MORE_THAN_SEVEN_DAYS,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_PERIOD,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_VEHICLE_INVOLVED,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_STUDIES,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_VEHICLE,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_WHO_PAID_EXPENSES,
    DYN_CAROUSEL_WORKERS_COMP_CATEGORY,
    INDEX,
    START_CLAIM_NUMBER_INFORMATION,
    WORKERS_COMP_ACCIDENT_BODY_PARTS_LIST,
    WORKERS_COMP_ACCIDENT_CLAIM_DATE,
    WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS,
    WORKERS_COMP_ACCIDENT_DATE_OF_APPLIED_MEDICAL_CARE,
    WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS,
    WORKERS_COMP_ACCIDENT_MEDICAL_INSTITUTION,
    WORKERS_COMP_ACCIDENT_NATURE_TYPE_ID,
    WORKERS_COMP_ACCIDENT_OTHER_INSURANCE_COMPANY,
    WORKERS_COMP_ACCIDENT_POLICE_DISTRICT,
    WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE,
    WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE_CLAIM_DESCRIPTION,
    WORKERS_COMP_ACCIDENT_UPLOAD_RECEIPT,
    WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER,
    WORKERS_COMP_ACCIDENT_WORKPLACE,
} = PersonRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayWorkersCompAccidentSituationSummary = ({
    model,
    upload,
    t,
    tWithNS,
    handleClick,
}: PersonSummaryProps) => {
    /**
     * Helpers
     */
    const {
        bodyPartList,
        category,
        causedIncapacity,
        claimDate,
        claimNumber,
        claimantRole,
        claimantWorkDay,
        damageAddress,
        dateOfAppliedMedicalCare,
        hasAppliedMedicalCare,
        hasExistingClaim,
        hasExpenses,
        incapacityMoreThanSevenDays,
        incapacityPeriod,
        incapacityPeriods,
        insuranceType,
        isPoliceContacted,
        isVehicleInvolved,
        lifeDamageMunicipality,
        locationType,
        medicalInstitution,
        natureTypeId,
        occupationalCondition,
        otherInsurance,
        otherInsuranceCompany,
        policeDistrict,
        registrationNumber,
        reportedToEmployerDate,
        studyLocation,
        typeOfStudies,
        typeOfVehicle,
        underInfluence,
        underInfluenceClaimDescription,
        whoPaidExpenses,
    } = model;

    // Classes
    const hasExistingClaimClass = classNames(
        {
            'col-6': isYes(hasExistingClaim),
            'col-12': !isYes(hasExistingClaim),
        },
        'divider align-button-start text-align-left'
    );

    const isVehicleInvolvedClass = classNames(
        {
            'col-6': isYes(isVehicleInvolved),
            'col-12': !isYes(isVehicleInvolved),
        },
        'divider align-button-start text-align-left'
    );

    const isPoliceContactedClass = classNames(
        {
            'col-6': isYes(isPoliceContacted),
            'col-12': !isYes(isPoliceContacted),
        },
        'divider align-button-start text-align-left'
    );

    const causedIncapacityClass = classNames(
        {
            'col-6': isYes(causedIncapacity),
            'col-12': !isYes(causedIncapacity),
        },
        'divider align-button-start text-align-left'
    );

    const hasAppliedMedicalCareClass = classNames(
        {
            'col-4': isYes(hasAppliedMedicalCare),
            'col-12': !isYes(hasAppliedMedicalCare),
        },
        'divider align-button-start text-align-left'
    );

    const hasExpensesClass = classNames(
        {
            'col-6 spacing': isYes(hasExpenses),
            'col-12 divider': !isYes(hasExpenses),
        },
        'align-button-start text-align-left'
    );

    const hasOtherInsuranceClass = classNames(
        {
            'col-4': isYes(otherInsurance),
            'col-12': !isYes(otherInsurance),
        },
        'divider align-button-start text-align-left'
    );

    return (
        <>
            {renderHasExistingClaim({
                inputWrapperClass: hasExistingClaimClass,
                textKey: tWithNS('hasExistingClaim.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasExistingClaim,
                buttonText: t(createTextYesNoDontKnow(hasExistingClaim)),
                path: DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
                handleClick,
            })}

            {renderClaimNumberInformation(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('claimNumberInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimNumber,
                    buttonText: claimNumber ? claimNumber : '-',
                    path: START_CLAIM_NUMBER_INFORMATION,
                    handleClick,
                },
                hasExistingClaim
            )}

            {renderInsuranceType({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('insuranceType.heading.title'),
                dataTestId: TEST_ID_PREFIX.insuranceType,
                buttonText: insuranceType ? t(`lob.person.start.insuranceType.select.${insuranceType}`) : '-',
                path: INDEX,
                handleClick,
            })}

            {renderCategory({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('category.heading.title'),
                dataTestId: TEST_ID_PREFIX.category,
                buttonText: category ? t(`lob.person.workersComp.category.select.${category}`) : '-',
                path: DYN_CAROUSEL_WORKERS_COMP_CATEGORY,
                handleClick,
            })}

            {renderClaimantRole({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('claimantRole.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimantRole,
                buttonText: claimantRole
                    ? t(`lob.person.workersComp.accident.claimantRole.select.${claimantRole}`)
                    : '-',
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CLAIMANT_ROLE,
                handleClick,
            })}

            {renderStudyLocation(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('studyLocation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.studyLocation,
                    buttonText: studyLocation
                        ? t(`lob.person.workersComp.accident.studyLocation.select.${studyLocation}`)
                        : '-',
                    path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION,
                    handleClick,
                },
                claimantRole
            )}

            {renderTypeOfStudies(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('typeOfStudies.heading.title'),
                    dataTestId: TEST_ID_PREFIX.typeOfStudies,
                    buttonText: typeOfStudies
                        ? t(`lob.person.workersComp.accident.typeOfStudies.select.${typeOfStudies}`)
                        : '-',
                    path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_STUDIES,
                    handleClick,
                },
                claimantRole
            )}

            {renderOccupationalCondition({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('occupationalCondition.heading.title'),
                dataTestId: TEST_ID_PREFIX.occupationalCondition,
                buttonText: occupationalCondition && occupationalCondition.key ? occupationalCondition.key : '-',
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION,
                handleClick,
            })}

            {renderDamageAddress({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('damageAddress.heading.title'),
                dataTestId: TEST_ID_PREFIX.damageAddress,
                buttonText: damageAddress ? damageAddress : '-',
                path: WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS,
                handleClick,
            })}

            {renderLifeDamageMunicipality({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('lifeDamageMunicipality.heading.title'),
                dataTestId: TEST_ID_PREFIX.damageAddress,
                buttonText: lifeDamageMunicipality && lifeDamageMunicipality.key ? lifeDamageMunicipality.key : '-',
                path: WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS,
                handleClick,
            })}

            {renderWorkplace({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('workplace.heading.title'),
                dataTestId: TEST_ID_PREFIX.locationType,
                buttonText: locationType && locationType.key ? locationType.key : '-',
                path: WORKERS_COMP_ACCIDENT_WORKPLACE,
                handleClick,
            })}

            {renderClaimDate({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtTimeParseAndFormat(claimDate) : '-',
                path: WORKERS_COMP_ACCIDENT_CLAIM_DATE,
                handleClick,
            })}

            {renderReportedToEmployerDate({
                inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                textKey: tWithNS('reportedToEmployerDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.reportedToEmployerDate,
                buttonText: reportedToEmployerDate ? dtParseAndFormat(reportedToEmployerDate) : '-',
                path: WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE,
                handleClick,
            })}

            {renderClaimantWorkDay({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('claimantWorkDay.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimantWorkDay,
                buttonText:
                    claimantWorkDay.from && claimantWorkDay.to
                        ? `${timeParseAndFormat(claimantWorkDay.from)} - ${timeParseAndFormat(claimantWorkDay.to)}`
                        : '-',
                path: WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE,
                handleClick,
            })}

            {renderNatureTypeId({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('natureTypeId.heading.title'),
                dataTestId: TEST_ID_PREFIX.natureTypeId,
                buttonText: natureTypeId ? t(`lob.person.health.accident.natureTypeId.select.${natureTypeId}`) : '-',
                path: WORKERS_COMP_ACCIDENT_NATURE_TYPE_ID,
                handleClick,
            })}

            {renderBodyPartList(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('bodyPartsList.heading.title'),
                    dataTestId: TEST_ID_PREFIX.bodyPartList,
                    buttonText: '',
                    path: WORKERS_COMP_ACCIDENT_BODY_PARTS_LIST,
                    handleClick,
                },
                bodyPartList
            )}

            {renderIsVehicleInvolved({
                inputWrapperClass: isVehicleInvolvedClass,
                textKey: tWithNS('isVehicleInvolved.heading.title'),
                dataTestId: TEST_ID_PREFIX.isVehicleInvolved,
                buttonText: t(createTextYesNoDontKnow(isVehicleInvolved)),
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_VEHICLE_INVOLVED,
                handleClick,
            })}

            {renderTypeOfVehicle(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('typeOfVehicle.heading.title'),
                    dataTestId: TEST_ID_PREFIX.typeOfVehicle,
                    buttonText: typeOfVehicle
                        ? t(`lob.person.workersComp.accident.typeOfVehicle.select.${typeOfVehicle}`)
                        : '-',
                    path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_VEHICLE,
                    handleClick,
                },
                isVehicleInvolved
            )}

            {renderRegistrationNumber(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('vehicleInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.registrationNumber,
                    buttonText: registrationNumber ? registrationNumber : '-',
                    path: WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER,
                    handleClick,
                },
                typeOfVehicle
            )}

            {renderIsPoliceContacted({
                inputWrapperClass: isPoliceContactedClass,
                textKey: tWithNS('isPoliceContacted.heading.title'),
                dataTestId: TEST_ID_PREFIX.isPoliceContacted,
                buttonText: t(createTextYesNoDontKnow(isPoliceContacted)),
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED,
                handleClick,
            })}

            {renderPoliceDistrict(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('policeDistrict.heading.title'),
                    dataTestId: TEST_ID_PREFIX.policeDistrict,
                    buttonText: policeDistrict ? policeDistrict : '-',
                    path: WORKERS_COMP_ACCIDENT_POLICE_DISTRICT,
                    handleClick,
                },
                isPoliceContacted
            )}

            {renderUnderInfluence({
                inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                textKey: tWithNS('underInfluence.heading.title'),
                dataTestId: TEST_ID_PREFIX.underInfluence,
                buttonText: t(createTextYesNoDontKnow(underInfluence)),
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE,
                handleClick,
            })}

            {renderUnderInfluenceClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('underInfluenceClaimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.underInfluenceClaimDescription,
                    buttonText: underInfluenceClaimDescription ? underInfluenceClaimDescription : '-',
                    path: WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE_CLAIM_DESCRIPTION,
                    handleClick,
                },
                underInfluence
            )}

            {renderCausedIncapacity({
                inputWrapperClass: causedIncapacityClass,
                textKey: tWithNS('causedIncapacity.heading.title'),
                dataTestId: TEST_ID_PREFIX.causedIncapacity,
                buttonText: t(createTextYesNoDontKnow(causedIncapacity)),
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CAUSED_INCAPACITY,
                handleClick,
            })}

            {renderIncapacityMoreThanSevenDays(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('incapacityMoreThanSevenDays.heading.title'),
                    dataTestId: TEST_ID_PREFIX.incapacityMoreThanSevenDays,
                    buttonText: t(createTextYesNoDontKnow(incapacityMoreThanSevenDays)),
                    path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_MORE_THAN_SEVEN_DAYS,
                    handleClick,
                },
                causedIncapacity,
                claimantRole
            )}

            {renderIncapacityPeriod(
                {
                    inputWrapperClass: 'col-6 divider align-button-start text-align-left',
                    textKey: tWithNS('incapacityPeriod.heading.title'),
                    dataTestId: TEST_ID_PREFIX.incapacityPeriod,
                    buttonText: incapacityPeriod
                        ? t(`lob.person.workersComp.accident.incapacityPeriod.select.${incapacityPeriod}`)
                        : '-',
                    path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_PERIOD,
                    handleClick,
                },
                causedIncapacity,
                claimantRole
            )}

            {renderIncapacityPeriods(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('incapacityPeriods.heading.title'),
                    dataTestId: TEST_ID_PREFIX.incapacityPeriod,
                    buttonText: '',
                    path: WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS,
                    handleClick,
                },
                causedIncapacity,
                incapacityPeriods,
                t
            )}

            {renderHasAppliedMedicalCare({
                inputWrapperClass: hasAppliedMedicalCareClass,
                textKey: tWithNS('hasAppliedMedicalCare.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasAppliedMedicalCare,
                buttonText: t(createTextYesNoDontKnow(hasAppliedMedicalCare)),
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE,
                handleClick,
            })}

            {renderDateOfAppliedMedicalCare(
                {
                    inputWrapperClass: 'col-4 divider align-button-start text-align-left',
                    textKey: tWithNS('dateOfAppliedMedicalCare.heading.title'),
                    dataTestId: TEST_ID_PREFIX.dateOfAppliedMedicalCare,
                    buttonText: dateOfAppliedMedicalCare ? dtParseAndFormat(dateOfAppliedMedicalCare) : '-',
                    path: WORKERS_COMP_ACCIDENT_DATE_OF_APPLIED_MEDICAL_CARE,
                    handleClick,
                },
                hasAppliedMedicalCare
            )}

            {renderMedicalInstitution(
                {
                    inputWrapperClass: 'col-4 divider align-button-start text-align-left',
                    textKey: tWithNS('medicalInstitution.heading.title'),
                    dataTestId: TEST_ID_PREFIX.medicalInstitution,
                    buttonText: medicalInstitution ? medicalInstitution : '-',
                    path: WORKERS_COMP_ACCIDENT_MEDICAL_INSTITUTION,
                    handleClick,
                },
                hasAppliedMedicalCare
            )}

            {renderHasExpenses({
                inputWrapperClass: hasExpensesClass,
                textKey: tWithNS('hasExpenses.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasExpenses,
                buttonText: t(createTextYesNoDontKnow(hasExpenses)),
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES,
                handleClick,
            })}

            {renderWhoPaidExpenses(
                {
                    inputWrapperClass: 'col-6 spacing align-button-start text-align-left',
                    textKey: tWithNS('whoPaidExpenses.heading.title'),
                    dataTestId: TEST_ID_PREFIX.whoPaidExpenses,
                    buttonText: whoPaidExpenses
                        ? t(`lob.person.workersComp.accident.whoPaidExpenses.select.${whoPaidExpenses}`)
                        : '-',
                    path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_WHO_PAID_EXPENSES,
                    handleClick,
                },
                hasExpenses
            )}

            {renderReceipt(
                {
                    inputWrapperClass: 'col-12 divider align-button-start text-align-left',
                    textKey: tWithNS('uploadReceipt.heading.title'),
                    dataTestId: TEST_ID_PREFIX.uploadReceipt,
                    buttonText: '',
                    path: WORKERS_COMP_ACCIDENT_UPLOAD_RECEIPT,
                    handleClick,
                },
                hasExpenses,
                upload.receipt
            )}

            {renderHasOtherInsurance({
                inputWrapperClass: hasOtherInsuranceClass,
                textKey: tWithNS('hasOtherInsurance.heading.title'),
                dataTestId: TEST_ID_PREFIX.otherInsurance,
                buttonText: t(createTextYesNoDontKnow(otherInsurance)),
                path: DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE,
                handleClick,
            })}

            {renderOtherInsuranceCompany(
                {
                    inputWrapperClass: 'col-8 divider align-button-start text-align-left',
                    textKey: tWithNS('otherInsuranceCompany.heading.title'),
                    dataTestId: TEST_ID_PREFIX.otherInsuranceCompany,
                    buttonText: otherInsuranceCompany ? otherInsuranceCompany : '-',
                    path: WORKERS_COMP_ACCIDENT_OTHER_INSURANCE_COMPANY,
                    handleClick,
                },
                otherInsurance
            )}
        </>
    );
};
