import React from 'react';
import classNames from 'classnames';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { displayPerson, formatClaimant, isYes } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { renderReporterInformation } from './components/end/RenderReporterInformation';
import { renderPolicyHolder } from './components/end/RenderPolicyHolder';
import { renderAttachment } from './components/end/RenderAttachment';
import { renderPolicyHoldersContact } from './components/end/RenderPolicyHoldersContact';
import { renderHasOtherInsurance } from './components/end/RenderHasOtherInsurance';
import { renderOtherInsurance } from './components/end/RenderOtherInsurance';
import { renderExternalReference } from './components/end/RenderExternalReference';
import { LpoSummaryProps } from 'interfaces/types/SummaryTypes';
import { formatCompanyInformation } from '../../../utils/strings/formatCompanyInformation';
import { formatReporterInformation } from '../../../utils/strings/formatReporterInformation';
import { renderClaimantInformation } from './components/end/RenderClaimantInformation';
import { renderBankAccountInformation } from './components/end/RenderBankAccountInformation';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    END_OTHER_INSURANCE_COMPANY,
    END_POLICY_HOLDER,
    END_POLICY_HOLDERS_CONTACT,
    END_REPORTER_INFORMATION,
    END_UPLOAD_ATTACHMENT,
    TRAVEL_CLAIMANT_INFORMATION,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayEndSummary = ({ model, t, tWithNS, handleClick, upload, common }: LpoSummaryProps) => {
    const {
        bankAccountInformation,
        claimantInformation,
        companyInformation,
        externalReference,
        otherInsurance,
        otherInsuranceCompany,
        policyHoldersContact,
        reporterInformation,
    } = model;
    const { attachment } = upload;
    const { lob } = common;

    // Classes
    const hasOtherInsuranceClass = classNames(
        {
            'col-6': isYes(otherInsurance),
            'col-12': !isYes(otherInsurance),
        },
        'divider text-align-left'
    );

    return (
        <>
            {renderAttachment(
                {
                    textKey: `${tWithNS('uploadAttachment.heading.title')}`,
                    dataTestId: TEST_ID_PREFIX.uploadAttachments,
                    path: END_UPLOAD_ATTACHMENT,
                    buttonText: '',
                },
                `${tWithNS('uploadAttachment.heading.title')}`,
                TEST_ID_PREFIX.uploadAttachments,
                attachment
            )}

            {renderHasOtherInsurance(
                {
                    inputWrapperClass: hasOtherInsuranceClass,
                    textKey: tWithNS('hasOtherInsurance.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasOtherInsurance,
                    buttonText: t(createTextYesNoDontKnow(otherInsurance)),
                    path: DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
                    handleClick,
                },
                otherInsurance
            )}

            {renderOtherInsurance(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('otherInsuranceCompany.heading.title'),
                    dataTestId: TEST_ID_PREFIX.otherInsuranceCompany,
                    buttonText: otherInsuranceCompany ? otherInsuranceCompany : '-',
                    path: END_OTHER_INSURANCE_COMPANY,
                    handleClick,
                },
                otherInsurance
            )}

            {renderPolicyHolder({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHolder.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHolder,
                buttonText: companyInformation ? formatCompanyInformation(companyInformation) : '-',
                path: END_POLICY_HOLDER,
                handleClick,
            })}

            {renderExternalReference(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('externalReference.heading.title'),
                    dataTestId: TEST_ID_PREFIX.externalReference,
                    buttonText: externalReference ? externalReference : '-',
                    path: END_POLICY_HOLDER,
                    handleClick,
                },
                externalReference
            )}

            {renderClaimantInformation(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimantInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimantInformation,
                    buttonText: formatClaimant(claimantInformation),
                    path: TRAVEL_CLAIMANT_INFORMATION,
                    handleClick,
                },
                lob
            )}

            {renderReporterInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('reporterInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.reporterInformation,
                buttonText: formatReporterInformation(reporterInformation),
                path: END_REPORTER_INFORMATION,
                handleClick,
            })}

            {renderPolicyHoldersContact({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHoldersContact.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHoldersContact,
                buttonText: displayPerson(policyHoldersContact),
                path: END_POLICY_HOLDERS_CONTACT,
                handleClick,
            })}

            {renderBankAccountInformation(bankAccountInformation, lob, t)}
        </>
    );
};
