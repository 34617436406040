import { ObjectWithDynamicKey, YesNoKeys } from '@protectorinsurance/ds-can';

export enum LpoRoutePaths {
    INDEX = '/fin/:language/:lob',
    ERROR = '/error',

    // Dyn
    DYN_BASE = '/fin/:language/:lob/dyn/',
    DYN_CAROUSEL = '/fin/:language/:lob/dyn/:flow?/:slug',
    // Start
    DYN_CAROUSEL_START_IS_POLICE_CONTACTED = '/fin/:language/:lob/dyn/start/is-police-contacted',
    // Travel
    DYN_CAROUSEL_TRAVEL_CLAIM_TYPE = '/fin/:language/:lob/dyn/travel/claim-type',
    // End
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE = '/fin/:language/:lob/dyn/end/has-other-insurance',

    // Start
    START_ONBOARDING = '/fin/:language/:lob/start/onboarding',
    START_PRIVACY = '/fin/:language/:lob/start/privacy',
    START_CLAIM_DATE = '/fin/:language/:lob/start/claim-date',
    START_ACCIDENT_LOCATION = '/fin/:language/:lob/start/accident-location',
    START_POLICE_CASE_NUMBER = '/fin/:language/:lob/start/police-case-number',
    START_CLAIM_DESCRIPTION = '/fin/:language/:lob/start/claim-description',

    // Property
    PROPERTY_PROPERTY_ADDRESS = '/fin/:language/:lob/property/property-address',

    // Travel
    TRAVEL_TRAVEL_DATES = '/fin/:language/:lob/travel/travel-dates',
    TRAVEL_TRAVEL_INTERRUPTION = '/fin/:language/:lob/travel/travel-interruption',
    TRAVEL_CLAIMANT_INFORMATION = '/fin/:language/:lob/travel/claimant-information',

    // End
    END_UPLOAD_ATTACHMENT = '/fin/:language/:lob/end/upload-attachment',
    END_OTHER_INSURANCE_COMPANY = '/fin/:language/:lob/end/other-insurance-company',
    END_POLICY_HOLDER = '/fin/:language/:lob/end/policy-holder',
    END_REPORTER_INFORMATION = '/fin/:language/:lob/end/reporter-information',
    END_POLICY_HOLDERS_CONTACT = '/fin/:language/:lob/end/policy-holders-contact',
    END_BANK_ACCOUNT_INFORMATION = '/fin/:language/:lob/end/bank-account-information',
    END_FINAL_SUMMARY = '/fin/:language/:lob/end/final-summary',
    END_REPORT_COMPLETED = '/fin/:language/:lob/end/report-completed',
}

export const lpoWizardRoutes: ObjectWithDynamicKey = {
    // Start Routes
    [LpoRoutePaths.INDEX]: LpoRoutePaths.START_PRIVACY,
    [LpoRoutePaths.START_ONBOARDING]: LpoRoutePaths.START_PRIVACY,
    [LpoRoutePaths.START_PRIVACY]: LpoRoutePaths.START_CLAIM_DATE,
    [LpoRoutePaths.START_CLAIM_DATE]: LpoRoutePaths.START_ACCIDENT_LOCATION,
    [LpoRoutePaths.START_ACCIDENT_LOCATION]: LpoRoutePaths.DYN_CAROUSEL_START_IS_POLICE_CONTACTED,
    [LpoRoutePaths.DYN_CAROUSEL_START_IS_POLICE_CONTACTED]: {
        [YesNoKeys.YES]: LpoRoutePaths.START_POLICE_CASE_NUMBER,
        [YesNoKeys.NO]: LpoRoutePaths.START_CLAIM_DESCRIPTION,
    },
    [LpoRoutePaths.START_POLICE_CASE_NUMBER]: LpoRoutePaths.START_CLAIM_DESCRIPTION,
    [LpoRoutePaths.START_CLAIM_DESCRIPTION]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,

    // Property Routes
    [LpoRoutePaths.PROPERTY_PROPERTY_ADDRESS]: LpoRoutePaths.DYN_CAROUSEL_START_IS_POLICE_CONTACTED,

    // Travel Routes
    [LpoRoutePaths.DYN_CAROUSEL_TRAVEL_CLAIM_TYPE]: LpoRoutePaths.START_ONBOARDING,
    [LpoRoutePaths.TRAVEL_TRAVEL_DATES]: LpoRoutePaths.START_ACCIDENT_LOCATION,
    [LpoRoutePaths.TRAVEL_TRAVEL_INTERRUPTION]: LpoRoutePaths.START_CLAIM_DESCRIPTION,
    [LpoRoutePaths.TRAVEL_CLAIMANT_INFORMATION]: LpoRoutePaths.END_REPORTER_INFORMATION,

    // End Routes
    [LpoRoutePaths.END_UPLOAD_ATTACHMENT]: LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    [LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: LpoRoutePaths.END_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: LpoRoutePaths.END_POLICY_HOLDER,
    },
    [LpoRoutePaths.END_OTHER_INSURANCE_COMPANY]: LpoRoutePaths.END_POLICY_HOLDER,
    [LpoRoutePaths.END_POLICY_HOLDER]: LpoRoutePaths.END_REPORTER_INFORMATION,
    [LpoRoutePaths.END_REPORTER_INFORMATION]: LpoRoutePaths.END_POLICY_HOLDERS_CONTACT,
    [LpoRoutePaths.END_POLICY_HOLDERS_CONTACT]: LpoRoutePaths.END_FINAL_SUMMARY,
    [LpoRoutePaths.END_BANK_ACCOUNT_INFORMATION]: LpoRoutePaths.END_FINAL_SUMMARY,
    [LpoRoutePaths.END_FINAL_SUMMARY]: LpoRoutePaths.END_REPORT_COMPLETED,
};
