import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFlow } from '../../../sagas/selectors/flowSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { getFinalSummary } from '../../../utils/flow/getFinalSummaryUtil';
import {
    CircleCheckIcon,
    Clickable,
    FlowKeys,
    Grid,
    InsuranceTypeKeys,
    is,
    PageLayout,
    PrintLink,
    StandardModal,
    StartSummary,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectInsuranceType } from '../../../sagas/selectors/personSelectors';
import { selectCustomCAN, selectLocaleLanguageCode } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { ACCIDENT } = FlowKeys;
const { BACK_BUTTON, COMPLETED_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { HEALTH, WORK_COMP } = InsuranceTypeKeys;

/**
 * Page view and page logic
 */
export const DynReportCompletedPage = () => {
    const flow = useSelector(selectFlow);
    const insuranceType = useSelector(selectInsuranceType);
    const customCAN = useSelector(selectCustomCAN);
    let prefix = 'groupAccident';
    if (is(insuranceType, WORK_COMP)) {
        if (is(flow, ACCIDENT)) {
            prefix = 'workersComp.accident';
        } else {
            prefix = 'workersComp.illness';
        }
    }

    if (is(insuranceType, HEALTH)) {
        if (is(flow, ACCIDENT)) {
            prefix = 'health.accident';
        } else {
            prefix = 'health.illness';
        }
    }
    const { t } = useI18n();
    const tWithNS = useI18n(`lob.person.${prefix}.reportCompleted`);
    const language = useSelector(selectLocaleLanguageCode);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleBackButton = (e: any) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = (e: Clickable) => {
        e.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        setShowModal(false);
    };

    const handleConfirmModal = (e: Clickable) => {
        e.preventDefault();
        window.location.href = `/nor/${language}`;
    };

    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        window.location.replace(tWithNS.t('content.homePageLink'));
    };

    const printSummary = (e: Clickable) => {
        e.preventDefault();
        window.print();
    };

    return (
        <>
            <PageLayout
                backBtnText={t(BACK_BUTTON)}
                continueBtnText={t(COMPLETED_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerComponent={<PrintLink title={tWithNS.t(HELP_TEXT)} onClick={printSummary} />}
                headerSubTitle={tWithNS.t(SUB_TITLE)}
                headerTitle={tWithNS.t(TITLE)}
                pageClassName={'no-print'}
                showBackButton={false}
                {...{ handleContinueButton }}
            >
                <Grid>
                    <div className={'col-3'}>
                        <CircleCheckIcon wh={100} />
                    </div>
                    <div className={'col-9 text-align-left'}>
                        <StartSummary
                            description={tWithNS.t('body.paragraph.one')}
                            title={tWithNS.t('body.title')}
                            {...{ customCAN }}
                        />
                        <p tabIndex={0}>
                            {tWithNS.t('body.paragraph.two')}{' '}
                            <a href={`mailto:${tWithNS.t('body.email')}`}>{tWithNS.t('body.email')}</a>
                        </p>
                    </div>
                </Grid>
            </PageLayout>
            {getFinalSummary(flow, insuranceType, false, false)}
            {showModal && (
                <StandardModal
                    closeButtonText={t('modal.reportCompleted.closeButtonText')}
                    confirmButtonText={t('modal.reportCompleted.confirmButtonText')}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmModal}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={t('modal.reportCompleted.title')}
                >
                    <div className={'dialog__content text-center'}>{t('modal.reportCompleted.bodyText')}</div>
                </StandardModal>
            )}
        </>
    );
};
