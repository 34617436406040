import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { reversingDescriptionSchema } from '../fieldSchemas/reversingDescriptionSchema';

/**
 * Validation logic
 */
export const reversingClaimDescriptionSchema = (t: TFunction, label: string): Yup.ObjectSchema => {
    return Yup.object().shape({
        ...reversingDescriptionSchema(t, label),
    });
};
