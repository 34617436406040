import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { POSITION_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const positionSchema = (t: TFunction) => {
    return {
        position: Yup.string().label(t(POSITION_LABEL)).required().noSpecialChars().nullable(),
    };
};
