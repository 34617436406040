import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { driverSchema } from '../fieldSchemas/driverSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';

/**
 * Validation logic
 */
export const reporterAndDriverInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...contactDetailsSchema(t),
        ...driverSchema(t),
        ...nationalIdentitySchema(t, true),
    });
};
