import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { useI18n } from '../../../../hooks/useI18n';
import { selectClaimantInformation, selectClaimReporterRole } from '../../../../sagas/selectors/personSelectors';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import { claimantInformationHealthSchema } from '../../../../validations/schemas/claimantInformationHealthSchema';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    AutocompleteEnums,
    ClaimantModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    Nullable,
    PageLayout,
    SingleCheckbox,
    WhoReportKeys,
} from '@protectorinsurance/ds-can';
import { crossContactInfoFieldValidation } from '../../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { CITY, EMAIL, FIRST_NAME, LAST_NAME, NATIONALITY, NATIONAL_IDENTITY, PHONE, STREET, ZIP, MISSING_SSN } =
    FormFieldNames;
const {
    BACK_BUTTON,
    CITY_LABEL,
    CITY_PLACEHOLDER,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NATIONALITY_LABEL,
    NATIONALITY_PLACEHOLDER,
    NATIONAL_IDENTITY_LABEL,
    NATIONAL_IDENTITY_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    STREET_LABEL,
    STREET_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
    ZIP_LABEL,
    ZIP_PLACEHOLDER,
    MISSING_SSN_LABEL,
} = PhraseKeys;
const { MYSELF } = WhoReportKeys;
const {
    CITY: CITY_AUTOCOMPLETE,
    EMAIL: EMAIL_AUTOCOMPLETE,
    FAMILY_NAME,
    GIVEN_NAME,
    POSTAL_CODE,
    STREET_ADDRESS,
    TEL,
} = AutocompleteEnums;

/**
 * Interfaces
 */
interface ClaimantFormModel extends Omit<ClaimantModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const AccidentClaimantInformationPage = () => {
    const dispatch = useDispatch();
    const claimantInformation = useSelector(selectClaimantInformation);
    const whoReport = useSelector(selectClaimReporterRole);
    const customCAN = useSelector(selectCustomCAN);
    const checkMissingSSN = claimantInformation.missingSSN || false;
    const { t } = useI18n();
    const tWithNS = useI18n(`lob.person.health.accident.claimantInformation`);
    const {
        clearErrors,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimantFormModel>({
        resolver: yupResolver(claimantInformationHealthSchema(t, checkMissingSSN)),
        defaultValues: {
            ...claimantInformation,
            dateOfBirth: claimantInformation.dateOfBirth && claimantInformation.dateOfBirth.toDate(),
            nationalIdentity: claimantInformation.nationalIdentity || '',
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await crossContactInfoFieldValidation(id, trigger);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: ClaimantFormModel) => {
        let goToLink = wizardActions.goToNext();
        if (whoReport === MYSELF) {
            goToLink = wizardActions.goTo(PersonRoutePaths.H_A_BANK_ACCOUNT_INFORMATION);
        }

        dispatcherWithPromise(dispatch, personActions.update, {
            claimantInformation: {
                ...claimantInformation,
                ...values,
                dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
            },
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(goToLink));
    };

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked, id } = e.target;
        setValue('missingSSN', checked);
        if (checked) {
            setValue('nationalIdentity', '');
            clearErrors('nationalIdentity');
        }
        dispatch(wizardActions.skipBackToPrev(true));
        dispatcherWithPromise(dispatch, personActions.update, {
            claimantInformation: {
                ...claimantInformation,
                [id]: checked,
            },
        });
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <SingleCheckbox
                        checked={checkMissingSSN}
                        handleChange={handleChecked}
                        name={MISSING_SSN}
                        wrapperClass={'col-12 multiple'}
                        {...{ customCAN }}
                    >
                        {t(MISSING_SSN_LABEL)}
                    </SingleCheckbox>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={NATIONAL_IDENTITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                disabled={checkMissingSSN}
                                error={!!errors.nationalIdentity}
                                errorMessage={errors.nationalIdentity?.message}
                                id={NATIONAL_IDENTITY}
                                inputFieldWrapper={'col-4'}
                                label={t(NATIONAL_IDENTITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(NATIONAL_IDENTITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={STREET}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={STREET_ADDRESS}
                                error={!!errors.street}
                                errorMessage={errors.street?.message}
                                id={STREET}
                                inputFieldWrapper={'col-4'}
                                label={t(STREET_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(STREET_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={ZIP}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={POSTAL_CODE}
                                error={!!errors.zip}
                                errorMessage={errors.zip?.message}
                                id={ZIP}
                                inputFieldWrapper={'col-4'}
                                inputMode={'numeric'}
                                label={t(ZIP_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ZIP_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={CITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={CITY_AUTOCOMPLETE}
                                error={!!errors.city}
                                errorMessage={errors.city?.message}
                                id={CITY}
                                inputFieldWrapper={'col-4'}
                                label={t(CITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={NATIONALITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.nationality}
                                errorMessage={errors.nationality?.message}
                                id={NATIONALITY}
                                inputFieldWrapper={'col-4'}
                                label={t(NATIONALITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(NATIONALITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
