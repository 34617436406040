import { applyMiddleware, compose, createStore, Store } from 'redux';
import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { AppState, rootReducers, rootSagas } from './index';
import { AppActions } from './rootReducers';
import { togglesServiceActions, TogglesServiceAppEnums, CountryCodeISOEnums } from '@protectorinsurance/ds-can';

const { CLAIMS_AT_NET } = TogglesServiceAppEnums;
const { FINLAND } = CountryCodeISOEnums;

export const configureStore = (initState: AppState, history: History): Store<AppState, AppActions> => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    const composeEnhancer: typeof compose =
        process.env.NODE_ENV !== 'production' &&
        typeof window === 'object' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            : compose;

    const store = createStore<any, AppActions, any, AppState>(
        (state: AppState, action: AppActions) => rootReducers(state, action, history),
        initState,
        composeEnhancer(applyMiddleware(...middlewares))
    );

    // Init sagas
    sagaMiddleware.run(rootSagas);

    // Extensions
    (store as any).runSaga = sagaMiddleware.run;
    (store as any).injectedReducers = {}; // Reducer registry
    (store as any).injectedSagas = {}; // Saga registry

    // Hot reloading
    if ((module as any).hot) {
        // Enable Webpack hot module replacement for reducers
        (module as any).hot.accept('./index', () => {
            store.replaceReducer((state: AppState, action: AppActions) => rootReducers(state, action, history));
        });
    }

    // Dispatch init actions
    store.dispatch(
        togglesServiceActions.request({
            application: CLAIMS_AT_NET,
            country: FINLAND,
        })
    );

    return store;
};
