import { NumberSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';

export function numberOfDigits(
    this: NumberSchema,
    count: number,
    message: string = yupCustomLocale.string.insuranceNumber
) {
    // @ts-ignore
    return this.test('numberOfDigits', message, function (this: TestContext, value: number):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return value ? value.toString().length === count : true;
    });
}
