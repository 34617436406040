import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    AtLeast3DaysIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    IncapacityPeriodEnums,
    InjuryDayIcon,
    OneOrTwoDaysIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectIncapacityPeriod } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { AT_LEAST_THREE_DAYS, INJURY_DAY, ONE_OR_TWO_DAYS } = IncapacityPeriodEnums;

/**
 * Page view and page logic
 */
export const workersCompAccidentIncapacityPeriodPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: INJURY_DAY,
            name: t(`lob.person.workersComp.accident.incapacityPeriod.select.${INJURY_DAY}`),
            icon: <InjuryDayIcon />,
        },
        {
            id: ONE_OR_TWO_DAYS,
            name: t(`lob.person.workersComp.accident.incapacityPeriod.select.${ONE_OR_TWO_DAYS}`),
            icon: <OneOrTwoDaysIcon />,
        },
        {
            id: AT_LEAST_THREE_DAYS,
            name: t(`lob.person.workersComp.accident.incapacityPeriod.select.${AT_LEAST_THREE_DAYS}`),
            icon: <AtLeast3DaysIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_PERIOD,
        stateKey: 'incapacityPeriod',
        i18n: {
            ns: 'lob.person.workersComp.accident.incapacityPeriod',
        },
        selectorValue: selectIncapacityPeriod(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { incapacityPeriod: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
