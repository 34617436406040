import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { positionSchema } from '../fieldSchemas/positionSchema';
import { ownershipSchema } from '../fieldSchemas/ownershipSchema';

/**
 * Validation logic
 */
export const entrepreneurOwnershipSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...positionSchema(t),
        ...ownershipSchema(t),
    });
};
