import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    LobKeys,
    MuiTextInput,
    Nullable,
    PageLayout,
    ReporterInformationModel,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { lpoActions } from '../../../sagas/lpo';
import { selectReporterInformation } from '../../../sagas/selectors/lpoSelectors';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { crossContactInfoFieldValidation } from 'utils/validation/crossContactInfoFieldValidation';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { lpoReporterInformationSchema } from '../../../validations/schemas/lpoReporterInformationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    ROLE_LABEL,
    ROLE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL, FIRST_NAME, LAST_NAME, PHONE, ROLE } = FormFieldNames;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { CHANGE_OF_OWNERSHIP_NOR } = LobKeys;
const { END_FINAL_SUMMARY } = LpoRoutePaths;

/**
 * Interfaces
 */
interface ReporterFormModel extends Omit<ReporterInformationModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const EndReporterInformationPage = () => {
    const dispatch = useDispatch();
    const reporterInformation = useSelector(selectReporterInformation);
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.end.reporterInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ReporterFormModel>({
        resolver: yupResolver(lpoReporterInformationSchema(t)),
        defaultValues: {
            ...reporterInformation,
            dateOfBirth: reporterInformation.dateOfBirth && reporterInformation.dateOfBirth.toDate(),
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const onSubmit = (values: ReporterFormModel) => {
        let nextAction = wizardActions.goToNext();
        if (is(lob, CHANGE_OF_OWNERSHIP_NOR)) {
            nextAction = wizardActions.goTo(END_FINAL_SUMMARY);
        }

        dispatcherWithPromise(dispatch, lpoActions.update, {
            reporterInformation: { ...values, dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null },
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={ROLE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.role}
                                errorMessage={errors.role?.message}
                                id={ROLE}
                                inputFieldWrapper={'col-4'}
                                label={t(ROLE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ROLE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
