import React from 'react';
import { BodyPartListTypeModel, isGreater, SummaryItemProps } from '@protectorinsurance/ds-can';
import { SummaryArrayItem } from '../../../summaryArrayItem/SummaryArrayItem';

/**
 * Component view and component logic
 */
export const renderBody = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    bodyParts: BodyPartListTypeModel
) => {
    if (isGreater(bodyParts.length, 0)) {
        return (
            <div className={inputWrapperClass}>
                <SummaryArrayItem
                    labelTextKey={textKey ? textKey : ''}
                    name={dataTestId ? dataTestId : ''}
                    list={bodyParts}
                    textItemKeyPrefix={'lob.person.health.accident.situationSummary.bodyParts.parts'}
                    textItemKeyPostfix={'label'}
                    {...{ handleClick, path }}
                    show={true}
                />
            </div>
        );
    }
    return <></>;
};
