import React from 'react';
import {
    CarouselConfigLookupResponse,
    DryRoadIcon,
    RoadConditionKeys,
    SnowRoadIcon,
    WetRoadIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { selectRoadCondition } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { DRY, SNOW_ICE, WET } = RoadConditionKeys;

/**
 * Page view and page logic
 */
export const roadConditionPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: DRY, name: t(`motor.driving.roadCondition.select.${DRY}`), icon: <DryRoadIcon /> },
        { id: WET, name: t(`motor.driving.roadCondition.select.${WET}`), icon: <WetRoadIcon /> },
        { id: SNOW_ICE, name: t(`motor.driving.roadCondition.select.${SNOW_ICE}`), icon: <SnowRoadIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION,
        stateKey: 'roadCondition',
        i18n: {
            ns: 'motor.driving.roadCondition',
        },
        selectorValue: selectRoadCondition(state),
        options,
    };
};
