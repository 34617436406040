import { takeLatest } from 'redux-saga/effects';
import { AppState } from '../../store';
import { actionWithPromise, Nullable, Rejectable, Resolvable } from '@protectorinsurance/ds-can';
import { put, select } from 'typed-redux-saga';

/**
 * Action Types
 */
export enum MapsServiceActionTypes {
    TOKEN = '@app/maps/TOKEN',
    SET_TOKEN = '@app/maps/SET_TOKEN',
}

/**
 * Action Definitions
 */
export interface MapsServiceAction {
    type: MapsServiceActionTypes;
    data?: Nullable<google.maps.places.AutocompleteSessionToken>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const mapsServiceActions = {
    token: actionWithPromise<MapsServiceActionTypes>(MapsServiceActionTypes.TOKEN),
    setToken: actionWithPromise<MapsServiceActionTypes, Nullable<google.maps.places.AutocompleteSessionToken>>(
        MapsServiceActionTypes.SET_TOKEN
    ),
};

export interface MapsServiceState {
    token: Nullable<google.maps.places.AutocompleteSessionToken>;
}
export const mapsServiceInitState = {
    token: null,
};
export default function (state = mapsServiceInitState, { type, data }: MapsServiceAction) {
    switch (type) {
        case MapsServiceActionTypes.SET_TOKEN:
            return { ...state, token: data };
        default:
            return state;
    }
}

/**
 * Saga watchers
 */
export const mapsServiceWatcher = function* () {
    yield takeLatest(MapsServiceActionTypes.TOKEN, mapsServiceSagas.token);
};

/**
 * Saga functions
 */
export const mapsServiceSagas = {
    *token() {
        try {
            const mapsToken = yield* select(selectMapsToken);
            if (mapsToken === null) {
                const sessionToken = new google.maps.places.AutocompleteSessionToken();
                yield* put(mapsServiceActions.setToken(sessionToken));
            }
        } catch (e) {
            yield* put(mapsServiceActions.setToken(null));
        }
    },
};

export const selectMapsToken = (state: AppState) => state.services.maps.token;
