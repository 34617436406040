import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';

/**
 * Destructure necessary imports
 */
const { OWNERSHIP_LABEL } = PhraseKeys;
const { INVALID_NUMBER_FORMAT, NUMBER_GREATER_THAN, NUMBER_LESS_THAN } = FormFieldErrors;

/**
 * Validation logic
 */
export const ownershipSchema = (t: TFunction) => {
    return {
        ownership: Yup.number()
            .label(t(OWNERSHIP_LABEL))
            .min(1, t(NUMBER_GREATER_THAN))
            .max(100, t(NUMBER_LESS_THAN))
            .required()
            .typeError(t(INVALID_NUMBER_FORMAT))
            .nullable(),
    };
};
