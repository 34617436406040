import React from 'react';
import { useI18n } from '../../../../../hooks/useI18n';
import './FinalSummary.scss';
import { I18NOptions, PolicyHoldersContactModel, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayPolicyHoldersContactProps {
    model: PolicyHoldersContactModel;
    i18n?: I18NOptions;
}

export const DisplayPolicyHoldersContact = ({ model, i18n }: DisplayPolicyHoldersContactProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));
    const { firstName, lastName, role, email, phone } = model;

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix('text.policyHoldersContact')}
                </p>
            </div>
            <SummaryItemText
                label={tWithPrefix('text.fullName')}
                text={`${firstName} ${lastName}`}
                className={`col-4 ${email && phone ? 'spacing' : 'divider'} text-align-left`}
            />
            <SummaryItemText
                label={tWithPrefix('text.role')}
                text={`${role}`}
                className={`${email && phone ? 'col-8 spacing' : 'col-4 divider'} text-align-left`}
            />
            {email && (
                <SummaryItemText
                    label={tWithPrefix('text.email')}
                    text={`${email}`}
                    className={'col-4 divider text-align-left'}
                />
            )}
            {phone && (
                <SummaryItemText
                    label={tWithPrefix('text.phone')}
                    text={`${phone}`}
                    className={`${email ? 'col-8' : 'col-4'} divider text-align-left`}
                />
            )}
        </>
    );
};
