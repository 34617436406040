import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { REG_NR_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const registrationNumberSchema = (t: TFunction) => {
    return {
        registrationNumber: Yup.string().label(t(REG_NR_LABEL)).registrationNumber().nullable(),
    };
};
