import React from 'react';
import { useSelector } from 'react-redux';
import { selectFlow } from '../../../sagas/selectors/flowSelectors';
import { getFinalSummary } from '../../../utils/flow/getFinalSummaryUtil';
import { selectInsuranceType } from '../../../sagas/selectors/personSelectors';

/**
 * Page view and page logic
 */
export const DynFinalSummaryPage = () => {
    const flow = useSelector(selectFlow);
    const insuranceType = useSelector(selectInsuranceType);

    return <>{getFinalSummary(flow, insuranceType)}</>;
};
