import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { selectBodyPartsList } from '../../../../sagas/selectors/personSelectors';
import { personActions } from '../../../../sagas/person';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    BodyPartListHeadImage,
    bodyPartListIds,
    BodyPartListKeys,
    BodyPartListLowerBodyImage,
    BodyPartListModel,
    BodyPartListTypeModel,
    BodyPartListUpperBodyImage,
    Clickable,
    emptyFn,
    Formable,
    Grid,
    InputValidationError,
    isBodyPartChecked,
    PageLayout,
    PartCheckboxGroup,
    PartCheckboxGroupLeft,
    SVGClickable,
    updateSelectablePartsList,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import Tabs from '../../../../components/tabs/Tabs';
import TabPanel from '../../../../components/tabs/TabPanel';
import { CheckboxInput } from '../../../../components/inputs/CheckboxInput';
import { selectLocaleLanguageCode } from 'sagas/selectors/commonSelectors';
import { getLabelDirection } from '../../../../utils/person/bodyPartsListUtils';

enum TabKeys {
    HEAD = 'HEAD',
    LOWER_BODY = 'LOWER_BODY',
    UPPER_BODY = 'UPPER_BODY',
    FRONT = 'FRONT',
    BACK = 'BACK',
}

/**
 * Destructure necessary imports
 */
const {
    ANKLE_LEFT,
    ANKLE_RIGHT,
    ARM_LEFT_FRONT,
    ARM_RIGHT_FRONT,
    BACK,
    BACK_SPINE,
    CHEST,
    CRANIUM,
    EAR_LEFT,
    EAR_RIGHT,
    EYE_LEFT,
    EYE_RIGHT,
    FACE,
    FINGERS_LEFT,
    FINGERS_RIGHT,
    FOOT_LEFT,
    FOOT_RIGHT,
    HAND_LEFT,
    HAND_RIGHT,
    HEAD,
    HEAD_OTHER,
    HIP_FRONT,
    LEG_LEFT_FRONT,
    LEG_RIGHT_FRONT,
    LOWER_EXTREMITIES_FRONT,
    LOWER_EXTREMITIES_OTHER_FRONT,
    NECK_BACK,
    NECK_FRONT,
    PELVIC,
    RIBS_FRONT,
    SHOULDER_LEFT_BACK,
    SHOULDER_RIGHT_BACK,
    SHOULDER_LEFT_FRONT,
    SHOULDER_RIGHT_FRONT,
    TEETH,
    TOES_LEFT,
    TOES_RIGHT,
    TORSO_FRONT,
    TORSO_FRONT_OTHER,
    UPPER_EXTREMITIES_FRONT,
    UPPER_EXTREMITIES_OTHER_FRONT,
    WRIST_LEFT,
    WRIST_RIGHT,
} = BodyPartListKeys;
const { BACK_BUTTON, BOYD_PARTS_REQUIRED, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const WorkersCompAccidentBodyPartsListPage = () => {
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.accident.bodyPartsList');
    const dispatch = useDispatch();
    const bodyPartsList = useSelector(selectBodyPartsList);
    const language = useSelector(selectLocaleLanguageCode);
    const { register } = useForm<BodyPartListTypeModel>({});
    const labels = getLabelDirection(language);
    const [error, setError] = useState<boolean>(false);

    /**
     * Internal helper functions
     */
    const updateBodyParts = (key: BodyPartListKeys, id: number, selected: boolean) => {
        const parts = updateSelectablePartsList(key, id, bodyPartsList, selected);

        dispatch(personActions.update({ bodyPartList: parts }));
    };

    const isHeadDisabled = isBodyPartChecked(HEAD, bodyPartsList);
    const isHeadOtherDisabled = isBodyPartChecked(HEAD_OTHER, bodyPartsList);
    const isFacialAreaDisabled = isBodyPartChecked(FACE, bodyPartsList);
    const isTorsoFrontDisabled = isBodyPartChecked(TORSO_FRONT, bodyPartsList);
    const isTorsoFrontOtherDisabled = isBodyPartChecked(TORSO_FRONT_OTHER, bodyPartsList);
    const isUpperExtremitiesFrontDisabled = isBodyPartChecked(UPPER_EXTREMITIES_FRONT, bodyPartsList);
    const isUpperExtremitiesFrontOtherDisabled = isBodyPartChecked(UPPER_EXTREMITIES_OTHER_FRONT, bodyPartsList);
    const isLowerExtremitiesFrontDisabled = isBodyPartChecked(LOWER_EXTREMITIES_FRONT, bodyPartsList);
    const isLowerExtremitiesFrontOtherDisabled = isBodyPartChecked(LOWER_EXTREMITIES_OTHER_FRONT, bodyPartsList);

    const tCheckboxLabel = (direction: string, label: string) => `${t(direction)} ${tWithNS.t(label)}`;

    /**
     * Handle functions
     */
    const handleBackButton = useGoBack();
    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (bodyPartsList.length > 0) {
            setError(false);
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        } else {
            setError(true);
        }
    };

    const handleChange = (e: Formable) => {
        const { name, checked } = e.currentTarget;
        const bodyPart = bodyPartListIds.find((bp: { id: number; key: BodyPartListKeys }) => bp.key === name);

        if (bodyPart) {
            updateBodyParts(name as BodyPartListKeys, bodyPart.id, checked);
            if (bodyPartsList.length > 0) {
                setError(false);
            } else {
                setError(true);
            }
        }
    };

    const handleBlur = emptyFn;

    const handleSvgClick = (e: SVGClickable) => {
        const name = e.currentTarget.id as BodyPartListKeys;
        const bodyPart = bodyPartListIds.find((bp: { id: number; key: BodyPartListKeys }) => bp.key === name);

        if (bodyPart) {
            const checked = bodyPartsList.some((bp: BodyPartListModel) => bp.key === name);
            updateBodyParts(name, bodyPart.id, !checked);
            if (bodyPartsList.length > 0) {
                setError(false);
            } else {
                setError(true);
            }
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={'page__body-parts'}
            {...{ handleBackButton, handleContinueButton }}
        >
            <div className={'tabs__container mt-5'}>
                <Tabs
                    label={tWithNS.t('tabs.label')}
                    tabs={[
                        { id: TabKeys.HEAD, label: tWithNS.t('tabs.tab.head.label') },
                        { id: TabKeys.UPPER_BODY, label: tWithNS.t('tabs.tab.upperBody.label') },
                        { id: TabKeys.LOWER_BODY, label: tWithNS.t('tabs.tab.lowerBody.label') },
                    ]}
                >
                    <TabPanel tabId={TabKeys.HEAD}>
                        <div className="body-parts space-x-5 mt-5">
                            <div className={'body-parts__image'}>
                                <div className={'wrapper'}>
                                    <BodyPartListHeadImage onClick={handleSvgClick} selectedParts={bodyPartsList} />
                                    <div className="footer">
                                        <span>{tWithNS.t('image.footer.right')}</span>
                                        <span>{tWithNS.t('image.footer.left')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'body-parts__content'}>
                                {/* HEAD */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HEAD.description')}
                                    label={tWithNS.t('parts.HEAD.label')}
                                    labelFor={HEAD}
                                >
                                    <CheckboxInput
                                        id={HEAD}
                                        isChecked={isBodyPartChecked(HEAD, bodyPartsList)}
                                        isDisabled={isHeadOtherDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* CRANIUM */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.CRANIUM.description')}
                                    label={tWithNS.t('parts.CRANIUM.label')}
                                    labelFor={CRANIUM}
                                >
                                    <CheckboxInput
                                        id={CRANIUM}
                                        isChecked={isBodyPartChecked(CRANIUM, bodyPartsList)}
                                        isDisabled={isHeadDisabled || isHeadOtherDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* EAR */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.EAR.description')}
                                    label={tWithNS.t('parts.EAR.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={EAR_LEFT}
                                        isChecked={isBodyPartChecked(EAR_LEFT, bodyPartsList)}
                                        isDisabled={isHeadDisabled || isHeadOtherDisabled}
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.EAR_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={EAR_RIGHT}
                                        isChecked={isBodyPartChecked(EAR_RIGHT, bodyPartsList)}
                                        isDisabled={isHeadDisabled || isHeadOtherDisabled}
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.EAR_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* EYE */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.EYE.description')}
                                    label={tWithNS.t('parts.EYE.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={EYE_LEFT}
                                        isChecked={isBodyPartChecked(EYE_LEFT, bodyPartsList)}
                                        isDisabled={isHeadDisabled || isFacialAreaDisabled || isHeadOtherDisabled}
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.EYE_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={EYE_RIGHT}
                                        isChecked={isBodyPartChecked(EYE_RIGHT, bodyPartsList)}
                                        isDisabled={isHeadDisabled || isFacialAreaDisabled || isHeadOtherDisabled}
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.EYE_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* FACIAL AREA */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.FACE.description')}
                                    label={tWithNS.t('parts.FACE.label')}
                                    labelFor={FACE}
                                >
                                    <CheckboxInput
                                        id={FACE}
                                        isChecked={isBodyPartChecked(FACE, bodyPartsList)}
                                        isDisabled={isHeadDisabled || isHeadOtherDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* TEETH */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TEETH.description')}
                                    label={tWithNS.t('parts.TEETH.label')}
                                    labelFor={TEETH}
                                >
                                    <CheckboxInput
                                        id={TEETH}
                                        isChecked={isBodyPartChecked(TEETH, bodyPartsList)}
                                        isDisabled={isHeadDisabled || isFacialAreaDisabled || isHeadOtherDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* OTHER */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HEAD_OTHER.description')}
                                    label={tWithNS.t('parts.HEAD_OTHER.label')}
                                    labelFor={HEAD_OTHER}
                                >
                                    <CheckboxInput
                                        id={HEAD_OTHER}
                                        isChecked={isBodyPartChecked(HEAD_OTHER, bodyPartsList)}
                                        isDisabled={isHeadDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel tabId={TabKeys.UPPER_BODY}>
                        <div className="body-parts space-x-5 mt-5">
                            <div className={'body-parts-left__content'}>
                                <h2>{tWithNS.t('parts.heading.back')}</h2>
                                {/* NECK */}
                                <PartCheckboxGroupLeft
                                    description={tWithNS.t('parts.NECK_BACK.description')}
                                    label={tWithNS.t('parts.NECK_BACK.label')}
                                    labelFor={NECK_BACK}
                                >
                                    <CheckboxInput
                                        id={NECK_BACK}
                                        isChecked={isBodyPartChecked(NECK_BACK, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroupLeft>

                                {/* SHOULDER */}
                                <PartCheckboxGroupLeft
                                    description={tWithNS.t('parts.SHOULDER_BACK.description')}
                                    label={tWithNS.t('parts.SHOULDER_BACK.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={SHOULDER_LEFT_BACK}
                                        isChecked={isBodyPartChecked(SHOULDER_LEFT_BACK, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.SHOULDER_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={SHOULDER_RIGHT_BACK}
                                        isChecked={isBodyPartChecked(SHOULDER_RIGHT_BACK, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.SHOULDER_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroupLeft>

                                {/* BACK */}
                                <PartCheckboxGroupLeft
                                    description={tWithNS.t('parts.BACK.description')}
                                    label={tWithNS.t('parts.BACK.label')}
                                    labelFor={BACK}
                                >
                                    <CheckboxInput
                                        id={BACK}
                                        isChecked={isBodyPartChecked(BACK, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroupLeft>
                                <PartCheckboxGroupLeft
                                    description={tWithNS.t('parts.BACK_SPINE.description')}
                                    label={tWithNS.t('parts.BACK_SPINE.label')}
                                    labelFor={BACK_SPINE}
                                >
                                    <CheckboxInput
                                        id={BACK_SPINE}
                                        isChecked={isBodyPartChecked(BACK_SPINE, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroupLeft>
                            </div>
                            <div className={'body-parts__image'}>
                                <div className={'wrapper'}>
                                    <BodyPartListUpperBodyImage
                                        onClick={handleSvgClick}
                                        selectedParts={bodyPartsList}
                                    />
                                    <div className="footer">
                                        <span>{tWithNS.t('image.footer.right')}</span>
                                        <span>{tWithNS.t('image.footer.left')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'body-parts__content'}>
                                <h2>{tWithNS.t('parts.heading.front')}</h2>
                                {/* NECK */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.NECK_FRONT.description')}
                                    label={tWithNS.t('parts.NECK_FRONT.label')}
                                    labelFor={NECK_FRONT}
                                >
                                    <CheckboxInput
                                        id={NECK_FRONT}
                                        isChecked={isBodyPartChecked(NECK_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* CHEST */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.CHEST.description')}
                                    label={tWithNS.t('parts.CHEST.label')}
                                    labelFor={CHEST}
                                >
                                    <CheckboxInput
                                        id={CHEST}
                                        isChecked={isBodyPartChecked(CHEST, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* RIBS */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.RIBS_FRONT.description')}
                                    label={tWithNS.t('parts.RIBS_FRONT.label')}
                                    labelFor={RIBS_FRONT}
                                >
                                    <CheckboxInput
                                        id={RIBS_FRONT}
                                        isChecked={isBodyPartChecked(RIBS_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* SHOULDER */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.SHOULDER_FRONT.description')}
                                    label={tWithNS.t('parts.SHOULDER_FRONT.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={SHOULDER_LEFT_FRONT}
                                        isChecked={isBodyPartChecked(SHOULDER_LEFT_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.SHOULDER_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={SHOULDER_RIGHT_FRONT}
                                        isChecked={isBodyPartChecked(SHOULDER_RIGHT_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.SHOULDER_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* PELVIC */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.PELVIC.description')}
                                    label={tWithNS.t('parts.PELVIC.label')}
                                    labelFor={PELVIC}
                                >
                                    <CheckboxInput
                                        id={PELVIC}
                                        isChecked={isBodyPartChecked(PELVIC, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* TORSO */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TORSO_FRONT.description')}
                                    label={tWithNS.t('parts.TORSO_FRONT.label')}
                                    labelFor={TORSO_FRONT}
                                >
                                    <CheckboxInput
                                        id={TORSO_FRONT}
                                        isChecked={isBodyPartChecked(TORSO_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontOtherDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TORSO_FRONT_OTHER.description')}
                                    label={tWithNS.t('parts.TORSO_FRONT_OTHER.label')}
                                    labelFor={TORSO_FRONT_OTHER}
                                >
                                    <CheckboxInput
                                        id={TORSO_FRONT_OTHER}
                                        isChecked={isBodyPartChecked(TORSO_FRONT_OTHER, bodyPartsList)}
                                        isDisabled={
                                            isTorsoFrontDisabled ||
                                            isUpperExtremitiesFrontDisabled ||
                                            isUpperExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* ARM */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.ARM_FRONT.description')}
                                    label={tWithNS.t('parts.ARM_FRONT.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={ARM_LEFT_FRONT}
                                        isChecked={isBodyPartChecked(ARM_LEFT_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.ARM_LEFT_FRONT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={ARM_RIGHT_FRONT}
                                        isChecked={isBodyPartChecked(ARM_RIGHT_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.ARM_RIGHT_FRONT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* WRIST */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.WRIST.description')}
                                    label={tWithNS.t('parts.WRIST.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={WRIST_LEFT}
                                        isChecked={isBodyPartChecked(WRIST_LEFT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.WRIST_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={WRIST_RIGHT}
                                        isChecked={isBodyPartChecked(WRIST_RIGHT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.WRIST_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* HAND */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HAND.description')}
                                    label={tWithNS.t('parts.HAND.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={HAND_LEFT}
                                        isChecked={isBodyPartChecked(HAND_LEFT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.HAND_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={HAND_RIGHT}
                                        isChecked={isBodyPartChecked(HAND_RIGHT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.HAND_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* FINGERS */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.FINGERS.description')}
                                    label={tWithNS.t('parts.FINGERS.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={FINGERS_LEFT}
                                        isChecked={isBodyPartChecked(FINGERS_LEFT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.FINGERS_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={FINGERS_RIGHT}
                                        isChecked={isBodyPartChecked(FINGERS_RIGHT, bodyPartsList)}
                                        isDisabled={
                                            isUpperExtremitiesFrontDisabled || isUpperExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.FINGERS_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* UPPER EXTREMITIES */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.UPPER_EXTREMITIES_FRONT.description')}
                                    label={tWithNS.t('parts.UPPER_EXTREMITIES_FRONT.label')}
                                    labelFor={UPPER_EXTREMITIES_FRONT}
                                >
                                    <CheckboxInput
                                        id={UPPER_EXTREMITIES_FRONT}
                                        isChecked={isBodyPartChecked(UPPER_EXTREMITIES_FRONT, bodyPartsList)}
                                        isDisabled={isUpperExtremitiesFrontOtherDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.UPPER_EXTREMITIES_OTHER_FRONT.description')}
                                    label={tWithNS.t('parts.UPPER_EXTREMITIES_OTHER_FRONT.label')}
                                    labelFor={UPPER_EXTREMITIES_OTHER_FRONT}
                                >
                                    <CheckboxInput
                                        id={UPPER_EXTREMITIES_OTHER_FRONT}
                                        isChecked={isBodyPartChecked(UPPER_EXTREMITIES_OTHER_FRONT, bodyPartsList)}
                                        isDisabled={isUpperExtremitiesFrontDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel tabId={TabKeys.LOWER_BODY}>
                        <div className="body-parts space-x-5 mt-5">
                            <div className={'body-parts-left__content'}>
                                <h2>{tWithNS.t('parts.heading.back')}</h2>
                            </div>
                            <div className={'body-parts__image'}>
                                <div className={'wrapper'}>
                                    <BodyPartListLowerBodyImage
                                        onClick={handleSvgClick}
                                        selectedParts={bodyPartsList}
                                    />
                                    <div className="footer">
                                        <span>{tWithNS.t('image.footer.right')}</span>
                                        <span>{tWithNS.t('image.footer.left')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'body-parts__content'}>
                                <h2>{tWithNS.t('parts.heading.front')}</h2>
                                {/* HIP */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.HIP_FRONT.description')}
                                    label={tWithNS.t('parts.HIP_FRONT.label')}
                                    labelFor={HIP_FRONT}
                                >
                                    <CheckboxInput
                                        id={HIP_FRONT}
                                        isChecked={isBodyPartChecked(HIP_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* LEG */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.LEG_FRONT.description')}
                                    label={tWithNS.t('parts.LEG_FRONT.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={LEG_LEFT_FRONT}
                                        isChecked={isBodyPartChecked(LEG_LEFT_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.LEG_LEFT_FRONT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={LEG_RIGHT_FRONT}
                                        isChecked={isBodyPartChecked(LEG_RIGHT_FRONT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.LEG_RIGHT_FRONT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* ANKLE */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.ANKLE.description')}
                                    label={tWithNS.t('parts.ANKLE.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={ANKLE_LEFT}
                                        isChecked={isBodyPartChecked(ANKLE_LEFT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.ANKLE_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={ANKLE_RIGHT}
                                        isChecked={isBodyPartChecked(ANKLE_RIGHT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.ANKLE_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* FOOT */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.FOOT.description')}
                                    label={tWithNS.t('parts.FOOT.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={FOOT_LEFT}
                                        isChecked={isBodyPartChecked(FOOT_LEFT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.FOOT_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={FOOT_RIGHT}
                                        isChecked={isBodyPartChecked(FOOT_RIGHT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.FOOT_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* TOES */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.TOES.description')}
                                    label={tWithNS.t('parts.TOES.label')}
                                >
                                    <CheckboxInput
                                        checkIconClassName={labels.left}
                                        id={TOES_LEFT}
                                        isChecked={isBodyPartChecked(TOES_LEFT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.left, tWithNS.t('parts.TOES_LEFT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                    <CheckboxInput
                                        checkIconClassName={labels.right}
                                        id={TOES_RIGHT}
                                        isChecked={isBodyPartChecked(TOES_RIGHT, bodyPartsList)}
                                        isDisabled={
                                            isLowerExtremitiesFrontDisabled || isLowerExtremitiesFrontOtherDisabled
                                        }
                                        label={tCheckboxLabel(labels.right, tWithNS.t('parts.TOES_RIGHT.label'))}
                                        labelClassName={language}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>

                                {/* LOWER EXTREMITIES */}
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.LOWER_EXTREMITIES_FRONT.description')}
                                    label={tWithNS.t('parts.LOWER_EXTREMITIES_FRONT.label')}
                                    labelFor={LOWER_EXTREMITIES_FRONT}
                                >
                                    <CheckboxInput
                                        id={LOWER_EXTREMITIES_FRONT}
                                        isChecked={isBodyPartChecked(LOWER_EXTREMITIES_FRONT, bodyPartsList)}
                                        isDisabled={isLowerExtremitiesFrontOtherDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                                <PartCheckboxGroup
                                    description={tWithNS.t('parts.LOWER_EXTREMITIES_OTHER_FRONT.description')}
                                    label={tWithNS.t('parts.LOWER_EXTREMITIES_OTHER_FRONT.label')}
                                    labelFor={LOWER_EXTREMITIES_OTHER_FRONT}
                                >
                                    <CheckboxInput
                                        id={LOWER_EXTREMITIES_OTHER_FRONT}
                                        isChecked={isBodyPartChecked(LOWER_EXTREMITIES_OTHER_FRONT, bodyPartsList)}
                                        isDisabled={isLowerExtremitiesFrontDisabled}
                                        reference={register}
                                        {...{ handleBlur, handleChange }}
                                    />
                                </PartCheckboxGroup>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
            {error && (
                <Grid>
                    <InputValidationError
                        className={'col-12'}
                        error={t(BOYD_PARTS_REQUIRED)}
                        fieldName={'body-parts'}
                    />
                </Grid>
            )}
        </PageLayout>
    );
};
