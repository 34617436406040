import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimLocationKeys,
    Clickable,
    IntersectionIcon,
    ParkingIcon,
    RoadIcon,
    RoundAboutIcon,
    TypeOfRoadKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { selectClaimLocationType } from '../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { INTERSECTION, PARKING_OTHER, ROAD, ROUNDABOUT } = ClaimLocationKeys;
const { OTHER } = TypeOfRoadKeys;

/**
 * Page view and page logic
 */
export const claimLocationTypePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: ROAD, name: t(`motor.driving.claimLocationType.select.${ROAD}`), icon: <RoadIcon /> },
        {
            id: INTERSECTION,
            name: t(`motor.driving.claimLocationType.select.${INTERSECTION}`),
            icon: <IntersectionIcon />,
        },
        { id: ROUNDABOUT, name: t(`motor.driving.claimLocationType.select.${ROUNDABOUT}`), icon: <RoundAboutIcon /> },
        {
            id: PARKING_OTHER,
            name: t(`motor.driving.claimLocationType.select.${PARKING_OTHER}`),
            icon: <ParkingIcon />,
        },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_CLAIM_LOCATION_TYPE,
        stateKey: 'claimLocationType',
        i18n: {
            ns: 'motor.driving.claimLocationType',
        },
        selectorValue: selectClaimLocationType(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (option.id !== selectClaimLocationType(state)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                let data: { claimLocationType: ClaimLocationKeys; typeOfRoad?: TypeOfRoadKeys } = {
                    claimLocationType: option.id,
                };
                if (option.id === PARKING_OTHER) {
                    data = { claimLocationType: option.id, typeOfRoad: OTHER };
                }

                dispatcherWithPromise(dispatch, motorActions.update, data)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
