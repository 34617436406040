import React from 'react';
import { useI18n } from '../../../../../hooks/useI18n';
import './FinalSummary.scss';
import { ClaimCompanyModel, I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayCompanyInformationProps {
    model: Partial<ClaimCompanyModel>;
    i18n?: I18NOptions;
}

export const DisplayCompanyInformation = ({ model, i18n }: DisplayCompanyInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));
    const { name, departmentName, businessNumber, policyNumber, street, city, zip } = model;

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 spacing header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix(`text.companyInformation`)}
                </p>
            </div>
            <SummaryItemText
                label={tWithPrefix('text.name')}
                text={`${name}`}
                className={'col-4 spacing text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.departmentName')}
                text={`${departmentName}`}
                className={'col-8 spacing text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.businessNumber')}
                text={`${businessNumber}`}
                className={'col-4 spacing text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.insuranceNumber')}
                text={`${policyNumber}`}
                className={'col-8 spacing text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.street')}
                text={`${street}`}
                className={'col-4 divider text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.zip')}
                text={`${zip}`}
                className={'col-4 divider text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.city')}
                text={`${city}`}
                className={'col-4 divider text-align-left'}
            />
        </>
    );
};
