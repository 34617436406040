import { TFunction } from 'i18next';
import { LanguageCodeEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
const createLanguageKey = (id: string) => {
    return `lob.person.language.select.${id}`;
};

export const getLanguageOption = (t: TFunction, key: LanguageCodeEnums) => {
    return {
        value: key,
        label: t(createLanguageKey(key)),
    };
};
