import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { motorCompanySchema } from '../fieldSchemas/motorCompanySchema';

/**
 * Validation logic
 */
export const motorCompanyInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...motorCompanySchema(t),
    });
};
