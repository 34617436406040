import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from 'hooks/useI18n';
import { deleteReceiptActions } from '../../../../sagas/upload/receipt/deleteReceipt';
import { uploadReceiptActions } from '../../../../sagas/upload/receipt/uploadReceipt';
import { selectReceiptFiles } from '../../../../sagas/selectors/uploadSelectors';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    Clickable,
    FileCategoryEnums,
    FileModel,
    FileUploader,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { getFileCategoryOption } from '../../../../utils/flow/fileUtils';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { receiptActions } from '../../../../sagas/upload/receipt/receipt';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DROPZONE_DESCRIPTION,
    DROPZONE_TITLE,
    FILE_AMOUNT_LABEL,
    FILE_AMOUNT_PLACEHOLDER,
    FILE_CATEGORY_LABEL,
    FILE_CATEGORY_PLACEHOLDER,
    FILE_DEFINITION_LABEL,
    FILE_DEFINITION_PLACEHOLDER,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    UPLOAD_LABEL,
} = PhraseKeys;
const { FILE_CATEGORY } = FormFieldNames;
const { MISSING_FILE_AMOUNT, MISSING_FILE_CATEGORY, MISSING_FILE_DEFINITION } = FormFieldErrors;
const {
    GENERAL_DOCTOR,
    IMAGING,
    LABORATORY,
    MEDICINES,
    OTHER_COMPENSATIONS,
    PHYSIOTHERAPY,
    SPECIALIST,
    SURGERY,
} = FileCategoryEnums;

/**
 * Page view and page logic
 */
export const IllnessUploadReceiptPage = () => {
    const dispatch = useDispatch();
    const [fileCategories, setFileCategories] = useState<Array<{ option: OptionType | null; id: string }>>([]);
    const fileStore = useSelector(selectReceiptFiles);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.health.illness.uploadReceipt');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    useEffect(() => {
        const categories: Array<{ option: OptionType | null; id: string }> = [];
        fileStore.forEach((file: FileModel) => {
            const selected = options.find((x) => x.value === file.category);
            if (selected) {
                categories.push({ option: selected, id: file.id });
            }
        });
        setFileCategories(categories);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileStore, setFileCategories]);

    // Options
    const generalDoctorOption = getFileCategoryOption(t, GENERAL_DOCTOR);
    const imagingOption = getFileCategoryOption(t, IMAGING);
    const laboratoryOption = getFileCategoryOption(t, LABORATORY);
    const medicinesOption = getFileCategoryOption(t, MEDICINES);
    const otherCompensationsOption = getFileCategoryOption(t, OTHER_COMPENSATIONS);
    const physiotherapyOption = getFileCategoryOption(t, PHYSIOTHERAPY);
    const specialistOption = getFileCategoryOption(t, SPECIALIST);
    const surgeryOption = getFileCategoryOption(t, SURGERY);

    const options = [
        generalDoctorOption,
        specialistOption,
        imagingOption,
        medicinesOption,
        physiotherapyOption,
        surgeryOption,
        laboratoryOption,
        otherCompensationsOption,
    ];

    const handleOnSuccess = (files: FileModel[]) => {
        files.forEach((file) => dispatch(uploadReceiptActions.request(file)));
    };

    const handleDelete = (e: Clickable, file: FileModel) => {
        e.preventDefault();
        dispatch(deleteReceiptActions.request(file));
    };

    const checkFileErrors: boolean = fileStore.some((file: FileModel) => {
        return file.errors.length > 0;
    });

    const checkFileCategory: boolean = fileStore.some((file: FileModel) => {
        return !file.category;
    });

    const checkFileDefinitions: boolean = fileStore.some((file: FileModel) => {
        return !file.definition;
    });

    const checkFileAmounts: boolean = fileStore.some((file: FileModel) => {
        return !file.amount;
    });

    const handleFileUpdate = (idx: number, option: any) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        const updatedFile: FileModel = {
            ...fileStore[idx],
            category: value as FileCategoryEnums,
        };

        const fileCategory = { option: object, id: fileStore[idx].id };
        const exists = fileCategories.some((o) => o.id === fileCategory.id);
        if (!exists) {
            setFileCategories([...fileCategories, fileCategory]);
        } else {
            const newFileCategories = fileCategories.filter(({ id }) => id !== fileCategory.id);
            setFileCategories([...newFileCategories, fileCategory]);
        }
        dispatch(receiptActions.update(updatedFile));
    };

    const definitionCallback = ({ id, definition }: { id: string; definition: string }) => {
        const fileIndex = fileStore.findIndex((file) => file.id === id);
        const updatedFile: FileModel = {
            ...fileStore[fileIndex],
            definition,
            requireDefinition: true,
        };
        dispatch(receiptActions.update(updatedFile));
    };

    const amountCallback = ({ id, amount }: { id: string; amount: string }) => {
        const fileIndex = fileStore.findIndex((file) => file.id === id);
        const updatedFile: FileModel = {
            ...fileStore[fileIndex],
            amount: Number(Number(amount).toFixed(2)),
            requireAmount: true,
        };
        dispatch(receiptActions.update(updatedFile));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={checkFileErrors || checkFileCategory || checkFileAmounts || checkFileDefinitions}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <FileUploader
                categoryErrorMessage={t(MISSING_FILE_CATEGORY)}
                fileItemId={FILE_CATEGORY}
                fileItemLabel={t(FILE_CATEGORY_LABEL)}
                fileItemPlaceholder={t(FILE_CATEGORY_PLACEHOLDER)}
                handleAmountCallback={amountCallback}
                handleDefinitionCallback={definitionCallback}
                hasReceiptInformation={true}
                label={tWithNS.t(DROPZONE_DESCRIPTION)}
                noOptionsMessage={t(NO_OPTIONS_MESSAGE)}
                onDelete={handleDelete}
                onSuccess={handleOnSuccess}
                receiptAmountErrorMessage={t(MISSING_FILE_AMOUNT)}
                receiptAmountLabel={t(FILE_AMOUNT_LABEL)}
                receiptAmountPlaceholder={t(FILE_AMOUNT_PLACEHOLDER)}
                receiptDefinitionErrorMessage={t(MISSING_FILE_DEFINITION)}
                receiptDefinitionLabel={t(FILE_DEFINITION_LABEL)}
                receiptDefinitionPlaceholder={t(FILE_DEFINITION_PLACEHOLDER)}
                requireCategory={true}
                text={tWithNS.t(UPLOAD_LABEL)}
                title={tWithNS.t(DROPZONE_TITLE)}
                {...{ fileCategories, fileStore, handleFileUpdate, options }}
            />
        </PageLayout>
    );
};
