import { produce } from 'immer';
import { AttachmentModel } from '../../../interfaces/models/Attachment.model';
import { actionWithPromise, FileModel, Rejectable, Resolvable, YesNoKeys } from '@protectorinsurance/ds-can';

/**
 * Action Types
 */
export enum CertificateActionTypes {
    REQUEST = '@app/upload/certificate/REQUEST',
    SUCCESS = '@app/upload/certificate/SUCCESS',
    ADD = '@app/upload/certificate/ADD',
    UPDATE = '@app/upload/certificate/UPDATE',
    FAILURE = '@app/upload/certificate/FAILURE',
    REMOVE = '@app/upload/certificate/REMOVE',
}

/**
 * Action Definitions
 */
export interface ICertificateAction {
    type: CertificateActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Init state
 */
export const certificateInitState: AttachmentModel = {
    files: [],
    hasAttachment: null,
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = certificateInitState, { type, data }: ICertificateAction) {
    return produce(state, (draft: AttachmentModel) => {
        switch (type) {
            case CertificateActionTypes.ADD:
                draft.files.push(data);
                draft.hasAttachment = YesNoKeys.YES;
                break;
            case CertificateActionTypes.UPDATE:
                const { id, status, errors } = data;
                const index = state.files.findIndex((x: FileModel) => x.id === id);
                draft.files[index].status = status;
                draft.files[index].errors = errors;
                break;
            case CertificateActionTypes.REMOVE:
                draft.files = state.files.filter((x) => x.id !== data.id);
                break;
            default:
                break;
        }
    });
}
/**
 * Redux Actions
 */
export const certificateActions = {
    add: actionWithPromise(CertificateActionTypes.ADD),
    failure: actionWithPromise(CertificateActionTypes.FAILURE),
    success: actionWithPromise(CertificateActionTypes.SUCCESS),
    remove: actionWithPromise(CertificateActionTypes.REMOVE),
    request: actionWithPromise(CertificateActionTypes.REQUEST),
    update: actionWithPromise(CertificateActionTypes.UPDATE),
};
