export const MAX_LENGTH_250 = 250;
export const MAX_LENGTH_80 = 80;
export const MAX_LENGTH_CLAIM_DESCRIPTION = 100000;

export enum FormFieldNames {
    ACCIDENT_DESCRIPTION = 'accidentDescription',
    ACCIDENT_SKETCH = 'accidentSketch',
    ACCOUNT_NUMBER = 'accountNumber',
    ACCOUNT_OWNER_FAMILY_NAME = 'ownerFamilyName',
    ACCOUNT_OWNER_GIVEN_NAME = 'ownerGivenName',
    BANK_ACCOUNT_NUMBER = 'bankAccountNumber',
    BUSINESS_NUMBER = 'businessNumber',
    CHASSIS_NUMBER = 'chassisNumber',
    CITY = 'city',
    CLAIM_CAUSE = 'claimCause',
    CLAIM_DATE = 'claimDate',
    CLAIM_DESCRIPTION = 'claimDescription',
    CLAIM_NUMBER = 'claimNumber',
    CLAIM_TIME = 'claimTime',
    COMPANY_NAME = 'companyName',
    COUNTRY = 'country',
    DAMAGE_ADDRESS = 'damageAddress',
    DAMAGE_DESCRIPTION = 'damageDescription',
    DAMAGED_ITEM = 'damagedItem',
    DATE_OF_APPLIED_MEDICAL_CARE = 'dateOfAppliedMedicalCare',
    DATE_OF_EMPLOYMENT = 'dateOfEmployment',
    DATE_OF_TERMINATION = 'dateOfTermination',
    DEPARTMENT_NAME = 'departmentName',
    DIAGNOSIS_CODE = 'diagnosisCode',
    DISTANCE_FROM_ROAD_SIDE = 'distanceFromRoadSide',
    DITCH_CLAIM_DESCRIPTION = 'ditchClaimDescription',
    DONT_KNOW_BANK_INFO = 'dontKnowBankInformation',
    DRIVING_SPEED = 'drivingSpeed',
    EMAIL = 'email',
    EMPLOYMENT_RELATIONSHIP = 'employmentRelationship',
    ENGINE_CLAIM_DESCRIPTION = 'engineClaimDescription',
    EXTERNAL_REFERENCE = 'externalReference',
    FILE_CATEGORY = 'fileCategory',
    FILE_DEFINITION = 'definition',
    FIRE_CLAIM_DESCRIPTION = 'fireClaimDescription',
    FIRST_NAME = 'firstName',
    FREIGHT_WEIGHT = 'freightWeight',
    FROM_DATE = 'from',
    FROM_TIME = 'fromTime',
    INJURED_PROFESSION = 'injuredProfession',
    INSURANCE_NUMBER = 'insuranceNumber',
    IS_COMPANY = 'isCompany',
    IS_DRIVER = 'isDriver',
    IS_REPORTER = 'isReporter',
    LANGUAGE = 'language',
    LAST_NAME = 'lastName',
    LIFE_DAMAGE_MUNICIPALITY = 'lifeDamageMunicipality',
    LOCATION_TYPE = 'locationType',
    MAKE = 'make',
    MEDICAL_INSTITUTION = 'medicalInstitution',
    MILEAGE = 'mileage',
    MISSING_ITEMS = 'missingItems',
    MISSING_SSN = 'missingSSN',
    MODEL = 'model',
    NAME = 'name',
    NATIONALITY = 'nationality',
    NATIONAL_IDENTITY = 'nationalIdentity',
    NATURE_TYPE_ID = 'natureTypeId',
    NOTE = 'note',
    OCCUPATION_TYPE = 'occupationType',
    OD_CAUSE = 'odCause',
    OTHER_ID = 'otherId',
    OTHER_INSURANCE_COMPANY = 'otherInsuranceCompany',
    OTHER_MISSING_ITEMS_CLAIM_DESCRIPTION = 'otherMissingItemsClaimDescription',
    OWNERSHIP = 'ownership',
    PARKING_DATE = 'parkingDate',
    PARKING_TIME = 'parkingTime',
    PHONE = 'phone',
    PLACE_OF_ACCIDENT = 'placeOfAccident',
    POLICE_CASE_NUMBER = 'policeCaseNumber',
    POLICE_DISTRICT = 'policeDistrict',
    POLICY_NUMBER = 'policyNumber',
    POSITION = 'position',
    PROPERTY_ADDRESS = 'note',
    PROPERTY_UNIT = 'unit',
    RATE = 'rate',
    RECOVERY_DATE = 'recoveryDate',
    REG_NR = 'registrationNumber',
    RECEIPT_TYPE = 'receiptType',
    REVERSING_CLAIM_DESCRIPTION = 'reversingClaimDescription',
    ROAD_CONDITION_CLAIM_DESCRIPTION = 'roadConditionClaimDescription',
    ROAD_WIDTH = 'roadWidth',
    ROLE = 'role',
    SPEED_LIMIT = 'speedLimit',
    SPEED_ON_IMPACT = 'speedOnImpact',
    STREET = 'street',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION = 'theftAndDamagesClaimDescription',
    TIME_SINCE_ACTION = 'timeSinceAction',
    TO_DATE = 'to',
    TO_TIME = 'toTime',
    TYPE = 'type',
    VEHICLE_TYPE = 'vehicleType',
    VIN_NUMBER = 'VINNumber',
    WHO_RESPONSIBLE_CLAIM_DESCRIPTION = 'whoResponsibleClaimDescription',
    WHO_UNDER_INFLUENCE = 'whoUnderInfluence',
    WORKSHOP_INFORMATION = 'workshopInformation',
    ZIP = 'zip',
}

export enum FormFieldErrors {
    ACCOUNT_NUMBER_ERROR = 'form.validation.string.accountNumber',
    EMAIL_ERROR_MESSAGE = 'form.validation.string.email',
    FIELD_ERROR = 'form.validation.string.fieldError',
    IF_REQUIRED_ERROR_MESSAGE = 'form.validation.string.ifRequired',
    INSURANCE_NUMBER_ERROR = 'form.validation.string.insuranceNumber',
    INVALID_DATE_ORDER = 'form.validation.string.invalidDateOrder',
    INVALID_NUMBER_FORMAT = 'form.validation.string.invalidNumberFormat',
    MANDATORY_DATE_FIELD = 'form.validation.string.mandatoryDateField',
    MANDATORY_TIME_FIELD = 'form.validation.string.mandatoryTimeField',
    MAX_LENGTH_ERROR_MESSAGE = 'form.validation.string.maxLength',
    MIN_LENGTH_ERROR_MESSAGE = 'form.validation.string.minLength',
    MISSING_FILE_AMOUNT = 'form.validation.string.missingFileAmount',
    MISSING_FILE_CATEGORY = 'form.validation.string.missingFileCategory',
    MISSING_FILE_DEFINITION = 'form.validation.string.missingFileDefinition',
    MISSING_ITEMS_REQUIRED = 'form.validation.string.missingItems',
    NO_JWT_RECEIVED = 'form.validation.string.noJWTReceived',
    NO_NEGATIVE_NUMBERS = 'form.validation.number.noNegativeNumbers',
    NO_STORED_CLAIM = 'form.validation.string.noStoredClaim',
    NUMBER_GREATER_THAN = 'form.validation.string.numberGreaterThan',
    NUMBER_LESS_THAN = 'form.validation.string.numberLessThan',
    NUMBER_OF_DIGITS_ERROR = 'form.validation.number.numberOfDigits',
    OVERLAPPING_PERIODS = 'form.validation.string.overlappingPeriods',
    PERSON_EXISTS = 'form.validation.string.personExists',
    REGISTRATION_NUMBER_EXISTS = 'form.validation.string.registrationNumberExists',
    REQUIRED_ERROR_MESSAGE = 'form.validation.string.required',
    TYPE_ERROR = 'form.validation.number.typeError',
}
