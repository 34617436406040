import React from 'react';
import { is, isYes, MotorClaimCauseKeys, SummaryPersonItem, SummaryPersonList } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { MotorRoutePaths } from '../../../../config/wizardRouter/motorWizardRoutes';
import { claimCauseSummary } from '../../../../utils/claimCauseUtils';
import { MotorSummaryProps } from 'interfaces/types/SummaryTypes';

/**
 * Destructure necessary imports
 */
const {
    END_DRIVER_INFORMATION,
    END_INJURED_PERSON_INFORMATION,
    END_POLICY_HOLDERS_CONTACT,
    END_REPORTER_INFORMATION,
    END_WITNESS_INFORMATION,
} = MotorRoutePaths;
const { OTHER } = MotorClaimCauseKeys;

/**
 * Page view and logic
 */
export const DisplayIncidentEndSummary = ({ model, tWithNS, printSummary = false, handleClick }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const {
        claimCause,
        driverInformation,
        hasParkedWitnesses,
        hasPersonInjuries,
        hasWitnesses,
        injuredPersonInformationList,
        policyHoldersContact,
        reporterInformation,
        witnesses,
        isDriving,
    } = model;

    const renderWitnesses = () => {
        return (
            <>
                {/* Witnesses */}
                {(isYes(hasWitnesses) || isYes(hasParkedWitnesses)) && (
                    <SummaryPersonList
                        className={'col-12 divider'}
                        list={witnesses}
                        path={END_WITNESS_INFORMATION}
                        textKey={tWithNS('witnessInformation.heading.title')}
                        dataTestId={TEST_ID_PREFIX.witnesses}
                        {...{ handleClick, printSummary }}
                    />
                )}
            </>
        );
    };

    const renderInjuries = () => {
        return (
            <>
                {/* Injured */}
                {isYes(hasPersonInjuries) && (
                    <SummaryPersonList
                        className={'col-12 divider'}
                        list={injuredPersonInformationList}
                        path={END_INJURED_PERSON_INFORMATION}
                        textKey={tWithNS('injuredPersonInformationList.heading.title')}
                        dataTestId={TEST_ID_PREFIX.injuredPersonInformationList}
                        {...{ handleClick, printSummary }}
                    />
                )}
            </>
        );
    };

    const renderPolicyHoldersContact = () => {
        return (
            <>
                {/* Policy Holders contact */}
                <SummaryPersonItem
                    item={policyHoldersContact}
                    textKey={tWithNS('policyHoldersContact.heading.title')}
                    dataTestId={TEST_ID_PREFIX.policyHoldersContact}
                    path={END_POLICY_HOLDERS_CONTACT}
                    className={'col-12 spacing'}
                    {...{ handleClick, printSummary }}
                />
            </>
        );
    };

    const renderDriverInformation = () => {
        return (
            <>
                {/* DriverInformation */}
                {isYes(isDriving) && (
                    <SummaryPersonItem
                        item={driverInformation}
                        textKey={tWithNS('driverInformation.heading.title')}
                        dataTestId={TEST_ID_PREFIX.driverInformation}
                        path={END_DRIVER_INFORMATION}
                        className={'col-12 spacing'}
                        {...{ handleClick, printSummary }}
                    />
                )}
            </>
        );
    };

    const renderReporter = () => {
        return (
            <>
                {/* Reporter */}
                <SummaryPersonItem
                    item={reporterInformation}
                    textKey={tWithNS('reporterInformation.heading.title')}
                    dataTestId={TEST_ID_PREFIX.reporterInformation}
                    path={END_REPORTER_INFORMATION}
                    className={'col-12 divider'}
                    {...{ handleClick, printSummary }}
                />
            </>
        );
    };

    return (
        <>
            {!is(claimCause, OTHER) && !claimCauseSummary(claimCause) && (
                <>
                    {renderWitnesses()}

                    {renderInjuries()}
                </>
            )}

            {renderPolicyHoldersContact()}

            {!is(claimCause, OTHER) && !claimCauseSummary(claimCause) && (
                <>
                    {renderDriverInformation()}

                    {renderReporter()}
                </>
            )}
        </>
    );
};
