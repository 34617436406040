import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { registrationNumberFinlandValidator } from '@protectorinsurance/ds-can';

/**
 * Method logic
 */
export function registrationNumber(this: StringSchema, message: string = yupCustomLocale.string.registrationNumber) {
    // @ts-ignore
    return this.test('registrationNumber', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return !value ? true : registrationNumberFinlandValidator(value).isValid();
    });
}
