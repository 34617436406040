import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useGoBack } from '../../../../hooks/useGoBack';
import { useI18n } from '../../../../hooks/useI18n';
import {
    AddIcon,
    CircleIconButton,
    Clickable,
    findClaimVehicle,
    Grid,
    PageLayout,
    PartyRelationKeys,
    SelectedVehicleTypeModel,
    VehicleListItem,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { selectVehicles } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../config/wizardRouter/motorWizardRoutes';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { ADD_BUTTON, BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER } = MotorRoutePaths;
const { vehicleList } = TEST_ID_PREFIX;
const { OTHER } = PartyRelationKeys;

/**
 * Page view and page logic
 */
export const DVDamagesPage = () => {
    const dispatch = useDispatch();
    const vehicles = useSelector(selectVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const claimVehicle = findClaimVehicle(vehicles);
    const otherVehicles = vehicles.filter((v: VehicleModel) => v.partyRelation === OTHER);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.damages');

    const handleBackButton = useGoBack();

    const handleAddButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(wizardActions.goTo(DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleDelete = (id: SelectedVehicleTypeModel, e?: Clickable) => {
        e?.preventDefault();
        const vehicleArray = vehicles.filter((vehicle: VehicleModel) => vehicle.internalId !== id);
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehicleArray });
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={vehicles.length < 2}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            {claimVehicle && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.claimVehicle')}</h2>
                    <Grid>
                        <VehicleListItem
                            dataTestId={`${vehicleList}claimVehicle`}
                            make={claimVehicle?.make}
                            model={claimVehicle?.model}
                            registrationNumber={claimVehicle?.registrationNumber}
                            type={claimVehicle?.type}
                            vehicleType={claimVehicle?.vehicleType}
                            {...{ customCAN }}
                        />
                    </Grid>
                </>
            )}

            {otherVehicles.length > 0 && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.otherVehicles')}</h2>
                    <Grid>
                        {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                            const { internalId, make, model, registrationNumber, type, vehicleType } = vehicle;
                            return (
                                <VehicleListItem
                                    dataTestId={`${vehicleList}${idx}`}
                                    handleDelete={() => handleDelete(internalId)}
                                    {...{ customCAN, make, model, registrationNumber, type, vehicleType }}
                                />
                            );
                        })}
                    </Grid>
                </>
            )}
            <CircleIconButton
                ariaLabel={t(ADD_BUTTON)}
                className={'dual add-btn'}
                dataTestId={'btn-add-vehicle'}
                handleClick={handleAddButton}
                icon={<AddIcon />}
                label={tWithNS.t('add.button')}
            />
        </PageLayout>
    );
};
