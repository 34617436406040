import { TFunction } from 'i18next';
import { OdCauseEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createOdCauseKey = (id: string) => {
    return `lob.person.odCause.select.${id}`;
};

export const getOdCauseOption = (t: TFunction, key: OdCauseEnums, value: string) => {
    return {
        value,
        label: t(createOdCauseKey(key)),
    };
};
