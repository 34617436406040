import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { useI18n } from '../../../../hooks/useI18n';
import {
    selectBankAccountInformation,
    selectClaimantInformation,
    selectClaimantRole,
} from '../../../../sagas/selectors/personSelectors';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    AutocompleteEnums,
    ClaimantModel,
    ClaimantRoleEnums,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    LanguageCodeEnums,
    MuiAutocomplete,
    MuiTextInput,
    Nullable,
    OptionType,
    PageLayout,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { crossContactInfoFieldValidation } from '../../../../utils/validation/crossContactInfoFieldValidation';
import { claimantInformationWorkersCompSchema } from '../../../../validations/schemas/claimantInformationWorkersCompSchema';
import { getLanguageOption } from '../../../../utils/person/languageUtils';
import { getCountry } from '../../../../utils/country/getCountry';
import { selectCustomCAN, selectLocaleLanguageCode } from '../../../../sagas/selectors/commonSelectors';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

/**
 * Destructure necessary imports
 */
const {
    BANK_ACCOUNT_NUMBER,
    CITY,
    EMAIL,
    FIRST_NAME,
    LANGUAGE,
    LAST_NAME,
    NATIONALITY,
    NATIONAL_IDENTITY,
    PHONE,
    STREET,
    ZIP,
    MISSING_SSN,
} = FormFieldNames;
const {
    BACK_BUTTON,
    BANK_ACCOUNT_NUMBER_LABEL,
    BANK_ACCOUNT_NUMBER_PLACEHOLDER,
    CITY_LABEL,
    CITY_PLACEHOLDER,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LANGUAGE_LABEL,
    LANGUAGE_PLACEHOLDER,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NATIONALITY_LABEL,
    NATIONALITY_PLACEHOLDER,
    NATIONAL_IDENTITY_LABEL,
    NATIONAL_IDENTITY_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    STREET_LABEL,
    STREET_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
    ZIP_LABEL,
    ZIP_PLACEHOLDER,
    MISSING_SSN_LABEL,
} = PhraseKeys;
const {
    CITY: CITY_AUTOCOMPLETE,
    EMAIL: EMAIL_AUTOCOMPLETE,
    FAMILY_NAME,
    GIVEN_NAME,
    POSTAL_CODE,
    STREET_ADDRESS,
    TEL,
} = AutocompleteEnums;
const { FI, SE } = LanguageCodeEnums;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { ENTREPRENEUR } = ClaimantRoleEnums;
const { WORKERS_COMP_ACCIDENT_CLAIMANT_POSITION } = PersonRoutePaths;

/**
 * Interfaces
 */
interface ClaimantFormModel extends Omit<ClaimantModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const WorkersCompAccidentClaimantInformationPage = () => {
    const dispatch = useDispatch();
    const claimantInformation = useSelector(selectClaimantInformation);
    const bankAccountInformation = useSelector(selectBankAccountInformation);
    const claimantRole = useSelector(selectClaimantRole);
    const customCAN = useSelector(selectCustomCAN);
    const checkMissingSSN = claimantInformation.missingSSN || false;
    const lang = useSelector(selectLocaleLanguageCode);
    const [language, setLanguage] = useState<OptionType | null>(null);
    const [nationalityId, setNationalityId] = useState<OptionType | null>(null);
    const [languageError, setLanguageError] = useState<string>('');
    const [nationalityIdError, setNationalityIdError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n(`lob.person.workersComp.accident.claimantInformation`);
    const {
        clearErrors,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimantFormModel>({
        resolver: yupResolver(claimantInformationWorkersCompSchema(t, checkMissingSSN)),
        defaultValues: {
            ...claimantInformation,
            dateOfBirth: claimantInformation.dateOfBirth && claimantInformation.dateOfBirth.toDate(),
            nationalIdentity: claimantInformation.nationalIdentity || '',
        },
    });

    // Options
    const finnishOption = getLanguageOption(t, FI);
    const swedishOption = getLanguageOption(t, SE);

    const languageOptions = [finnishOption, swedishOption];
    const nationalityIdOptions = getCountry(lang).sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        const selected = languageOptions.find((x) => x.value === claimantInformation.language);
        if (selected) {
            setLanguage(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimantInformation, setLanguage]);

    useEffect(() => {
        const selected = nationalityIdOptions.find((x) => Number(x.value) === claimantInformation.nationalityId.id);
        if (selected) {
            setNationalityId(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimantInformation, setNationalityId]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await crossContactInfoFieldValidation(id, trigger);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleLanguageSelectBlur = async (e: FormChangeable) => {
        e.preventDefault();
        if (!claimantInformation.language) {
            setLanguageError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setLanguageError('');
        }
    };

    const handleNationalityIdSelectBlur = async (e: FormChangeable) => {
        e.preventDefault();
        if (!claimantInformation.nationalityId.id) {
            setNationalityIdError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setNationalityIdError('');
        }
    };

    const handleLanguageSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setLanguageError('');
        setLanguage(object);
        dispatch(personActions.update({ claimantInformation: { ...claimantInformation, language: value } }));
    };

    const handleNationalityIdSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        const key = object ? object.label : null;
        setNationalityIdError('');
        setNationalityId(object);
        dispatch(
            personActions.update({
                claimantInformation: { ...claimantInformation, nationalityId: { id: Number(value), key } },
            })
        );
    };

    const onSubmit = (values: ClaimantFormModel) => {
        if (!claimantInformation.language) {
            setLanguageError(t(REQUIRED_ERROR_MESSAGE));
        }

        if (!claimantInformation.nationalityId.id) {
            setNationalityIdError(t(REQUIRED_ERROR_MESSAGE));
        }

        if (claimantInformation.language && claimantInformation.nationalityId.id) {
            setLanguageError('');
            setNationalityIdError('');
            let goToLink = wizardActions.goToNext();
            if (is(claimantRole, ENTREPRENEUR)) {
                goToLink = wizardActions.goTo(WORKERS_COMP_ACCIDENT_CLAIMANT_POSITION);
            }

            dispatcherWithPromise(dispatch, personActions.update, {
                claimantInformation: {
                    ...claimantInformation,
                    ...values,
                    language: claimantInformation.language,
                    nationalityId: { ...claimantInformation.nationalityId },
                    dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                },
                bankAccountInformation: {
                    ...bankAccountInformation,
                    accountNumber: values.bankAccountNumber ? values.bankAccountNumber : null,
                },
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(goToLink));
        }
    };

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked, id } = e.target;
        setValue('missingSSN', checked);
        if (checked) {
            setValue('nationalIdentity', '');
            clearErrors('nationalIdentity');
        }
        dispatch(wizardActions.skipBackToPrev(true));
        dispatcherWithPromise(dispatch, personActions.update, {
            claimantInformation: {
                ...claimantInformation,
                [id]: checked,
            },
        });
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <SingleCheckbox
                        checked={checkMissingSSN}
                        handleChange={handleChecked}
                        name={MISSING_SSN}
                        wrapperClass={'col-12 multiple'}
                        {...{ customCAN }}
                    >
                        {t(MISSING_SSN_LABEL)}
                    </SingleCheckbox>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={NATIONAL_IDENTITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                disabled={checkMissingSSN}
                                error={!!errors.nationalIdentity}
                                errorMessage={errors.nationalIdentity?.message}
                                id={NATIONAL_IDENTITY}
                                inputFieldWrapper={'col-4'}
                                label={t(NATIONAL_IDENTITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(NATIONAL_IDENTITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={STREET}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={STREET_ADDRESS}
                                error={!!errors.street}
                                errorMessage={errors.street?.message}
                                id={STREET}
                                inputFieldWrapper={'col-4'}
                                label={t(STREET_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(STREET_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={ZIP}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={POSTAL_CODE}
                                error={!!errors.zip}
                                errorMessage={errors.zip?.message}
                                id={ZIP}
                                inputFieldWrapper={'col-4'}
                                inputMode={'numeric'}
                                label={t(ZIP_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ZIP_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={CITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={CITY_AUTOCOMPLETE}
                                error={!!errors.city}
                                errorMessage={errors.city?.message}
                                id={CITY}
                                inputFieldWrapper={'col-4'}
                                label={t(CITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!nationalityIdError}
                        errorMessage={nationalityIdError}
                        id={NATIONALITY}
                        inputFieldWrapper={'col-4'}
                        label={t(NATIONALITY_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleNationalityIdSelectBlur}
                        onChange={handleNationalityIdSelect}
                        openOnFocus={true}
                        options={nationalityIdOptions}
                        placeholder={t(NATIONALITY_PLACEHOLDER)}
                        value={nationalityId}
                        {...{ customCAN }}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={BANK_ACCOUNT_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.bankAccountNumber}
                                errorMessage={errors.bankAccountNumber?.message}
                                id={BANK_ACCOUNT_NUMBER}
                                inputFieldWrapper={'col-8'}
                                label={t(BANK_ACCOUNT_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(BANK_ACCOUNT_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!languageError}
                        errorMessage={languageError}
                        id={LANGUAGE}
                        inputFieldWrapper={'col-4'}
                        label={t(LANGUAGE_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleLanguageSelectBlur}
                        onChange={handleLanguageSelect}
                        openOnFocus={true}
                        options={languageOptions}
                        placeholder={t(LANGUAGE_PLACEHOLDER)}
                        value={language}
                        {...{ customCAN }}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
