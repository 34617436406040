import React from 'react';
import { FileList, isGreater, isYes, SummaryItemProps, YesNoModel } from '@protectorinsurance/ds-can';
import { AttachmentModel } from 'interfaces/models/Attachment.model';

/**
 * Component view and component logic
 */
export const renderReceipt = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    hasExpenses: YesNoModel,
    receipt: AttachmentModel
) => {
    if (isYes(hasExpenses) && isGreater(receipt.files.length, 0)) {
        return (
            <div className={inputWrapperClass}>
                <FileList
                    list={receipt.files}
                    textKey={textKey ? textKey : ''}
                    dataTestId={dataTestId ? dataTestId : ''}
                    show={true}
                    {...{ handleClick, path }}
                />
            </div>
        );
    }
    return <></>;
};
