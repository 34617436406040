import { getLocaleFromUrl, LanguageCodeEnums } from '@protectorinsurance/ds-can';

const locale = getLocaleFromUrl();
const i18nIsoCountries = require('i18n-iso-countries');
const isSweden = locale && locale.language === LanguageCodeEnums.SE;
const isFinland = locale && locale.language === LanguageCodeEnums.FI;
let language: string;
if (isFinland) {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
    language = 'fi';
} else if (isSweden) {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
    language = 'sv';
} else {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    language = 'en';
}
export const countryCodes = i18nIsoCountries.getAlpha2Codes();
export const countryNames = i18nIsoCountries.getNames(language);
