import { LANG_CODE_ENGLAND, LANG_CODE_SWEDEN, LanguageAttributeCodes } from '../../config/locale';

/**
 * Destructure necessary imports
 */
const { EN, FI, SE } = LanguageAttributeCodes;

/**
 * Logic
 */
export const setHTMLLang = (language: string) => {
    if (language === LANG_CODE_ENGLAND) {
        return EN;
    } else if (language === LANG_CODE_SWEDEN) {
        return SE;
    }

    return FI;
};
