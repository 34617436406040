import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    MuiAutocomplete,
    OdCauseEnums,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectOdCause } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { useGoBack } from '../../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { wizardRouterActions } from '../../../../sagas/wizardRouter';
import { commonActions } from '../../../../sagas/common';
import { getOdCauseOption } from '../../../../utils/person/odCauseUtils';
import { personActions } from '../../../../sagas/person';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    OD_CAUSE_LABEL,
    OD_CAUSE_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { OD_CAUSE } = FormFieldNames;
const {
    AFLATOXINS,
    ALDEHYDS,
    ALIFATIC,
    ALUMINIUM,
    ANTIBIOTICS,
    ANTIMONE,
    ARSENIC,
    BERYLLIUM,
    CADMIUM,
    CANCER_MEDICINE,
    CARBON_HYDROGENS,
    CARBON_MONOXIDE,
    CHROMIUM,
    COBALT,
    CYAN,
    FENOL,
    HALOGENS,
    HYDROGEN_PEROXIDE,
    LEAD,
    MANGANE,
    NICKEL,
    NITROGEN,
    NITROGEN_ACIDS,
    ORGANIC_ACIDS,
    ORGANIC_DUST,
    OTHER_CHEMICAL_FACTOR,
    PHOSPHORUS,
    PLASTIC,
    QUICKSILVER,
    SULFUR,
    TIN,
    TIURAMS,
    UN_ORGANIC_BASES,
    UN_ORGANIC_DUST,
    VANADIUM,
    ZINC,
} = OdCauseEnums;

/**
 * Page view and page logic
 */
export const WorkersCompIllnessChemicalFactorPage = () => {
    const dispatch = useDispatch();
    const [cause, setCause] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const odCause = useSelector(selectOdCause);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.illness.chemicalFactor');

    // Options
    // Numbers are set based on what Backend needs for mapping
    const aflatoxinsOption = getOdCauseOption(t, AFLATOXINS, '1026');
    const aldehydsOption = getOdCauseOption(t, ALDEHYDS, '1018');
    const alifaticOption = getOdCauseOption(t, ALIFATIC, '1015');
    const aluminiumOption = getOdCauseOption(t, ALUMINIUM, '1031');
    const antibioticsOption = getOdCauseOption(t, ANTIBIOTICS, '1021');
    const antimoneOption = getOdCauseOption(t, ANTIMONE, '1029');
    const arsenicOption = getOdCauseOption(t, ARSENIC, '997');
    const berylliumOption = getOdCauseOption(t, BERYLLIUM, '998');
    const cadmiumOption = getOdCauseOption(t, CADMIUM, '1001');
    const cancerMedicineOption = getOdCauseOption(t, CANCER_MEDICINE, '1027');
    const carbonHydrogensOption = getOdCauseOption(t, CARBON_HYDROGENS, '1016');
    const carbonMonoxideOption = getOdCauseOption(t, CARBON_MONOXIDE, '1013');
    const chromiumOption = getOdCauseOption(t, CHROMIUM, '1003');
    const cobaltOption = getOdCauseOption(t, COBALT, '1002');
    const cyanOption = getOdCauseOption(t, CYAN, '1010');
    const fenolOption = getOdCauseOption(t, FENOL, '1020');
    const halogensOption = getOdCauseOption(t, HALOGENS, '1009');
    const hydrogenPeroxideOption = getOdCauseOption(t, HYDROGEN_PEROXIDE, '1028');
    const leadOption = getOdCauseOption(t, LEAD, '1004');
    const manganeOption = getOdCauseOption(t, MANGANE, '1005');
    const nickelOption = getOdCauseOption(t, NICKEL, '1006');
    const nitrogenOption = getOdCauseOption(t, NITROGEN, '1012');
    const nitrogenAcidsOption = getOdCauseOption(t, NITROGEN_ACIDS, '1017');
    const organicAcidsOption = getOdCauseOption(t, ORGANIC_ACIDS, '1019');
    const organicDustOption = getOdCauseOption(t, ORGANIC_DUST, '1023');
    const otherOption = getOdCauseOption(t, OTHER_CHEMICAL_FACTOR, '548132');
    const phosphorusOption = getOdCauseOption(t, PHOSPHORUS, '1000');
    const plasticOption = getOdCauseOption(t, PLASTIC, '1022');
    const quicksilverOption = getOdCauseOption(t, QUICKSILVER, '999');
    const sulfurOption = getOdCauseOption(t, SULFUR, '1011');
    const tinOption = getOdCauseOption(t, TIN, '1030');
    const tiuramsOption = getOdCauseOption(t, TIURAMS, '1025');
    const unOrganicBasesOption = getOdCauseOption(t, UN_ORGANIC_BASES, '1014');
    const unOrganicDustOption = getOdCauseOption(t, UN_ORGANIC_DUST, '1024');
    const vanadiumOption = getOdCauseOption(t, VANADIUM, '1008');
    const zincOption = getOdCauseOption(t, ZINC, '1007');

    const options = [
        arsenicOption,
        berylliumOption,
        quicksilverOption,
        phosphorusOption,
        cadmiumOption,
        cobaltOption,
        chromiumOption,
        leadOption,
        manganeOption,
        nickelOption,
        zincOption,
        vanadiumOption,
        halogensOption,
        cyanOption,
        sulfurOption,
        nitrogenOption,
        carbonMonoxideOption,
        unOrganicBasesOption,
        alifaticOption,
        carbonHydrogensOption,
        nitrogenAcidsOption,
        aldehydsOption,
        organicAcidsOption,
        fenolOption,
        antibioticsOption,
        cancerMedicineOption,
        plasticOption,
        organicDustOption,
        unOrganicDustOption,
        tiuramsOption,
        aflatoxinsOption,
        hydrogenPeroxideOption,
        antimoneOption,
        tinOption,
        aluminiumOption,
        otherOption,
    ];

    useEffect(() => {
        const selected = options.find((x) => Number(x.value) === odCause?.id);
        if (selected) {
            setCause(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [odCause, setCause]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(odCause, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        const key = object ? object.label : null;
        setError('');
        setCause(object);
        dispatch(personActions.update({ odCause: { id: Number(value), propertyId: 46, key } }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(odCause, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={OD_CAUSE}
                    inputFieldWrapper={'col-12'}
                    label={t(OD_CAUSE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(OD_CAUSE_PLACEHOLDER)}
                    value={cause}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
