import React from 'react';
import { is, LobKeys, Nullable, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { TRAVEL } = LobKeys;

/**
 * Component view and component logic
 */
export const renderClaimantInformation = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>
) => {
    if (is(lob, TRAVEL)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }

    return <></>;
};
