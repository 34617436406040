import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from 'config/phraseKeys';
import { useI18n } from 'hooks/useI18n';
import { countryCodes, countryNames } from '../../../utils/i18nCountriesUtils';
import { useGoBack } from 'hooks/useGoBack';
import dispatcherWithPromise from 'utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { selectTravelInterruption } from '../../../sagas/selectors/lpoSelectors';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    COUNTRY_LABEL,
    COUNTRY_PLACEHOLDER,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { COUNTRY } = FormFieldNames;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const TravelTravelInterruptionPage = () => {
    const dispatch = useDispatch();
    const travelInterruption = useSelector(selectTravelInterruption);
    const customCAN = useSelector(selectCustomCAN);
    const [country, setCountry] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.travel.travelInterruption');

    const countries = Object.keys(countryCodes).map((key) => ({
        label: `${countryNames[key]} (${countryCodes[key]})`,
        value: countryCodes[key],
    }));

    useEffect(() => {
        const selected = countries.find((x) => x.value === travelInterruption);
        if (selected) {
            setCountry(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [travelInterruption, setCountry]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(travelInterruption, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setCountry(object);
        dispatch(lpoActions.update({ travelInterruption: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(travelInterruption, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={COUNTRY}
                    inputFieldWrapper={'col-12'}
                    label={t(COUNTRY_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    options={countries}
                    placeholder={t(COUNTRY_PLACEHOLDER)}
                    value={country}
                    {...{ customCAN }}
                />
            </Grid>
        </PageLayout>
    );
};
