import { TFunction } from 'i18next';
import { OptionType } from '@protectorinsurance/ds-can';

export enum NatureTypeIds {
    WOUNDS_SUPERFICIAL_INJURIES = '18',
    BONE_FRACTURE = '19',
    DISLOCATIONS_SPRAINS_STRAINS = '20',
    TRAUMATIC_AMPUTATIONS = '21',
    CONCUSSIONS_INTERNAL_INJURIES = '22',
    BURNS_SCALDS_FROSTBITES = '23',
    POISONING_INFECTIONS = '24',
    DROWNING_ASPHYXIATION = '25',
    SOUND_VIBRATION_PRESSURE = '26',
    EXTREME_TEMPERATURE_LIGHT_RADIATION = '27',
    SHOCK = '28',
    MULTIPLE_INJURIES = '29',
    NOT_INCLUDED_UNDER_OTHER_HEADINGS = '30',
}

const createNatureTypeIdKey = (id: string) => {
    return `lob.person.health.accident.natureTypeId.select.${id}`;
};

export const getNatureTypeIdOptions = (t: TFunction): OptionType[] => {
    return [
        {
            value: NatureTypeIds.WOUNDS_SUPERFICIAL_INJURIES,
            label: t(createNatureTypeIdKey(NatureTypeIds.WOUNDS_SUPERFICIAL_INJURIES)),
        },
        {
            value: NatureTypeIds.BONE_FRACTURE,
            label: t(createNatureTypeIdKey(NatureTypeIds.BONE_FRACTURE)),
        },
        {
            value: NatureTypeIds.DISLOCATIONS_SPRAINS_STRAINS,
            label: t(createNatureTypeIdKey(NatureTypeIds.DISLOCATIONS_SPRAINS_STRAINS)),
        },
        {
            value: NatureTypeIds.TRAUMATIC_AMPUTATIONS,
            label: t(createNatureTypeIdKey(NatureTypeIds.TRAUMATIC_AMPUTATIONS)),
        },
        {
            value: NatureTypeIds.CONCUSSIONS_INTERNAL_INJURIES,
            label: t(createNatureTypeIdKey(NatureTypeIds.CONCUSSIONS_INTERNAL_INJURIES)),
        },
        {
            value: NatureTypeIds.BURNS_SCALDS_FROSTBITES,
            label: t(createNatureTypeIdKey(NatureTypeIds.BURNS_SCALDS_FROSTBITES)),
        },
        {
            value: NatureTypeIds.POISONING_INFECTIONS,
            label: t(createNatureTypeIdKey(NatureTypeIds.POISONING_INFECTIONS)),
        },
        {
            value: NatureTypeIds.DROWNING_ASPHYXIATION,
            label: t(createNatureTypeIdKey(NatureTypeIds.DROWNING_ASPHYXIATION)),
        },
        {
            value: NatureTypeIds.SOUND_VIBRATION_PRESSURE,
            label: t(createNatureTypeIdKey(NatureTypeIds.SOUND_VIBRATION_PRESSURE)),
        },
        {
            value: NatureTypeIds.EXTREME_TEMPERATURE_LIGHT_RADIATION,
            label: t(createNatureTypeIdKey(NatureTypeIds.EXTREME_TEMPERATURE_LIGHT_RADIATION)),
        },
        { value: NatureTypeIds.SHOCK, label: t(createNatureTypeIdKey(NatureTypeIds.SHOCK)) },
        {
            value: NatureTypeIds.MULTIPLE_INJURIES,
            label: t(createNatureTypeIdKey(NatureTypeIds.MULTIPLE_INJURIES)),
        },
        {
            value: NatureTypeIds.NOT_INCLUDED_UNDER_OTHER_HEADINGS,
            label: t(createNatureTypeIdKey(NatureTypeIds.NOT_INCLUDED_UNDER_OTHER_HEADINGS)),
        },
    ];
};
