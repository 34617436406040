import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    MotorcycleIcon,
    OtherInjuriesIcon,
    TrainIcon,
    TypeOfVehicleEnums,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectTypeOfVehicle } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { BUS_TRAM_TRAIN, CAR, MOTORCYCLE, OTHER } = TypeOfVehicleEnums;

/**
 * Page view and page logic
 */
export const workersCompAccidentTypeOfVehiclePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: CAR,
            name: t(`lob.person.workersComp.accident.typeOfVehicle.select.${CAR}`),
            icon: <CarIcon />,
        },
        {
            id: MOTORCYCLE,
            name: t(`lob.person.workersComp.accident.typeOfVehicle.select.${MOTORCYCLE}`),
            icon: <MotorcycleIcon />,
        },
        {
            id: BUS_TRAM_TRAIN,
            name: t(`lob.person.workersComp.accident.typeOfVehicle.select.${BUS_TRAM_TRAIN}`),
            icon: <TrainIcon />,
        },
        {
            id: OTHER,
            name: t(`lob.person.workersComp.accident.typeOfVehicle.select.${OTHER}`),
            icon: <OtherInjuriesIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_VEHICLE,
        stateKey: 'typeOfVehicle',
        i18n: {
            ns: 'lob.person.workersComp.accident.typeOfVehicle',
        },
        selectorValue: selectTypeOfVehicle(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { typeOfVehicle: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
