import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';

/*eslint-disable */
const requiredIf = '${path} is a required field.';
const digits = '${path} should only be numbers.';
const claimNumber = '${path} should be of format PRO-XXXXXX.';
const ssn = '${path} is not valid';
const businessNumber = '${path} should be of format XXXXXXX-X.';
const minNumber = '${path} should be at least ${length} characters.';
const onlyAscii = 'Only normal letters/characters are allowed in ${path}.';
const registrationNumber = '${path} is not valid.';
const bankAccountNumber = '${path} is not valid.';
const email = '${path} is not valid.';
/*eslint-enable */

export const yupCustomLocale: ObjectWithDynamicKey = {
    string: {
        requiredIf,
        digits,
        claimNumber,
        ssn,
        businessNumber,
        onlyAscii,
        registrationNumber,
        bankAccountNumber,
        email,
    },
    number: {
        minNumber,
    },
};
