import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { hasExistingClaimPage } from './start/hasExistingClaim';
import { healthAccidentHasAppliedKelaPage } from './health/accident/hasAppliedKela';
import { healthAccidentHasAttachmentsPage } from './health/accident/hasAttachment';
import { healthAccidentHasExpensesPage } from './health/accident/hasExpenses';
import { healthAccidentHasOtherInsurancePage } from './health/accident/hasOtherInsurance';
import { healthAccidentTypeOfDoctorPage } from './health/accident/typeOfDoctor';
import { healthAccidentWhoPaidExpensesPage } from './health/accident/whoPaidExpenses';
import { healthAccidentClaimReporterRolePage } from './health/accident/claimReporterRole';
import { healthCategoryPage } from './health/category';
import { healthIllnessHasAppliedKelaPage } from './health/illness/hasAppliedKela';
import { healthIllnessHasAttachmentsPage } from './health/illness/hasAttachment';
import { healthIllnessHasExpensesPage } from './health/illness/hasExpenses';
import { healthIllnessHasDiagnosisCodePage } from './health/illness/hasDiagnosisCode';
import { healthIllnessHasOtherInsurancePage } from './health/illness/hasOtherInsurance';
import { healthIllnessTypeOfDoctorPage } from './health/illness/typeOfDoctor';
import { healthIllnessWhoPaidExpensesPage } from './health/illness/whoPaidExpenses';
import { healthIllnessClaimReporterRolePage } from './health/illness/claimReporterRole';
import { groupAccidentClaimantAgeCategoryPage } from './groupAccident/claimantAgeCategory';
import { groupAccidentClaimLocationPage } from './groupAccident/claimLocation';
import { groupAccidentHasAppliedMedicalCarePage } from './groupAccident/hasAppliedMedicalCare';
import { groupAccidentHasExpensesPage } from './groupAccident/hasExpenses';
import { groupAccidentHasAppliedKelaPage } from './groupAccident/hasAppliedKela';
import { groupAccidentWhoPaidExpensesPage } from './groupAccident/whoPaidExpenses';
import { groupAccidentHasOtherInsurancePage } from './groupAccident/hasOtherInsurance';
import { workersCompCategoryPage } from './workersComp/category';
import { workersCompIllnessClaimantRolePage } from './workersComp/illness/claimantRole';
import { workersCompIllnessTypeOfCausePage } from './workersComp/illness/typeOfCause';
import { workersCompIllnessPhysicalFactorPage } from './workersComp/illness/physicalFactor';
import { workersCompIllnessBiologicalFactorPage } from './workersComp/illness/biologicalFactor';
import { workersCompIllnessCausedIncapacityPage } from './workersComp/illness/causedIncapacity';
import { workersCompIllnessHasAppliedMedicalCarePage } from './workersComp/illness/hasAppliedMedicalCare';
import { workersCompIllnessHasExpensesPage } from './workersComp/illness/hasExpenses';
import { workersCompIllnessWhoPaidExpensesPage } from './workersComp/illness/whoPaidExpenses';
import { workersCompIllnessHasCompanySharesPage } from './workersComp/illness/hasCompanyShares';
import { workersCompAccidentClaimantRolePage } from './workersComp/accident/claimantRole';
import { workersCompAccidentStudyLocationPage } from './workersComp/accident/studyLocation';
import { workersCompAccidentTypeOfStudiesPage } from './workersComp/accident/typeOfStudies';
import { workersCompAccidentOccupationalConditionPage } from './workersComp/accident/occupationalCondition';
import { workersCompAccidentOutsideOccupationalConditionPage } from './workersComp/accident/outsideOccupationalCondition';
import { workersCompAccidentOtherOccupationalConditionPage } from './workersComp/accident/otherOccupationalCondition';
import { workersCompAccidentIsVehicleInvolvedPage } from './workersComp/accident/isVehicleInvolved';
import { workersCompAccidentTypeOfVehiclePage } from './workersComp/accident/typeOfVehicle';
import { workersCompAccidentIsPoliceContactedPage } from './workersComp/accident/isPoliceContacted';
import { workersCompAccidentUnderInfluencePage } from './workersComp/accident/underInfluence';
import { workersCompAccidentCausedIncapacityPage } from './workersComp/accident/causedIncapacity';
import { workersCompAccidentIncapacityMoreThanSevenDaysPage } from './workersComp/accident/incapacityMoreThanSevenDays';
import { workersCompAccidentIncapacityPeriodPage } from './workersComp/accident/incapacityPeriod';
import { workersCompAccidentHasAppliedMedicalCarePage } from './workersComp/accident/hasAppliedMedicalCare';
import { workersCompAccidentHasExpensesPage } from './workersComp/accident/hasExpenses';
import { workersCompAccidentWhoPaidExpensesPage } from './workersComp/accident/whoPaidExpenses';
import { workersCompAccidentHasOtherInsurancePage } from './workersComp/accident/hasOtherInsurance';
import { workersCompAccidentHasCompanySharesPage } from './workersComp/accident/hasCompanyShares';

/**
 * Config logic
 */
export const personCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    hasExistingClaimPage(props),

    // Health
    healthCategoryPage(props),

    // Health / Accident
    healthAccidentClaimReporterRolePage(props),
    healthAccidentHasAppliedKelaPage(props),
    healthAccidentHasOtherInsurancePage(props),
    healthAccidentHasExpensesPage(props),
    healthAccidentWhoPaidExpensesPage(props),
    healthAccidentTypeOfDoctorPage(props),
    healthAccidentHasAttachmentsPage(props),

    // Health / Illness
    healthIllnessClaimReporterRolePage(props),
    healthIllnessHasDiagnosisCodePage(props),
    healthIllnessHasAppliedKelaPage(props),
    healthIllnessHasOtherInsurancePage(props),
    healthIllnessHasExpensesPage(props),
    healthIllnessWhoPaidExpensesPage(props),
    healthIllnessTypeOfDoctorPage(props),
    healthIllnessHasAttachmentsPage(props),

    // Group Accident
    groupAccidentClaimantAgeCategoryPage(props),
    groupAccidentClaimLocationPage(props),
    groupAccidentHasAppliedMedicalCarePage(props),
    groupAccidentHasExpensesPage(props),
    groupAccidentHasAppliedKelaPage(props),
    groupAccidentWhoPaidExpensesPage(props),
    groupAccidentHasOtherInsurancePage(props),

    // Workers Comp
    workersCompCategoryPage(props),

    // Workers Comp / Illness
    workersCompIllnessClaimantRolePage(props),
    workersCompIllnessTypeOfCausePage(props),
    workersCompIllnessPhysicalFactorPage(props),
    workersCompIllnessBiologicalFactorPage(props),
    workersCompIllnessCausedIncapacityPage(props),
    workersCompIllnessHasAppliedMedicalCarePage(props),
    workersCompIllnessHasExpensesPage(props),
    workersCompIllnessWhoPaidExpensesPage(props),
    workersCompIllnessHasCompanySharesPage(props),

    // Workers Comp / Accident
    workersCompAccidentClaimantRolePage(props),
    workersCompAccidentStudyLocationPage(props),
    workersCompAccidentTypeOfStudiesPage(props),
    workersCompAccidentOccupationalConditionPage(props),
    workersCompAccidentOutsideOccupationalConditionPage(props),
    workersCompAccidentOtherOccupationalConditionPage(props),
    workersCompAccidentIsVehicleInvolvedPage(props),
    workersCompAccidentTypeOfVehiclePage(props),
    workersCompAccidentIsPoliceContactedPage(props),
    workersCompAccidentUnderInfluencePage(props),
    workersCompAccidentCausedIncapacityPage(props),
    workersCompAccidentIncapacityMoreThanSevenDaysPage(props),
    workersCompAccidentIncapacityPeriodPage(props),
    workersCompAccidentHasAppliedMedicalCarePage(props),
    workersCompAccidentHasExpensesPage(props),
    workersCompAccidentWhoPaidExpensesPage(props),
    workersCompAccidentHasOtherInsurancePage(props),
    workersCompAccidentHasCompanySharesPage(props),
];
