import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectInjuredProfession, selectOccupation } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    FormChangeable,
    getLocaleFromUrl,
    Grid,
    HiddenInputSubmit,
    InjuredProfessionTypeModel,
    is,
    MuiAutocomplete,
    MuiTextInput,
    Nullable,
    OccupationTypeModel,
    OptionType,
    PageLayout,
    updateDateByDate,
} from '@protectorinsurance/ds-can';
import { injuredOccupationSchema } from '../../../../validations/schemas/injuredOccupationSchema';
import {
    EmploymentRelationshipEnums,
    getEmploymentRelationshipOption,
} from '../../../../utils/person/employmentRelationshipUtils';
import { getOccupationTypeOption, OccupationTypeEnums } from '../../../../utils/person/occupationTypeUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { getMomentLocale } from '../../../../utils/locale/getMomentLocale';
import { DatePickerInput } from '../../../../components/date/DatePickerInput';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { DATE_OF_EMPLOYMENT, DATE_OF_TERMINATION, EMPLOYMENT_RELATIONSHIP, INJURED_PROFESSION, OCCUPATION_TYPE } =
    FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DATE_OF_EMPLOYMENT_LABEL,
    DATE_OF_TERMINATION_LABEL,
    EMPLOYMENT_RELATIONSHIP_LABEL,
    EMPLOYMENT_RELATIONSHIP_PLACEHOLDER,
    HELP_TEXT,
    INJURED_PROFESSION_LABEL,
    INJURED_PROFESSION_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    OCCUPATION_TYPE_LABEL,
    OCCUPATION_TYPE_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { PERMANENT, TEMPORARY } = EmploymentRelationshipEnums;
const { MAIN_OCCUPATION, SECONDARY_OCCUPATION } = OccupationTypeEnums;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Interfaces
 */
interface InjuredOccupationModel {
    dateOfEmployment: Nullable<Date>;
    dateOfTermination: Nullable<Date>;
    employmentRelationship: OccupationTypeModel;
    injuredProfession: InjuredProfessionTypeModel;
    occupationType: OccupationTypeModel;
}

/**
 * Page view and page logic
 */
export const WorkersCompAccidentInjuredProfessionPage = () => {
    const dispatch = useDispatch();
    const injuredProfession = useSelector(selectInjuredProfession);
    const occupationModel = useSelector(selectOccupation);
    const customCAN = useSelector(selectCustomCAN);
    const { dateOfEmployment, dateOfTermination, employmentRelationship, occupationType } = occupationModel;
    const [employment, setEmployment] = useState<OptionType | null>(null);
    const [employmentError, setEmploymentError] = useState<string>('');
    const [occupation, setOccupation] = useState<OptionType | null>(null);
    const [occupationError, setOccupationError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.accident.injuredProfession');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
        watch,
    } = useForm<InjuredOccupationModel>({
        resolver: yupResolver(injuredOccupationSchema(t, is(employmentRelationship, TEMPORARY))),
        defaultValues: {
            injuredProfession,
            dateOfEmployment: dateOfEmployment && dateOfEmployment.toDate(),
            dateOfTermination: dateOfTermination && dateOfTermination.toDate(),
            employmentRelationship,
            occupationType,
        },
    });
    const employmentDate = watch(DATE_OF_EMPLOYMENT);
    const terminationDate = watch(DATE_OF_TERMINATION);

    // Options
    const mainOccupationOption = getOccupationTypeOption(MAIN_OCCUPATION, t);
    const permanentOption = getEmploymentRelationshipOption(PERMANENT, t);
    const secondaryOccupationOption = getOccupationTypeOption(SECONDARY_OCCUPATION, t);
    const temporaryOption = getEmploymentRelationshipOption(TEMPORARY, t);

    const employmentOptions = [permanentOption, temporaryOption];
    const occupationTypeOptions = [mainOccupationOption, secondaryOccupationOption];

    useEffect(() => {
        const selected = employmentOptions.find((x) => x.value === employmentRelationship);
        if (selected) {
            setEmployment(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employmentRelationship, setEmployment]);

    useEffect(() => {
        const selected = occupationTypeOptions.find((x) => x.value === occupationType);
        if (selected) {
            setOccupation(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [occupationType, setOccupation]);
    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleDateOfEmploymentChange = async (date: Nullable<Date>) => {
        setValue('dateOfEmployment', date, { shouldValidate: true });
    };

    const handleDateOfTerminationChange = async (date: Nullable<Date>) => {
        setValue('dateOfTermination', date, { shouldValidate: true });
    };

    const handleEmploymentRelationshipBlur = async (e: FormChangeable) => {
        e.preventDefault();
        if (!employmentRelationship) {
            setEmploymentError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setEmploymentError('');
        }
    };

    const handleEmploymentRelationshipSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setEmploymentError('');
        setEmployment(object);
        dispatch(personActions.update({ occupation: { ...occupationModel, employmentRelationship: value } }));
    };

    const handleOccupationTypeBlur = async (e: FormChangeable) => {
        e.preventDefault();
        if (!occupationType) {
            setOccupationError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setOccupationError('');
        }
    };

    const handleOccupationTypeSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setOccupationError('');
        setOccupation(object);
        dispatch(personActions.update({ occupation: { ...occupationModel, occupationType: value } }));
    };

    const onSubmit = (values: InjuredOccupationModel) => {
        if (!employmentRelationship && !occupationType) {
            setEmploymentError(t(REQUIRED_ERROR_MESSAGE));
            setOccupationError(t(REQUIRED_ERROR_MESSAGE));
        } else if (!employmentRelationship) {
            setEmploymentError(t(REQUIRED_ERROR_MESSAGE));
            setOccupationError('');
        } else if (!occupationType) {
            setEmploymentError('');
            setOccupationError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setEmploymentError('');
            setOccupationError('');
            dispatcherWithPromise(dispatch, personActions.update, {
                injuredProfession: values.injuredProfession,
                occupation: {
                    dateOfEmployment: values.dateOfEmployment
                        ? updateDateByDate(dateOfEmployment, moment(values.dateOfEmployment))
                        : null,
                    dateOfTermination: values.dateOfTermination
                        ? updateDateByDate(dateOfTermination, moment(values.dateOfTermination))
                        : null,
                    employmentRelationship,
                    occupationType,
                },
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={INJURED_PROFESSION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.injuredProfession}
                                errorMessage={errors.injuredProfession?.message}
                                id={INJURED_PROFESSION}
                                inputFieldWrapper={'col-4'}
                                label={t(INJURED_PROFESSION_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(INJURED_PROFESSION_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!employmentError}
                        errorMessage={employmentError}
                        id={EMPLOYMENT_RELATIONSHIP}
                        inputFieldWrapper={'col-4'}
                        label={t(EMPLOYMENT_RELATIONSHIP_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleEmploymentRelationshipBlur}
                        onChange={handleEmploymentRelationshipSelect}
                        openOnFocus={true}
                        options={employmentOptions}
                        placeholder={t(EMPLOYMENT_RELATIONSHIP_PLACEHOLDER)}
                        value={employment}
                        {...{ customCAN }}
                    />
                    <MuiAutocomplete
                        error={!!occupationError}
                        errorMessage={occupationError}
                        id={OCCUPATION_TYPE}
                        inputFieldWrapper={'col-4'}
                        label={t(OCCUPATION_TYPE_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleOccupationTypeBlur}
                        onChange={handleOccupationTypeSelect}
                        openOnFocus={true}
                        options={occupationTypeOptions}
                        placeholder={t(OCCUPATION_TYPE_PLACEHOLDER)}
                        value={occupation}
                        {...{ customCAN }}
                    />
                    <DatePickerInput
                        disableFuture={true}
                        error={!!errors.dateOfEmployment}
                        errorMessage={errors.dateOfEmployment?.message}
                        id={DATE_OF_EMPLOYMENT}
                        inputFieldWrapper={'col-6'}
                        label={t(DATE_OF_EMPLOYMENT_LABEL)}
                        locale={getMomentLocale(getLocaleFromUrl())}
                        onChange={handleDateOfEmploymentChange}
                        value={employmentDate}
                        {...{ customCAN }}
                    />
                    <DatePickerInput
                        error={!!errors.dateOfTermination}
                        errorMessage={errors.dateOfTermination?.message}
                        id={DATE_OF_TERMINATION}
                        inputFieldWrapper={'col-6'}
                        label={t(DATE_OF_TERMINATION_LABEL)}
                        locale={getMomentLocale(getLocaleFromUrl())}
                        onChange={handleDateOfTerminationChange}
                        value={terminationDate}
                        {...{ customCAN }}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
