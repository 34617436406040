import React from 'react';
import {
    AtSchoolIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimantAgeCategoryKeys,
    ClaimLocationKeys,
    Clickable,
    is,
    OutOfSchoolHoursIcon,
    OutsideWorkingAreaDuringBreakIcon,
    WayToActivityIcon,
    WayToSchoolIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { selectClaimantAgeCategory, selectClaimLocation } from '../../../../sagas/selectors/personSelectors';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { wizardRouterActions } from '../../../../sagas/wizardRouter';

/**
 * Destructure necessary imports
 */
const { COMMUTING_LEISURE, COMMUTING_SCHOOL, LEISURE, LEISURE_CHILD, SCHOOL } = ClaimLocationKeys;
const { CHILD } = ClaimantAgeCategoryKeys;

/**
 * Page view and page logic
 */
export const groupAccidentClaimLocationPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const claimantAgeCategory = selectClaimantAgeCategory(state);
    let options = [
        {
            id: COMMUTING_LEISURE,
            name: t(`lob.person.groupAccident.claimLocation.select.${COMMUTING_LEISURE}`),
            icon: <WayToActivityIcon />,
        },
        {
            id: LEISURE,
            name: t(`lob.person.groupAccident.claimLocation.select.${LEISURE}`),
            icon: <OutsideWorkingAreaDuringBreakIcon />,
        },
    ];
    if (is(claimantAgeCategory, CHILD)) {
        options = [
            {
                id: COMMUTING_SCHOOL,
                name: t(`lob.person.groupAccident.claimLocation.select.${COMMUTING_SCHOOL}`),
                icon: <WayToSchoolIcon />,
            },
            {
                id: SCHOOL,
                name: t(`lob.person.groupAccident.claimLocation.select.${SCHOOL}`),
                icon: <AtSchoolIcon />,
            },
            {
                id: LEISURE_CHILD,
                name: t(`lob.person.groupAccident.claimLocation.select.${LEISURE_CHILD}`),
                icon: <OutOfSchoolHoursIcon />,
            },
        ];
    }

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_CLAIM_LOCATION,
        stateKey: 'claimLocation',
        i18n: {
            ns: 'lob.person.groupAccident.claimLocation',
        },
        selectorValue: selectClaimLocation(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { claimLocation: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardRouterActions.goToNext()));
            }
        },
    };
};
