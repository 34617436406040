import React from 'react';
import {
    BodyIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimTypeEnums,
    Clickable,
    DelayIcon,
    LpoClaimCauseEnums,
    PropertyDamageIcon,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectClaimType } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';
import { selectRequestId } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { OTHER } = LpoClaimCauseEnums;
const { INTERRUPTION, PERSON, PROPERTY } = ClaimTypeEnums;

/**
 * Page view and page logic
 */
export const travelClaimTypePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const requestId = selectRequestId(state);
    const options = [
        { id: PERSON, name: t(`lpo.claimType.select.${PERSON}`), icon: <BodyIcon /> },
        { id: PROPERTY, name: t(`lpo.claimType.select.${PROPERTY}`), icon: <PropertyDamageIcon /> },
        { id: INTERRUPTION, name: t(`lpo.claimType.select.${INTERRUPTION}`), icon: <DelayIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_TRAVEL_CLAIM_TYPE,
        stateKey: 'claimType',
        i18n: {
            ns: 'lpo.travel.claimType',
        },
        options,
        selectorValue: selectClaimType(state),
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (!requestId) {
                    // @ts-ignore
                    const uuid = crypto.randomUUID();
                    dispatch(commonActions.update({ requestId: uuid }));
                }

                dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: OTHER, claimType: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
