import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { NATIONAL_IDENTITY_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const nationalIdentitySchema = (t: TFunction, optional: boolean) => {
    if (optional) {
        return {
            nationalIdentity: Yup.string().label(t(NATIONAL_IDENTITY_LABEL)).ssn(optional).nullable(),
        };
    } else {
        return {
            nationalIdentity: Yup.string().label(t(NATIONAL_IDENTITY_LABEL)).required().ssn(),
        };
    }
};
