import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    AlreadyTowedIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    MotorClaimCauseKeys,
    NeedVehicleTowingKeys,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { selectClaimCause, selectIsDriving, selectNeedVehicleTowing } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { ALREADY_TOWED, NO, YES } = NeedVehicleTowingKeys;
const { COMPARTMENT_DAMAGE, ENGINE, LOST_KEY, NATURAL_DISASTER, OTHER, PARKING, THEFT_AND_DAMAGE, WRONG_FUEL } =
    MotorClaimCauseKeys;
const { NO: NO_DRIVING } = YesNoKeys;
/**
 * Page view and page logic
 */
export const needVehicleTowingPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.end.needVehicleTowing.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.end.needVehicleTowing.select.${NO}`), icon: <NoIcon /> },
        {
            id: ALREADY_TOWED,
            name: t(`motor.end.needVehicleTowing.select.${ALREADY_TOWED}`),
            icon: <AlreadyTowedIcon />,
        },
    ];
    const claimCause = selectClaimCause(state);
    const isDriving = selectIsDriving(state);

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING,
        stateKey: 'needVehicleTowing',
        i18n: {
            ns: 'motor.end.needVehicleTowing',
        },
        selectorValue: selectNeedVehicleTowing(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext(option.id);
                if (option.id === YES) {
                    dispatch(wizardActions.skipBackToPrev(true));
                } else if (claimCause === THEFT_AND_DAMAGE && option.id === NO) {
                    nextAction = wizardActions.goTo(MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED);
                } else if (claimCause === ENGINE && option.id === NO) {
                    nextAction = wizardActions.goTo(MotorRoutePaths.END_UPLOAD_ATTACHMENT);
                } else if (claimCause === PARKING && option.id === NO) {
                    nextAction = wizardActions.goTo(MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED);
                } else if (
                    claimCause === NATURAL_DISASTER ||
                    claimCause === COMPARTMENT_DAMAGE ||
                    claimCause === WRONG_FUEL ||
                    claimCause === LOST_KEY ||
                    (claimCause === OTHER && isDriving === NO_DRIVING)
                ) {
                    nextAction = wizardActions.goTo(MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES);
                }

                dispatcherWithPromise(dispatch, motorActions.update, { needVehicleTowing: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
