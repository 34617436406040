import { PhraseKeys } from '../config/phraseKeys';
import { DriverInformationModel, YesNoKeys, YesNoModel } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { DONT_KNOW, SELECT_NO, SELECT_YES } = PhraseKeys;

/**
 * Utility logic
 */
export const createTextYesNoDontKnow = (value: YesNoModel): string =>
    value === YesNoKeys.YES ? SELECT_YES : value === YesNoKeys.DONT_KNOW ? DONT_KNOW : SELECT_NO;

export const trueFalseToYesNo = (value: boolean): string => (value ? SELECT_YES : SELECT_NO);

export const driverInformationButtonText = (driverInformation: DriverInformationModel) => {
    const { firstName, lastName, nationalIdentity, phone, email } = driverInformation;
    return `${firstName} ${lastName} ${nationalIdentity} ${email ? email : ''} ${phone ? phone : ''}`;
};
