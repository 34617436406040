import { takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { selectLocaleCountryCode } from '../selectors/commonSelectors';
import {
    actionWithPromise,
    emptyFn,
    MunicipalityServiceModel,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { NODE_API_BASE_URL } from '../../config/api';
import { put, select } from 'typed-redux-saga';

/**
 * Action Types
 */
export enum MunicipalityServiceActionTypes {
    REQUEST = '@app/api/service/municipality/REQUEST',
    SUCCESS = '@app/api/service/municipality/SUCCESS',
    FAILURE = '@app/api/service/municipality/FAILURE',
}

/**
 * Action Definitions
 */
export type MunicipalityServiceState = MunicipalityServiceModel[];

export interface MunicipalityServiceAction {
    type: MunicipalityServiceActionTypes;
    data?: MunicipalityServiceState;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Init state
 */
export const municipalityServiceInitState: MunicipalityServiceState = [];

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = municipalityServiceInitState, { type, data }: MunicipalityServiceAction) {
    switch (type) {
        case MunicipalityServiceActionTypes.SUCCESS:
            return [...(data || [])];
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const municipalityServiceActions = {
    failure: actionWithPromise(MunicipalityServiceActionTypes.FAILURE),
    success: actionWithPromise(MunicipalityServiceActionTypes.SUCCESS),
    request: actionWithPromise(MunicipalityServiceActionTypes.REQUEST),
};

/**
 * Saga functions
 */
export const municipalityServiceSagas = {
    *request({ resolve = emptyFn, reject = emptyFn }: MunicipalityServiceAction): any {
        try {
            const countryCode = yield* select(selectLocaleCountryCode);
            const res = yield axios.get(`municipalities/${countryCode}`, {
                baseURL: NODE_API_BASE_URL,
            });
            yield* put(municipalityServiceActions.success(res.data.data));
            resolve();
        } catch (e) {
            yield* put(municipalityServiceActions.failure((e as any)?.response?.data || (e as any)));
            reject();
        }
    },
};

/**
 * Saga watchers
 */
export const municipalityServiceWatchers = function* () {
    yield takeLatest(MunicipalityServiceActionTypes.REQUEST, municipalityServiceSagas.request);
};
