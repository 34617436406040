import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isDev } from '../../utils/env';
import { bankAccountFinlandValidator, isEmpty } from '@protectorinsurance/ds-can';

export function bankAccountNumber(
    this: StringSchema,
    required: boolean,
    message: string = yupCustomLocale.string.bankAccountNumber
) {
    // @ts-ignore
    return this.test('bankAccountNumber', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        const skipValidation = isDev() || (!required && isEmpty(value));
        return skipValidation ? true : bankAccountFinlandValidator(value).isValid();
    });
}
