import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    FlowKeys,
    RecentlyInsuredIcon,
    TypeOfAutoClaimKeys,
    WindshieldIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { selectTypeOfAutoClaim } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../sagas/common';
import { flowActions } from '../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { STANDARD, WINDSHIELD } = TypeOfAutoClaimKeys;
const { START } = FlowKeys;

/**
 * Page view and page logic
 */
export const welcomePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: WINDSHIELD, name: t(`motor.start.welcome.select.${WINDSHIELD}`), icon: <WindshieldIcon /> },
        { id: STANDARD, name: t(`motor.parked.isLocked.select.${STANDARD}`), icon: <RecentlyInsuredIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_START_WELCOME,
        stateKey: 'typeOfAutoClaim',
        i18n: {
            ns: 'motor.start.welcome',
        },
        selectorValue: selectTypeOfAutoClaim(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, motorActions.update, { typeOfAutoClaim: option.id })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, START))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
