import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { fromDateSchema } from '../fieldSchemas/fromDateSchema';
import { toDateSchema } from '../fieldSchemas/toDateSchema';
import { rateSchema } from '../fieldSchemas/rateSchema';

/**
 * Validation logic
 */
export const incapacityPeriodSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...fromDateSchema(t),
        ...toDateSchema(t),
        ...rateSchema(t),
    });
};
