import { receiptActions } from './receipt';
import { pollReceiptActions } from './pollReceipt';
import { put, takeLatest } from 'redux-saga/effects';
import { actionWithPromise, api, emptyFn, FileModel, Rejectable, Resolvable } from '@protectorinsurance/ds-can';
import { select } from 'typed-redux-saga';
import { selectRequestId } from '../../selectors/commonSelectors';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum DeleteReceiptActionTypes {
    REQUEST = '@app/upload/receipt/delete/REQUEST',
    SUCCESS = '@app/upload/receipt/delete/SUCCESS',
    FAILURE = '@app/upload/receipt/delete/FAILURE',
}

/**
 * Action Definitions
 */
export interface IDeleteReceiptAction {
    type: DeleteReceiptActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const deleteReceiptActions = {
    request: actionWithPromise(DeleteReceiptActionTypes.REQUEST),
    success: actionWithPromise(DeleteReceiptActionTypes.SUCCESS),
    failure: actionWithPromise(DeleteReceiptActionTypes.FAILURE),
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: IDeleteReceiptAction) {
        try {
            const requestId = yield* select(selectRequestId);
            if (data && data.externalId) {
                yield api.delete(`upload/${data.externalId}`, {
                    baseURL: NODE_API_BASE_URL,
                    headers: { 'X-Request-Id': `${requestId}` },
                });
            }

            yield put(deleteReceiptActions.success());
            yield put(receiptActions.remove(data));
            resolve();
        } catch (e) {
            yield put(pollReceiptActions.failure((e as any).response.data));
            reject();
        }
    },
};

/**
 * Saga watchers
 */
export const deleteReceiptWatchers = function* () {
    yield takeLatest(DeleteReceiptActionTypes.REQUEST, sagas.request);
};
