import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    AtWorkDuringTravelIcon,
    AtWorkIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    InTheWorkingAreaIcon,
    is,
    OccupationalConditionEnums,
    OtherInjuriesIcon,
    OutsideWorkingAreaDuringBreakIcon,
    WayToWorkIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectOccupationalCondition } from '../../../../../sagas/selectors/personSelectors';
import {
    createOccupationalConditionKey,
    getOccupationalConditionOption,
} from '../../../../../utils/person/occupationalConditionUtils';

/**
 * Destructure necessary imports
 */
const {
    AT_WORK,
    AT_WORK_BREAK,
    AT_WORK_TRAVEL,
    ON_WAY_TO_WORK,
    OTHER,
    OUTSIDE_WORK_BREAK,
} = OccupationalConditionEnums;
const {
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OUTSIDE_OCCUPATIONAL_CONDITION,
    DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OTHER_OCCUPATIONAL_CONDITION,
} = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const workersCompAccidentOccupationalConditionPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: AT_WORK,
            name: t(createOccupationalConditionKey(AT_WORK)),
            icon: <AtWorkIcon />,
        },
        {
            id: AT_WORK_TRAVEL,
            name: t(createOccupationalConditionKey(AT_WORK_TRAVEL)),
            icon: <AtWorkDuringTravelIcon />,
        },
        {
            id: AT_WORK_BREAK,
            name: t(createOccupationalConditionKey(AT_WORK_BREAK)),
            icon: <InTheWorkingAreaIcon />,
        },
        {
            id: ON_WAY_TO_WORK,
            name: t(createOccupationalConditionKey(ON_WAY_TO_WORK)),
            icon: <WayToWorkIcon />,
        },
        {
            id: OUTSIDE_WORK_BREAK,
            name: t(createOccupationalConditionKey(OUTSIDE_WORK_BREAK)),
            icon: <OutsideWorkingAreaDuringBreakIcon />,
        },
        {
            id: OTHER,
            name: t(createOccupationalConditionKey(OTHER)),
            icon: <OtherInjuriesIcon />,
        },
    ];

    // Numbers are set based on what Backend needs for mapping
    const atWorkOption = getOccupationalConditionOption(t, AT_WORK, '818');
    const atWorkBreakOption = getOccupationalConditionOption(t, AT_WORK_BREAK, '820');
    const atWorkTravelOption = getOccupationalConditionOption(t, AT_WORK_TRAVEL, '819');
    const onWayToWorkOption = getOccupationalConditionOption(t, ON_WAY_TO_WORK, '821');
    const otherOption = getOccupationalConditionOption(t, OTHER, '');
    const outsideWorkBreakOption = getOccupationalConditionOption(t, OUTSIDE_WORK_BREAK, '822');

    const values = [
        atWorkOption,
        atWorkTravelOption,
        atWorkBreakOption,
        onWayToWorkOption,
        outsideWorkBreakOption,
        otherOption,
    ];

    const getValues = (option: string) => {
        const selected = values.find((x) => x.label === option);
        if (selected) {
            return { id: Number(selected.value), propertyId: 32, key: selected.label };
        }
        return null;
    };

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION,
        stateKey: 'occupationalCondition',
        i18n: {
            ns: 'lob.person.workersComp.accident.occupationalCondition',
        },
        selectorValue: selectOccupationalCondition(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext();
                if (is(option.id, AT_WORK_BREAK) || is(option.id, OUTSIDE_WORK_BREAK)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OUTSIDE_OCCUPATIONAL_CONDITION);
                }
                if (is(option.id, OTHER)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OTHER_OCCUPATIONAL_CONDITION);
                }
                dispatcherWithPromise(dispatch, personActions.update, { occupationalCondition: getValues(option.name) })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
