import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    HigherEducationIcon,
    HighSchoolIcon,
    SeventhGradeIcon,
    StudyLocationEnums,
    VocationalLabourMarketTrainingIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectStudyLocation } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { HIGH_SCHOOL, HIGHER_EDUCATION, SEVENTH_GRADE, VOCATIONAL_LABOUR_MARKET_TRAINING } = StudyLocationEnums;

/**
 * Page view and page logic
 */
export const workersCompAccidentStudyLocationPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: SEVENTH_GRADE,
            name: t(`lob.person.workersComp.accident.studyLocation.select.${SEVENTH_GRADE}`),
            icon: <SeventhGradeIcon />,
        },
        {
            id: HIGH_SCHOOL,
            name: t(`lob.person.workersComp.accident.studyLocation.select.${HIGH_SCHOOL}`),
            icon: <HighSchoolIcon />,
        },
        {
            id: HIGHER_EDUCATION,
            name: t(`lob.person.workersComp.accident.studyLocation.select.${HIGHER_EDUCATION}`),
            icon: <HigherEducationIcon />,
        },
        {
            id: VOCATIONAL_LABOUR_MARKET_TRAINING,
            name: t(`lob.person.workersComp.accident.studyLocation.select.${VOCATIONAL_LABOUR_MARKET_TRAINING}`),
            icon: <VocationalLabourMarketTrainingIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION,
        stateKey: 'studyLocation',
        i18n: {
            ns: 'lob.person.workersComp.accident.studyLocation',
        },
        selectorValue: selectStudyLocation(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { studyLocation: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
