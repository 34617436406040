import { OptionType } from '@protectorinsurance/ds-can';

export const countriesEN: OptionType[] = [
    {
        value: '1',
        label: 'Afghanistan',
    },
    {
        value: '2',
        label: 'Albania',
    },
    {
        value: '3',
        label: 'Antarctic',
    },
    {
        value: '4',
        label: 'Algeria',
    },
    {
        value: '5',
        label: 'American Samoa',
    },
    {
        value: '6',
        label: 'Andorra',
    },
    {
        value: '7',
        label: 'Angola',
    },
    {
        value: '8',
        label: 'Antigua and Barbuda',
    },
    {
        value: '9',
        label: 'Azerbaijan',
    },
    {
        value: '10',
        label: 'Argentina',
    },
    {
        value: '11',
        label: 'Australia',
    },
    {
        value: '12',
        label: 'Austria',
    },
    {
        value: '13',
        label: 'Bahamas',
    },
    {
        value: '14',
        label: 'Bahrain',
    },
    {
        value: '15',
        label: 'Bangladesh',
    },
    {
        value: '16',
        label: 'Armenia',
    },
    {
        value: '17',
        label: 'Barbados',
    },
    {
        value: '18',
        label: 'Belgia',
    },
    {
        value: '19',
        label: 'Bermuda',
    },
    {
        value: '20',
        label: 'Bhutan',
    },
    {
        value: '21',
        label: 'Bolivia',
    },
    {
        value: '22',
        label: 'Bosnia and Herzegovina',
    },
    {
        value: '23',
        label: 'Botswana',
    },
    {
        value: '24',
        label: 'Bouvet Island',
    },
    {
        value: '25',
        label: 'Brazil',
    },
    {
        value: '26',
        label: 'Belize',
    },
    {
        value: '27',
        label: 'British Indian Ocean Territory',
    },
    {
        value: '28',
        label: 'Solomon Islands',
    },
    {
        value: '29',
        label: 'British Virgin Islands',
    },
    {
        value: '30',
        label: 'Brunei',
    },
    {
        value: '31',
        label: 'Bulgaria',
    },
    {
        value: '32',
        label: 'Myanmar',
    },
    {
        value: '33',
        label: 'Burundi',
    },
    {
        value: '34',
        label: 'Belarus',
    },
    {
        value: '35',
        label: 'Cambodia',
    },
    {
        value: '36',
        label: 'Cameroon',
    },
    {
        value: '37',
        label: 'Canada',
    },
    {
        value: '38',
        label: 'Cape Verde',
    },
    {
        value: '39',
        label: 'Cayman Islands',
    },
    {
        value: '40',
        label: 'Central African Republic',
    },
    {
        value: '41',
        label: 'Sri Lanka',
    },
    {
        value: '42',
        label: 'Chad',
    },
    {
        value: '43',
        label: 'Chile',
    },
    {
        value: '44',
        label: 'China',
    },
    {
        value: '45',
        label: 'Taiwan',
    },
    {
        value: '46',
        label: 'Christmas Island',
    },
    {
        value: '47',
        label: 'Cocos Islands',
    },
    {
        value: '48',
        label: 'Colombia',
    },
    {
        value: '49',
        label: 'Comoros',
    },
    {
        value: '50',
        label: 'Mayotte',
    },
    {
        value: '51',
        label: 'Congo (Congo - Brazil)',
    },
    {
        value: '52',
        label: 'Kongo',
    },
    {
        value: '53',
        label: 'Cook Islands',
    },
    {
        value: '54',
        label: 'Costa Rica',
    },
    {
        value: '55',
        label: 'Croatia',
    },
    {
        value: '56',
        label: 'Cuba',
    },
    {
        value: '57',
        label: 'Cyprus',
    },
    {
        value: '58',
        label: 'Czech Republic',
    },
    {
        value: '59',
        label: 'Benin',
    },
    {
        value: '60',
        label: 'Denmark',
    },
    {
        value: '61',
        label: 'Dominica',
    },
    {
        value: '62',
        label: 'Dominican Republic',
    },
    {
        value: '63',
        label: 'Ecuador',
    },
    {
        value: '64',
        label: 'El Salvador',
    },
    {
        value: '65',
        label: 'Equatorial Guinea',
    },
    {
        value: '66',
        label: 'Ethiopia',
    },
    {
        value: '67',
        label: 'Eritrea',
    },
    {
        value: '68',
        label: 'Estonia',
    },
    {
        value: '69',
        label: 'Faeroe',
    },
    {
        value: '70',
        label: 'Falkland Islands',
    },
    {
        value: '71',
        label: 'South Georgia and the South Sandwich Islands',
    },
    {
        value: '72',
        label: 'Fvaluez',
    },
    {
        value: '73',
        label: 'Finland',
    },
    {
        value: '74',
        label: 'Åland',
    },
    {
        value: '75',
        label: 'France',
    },
    {
        value: '76',
        label: 'French Guiana',
    },
    {
        value: '77',
        label: 'French Polynesia',
    },
    {
        value: '78',
        label: 'Southern France',
    },
    {
        value: '79',
        label: 'Djibouti',
    },
    {
        value: '80',
        label: 'Gabon',
    },
    {
        value: '81',
        label: 'Georgia',
    },
    {
        value: '82',
        label: 'Gambia',
    },
    {
        value: '83',
        label: 'The State of Palestine',
    },
    {
        value: '84',
        label: 'Germany',
    },
    {
        value: '85',
        label: 'Ghana',
    },
    {
        value: '86',
        label: 'Gibraltar',
    },
    {
        value: '87',
        label: 'Kiribati',
    },
    {
        value: '88',
        label: 'Greece',
    },
    {
        value: '89',
        label: 'Greenland',
    },
    {
        value: '90',
        label: 'Grenada',
    },
    {
        value: '91',
        label: 'Guadeloupe',
    },
    {
        value: '92',
        label: 'Guam',
    },
    {
        value: '93',
        label: 'Guatemala',
    },
    {
        value: '94',
        label: 'Guinea',
    },
    {
        value: '95',
        label: 'Guyana',
    },
    {
        value: '96',
        label: 'Haiti',
    },
    {
        value: '97',
        label: 'Heard and McDonald Islands',
    },
    {
        value: '98',
        label: 'Vatican',
    },
    {
        value: '99',
        label: 'Honduras',
    },
    {
        value: '100',
        label: 'Hongkong',
    },
    {
        value: '101',
        label: 'Hungary',
    },
    {
        value: '102',
        label: 'Iceland',
    },
    {
        value: '103',
        label: 'India',
    },
    {
        value: '104',
        label: 'Indonesia',
    },
    {
        value: '105',
        label: 'Iran',
    },
    {
        value: '106',
        label: 'Iraq',
    },
    {
        value: '107',
        label: 'Ireland',
    },
    {
        value: '108',
        label: 'Israel',
    },
    {
        value: '109',
        label: 'Italy',
    },
    {
        value: '110',
        label: 'Ivory Coast',
    },
    {
        value: '111',
        label: 'Jamaica',
    },
    {
        value: '112',
        label: 'Japan',
    },
    {
        value: '113',
        label: 'Kazakhstan',
    },
    {
        value: '114',
        label: 'Jordan',
    },
    {
        value: '115',
        label: 'Kenya',
    },
    {
        value: '116',
        label: "Democratic People's Republic of Korea (North Korea)",
    },
    {
        value: '117',
        label: 'Republic of Korea (South Korea)',
    },
    {
        value: '118',
        label: 'Kuwait',
    },
    {
        value: '119',
        label: 'Kyrgyzstan',
    },
    {
        value: '120',
        label: 'Laos',
    },
    {
        value: '121',
        label: 'Lebanon',
    },
    {
        value: '122',
        label: 'Lesotho',
    },
    {
        value: '123',
        label: 'Latvia',
    },
    {
        value: '124',
        label: 'Liberia',
    },
    {
        value: '125',
        label: 'Libya',
    },
    {
        value: '126',
        label: 'Liechtenstein',
    },
    {
        value: '127',
        label: 'Lithuania',
    },
    {
        value: '128',
        label: 'Luxembourg',
    },
    {
        value: '129',
        label: 'Macao',
    },
    {
        value: '130',
        label: 'Madagascar',
    },
    {
        value: '131',
        label: 'Malawi',
    },
    {
        value: '132',
        label: 'Malaysia',
    },
    {
        value: '133',
        label: 'Maldives',
    },
    {
        value: '134',
        label: 'Mali',
    },
    {
        value: '135',
        label: 'Malta',
    },
    {
        value: '136',
        label: 'Martinique',
    },
    {
        value: '137',
        label: 'Mauritania',
    },
    {
        value: '138',
        label: 'Mauritius',
    },
    {
        value: '139',
        label: 'Mexico',
    },
    {
        value: '140',
        label: 'Monaco',
    },
    {
        value: '141',
        label: 'Mongolia',
    },
    {
        value: '142',
        label: 'Moldova',
    },
    {
        value: '143',
        label: 'Montenegro',
    },
    {
        value: '144',
        label: 'Montserrat',
    },
    {
        value: '145',
        label: 'Morocco',
    },
    {
        value: '146',
        label: 'Mozambique',
    },
    {
        value: '147',
        label: 'Oman',
    },
    {
        value: '148',
        label: 'Namibia',
    },
    {
        value: '149',
        label: 'Nauru',
    },
    {
        value: '150',
        label: 'Nepal',
    },
    {
        value: '151',
        label: 'Netherlands',
    },
    {
        value: '152',
        label: 'Curaçao',
    },
    {
        value: '153',
        label: 'Aruba',
    },
    {
        value: '154',
        label: 'Sint Maarten (part of the Netherlands)',
    },
    {
        value: '155',
        label: 'Bonaire, Sint Eustatius and Saba',
    },
    {
        value: '156',
        label: 'New Caledonia',
    },
    {
        value: '157',
        label: 'Vanuatu',
    },
    {
        value: '158',
        label: 'New Zealand',
    },
    {
        value: '159',
        label: 'Nicaragua',
    },
    {
        value: '160',
        label: 'Niger',
    },
    {
        value: '161',
        label: 'Nigeria',
    },
    {
        value: '162',
        label: 'Niue',
    },
    {
        value: '163',
        label: 'Norfolk Island',
    },
    {
        value: '164',
        label: 'Norway',
    },
    {
        value: '165',
        label: 'Northern Mariana Islands',
    },
    {
        value: '166',
        label: 'United States Minor Outlying Islands',
    },
    {
        value: '167',
        label: 'Micronesia',
    },
    {
        value: '168',
        label: 'Marshall Islands',
    },
    {
        value: '169',
        label: 'Palau',
    },
    {
        value: '170',
        label: 'Pakistan',
    },
    {
        value: '171',
        label: 'Panama',
    },
    {
        value: '172',
        label: 'Papua New Guinea',
    },
    {
        value: '173',
        label: 'Paraguay',
    },
    {
        value: '174',
        label: 'Peru',
    },
    {
        value: '175',
        label: 'Philippine Islands',
    },
    {
        value: '176',
        label: 'Pitcairn',
    },
    {
        value: '177',
        label: 'Poland',
    },
    {
        value: '178',
        label: 'Portugal',
    },
    {
        value: '179',
        label: 'Guinea-Bissau',
    },
    {
        value: '180',
        label: 'East Timor',
    },
    {
        value: '181',
        label: 'Puerto Rico',
    },
    {
        value: '182',
        label: 'Qatar',
    },
    {
        value: '183',
        label: 'Réunion',
    },
    {
        value: '184',
        label: 'Romania',
    },
    {
        value: '185',
        label: 'Russia',
    },
    {
        value: '186',
        label: 'Rwanda',
    },
    {
        value: '187',
        label: 'Saint Barthélemy',
    },
    {
        value: '188',
        label: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    {
        value: '189',
        label: 'Saint Kitts and Nevis',
    },
    {
        value: '190',
        label: 'Anguilla',
    },
    {
        value: '191',
        label: 'Saint Lucia',
    },
    {
        value: '192',
        label: 'Saint Martin (French part)',
    },
    {
        value: '193',
        label: 'Saint Pierre and Miquelon',
    },
    {
        value: '194',
        label: 'Saint Vincent and the Grenadines',
    },
    {
        value: '195',
        label: 'San Marino',
    },
    {
        value: '196',
        label: 'São Tomé and Príncipe',
    },
    {
        value: '197',
        label: 'Saudi-Arabia',
    },
    {
        value: '198',
        label: 'Senegal',
    },
    {
        value: '199',
        label: 'Serbia',
    },
    {
        value: '200',
        label: 'Seychelles',
    },
    {
        value: '201',
        label: 'Sierra Leone',
    },
    {
        value: '202',
        label: 'Singapore',
    },
    {
        value: '203',
        label: 'Slovakia',
    },
    {
        value: '204',
        label: 'Vietnam',
    },
    {
        value: '205',
        label: 'Slovenia',
    },
    {
        value: '206',
        label: 'Somalia',
    },
    {
        value: '207',
        label: 'South Africa',
    },
    {
        value: '208',
        label: 'Zimbabwe',
    },
    {
        value: '209',
        label: 'Spain',
    },
    {
        value: '210',
        label: 'South Sudan',
    },
    {
        value: '211',
        label: 'Sudan',
    },
    {
        value: '212',
        label: 'Western Sahara',
    },
    {
        value: '213',
        label: 'Surilabel',
    },
    {
        value: '214',
        label: 'Svalbard and Jan Mayen',
    },
    {
        value: '215',
        label: 'Swaziland',
    },
    {
        value: '216',
        label: 'Swedish',
    },
    {
        value: '217',
        label: 'Switzerland',
    },
    {
        value: '218',
        label: 'Syria',
    },
    {
        value: '219',
        label: 'Tajikistan',
    },
    {
        value: '220',
        label: 'Thailand',
    },
    {
        value: '221',
        label: 'Togo',
    },
    {
        value: '222',
        label: 'Tokelau',
    },
    {
        value: '223',
        label: 'Tonga',
    },
    {
        value: '224',
        label: 'Trinvaluead and Tobago',
    },
    {
        value: '225',
        label: 'United Arab Emirates',
    },
    {
        value: '226',
        label: 'Tunisia',
    },
    {
        value: '227',
        label: 'Turkey',
    },
    {
        value: '228',
        label: 'Turkmenistan',
    },
    {
        value: '229',
        label: 'Turks and Caicos Islands',
    },
    {
        value: '230',
        label: 'Tuvalu',
    },
    {
        value: '231',
        label: 'Uganda',
    },
    {
        value: '232',
        label: 'Ukraina',
    },
    {
        value: '233',
        label: 'Macedonia',
    },
    {
        value: '234',
        label: 'Egypt',
    },
    {
        value: '235',
        label: 'Britain',
    },
    {
        value: '236',
        label: 'Guernsey',
    },
    {
        value: '237',
        label: 'Jersey',
    },
    {
        value: '238',
        label: 'Man',
    },
    {
        value: '239',
        label: 'Tanzania',
    },
    {
        value: '240',
        label: 'United States (USA)',
    },
    {
        value: '241',
        label: 'US Virgin Islands',
    },
    {
        value: '242',
        label: 'Burkina Faso',
    },
    {
        value: '243',
        label: 'Uruguay',
    },
    {
        value: '244',
        label: 'Uzbekistan',
    },
    {
        value: '245',
        label: 'Venezuela',
    },
    {
        value: '246',
        label: 'Wallis and Futuna Islands',
    },
    {
        value: '247',
        label: 'Samoa',
    },
    {
        value: '248',
        label: 'Yemen',
    },
    {
        value: '249',
        label: 'Zambia',
    },
];
