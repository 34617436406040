import {
    actionWithPromise,
    BankAccountInformationModel,
    ClaimantModel,
    ClaimDescriptionTypeModel,
    ClaimTypeTypeModel,
    CompanyModel,
    Datable,
    emptyFn,
    ExternalReferenceTypeModel,
    initClaimantModel,
    initLocation,
    initPolicyHoldersContact,
    initReporterInformation,
    LocationModel,
    LpoClaimCauseTypeModel,
    Nullable,
    OtherInsuranceCompanyTypeModel,
    PoliceCaseNumberTypeModel,
    PolicyHoldersContactModel,
    PrivacyTypeModel,
    Rejectable,
    ReporterInformationModel,
    Resolvable,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { put, takeEvery } from 'redux-saga/effects';
import { initTravelDates, TravelDatesModel } from '../interfaces/models/TravelDates.model';

/**
 * Constants
 */
export enum LpoActionTypes {
    UPDATE = '@lpo/UPDATE',
    UPDATED = '@lpo/UPDATED',
}

/**
 * Interfaces
 */
export interface LpoAction {
    type: LpoActionTypes;
    data?: Partial<LpoState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface LpoState {
    acceptedPoliceContact: PrivacyTypeModel;
    acceptedPrivacy: PrivacyTypeModel;
    accidentLocation: LocationModel;
    bankAccountInformation: BankAccountInformationModel;
    claimCause: LpoClaimCauseTypeModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimType: ClaimTypeTypeModel;
    claimantInformation: ClaimantModel;
    companyInformation: CompanyModel;
    externalReference: ExternalReferenceTypeModel;
    isPoliceContacted: YesNoModel;
    isPolicyHolderReporter: YesNoModel;
    otherInsurance: YesNoModel;
    otherInsuranceCompany: OtherInsuranceCompanyTypeModel;
    policeCaseNumber: PoliceCaseNumberTypeModel;
    policyHoldersContact: PolicyHoldersContactModel;
    reporterInformation: ReporterInformationModel;
    travelDates: TravelDatesModel;
    travelInterruption: Nullable<string>;
}

/**
 * Initial State
 */
export const lpoInitState: LpoState = {
    acceptedPoliceContact: false,
    acceptedPrivacy: false,
    accidentLocation: initLocation,
    bankAccountInformation: {
        accountNumber: null,
        companyName: null,
        dontKnowBankInformation: false,
        isCompany: false,
        ownerFamilyName: null,
        ownerGivenName: null,
    },
    claimCause: null,
    claimDate: null,
    claimDescription: '',
    claimType: null,
    claimantInformation: initClaimantModel,
    companyInformation: {
        businessNumber: null,
        name: null,
    },
    externalReference: null,
    isPoliceContacted: null,
    isPolicyHolderReporter: null,
    otherInsurance: null,
    otherInsuranceCompany: null,
    policeCaseNumber: null,
    policyHoldersContact: initPolicyHoldersContact,
    reporterInformation: initReporterInformation,
    travelDates: initTravelDates,
    travelInterruption: null,
};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export default function (state = lpoInitState, { type, data }: LpoAction) {
    switch (type) {
        case LpoActionTypes.UPDATED:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const lpoActions = {
    update: actionWithPromise<LpoActionTypes.UPDATE, Partial<LpoState>>(LpoActionTypes.UPDATE),
    updated: actionWithPromise<LpoActionTypes.UPDATED, Partial<LpoState>>(LpoActionTypes.UPDATED),
};

/**
 * Saga watchers
 */
export const lpoWatcher = function* () {
    yield takeEvery(LpoActionTypes.UPDATE, lpoSagas.update);
};

/**
 * Saga functions
 */
export const lpoSagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: LpoAction) {
        try {
            yield put(lpoActions.updated({ ...data }));
            resolve();
        } catch (e) {
            reject();
        }
    },
};
