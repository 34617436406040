import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MotorClaimCauseKeys,
    MuiTextInput,
    PageLayout,
    WorkshopInformationModel,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../sagas/motor';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectClaimCause, selectIsDriving, selectWorkshopInformation } from '../../../sagas/selectors/motorSelectors';
import { workshopInformationSchema } from '../../../validations/schemas/workshopInformationSchema';
import { FormFieldNames } from '../../../config/formFieldNames';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    WORKSHOP_INFORMATION_LABEL,
    WORKSHOP_INFORMATION_PLACEHOLDER,
} = PhraseKeys;
const { WORKSHOP_INFORMATION } = FormFieldNames;
const { COMPARTMENT_DAMAGE, ENGINE, LOST_KEY, NATURAL_DISASTER, OTHER, PARKING, WRONG_FUEL } = MotorClaimCauseKeys;
const { DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES, DYN_CAROUSEL_END_IS_POLICE_CONTACTED, END_UPLOAD_ATTACHMENT } =
    MotorRoutePaths;
const { NO } = YesNoKeys;

/**
 * Page view and page logic
 */
export const EndFindWorkshop = () => {
    const dispatch = useDispatch();
    const workshopInformation = useSelector(selectWorkshopInformation);
    const claimCause = useSelector(selectClaimCause);
    const isDriving = useSelector(selectIsDriving);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.findWorkshop');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<WorkshopInformationModel>({
        resolver: yupResolver(workshopInformationSchema(t)),
        defaultValues: {
            workshopInformation,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: WorkshopInformationModel) => {
        let nextAction = wizardActions.goToNext();
        if (claimCause === ENGINE) {
            nextAction = wizardActions.goTo(END_UPLOAD_ATTACHMENT);
        } else if (claimCause === PARKING) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_END_IS_POLICE_CONTACTED);
        } else if (
            claimCause === NATURAL_DISASTER ||
            claimCause === COMPARTMENT_DAMAGE ||
            claimCause === WRONG_FUEL ||
            claimCause === LOST_KEY ||
            (claimCause === OTHER && isDriving === NO)
        ) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES);
        }

        dispatcherWithPromise(dispatch, motorActions.update, { workshopInformation: values.workshopInformation })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={WORKSHOP_INFORMATION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.workshopInformation}
                                errorMessage={errors.workshopInformation?.message}
                                id={WORKSHOP_INFORMATION}
                                inputFieldWrapper={'col-12'}
                                label={t(WORKSHOP_INFORMATION_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(WORKSHOP_INFORMATION_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
