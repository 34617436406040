import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useGoBack } from '../../../../hooks/useGoBack';
import { useI18n } from '../../../../hooks/useI18n';
import { Clickable, DoubleButton, Grid, PageLayout, YesNoKeys } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { useVehicles } from '../../../../hooks/useVehicles';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { selectSelectedVehicle } from '../../../../sagas/selectors/motorSelectors';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const DVAddExtrasPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.addExtras');
    const { vehicles } = useVehicles();
    const selectedVehicle = useSelector(selectSelectedVehicle);
    const customCAN = useSelector(selectCustomCAN);

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: [...vehicles, { ...selectedVehicle }] })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    const handleCompanyVehicleSelect = (companyVehicle: YesNoKeys, e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, {
            selectedVehicle: { ...selectedVehicle, companyVehicle },
        }).then(() => dispatch(commonActions.send()));
    };

    const handleLeasingSelect = (leasing: YesNoKeys, e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, {
            selectedVehicle: { ...selectedVehicle, leasing },
        }).then(() => dispatch(commonActions.send()));
    };

    const handleVATSelect = (VAT: YesNoKeys, e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, {
            selectedVehicle: { ...selectedVehicle, VAT },
        }).then(() => dispatch(commonActions.send()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={
                !selectedVehicle?.companyVehicle || !selectedVehicle?.leasing || !selectedVehicle?.VAT
            }
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid>
                <DoubleButton
                    primaryButtonClassName={selectedVehicle?.companyVehicle === YES ? 'active' : ''}
                    primaryButtonText={tWithNS.t(`companyVehicle.select.${YES}`)}
                    primaryOnClick={() => handleCompanyVehicleSelect(YES)}
                    primaryTestId={'primaryCompanyVehicleButton'}
                    secondaryButtonClassName={selectedVehicle?.companyVehicle === NO ? 'active' : ''}
                    secondaryButtonText={tWithNS.t(`companyVehicle.select.${NO}`)}
                    secondaryOnClick={() => handleCompanyVehicleSelect(NO)}
                    secondaryTestId={'secondaryCompanyVehicleButton'}
                    title={tWithNS.t('companyVehicle.title')}
                    wrapperClassName={'col-4'}
                    {...{ customCAN }}
                />

                <DoubleButton
                    primaryButtonClassName={selectedVehicle?.leasing === YES ? 'active' : ''}
                    primaryButtonText={tWithNS.t(`leasing.select.${YES}`)}
                    primaryOnClick={() => handleLeasingSelect(YES)}
                    primaryTestId={'primaryLeasingButton'}
                    secondaryButtonClassName={selectedVehicle?.leasing === NO ? 'active' : ''}
                    secondaryButtonText={tWithNS.t(`leasing.select.${NO}`)}
                    secondaryOnClick={() => handleLeasingSelect(NO)}
                    secondaryTestId={'secondaryLeasingButton'}
                    title={tWithNS.t('leasing.title')}
                    wrapperClassName={'col-4'}
                    {...{ customCAN }}
                />

                <DoubleButton
                    primaryButtonClassName={selectedVehicle?.VAT === YES ? 'active' : ''}
                    primaryButtonText={tWithNS.t(`VAT.select.${YES}`)}
                    primaryOnClick={() => handleVATSelect(YES)}
                    primaryTestId={'primaryVATButton'}
                    secondaryButtonClassName={selectedVehicle?.VAT === NO ? 'active' : ''}
                    secondaryButtonText={tWithNS.t(`VAT.select.${NO}`)}
                    secondaryOnClick={() => handleVATSelect(NO)}
                    secondaryTestId={'secondaryVATButton'}
                    title={tWithNS.t('VAT.title')}
                    wrapperClassName={'col-4'}
                    {...{ customCAN }}
                />
            </Grid>
        </PageLayout>
    );
};
