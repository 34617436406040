import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isEmpty } from '@protectorinsurance/ds-can';

export function requiredIfChecked(
    this: StringSchema,
    checkboxKey: string,
    checkedCondition: boolean,
    message: string = yupCustomLocale.string.requiredIf
) {
    // @ts-ignore
    return this.test('requiredIf', message, function (this: TestContext, value: string | null):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        // Should validate ok if value is filled in or checkbox has checkedCondition
        return this.parent[checkboxKey] === checkedCondition || !isEmpty(value);
    });
}
