import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/en-gb';
import { getMomentLocale } from '../utils/locale/getMomentLocale';
import { getLocaleFromUrl } from '@protectorinsurance/ds-can';

export const initMoment = () => {
    const momentLocale = getMomentLocale(getLocaleFromUrl());
    moment.locale(momentLocale);
};

initMoment();
