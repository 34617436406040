import { OptionType } from '@protectorinsurance/ds-can';

export const countriesFI: OptionType[] = [
    {
        value: '1',
        label: 'Afganistan',
    },
    {
        value: '2',
        label: 'Albania',
    },
    {
        value: '3',
        label: 'Antarktis',
    },
    {
        value: '4',
        label: 'Algeria',
    },
    {
        value: '5',
        label: 'Amerikan Samoa',
    },
    {
        value: '6',
        label: 'Andorra',
    },
    {
        value: '7',
        label: 'Angola',
    },
    {
        value: '8',
        label: 'Antigua ja Barbuda',
    },
    {
        value: '9',
        label: 'Azerbavaluezan',
    },
    {
        value: '10',
        label: 'Argentiina',
    },
    {
        value: '11',
        label: 'Australia',
    },
    {
        value: '12',
        label: 'Itävalta',
    },
    {
        value: '13',
        label: 'Bahama',
    },
    {
        value: '14',
        label: 'Bahrain',
    },
    {
        value: '15',
        label: 'Bangladesh',
    },
    {
        value: '16',
        label: 'Armenia',
    },
    {
        value: '17',
        label: 'Barbados',
    },
    {
        value: '18',
        label: 'Belgia',
    },
    {
        value: '19',
        label: 'Bermuda',
    },
    {
        value: '20',
        label: 'Bhutan',
    },
    {
        value: '21',
        label: 'Bolivia',
    },
    {
        value: '22',
        label: 'Bosnia ja Hertsegovina',
    },
    {
        value: '23',
        label: 'Botswana',
    },
    {
        value: '24',
        label: "Bouvet'nsaari",
    },
    {
        value: '25',
        label: 'Brasilia',
    },
    {
        value: '26',
        label: 'Belize',
    },
    {
        value: '27',
        label: 'Brittiläinen Intian valtameren alue',
    },
    {
        value: '28',
        label: 'Salomonsaaret',
    },
    {
        value: '29',
        label: 'Brittiläiset Neitsytsaaret',
    },
    {
        value: '30',
        label: 'Brunei',
    },
    {
        value: '31',
        label: 'Bulgaria',
    },
    {
        value: '32',
        label: 'Myanmar',
    },
    {
        value: '33',
        label: 'Burundi',
    },
    {
        value: '34',
        label: 'Valko-Venäjä',
    },
    {
        value: '35',
        label: 'Kambodza',
    },
    {
        value: '36',
        label: 'Kamerun',
    },
    {
        value: '37',
        label: 'Kanada',
    },
    {
        value: '38',
        label: 'Kap Verde',
    },
    {
        value: '39',
        label: 'Caymansaaret',
    },
    {
        value: '40',
        label: 'Keski-Afrikan tasavalta',
    },
    {
        value: '41',
        label: 'Sri Lanka',
    },
    {
        value: '42',
        label: 'Tsad',
    },
    {
        value: '43',
        label: 'Chile',
    },
    {
        value: '44',
        label: 'Kiina',
    },
    {
        value: '45',
        label: 'Taiwan',
    },
    {
        value: '46',
        label: 'Joulusaari',
    },
    {
        value: '47',
        label: 'Kookossaaret',
    },
    {
        value: '48',
        label: 'Kolumbia',
    },
    {
        value: '49',
        label: 'Komorit',
    },
    {
        value: '50',
        label: 'Mayotte',
    },
    {
        value: '51',
        label: 'Kongo (Kongo-Brazzaville)',
    },
    {
        value: '52',
        label: 'Kongo (Kongo-Kinshasa)',
    },
    {
        value: '53',
        label: 'Cookinsaaret',
    },
    {
        value: '54',
        label: 'Costa Rica',
    },
    {
        value: '55',
        label: 'Kroatia',
    },
    {
        value: '56',
        label: 'Kuuba',
    },
    {
        value: '57',
        label: 'Kypros',
    },
    {
        value: '58',
        label: 'Tsekki',
    },
    {
        value: '59',
        label: 'Benin',
    },
    {
        value: '60',
        label: 'Tanska',
    },
    {
        value: '61',
        label: 'Dominica',
    },
    {
        value: '62',
        label: 'Dominikaaninen tasavalta',
    },
    {
        value: '63',
        label: 'Ecuador',
    },
    {
        value: '64',
        label: 'El Salvador',
    },
    {
        value: '65',
        label: 'Päiväntasaajan Guinea',
    },
    {
        value: '66',
        label: 'Etiopia',
    },
    {
        value: '67',
        label: 'Eritrea',
    },
    {
        value: '68',
        label: 'Viro',
    },
    {
        value: '69',
        label: 'Färsaaret',
    },
    {
        value: '70',
        label: 'Falklandinsaaret',
    },
    {
        value: '71',
        label: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
    },
    {
        value: '72',
        label: 'Fvaluezi',
    },
    {
        value: '73',
        label: 'Suomi',
    },
    {
        value: '74',
        label: 'Ahvenanmaa',
    },
    {
        value: '75',
        label: 'Ranska',
    },
    {
        value: '76',
        label: 'Ranskan Guayana',
    },
    {
        value: '77',
        label: 'Ranskan Polynesia',
    },
    {
        value: '78',
        label: 'Ranskan eteläiset alueet',
    },
    {
        value: '79',
        label: 'Djibouti',
    },
    {
        value: '80',
        label: 'Gabon',
    },
    {
        value: '81',
        label: 'Georgia',
    },
    {
        value: '82',
        label: 'Gambia',
    },
    {
        value: '83',
        label: 'Palestiinan valtio',
    },
    {
        value: '84',
        label: 'Saksa',
    },
    {
        value: '85',
        label: 'Ghana',
    },
    {
        value: '86',
        label: 'Gibraltar',
    },
    {
        value: '87',
        label: 'Kiribati',
    },
    {
        value: '88',
        label: 'Kreikka',
    },
    {
        value: '89',
        label: 'Grönlanti',
    },
    {
        value: '90',
        label: 'Grenada',
    },
    {
        value: '91',
        label: 'Guadeloupe',
    },
    {
        value: '92',
        label: 'Guam',
    },
    {
        value: '93',
        label: 'Guatemala',
    },
    {
        value: '94',
        label: 'Guinea',
    },
    {
        value: '95',
        label: 'Guyana',
    },
    {
        value: '96',
        label: 'Haiti',
    },
    {
        value: '97',
        label: 'Heard ja McDonaldinsaaret',
    },
    {
        value: '98',
        label: 'Vatikaani',
    },
    {
        value: '99',
        label: 'Honduras',
    },
    {
        value: '100',
        label: 'Hongkong',
    },
    {
        value: '101',
        label: 'Unkari',
    },
    {
        value: '102',
        label: 'Islanti',
    },
    {
        value: '103',
        label: 'Intia',
    },
    {
        value: '104',
        label: 'Indonesia',
    },
    {
        value: '105',
        label: 'Iran',
    },
    {
        value: '106',
        label: 'Irak',
    },
    {
        value: '107',
        label: 'Irlanti',
    },
    {
        value: '108',
        label: 'Israel',
    },
    {
        value: '109',
        label: 'Italia',
    },
    {
        value: '110',
        label: 'Norsunluurannikko',
    },
    {
        value: '111',
        label: 'Jamaika',
    },
    {
        value: '112',
        label: 'Japani',
    },
    {
        value: '113',
        label: 'Kazakstan',
    },
    {
        value: '114',
        label: 'Jordania',
    },
    {
        value: '115',
        label: 'Kenia',
    },
    {
        value: '116',
        label: 'Korean demokraattinen kansantasavalta (Pohjois-Korea)',
    },
    {
        value: '117',
        label: 'Korean tasavalta (Etelä-Korea)',
    },
    {
        value: '118',
        label: 'Kuwait',
    },
    {
        value: '119',
        label: 'Kirgisia',
    },
    {
        value: '120',
        label: 'Laos',
    },
    {
        value: '121',
        label: 'Libanon',
    },
    {
        value: '122',
        label: 'Lesotho',
    },
    {
        value: '123',
        label: 'Latvia',
    },
    {
        value: '124',
        label: 'Liberia',
    },
    {
        value: '125',
        label: 'Libya',
    },
    {
        value: '126',
        label: 'Liechtenstein',
    },
    {
        value: '127',
        label: 'Liettua',
    },
    {
        value: '128',
        label: 'Luxemburg',
    },
    {
        value: '129',
        label: 'Macao',
    },
    {
        value: '130',
        label: 'Madagaskar',
    },
    {
        value: '131',
        label: 'Malawi',
    },
    {
        value: '132',
        label: 'Malesia',
    },
    {
        value: '133',
        label: 'Malediivit',
    },
    {
        value: '134',
        label: 'Mali',
    },
    {
        value: '135',
        label: 'Malta',
    },
    {
        value: '136',
        label: 'Martinique',
    },
    {
        value: '137',
        label: 'Mauritania',
    },
    {
        value: '138',
        label: 'Mauritius',
    },
    {
        value: '139',
        label: 'Meksiko',
    },
    {
        value: '140',
        label: 'Monaco',
    },
    {
        value: '141',
        label: 'Mongolia',
    },
    {
        value: '142',
        label: 'Moldova',
    },
    {
        value: '143',
        label: 'Montenegro',
    },
    {
        value: '144',
        label: 'Montserrat',
    },
    {
        value: '145',
        label: 'Marokko',
    },
    {
        value: '146',
        label: 'Mosambik',
    },
    {
        value: '147',
        label: 'Oman',
    },
    {
        value: '148',
        label: 'Namibia',
    },
    {
        value: '149',
        label: 'Nauru',
    },
    {
        value: '150',
        label: 'Nepal',
    },
    {
        value: '151',
        label: 'Alankomaat',
    },
    {
        value: '152',
        label: 'Curaçao',
    },
    {
        value: '153',
        label: 'Aruba',
    },
    {
        value: '154',
        label: 'Sint Maarten (Alankomavalueen osa)',
    },
    {
        value: '155',
        label: 'Bonaire, Sint Eustatius ja Saba',
    },
    {
        value: '156',
        label: 'Uusi-Kaledonia',
    },
    {
        value: '157',
        label: 'Vanuatu',
    },
    {
        value: '158',
        label: 'Uusi-Seelanti',
    },
    {
        value: '159',
        label: 'Nicaragua',
    },
    {
        value: '160',
        label: 'Niger',
    },
    {
        value: '161',
        label: 'Nigeria',
    },
    {
        value: '162',
        label: 'Niue',
    },
    {
        value: '163',
        label: 'Norfolkinsaari',
    },
    {
        value: '164',
        label: 'Norja',
    },
    {
        value: '165',
        label: 'Pohjois-Mariaanit',
    },
    {
        value: '166',
        label: 'Yhdysvaltain pienet erillissaaret',
    },
    {
        value: '167',
        label: 'Mikronesia',
    },
    {
        value: '168',
        label: 'Marshallinsaaret',
    },
    {
        value: '169',
        label: 'Palau',
    },
    {
        value: '170',
        label: 'Pakistan',
    },
    {
        value: '171',
        label: 'Panama',
    },
    {
        value: '172',
        label: 'Papua-Uusi-Guinea',
    },
    {
        value: '173',
        label: 'Paraguay',
    },
    {
        value: '174',
        label: 'Peru',
    },
    {
        value: '175',
        label: 'Filippiinit',
    },
    {
        value: '176',
        label: 'Pitcairn',
    },
    {
        value: '177',
        label: 'Puola',
    },
    {
        value: '178',
        label: 'Portugali',
    },
    {
        value: '179',
        label: 'Guinea-Bissau',
    },
    {
        value: '180',
        label: 'Itä-Timor',
    },
    {
        value: '181',
        label: 'Puerto Rico',
    },
    {
        value: '182',
        label: 'Qatar',
    },
    {
        value: '183',
        label: 'Réunion',
    },
    {
        value: '184',
        label: 'Romania',
    },
    {
        value: '185',
        label: 'Venäjä',
    },
    {
        value: '186',
        label: 'Ruanda',
    },
    {
        value: '187',
        label: 'Saint Barthélemy',
    },
    {
        value: '188',
        label: 'Saint Helena, Ascension ja Tristan da Cunha',
    },
    {
        value: '189',
        label: 'Saint Kitts ja Nevis',
    },
    {
        value: '190',
        label: 'Anguilla',
    },
    {
        value: '191',
        label: 'Saint Lucia',
    },
    {
        value: '192',
        label: 'Saint Martin (Ranskan osa)',
    },
    {
        value: '193',
        label: 'Saint-Pierre ja Miquelon',
    },
    {
        value: '194',
        label: 'Saint Vincent ja Grenadiinit',
    },
    {
        value: '195',
        label: 'San Marino',
    },
    {
        value: '196',
        label: 'São Tomé ja Príncipe',
    },
    {
        value: '197',
        label: 'Saudi-Arabia',
    },
    {
        value: '198',
        label: 'Senegal',
    },
    {
        value: '199',
        label: 'Serbia',
    },
    {
        value: '200',
        label: 'Seychellit',
    },
    {
        value: '201',
        label: 'Sierra Leone',
    },
    {
        value: '202',
        label: 'Singapore',
    },
    {
        value: '203',
        label: 'Slovakia',
    },
    {
        value: '204',
        label: 'Vietnam',
    },
    {
        value: '205',
        label: 'Slovenia',
    },
    {
        value: '206',
        label: 'Somalia',
    },
    {
        value: '207',
        label: 'Etelä-Afrikka',
    },
    {
        value: '208',
        label: 'Zimbabwe',
    },
    {
        value: '209',
        label: 'Espanja',
    },
    {
        value: '210',
        label: 'Etelä-Sudan',
    },
    {
        value: '211',
        label: 'Sudan',
    },
    {
        value: '212',
        label: 'Länsi-Sahara',
    },
    {
        value: '213',
        label: 'Surilabel',
    },
    {
        value: '214',
        label: 'Svalbard ja Jan Mayen',
    },
    {
        value: '215',
        label: 'Swazimaa',
    },
    {
        value: '216',
        label: 'Ruotsi',
    },
    {
        value: '217',
        label: 'Sveitsi',
    },
    {
        value: '218',
        label: 'Syyria',
    },
    {
        value: '219',
        label: 'Tadzikistan',
    },
    {
        value: '220',
        label: 'Thaimaa',
    },
    {
        value: '221',
        label: 'Togo',
    },
    {
        value: '222',
        label: 'Tokelau',
    },
    {
        value: '223',
        label: 'Tonga',
    },
    {
        value: '224',
        label: 'Trinvaluead ja Tobago',
    },
    {
        value: '225',
        label: 'Arabiemiirikunnat',
    },
    {
        value: '226',
        label: 'Tunisia',
    },
    {
        value: '227',
        label: 'Turkki',
    },
    {
        value: '228',
        label: 'Turkmenistan',
    },
    {
        value: '229',
        label: 'Turks- ja Caicossaaret',
    },
    {
        value: '230',
        label: 'Tuvalu',
    },
    {
        value: '231',
        label: 'Uganda',
    },
    {
        value: '232',
        label: 'Ukraina',
    },
    {
        value: '233',
        label: 'Makedonia',
    },
    {
        value: '234',
        label: 'Egypti',
    },
    {
        value: '235',
        label: 'Britannia',
    },
    {
        value: '236',
        label: 'Guernsey',
    },
    {
        value: '237',
        label: 'Jersey',
    },
    {
        value: '238',
        label: 'Mansaari',
    },
    {
        value: '239',
        label: 'Tansania',
    },
    {
        value: '240',
        label: 'Yhdysvallat (USA)',
    },
    {
        value: '241',
        label: 'Yhdysvaltain Neitsytsaaret',
    },
    {
        value: '242',
        label: 'Burkina Faso',
    },
    {
        value: '243',
        label: 'Uruguay',
    },
    {
        value: '244',
        label: 'Uzbekistan',
    },
    {
        value: '245',
        label: 'Venezuela',
    },
    {
        value: '246',
        label: 'Wallis ja Futuna',
    },
    {
        value: '247',
        label: 'Samoa',
    },
    {
        value: '248',
        label: 'Jemen',
    },
    {
        value: '249',
        label: 'Sambia',
    },
];
