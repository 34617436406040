import React, { useRef } from 'react';
import { useMarkers } from '../../../hooks/useMarkers';
import { createOnClickAddDamagePoint } from '../../../utils/svgUtils';
import { DamageMarkers } from '../DamageMarker';
import { BodyKeys, SvgImg } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { BACK_LEFT, BACK_RIGHT, FRONT_RIGHT, CENTER_LEFT, CENTER_RIGHT, ROOF, FRONT_LEFT } = BodyKeys;

/**
 * Component view and component logic
 */
export const LightTruckArialImage = ({
    addDamagePoint,
    selectedDamagePoints,
    removeDamagePoint,
    title,
    id = 'light-truck-arial-image-svg',
}: SvgImg) => {
    const refSVG = useRef<SVGSVGElement>(null);
    const thisMarkers = useMarkers(selectedDamagePoints, id);
    const onClick = createOnClickAddDamagePoint(refSVG, addDamagePoint);
    return (
        <>
            <svg
                id={id}
                className={'vehicle-img-svg arial-image-svg'}
                ref={refSVG}
                width="326"
                height="798"
                viewBox="0 0 326 798"
                aria-labelledby={`${id}-title`}
                role="img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-testid={id}
            >
                <title id={`${id}-title`}>{title}</title>
                <g className={'arial-image-svg__selectable'}>
                    <rect
                        id={ROOF}
                        onClick={onClick}
                        x="59"
                        y="267"
                        width="202"
                        height="333"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <rect
                        id={CENTER_LEFT}
                        onClick={onClick}
                        x="2.5"
                        y="267.5"
                        width="56"
                        height="332"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <rect
                        id={CENTER_RIGHT}
                        onClick={onClick}
                        x="261.5"
                        y="267.5"
                        width="62"
                        height="332"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <path
                        id={BACK_LEFT}
                        onClick={onClick}
                        d="M7 830.5H162.246V600.218H2.5V826C2.5 828.485 4.51472 830.5 7 830.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <path
                        id={BACK_RIGHT}
                        onClick={onClick}
                        d="M319.12 830.5H163.246V600.218H323.62V826C323.62 828.485 321.605 830.5 319.12 830.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <path
                        id={FRONT_RIGHT}
                        onClick={onClick}
                        d="M166.49 36.5H322.992V266.782H161.99V41C161.99 38.5147 164.005 36.5 166.49 36.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <path
                        id={FRONT_LEFT}
                        onClick={onClick}
                        d="M7 36.5H162.5V266.5H2.5V41C2.5 38.5147 4.51472 36.5 7 36.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                </g>
                <g className={'arial-image-svg__content'}>
                    <path
                        d="M66.9195 297.5L66.9195 785.5M114.92 297.5L114.919 785.5M161.92 297.5L161.919 785.5M206.92 297.5L206.919 785.5M252.92 297.5L252.919 785.5M29.1196 226.5V831H297V226.5H324C325.045 206.203 319.5 199 297 196.5V147C297 37.9999 231 38 216.5 38H109.62C95.1196 38 29.1196 37.9999 29.1196 147V196.5C6.61951 199 1.07424 206.203 2.11962 226.5H29.1196ZM40.1195 269.5V180.5C40.1195 149 114.66 134.641 164.12 134.5C213.943 134.358 287.12 149 287.12 180.5V269.5C242.891 258.893 212.5 257.147 164.12 257.5C115.739 257.147 84.3482 258.893 40.1195 269.5Z"
                        stroke="black"
                        strokeWidth="3.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <DamageMarkers removeDamagePoint={removeDamagePoint} selectedDamagePoints={thisMarkers} />
            </svg>
        </>
    );
};
