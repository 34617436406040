import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimantAgeCategoryKeys,
    Clickable,
    CompanyIcon,
    IAmInjuredIcon,
    is,
    OnBehalfOfAChildIcon,
    WhoPaidExpensesKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../sagas/person';
import { selectClaimantAgeCategory, selectWhoPaidExpenses } from '../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { CLAIMANT, GUARDIAN, POLICY_HOLDER } = WhoPaidExpensesKeys;
const { CHILD } = ClaimantAgeCategoryKeys;

/**
 * Page view and page logic
 */
export const groupAccidentWhoPaidExpensesPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const claimantAgeCategory = selectClaimantAgeCategory(state);
    let options = [
        {
            id: CLAIMANT,
            name: t(`lob.person.groupAccident.whoPaidExpenses.select.${CLAIMANT}`),
            icon: <IAmInjuredIcon />,
        },
        {
            id: POLICY_HOLDER,
            name: t(`lob.person.groupAccident.whoPaidExpenses.select.${POLICY_HOLDER}`),
            icon: <CompanyIcon />,
        },
    ];

    if (is(claimantAgeCategory, CHILD)) {
        options = [
            {
                id: CLAIMANT,
                name: t(`lob.person.groupAccident.whoPaidExpenses.select.${CLAIMANT}`),
                icon: <IAmInjuredIcon />,
            },
            {
                id: POLICY_HOLDER,
                name: t(`lob.person.groupAccident.whoPaidExpenses.select.${POLICY_HOLDER}`),
                icon: <CompanyIcon />,
            },
            {
                id: GUARDIAN,
                name: t(`lob.person.groupAccident.whoPaidExpenses.select.${GUARDIAN}`),
                icon: <OnBehalfOfAChildIcon />,
            },
        ];
    }

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_WHO_PAID_EXPENSES,
        stateKey: 'whoPaidExpenses',
        i18n: {
            ns: 'lob.person.groupAccident.whoPaidExpenses',
        },
        selectorValue: selectWhoPaidExpenses(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { whoPaidExpenses: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
