import React from 'react';
import { BankAccountInformationModel, is, LobKeys, Nullable, SummaryItemText } from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { TRAVEL } = LobKeys;

/**
 * Component view and component logic
 */
export const renderBankAccountInformation = (
    {
        accountNumber,
        companyName,
        dontKnowBankInformation,
        isCompany,
        ownerFamilyName,
        ownerGivenName,
    }: BankAccountInformationModel,
    lob: Nullable<LobKeys>,
    t: TFunction
) => {
    if (is(lob, TRAVEL)) {
        return (
            <>
                {dontKnowBankInformation ? (
                    <SummaryItemText
                        label={t('form.fields.dontKnowBankInformation')}
                        text={t(`dontKnowBankInformation.select.${dontKnowBankInformation}`)}
                        className={'col-12 divider text-align-left'}
                    />
                ) : (
                    <>
                        {isCompany && companyName ? (
                            <SummaryItemText
                                label={t('form.fields.companyInformation.name')}
                                text={`${companyName}`}
                                className={'col-8 divider text-align-left'}
                            />
                        ) : (
                            ''
                        )}
                        {ownerGivenName || ownerFamilyName ? (
                            <SummaryItemText
                                label={`${t('form.fields.ownerGivenName')} ${t('form.fields.ownerFamilyName')}`}
                                text={`${ownerGivenName} ${ownerFamilyName}`}
                                className={'col-8 divider text-align-left'}
                            />
                        ) : (
                            ''
                        )}
                        <SummaryItemText
                            label={t('form.fields.accountNumber')}
                            text={`${accountNumber}`}
                            className={'col-4 divider text-align-left'}
                        />
                    </>
                )}
            </>
        );
    }

    return <></>;
};
