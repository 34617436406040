import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    EngineDamageIcon,
    FireDamagesIcon,
    MotorClaimCauseKeys,
    MotorClaimCauseTypeModel,
    OtherInjuriesIcon,
    ParkedIcon,
    TheftAndDamageIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { selectClaimCause, selectClaimCauseList } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../sagas/common';
import { claimCauseListUtil } from '../../../../utils/claimCauseListUtils';
import { flowActions } from '../../../../sagas/flow';
import { claimCauseSelected } from '../../../../utils/claimCauseUtils';

/**
 * Destructure necessary imports
 */
const { THEFT_AND_DAMAGE, ENGINE, PARKING, FIRE, OTHER } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const parkedClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        {
            id: THEFT_AND_DAMAGE,
            name: t(`motor.parked.claimCause.select.${THEFT_AND_DAMAGE}`),
            icon: <TheftAndDamageIcon />,
        },
        { id: ENGINE, name: t(`motor.parked.claimCause.select.${ENGINE}`), icon: <EngineDamageIcon /> },
        { id: PARKING, name: t(`motor.parked.claimCause.select.${PARKING}`), icon: <ParkedIcon /> },
        { id: FIRE, name: t(`motor.parked.claimCause.select.${FIRE}`), icon: <FireDamagesIcon /> },
        { id: OTHER, name: t(`motor.parked.claimCause.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    const claimCauseList = selectClaimCauseList(state);
    const claimCause = selectClaimCause(state);

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE,
        stateKey: 'claimCauseList',
        i18n: {
            ns: 'motor.parked.claimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, motorActions.update, {
                    claimCauseList: claimCauseListUtil(
                        [...claimCauseList],
                        MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE,
                        option.id
                    ),
                });

                dispatch(wizardActions.skipBackToPrev(true));
                dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id, flow: option.id })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
        isSelected: (id) => {
            return claimCauseSelected(id as MotorClaimCauseTypeModel, claimCause);
        },
        testInitState: (key) => ({
            claimCauseList: [{ claimCause: key }],
            claimCause: key,
        }),
    };
};
