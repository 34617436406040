import { AppState } from '../store';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import { CarouselConfigLookupResponse, is, LobKeys, Nullable } from '@protectorinsurance/ds-can';
import { motorCarouselConfigPages } from '../config/carouselConfigPages/motor/motorCarouselConfigPages';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { personCarouselConfigPages } from '../config/carouselConfigPages/person/personCarouselConfigPages';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import { lpoCarouselConfigPages } from '../config/carouselConfigPages/lpo/lpoCarouselConfigPages';

export interface CarouselConfigLookupProps {
    slug: string | undefined;
    t: TFunction;
    dispatch: Dispatch;
    state: AppState;
    lob: Nullable<LobKeys>;
}

export const carouselConfigLookup = (props: CarouselConfigLookupProps): CarouselConfigLookupResponse | null => {
    const { slug, lob } = props;
    if (!slug) return null;

    let routes: MotorRoutePaths | PersonRoutePaths | LpoRoutePaths = LpoRoutePaths.DYN_BASE;
    let pages = lpoCarouselConfigPages(props);
    if (is(lob, LobKeys.AUTO)) {
        routes = MotorRoutePaths.DYN_BASE;
        pages = motorCarouselConfigPages(props);
    }

    if (is(lob, LobKeys.PERSON)) {
        routes = PersonRoutePaths.DYN_BASE;
        pages = personCarouselConfigPages(props);
    }

    const page = pages.find((page) => page.slug.replace(routes, '') === slug);

    return page ? page : null;
};
