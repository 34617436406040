import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { useI18n } from '../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    DamageAddressModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectDamageAddress, selectLifeDamageMunicipality } from '../../../../sagas/selectors/personSelectors';
import { damageAddressSchema } from '../../../../validations/schemas/damageAddressSchema';
import { selectMunicipalityService } from '../../../../sagas/selectors/municipalitySelectors';
import { municipalityServiceActions } from 'sagas/services/municipalityService';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { DAMAGE_ADDRESS, LIFE_DAMAGE_MUNICIPALITY } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DAMAGE_ADDRESS_LABEL,
    DAMAGE_ADDRESS_PLACEHOLDER,
    HELP_TEXT,
    LIFE_DAMAGE_MUNICIPALITY_LABEL,
    LIFE_DAMAGE_MUNICIPALITY_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const WorkersCompIllnessDamageAddressPage = () => {
    const dispatch = useDispatch();
    const municipalityService = useSelector(selectMunicipalityService);
    const damageAddress = useSelector(selectDamageAddress);
    const lifeDamageMunicipality = useSelector(selectLifeDamageMunicipality);
    const customCAN = useSelector(selectCustomCAN);
    const [municipality, setMunicipality] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n(`lob.person.workersComp.illness.damageAddress`);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<DamageAddressModel>({
        resolver: yupResolver(damageAddressSchema(t)),
        defaultValues: {
            damageAddress,
        },
    });
    const options = municipalityService.map((obj) => {
        return { label: obj.name, value: obj.external_id };
    });

    useEffect(() => {
        dispatch(municipalityServiceActions.request());
    }, [dispatch]);

    useEffect(() => {
        const selected = options.find((x) => Number(x.value) === lifeDamageMunicipality?.id);
        if (selected) {
            setMunicipality(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lifeDamageMunicipality, setMunicipality]);

    const handleBackButton = useGoBack();

    const handleSelectBlur = async (e: FormChangeable) => {
        e.preventDefault();
        if (!lifeDamageMunicipality?.id) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        const key = object ? object.label : null;
        setError('');
        setMunicipality(object);
        dispatch(personActions.update({ lifeDamageMunicipality: { id: Number(value), propertyId: 29, key } }));
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: DamageAddressModel) => {
        if (!lifeDamageMunicipality?.id) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, personActions.update, {
                damageAddress: values.damageAddress,
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={DAMAGE_ADDRESS}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.damageAddress}
                                errorMessage={errors.damageAddress?.message}
                                id={DAMAGE_ADDRESS}
                                inputFieldWrapper={'col-6'}
                                label={t(DAMAGE_ADDRESS_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(DAMAGE_ADDRESS_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!error}
                        errorMessage={error}
                        id={LIFE_DAMAGE_MUNICIPALITY}
                        inputFieldWrapper={'col-6'}
                        label={t(LIFE_DAMAGE_MUNICIPALITY_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleSelectBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(LIFE_DAMAGE_MUNICIPALITY_PLACEHOLDER)}
                        value={municipality}
                        {...{ customCAN, options }}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
