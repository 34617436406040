import React from 'react';
import { PersonState } from '../../../../sagas/person';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { DisplayStartSituationSummary } from '../../start/DisplayStartSituationSummary';
import { FileList, FileModel, isGreater, isYes, SummaryItem } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow, trueFalseToYesNo } from 'utils/displayUtils';
import { dtParseAndFormat } from '../../../../utils/date/dateUtils';
import { SummaryProps } from '../../../../interfaces/types/SummaryTypes';
import { SummaryArrayItem } from '../../../summaryArrayItem/SummaryArrayItem';

interface DisplayHealthAccidentSituationSummaryProps extends SummaryProps {
    model: PersonState;
    receipts: FileModel[];
    attachments: FileModel[];
}

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_APPLIED_KELA,
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_ATTACHMENTS,
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_EXPENSES,
    DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_HEALTH_ACCIDENT_TYPE_OF_DOCTOR,
    DYN_CAROUSEL_HEALTH_ACCIDENT_WHO_PAID_EXPENSES,
    DYN_CAROUSEL_HEALTH_ACCIDENT_CLAIM_REPORTER_ROLE,
    DYN_CAROUSEL_HEALTH_CATEGORY,
    DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
    INDEX,
    START_CLAIM_NUMBER_INFORMATION,
    H_A_PRIVACY,
    H_A_CLAIM_DATE,
    H_A_NATURE_TYPE_ID,
    H_A_BODY_PARTS,
    H_A_OTHER_INSURANCE_COMPANY,
    H_A_UPLOAD_RECEIPT,
    H_A_UPLOAD_ATTACHMENT,
} = PersonRoutePaths;

export const DisplayHealthAccidentSituationSummary = ({
    model,
    receipts,
    attachments,
    t,
    handleClick,
}: DisplayHealthAccidentSituationSummaryProps) => {
    /**
     * Helpers
     */
    const {
        hasExistingClaim,
        claimNumber,
        insuranceType,
        category,
        claimReporterRole,
        acceptedPrivacy,
        claimDate,
        natureTypeId,
        bodyPartList,
        hasAppliedKela,
        otherInsurance,
        otherInsuranceCompany,
        hasExpenses,
        whoPaidExpenses,
        typeOfDoctor,
        hasAttachment,
    } = model;

    const renderClaimReport = () => {
        return (
            <DisplayStartSituationSummary
                hasExistingClaim={{
                    showExistingClaim: isYes(hasExistingClaim),
                    textKey: t('lob.person.health.accident.situationSummary.hasExistingClaim.heading.title'),
                    buttonText: t(createTextYesNoDontKnow(hasExistingClaim)),
                    path: DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
                    handleClick,
                }}
                claimNumber={{
                    textKey: t('lob.person.health.accident.situationSummary.claimNumberInformation.heading.title'),
                    buttonText: claimNumber ? claimNumber : '',
                    path: START_CLAIM_NUMBER_INFORMATION,
                    handleClick,
                }}
                insuranceType={{
                    textKey: t('lob.person.health.accident.situationSummary.insuranceType.heading.title'),
                    buttonText: t(`lob.person.start.insuranceType.select.${insuranceType}`),
                    path: INDEX,
                    handleClick,
                }}
                category={{
                    textKey: t('lob.person.health.accident.situationSummary.category.heading.title'),
                    buttonText: t(`lob.person.health.category.select.${category}`),
                    path: DYN_CAROUSEL_HEALTH_CATEGORY,
                    handleClick,
                }}
                claimReporterRole={{
                    textKey: t('lob.person.health.accident.situationSummary.claimReporterRole.heading.title'),
                    buttonText: t(`lob.person.health.accident.claimReporterRole.select.${claimReporterRole}`),
                    path: DYN_CAROUSEL_HEALTH_ACCIDENT_CLAIM_REPORTER_ROLE,
                    handleClick,
                }}
            />
        );
    };

    const renderPrivacy = () => {
        return (
            <>
                {/* Privacy */}
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.health.accident.situationSummary.privacy.heading.title')}
                    dataTestId={TEST_ID_PREFIX.acceptedPrivacy}
                    buttonText={t(trueFalseToYesNo(acceptedPrivacy))}
                    path={H_A_PRIVACY}
                    handleClick={handleClick}
                />
            </>
        );
    };

    const renderClaim = () => {
        return (
            <>
                {/* Claim Date */}
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.health.accident.situationSummary.claimDate.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimDate}
                    buttonText={claimDate ? dtParseAndFormat(claimDate) : ''}
                    path={H_A_CLAIM_DATE}
                    handleClick={handleClick}
                />

                {/* Type Of Accident */}
                <SummaryItem
                    inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                    textKey={t('lob.person.health.accident.situationSummary.natureTypeId.heading.title')}
                    dataTestId={TEST_ID_PREFIX.natureTypeId}
                    buttonText={t(`lob.person.health.accident.natureTypeId.select.${natureTypeId}`)}
                    path={H_A_NATURE_TYPE_ID}
                    handleClick={handleClick}
                />
            </>
        );
    };

    const renderBody = () => {
        return (
            <>
                {/* Body Parts */}
                {isGreater(bodyPartList.length, 0) && (
                    <div className={'col-12 divider align-button-start text-align-left'}>
                        <SummaryArrayItem
                            labelTextKey={t('lob.person.health.accident.situationSummary.bodyParts.heading.title')}
                            name={TEST_ID_PREFIX.bodyParts}
                            list={bodyPartList}
                            textItemKeyPrefix={'lob.person.health.accident.situationSummary.bodyParts.parts'}
                            textItemKeyPostfix={'label'}
                            path={H_A_BODY_PARTS}
                            handleClick={handleClick}
                            show={true}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderKela = () => {
        return (
            <>
                {/* Has Applied Kela */}
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.health.accident.situationSummary.hasAppliedKela.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasAppliedKela}
                    buttonText={t(createTextYesNoDontKnow(hasAppliedKela))}
                    path={DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_APPLIED_KELA}
                    handleClick={handleClick}
                />
            </>
        );
    };

    const renderOtherInsurance = () => {
        return (
            <>
                {/* Has Other Insurance */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(otherInsurance) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.health.accident.situationSummary.hasOtherInsurance.heading.title')}
                    dataTestId={TEST_ID_PREFIX.otherInsurance}
                    buttonText={t(createTextYesNoDontKnow(otherInsurance))}
                    path={DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_OTHER_INSURANCE}
                    handleClick={handleClick}
                />

                {/* Other Insurance Company */}
                {isYes(otherInsurance) && (
                    <SummaryItem
                        inputWrapperClass={'col-8  divider align-button-start text-align-left'}
                        textKey={t('lob.person.health.accident.situationSummary.otherInsuranceCompany.heading.title')}
                        dataTestId={TEST_ID_PREFIX.otherInsuranceCompany}
                        buttonText={otherInsuranceCompany ? otherInsuranceCompany : ''}
                        path={H_A_OTHER_INSURANCE_COMPANY}
                        handleClick={handleClick}
                    />
                )}
            </>
        );
    };

    const renderExpenses = () => {
        return (
            <>
                {/* Has Expenses */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasExpenses) ? 'col-4 spacing' : 'col-12 divider'
                    } align-button-start text-align-left`}
                    textKey={t('lob.person.health.accident.situationSummary.hasExpenses.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasExpenses}
                    buttonText={t(createTextYesNoDontKnow(hasExpenses))}
                    path={DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_EXPENSES}
                    handleClick={handleClick}
                />

                {isYes(hasExpenses) && (
                    <>
                        {/* Type Of Doctor */}
                        <SummaryItem
                            inputWrapperClass={'col-4 spacing align-button-start text-align-left'}
                            textKey={t('lob.person.health.accident.situationSummary.typeOfDoctor.heading.title')}
                            dataTestId={TEST_ID_PREFIX.typeOfDoctor}
                            buttonText={t(`lob.person.health.accident.typeOfDoctor.select.${typeOfDoctor}`)}
                            path={DYN_CAROUSEL_HEALTH_ACCIDENT_TYPE_OF_DOCTOR}
                            handleClick={handleClick}
                        />

                        {/* Who Paid Expenses */}
                        <SummaryItem
                            inputWrapperClass={'col-4 spacing align-button-start text-align-left'}
                            textKey={t('lob.person.health.accident.situationSummary.whoPaidExpenses.heading.title')}
                            dataTestId={TEST_ID_PREFIX.whoPaidExpenses}
                            buttonText={t(`lob.person.health.accident.whoPaidExpenses.select.${whoPaidExpenses}`)}
                            path={DYN_CAROUSEL_HEALTH_ACCIDENT_WHO_PAID_EXPENSES}
                            handleClick={handleClick}
                        />

                        {/* List of Receipts */}
                        <div className={'col-12 divider align-button-start text-align-left'}>
                            <FileList
                                list={receipts}
                                path={H_A_UPLOAD_RECEIPT}
                                show={receipts.length > 0}
                                dataTestId={TEST_ID_PREFIX.uploadReceipt}
                                textKey={`${t(
                                    'lob.person.health.accident.situationSummary.uploadReceipt.heading.title'
                                )}`}
                                handleClick={handleClick}
                            />{' '}
                        </div>
                    </>
                )}
            </>
        );
    };

    const renderAttachments = () => {
        return (
            <>
                {/* Has Attachments */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasAttachment) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.health.accident.situationSummary.hasAttachments.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasAttachments}
                    buttonText={t(createTextYesNoDontKnow(hasAttachment))}
                    path={DYN_CAROUSEL_HEALTH_ACCIDENT_HAS_ATTACHMENTS}
                    handleClick={handleClick}
                />

                {/* List of Attachments */}
                {isYes(hasAttachment) && (
                    <div className={'col-8 divider align-button-start text-align-left'}>
                        <FileList
                            list={attachments}
                            path={H_A_UPLOAD_ATTACHMENT}
                            show={attachments.length > 0}
                            dataTestId={TEST_ID_PREFIX.uploadAttachments}
                            textKey={`${t(
                                'lob.person.health.accident.situationSummary.uploadAttachment.heading.title'
                            )}`}
                            handleClick={handleClick}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {renderClaimReport()}

            {renderPrivacy()}

            {renderClaim()}

            {renderBody()}

            {renderKela()}

            {renderOtherInsurance()}

            {renderExpenses()}

            {renderAttachments()}
        </>
    );
};
