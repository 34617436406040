import { combineReducers } from 'redux';

import maps, { MapsServiceAction, MapsServiceActionTypes, MapsServiceState, mapsServiceWatcher } from './mapsService';
import bankIDJWTService, {
    BankIDJWTServiceAction,
    BankIDJWTServiceActionTypes,
    BankIDJWTState,
    bankIDJWTWatcher,
} from './bankIDJWTService';
import {
    PDFServiceAction,
    PDFServiceActionTypes,
    pdfServiceReducer,
    PDFServiceState,
    pdfServiceWatcher,
    TogglesServiceAction,
    TogglesServiceActionTypes,
    togglesServiceReducer,
    TogglesServiceState,
    togglesServiceWatcher,
    VehicleServiceAction,
    VehicleServiceActionTypes,
    vehicleServiceReducer,
    VehicleState,
    vehicleWatcher,
} from '@protectorinsurance/ds-can';
import municipalityService, {
    MunicipalityServiceAction,
    MunicipalityServiceActionTypes,
    MunicipalityServiceState,
    municipalityServiceWatchers,
} from './municipalityService';
import { LogServiceActions, LogServiceActionTypes, logServiceWatchers } from './logService';

export type ServicesActionTypes =
    | VehicleServiceActionTypes
    | MapsServiceActionTypes
    | BankIDJWTServiceActionTypes
    | PDFServiceActionTypes
    | TogglesServiceActionTypes
    | MunicipalityServiceActionTypes
    | LogServiceActionTypes;

export type ServicesActions =
    | VehicleServiceAction
    | MapsServiceAction
    | BankIDJWTServiceAction
    | PDFServiceAction
    | TogglesServiceAction
    | MunicipalityServiceAction
    | LogServiceActions;

export interface ServicesState {
    vehicleService: VehicleState;
    maps: MapsServiceState;
    bankIDJWTService: BankIDJWTState;
    pdf: PDFServiceState;
    togglesService: TogglesServiceState;
    municipalityService: MunicipalityServiceState;
}

/**
 * Export namespace watchers
 */
export const servicesWatchers = [
    vehicleWatcher,
    mapsServiceWatcher,
    bankIDJWTWatcher,
    pdfServiceWatcher,
    togglesServiceWatcher,
    municipalityServiceWatchers,
    logServiceWatchers,
];

/**
 * Export combined reducers as default
 */
export default combineReducers({
    vehicleService: vehicleServiceReducer,
    maps,
    bankIDJWTService,
    pdf: pdfServiceReducer,
    togglesService: togglesServiceReducer,
    municipalityService,
});
