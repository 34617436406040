import { AppState } from '../../store';

export const selectPersonState = (state: AppState) => state.person;

// Health selectors
export const selectHasExistingClaim = (state: AppState) => state.person.hasExistingClaim;
export const selectClaimNumber = (state: AppState) => state.person.claimNumber;
export const selectInsuranceType = (state: AppState) => state.person.insuranceType;
export const selectCategory = (state: AppState) => state.person.category;
export const selectClaimReporterRole = (state: AppState) => state.person.claimReporterRole;
export const selectAcceptedPrivacy = (state: AppState) => state.person.acceptedPrivacy;
export const selectClaimDate = (state: AppState) => state.person.claimDate;
export const selectNatureTypeId = (state: AppState) => state.person.natureTypeId;
export const selectHasDiagnosisCode = (state: AppState) => state.person.hasDiagnosisCode;
export const selectDiagnosisCode = (state: AppState) => state.person.diagnosisCode;
export const selectHasAppliedKela = (state: AppState) => state.person.hasAppliedKela;
export const selectOtherInsurance = (state: AppState) => state.person.otherInsurance;
export const selectOtherInsuranceCompany = (state: AppState) => state.person.otherInsuranceCompany;
export const selectHasExpenses = (state: AppState) => state.person.hasExpenses;
export const selectWhoPaidExpenses = (state: AppState) => state.person.whoPaidExpenses;
export const selectTypeOfDoctor = (state: AppState) => state.person.typeOfDoctor;
export const selectHasAttachment = (state: AppState) => state.person.hasAttachment;
export const selectClaimDescription = (state: AppState) => state.person.claimDescription;
export const selectCompanyInformation = (state: AppState) => state.person.companyInformation;
export const selectClaimantInformation = (state: AppState) => state.person.claimantInformation;
export const selectReporterInformation = (state: AppState) => state.person.reporterInformation;
export const selectBankAccountInformation = (state: AppState) => state.person.bankAccountInformation;
export const selectBankAccountContactInformation = (state: AppState) => state.person.bankAccountContactInformation;

// Group Accident
export const selectClaimantAgeCategory = (state: AppState) => state.person.claimantAgeCategory;
export const selectClaimLocation = (state: AppState) => state.person.claimLocation;
export const selectClaimCause = (state: AppState) => state.person.claimCause;
export const selectHasAppliedMedicalCare = (state: AppState) => state.person.hasAppliedMedicalCare;

// Workers Comp
export const selectClaimantRole = (state: AppState) => state.person.claimantRole;
export const selectReportedToEmployerDate = (state: AppState) => state.person.reportedToEmployerDate;
export const selectTypeOfCause = (state: AppState) => state.person.typeOfCause;
export const selectOdCause = (state: AppState) => state.person.odCause;
export const selectCausedIncapacity = (state: AppState) => state.person.causedIncapacity;
export const selectLocationType = (state: AppState) => state.person.locationType;
export const selectIncapacityPeriods = (state: AppState) => state.person.incapacityPeriods;
export const selectDateOfAppliedMedicalCare = (state: AppState) => state.person.dateOfAppliedMedicalCare;
export const selectMedicalInstitution = (state: AppState) => state.person.medicalInstitution;
export const selectInjuredProfession = (state: AppState) => state.person.injuredProfession;
export const selectEntrepreneurOwnership = (state: AppState) => state.person.entrepreneurOwnership;
export const selectHasCompanyShares = (state: AppState) => state.person.hasCompanyShares;
export const selectClaimantPosition = (state: AppState) => state.person.claimantPosition;
export const selectPolicyHoldersContact = (state: AppState) => state.person.policyHoldersContact;
export const selectStudyLocation = (state: AppState) => state.person.studyLocation;
export const selectTypeOfStudies = (state: AppState) => state.person.typeOfStudies;
export const selectOccupationalCondition = (state: AppState) => state.person.occupationalCondition;
export const selectDamageAddress = (state: AppState) => state.person.damageAddress;
export const selectLifeDamageMunicipality = (state: AppState) => state.person.lifeDamageMunicipality;
export const selectClaimantWorkDay = (state: AppState) => state.person.claimantWorkDay;
export const selectBodyPartsList = (state: AppState) => state.person.bodyPartList;
export const selectIsVehicleInvolved = (state: AppState) => state.person.isVehicleInvolved;
export const selectTypeOfVehicle = (state: AppState) => state.person.typeOfVehicle;
export const selectRegistrationNumber = (state: AppState) => state.person.registrationNumber;
export const selectIsPoliceContacted = (state: AppState) => state.person.isPoliceContacted;
export const selectPoliceDistrict = (state: AppState) => state.person.policeDistrict;
export const selectUnderInfluence = (state: AppState) => state.person.underInfluence;
export const selectUnderInfluenceClaimDescription = (state: AppState) => state.person.underInfluenceClaimDescription;
export const selectIncapacityMoreThanSevenDays = (state: AppState) => state.person.incapacityMoreThanSevenDays;
export const selectIncapacityPeriod = (state: AppState) => state.person.incapacityPeriod;
export const selectOccupation = (state: AppState) => state.person.occupation;
