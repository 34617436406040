import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { ClaimantRoleEnums, Clickable, is, PageLayout, StartSummary } from '@protectorinsurance/ds-can';
import { selectClaimantRole } from '../../../../sagas/selectors/personSelectors';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { STUDENT } = ClaimantRoleEnums;
const { DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const WorkersCompAccidentOnboardingPage = () => {
    const dispatch = useDispatch();
    const claimantRole = useSelector(selectClaimantRole);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.accident.onboarding');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        let nextAction = wizardActions.goToNext();
        if (is(claimantRole, STUDENT)) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION);
        }
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <StartSummary
                avgMin={tWithNS.t('avgMin')}
                description={tWithNS.t('description')}
                sessionText={tWithNS.t('sessionInfo')}
                subTitle={tWithNS.t('body.subTitle')}
                title={tWithNS.t('body.title')}
                {...{ customCAN }}
            />
        </PageLayout>
    );
};
