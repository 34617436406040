import { LabelDirectionEnums } from '../../interfaces/enums/LabelDirection.enums';
import { is, LanguageCodeEnums } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { LEFT_ENGLISH, LEFT_FINNISH, LEFT_SWEDISH, RIGHT_ENGLISH, RIGHT_FINNISH, RIGHT_SWEDISH } = LabelDirectionEnums;
const { FI, SE } = LanguageCodeEnums;

/**
 * Function logic
 */
export const getLabelDirection = (language: string) => {
    if (is(language, FI)) {
        return { left: LEFT_FINNISH, right: RIGHT_FINNISH };
    }

    if (is(language, SE)) {
        return { left: LEFT_SWEDISH, right: RIGHT_SWEDISH };
    }

    return { left: LEFT_ENGLISH, right: RIGHT_ENGLISH };
};
