import { put, takeLatest } from 'redux-saga/effects';
import { wizardRouterActions } from './wizardRouter';
import { isCypress, isProd, isTest } from 'utils/env';
import { BaseRoutePaths } from '../config/wizardRouter/baseWizardRoutes';
import { commonActions } from './common';
import { actionWithPromise } from '@protectorinsurance/ds-can';

/**
 * Constants
 */
export enum InitActionTypes {
    INIT = '@app/INIT',
}

/**
 * Redux Actions
 */
export const initActions = {
    init: actionWithPromise(InitActionTypes.INIT),
};

/**
 * Saga watchers
 */
export const initWatcher = function* () {
    yield takeLatest(InitActionTypes.INIT, initSagas.init);
};

/**
 * Saga functions
 */
export const initSagas = {
    *init() {
        yield put(commonActions.init());

        // Run on PROD, CYPRESS and TEST.
        if (isProd() || isCypress() || isTest()) {
            yield put(wizardRouterActions.goTo(BaseRoutePaths.DEFAULT_INDEX));
        }
    },
};
