import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';

// Dyn imports
import { DynCarouselPage } from '../pages/DynCarouselPage';
import { DynFinalSummaryPage } from '../pages/person/dynamic/DynFinalSummary';
import { DynReportCompletedPage } from '../pages/person/dynamic/DynReportCompleted';

// Start imports
import { WelcomePage } from '../pages/person/start/Welcome';
import { ClaimNumberInformationPage } from '../pages/person/start/ClaimNumberInformation';
import { StopPage } from '../pages/person/start/Stop';

// Health / Accident imports
import { AccidentOnboardingPage as HAOnboardingPage } from '../pages/person/health/accident/Onboarding';
import { AccidentPrivacyPage as HAPrivacyPage } from '../pages/person/health/accident/Privacy';
import { AccidentClaimDatePage as HAClaimDatePage } from '../pages/person/health/accident/ClaimDate';
import { AccidentNatureTypeIdPage as HANatureTypeIdPage } from '../pages/person/health/accident/NatureTypeId';
import { AccidentBodyPartsListPage as HABodyPartsPage } from '../pages/person/health/accident/BodyPartsList';
import { AccidentOtherInsuranceCompanyPage as HAOtherInsuranceCompanyPage } from '../pages/person/health/accident/OtherInsuranceCompany';
import { AccidentUploadReceiptPage as HAUploadReceiptPage } from '../pages/person/health/accident/UploadReceipt';
import { AccidentUploadAttachmentPage as HAUploadAttachmentPage } from '../pages/person/health/accident/UploadAttachment';
import { AccidentSituationSummaryPage as HASituationSummaryPage } from '../pages/person/health/accident/SituationSummary';
import { AccidentCompanyInformationPage as HACompanyInformationPage } from '../pages/person/health/accident/CompanyInformation';
import { AccidentClaimantInformationPage as HAClaimantInformationPage } from '../pages/person/health/accident/ClaimantInformation';
import { AccidentReporterInformationPage as HAReporterInformationPage } from '../pages/person/health/accident/ReporterInformation';
import { AccidentBankAccountInformationPage as HABankAccountInformationPage } from '../pages/person/health/accident/BankAccountInformation';
import { AccidentBankAccountContactInformationPage as HABankAccountContactInformationPage } from '../pages/person/health/accident/BankAccountContactInformation';

// Health / Illness imports
import { IllnessOnboardingPage as HIOnboardingPage } from '../pages/person/health/illness/Onboarding';
import { IllnessPrivacyPage as HIPrivacyPage } from '../pages/person/health/illness/Privacy';
import { IllnessClaimDatePage as HIClaimDatePage } from '../pages/person/health/illness/ClaimDate';
import { IllnessDiagnosisCodeInformationPage as HIDiagnosisCodeInformationPage } from '../pages/person/health/illness/DiagnosisCodeInformation';
import { IllnessOtherInsuranceCompanyPage as HIOtherInsuranceCompanyPage } from '../pages/person/health/illness/OtherInsuranceCompany';
import { IllnessUploadReceiptPage as HIUploadReceiptPage } from '../pages/person/health/illness/UploadReceipt';
import { IllnessUploadAttachmentPage as HIUploadAttachmentPage } from '../pages/person/health/illness/UploadAttachment';
import { IllnessSituationSummaryPage as HISituationSummaryPage } from '../pages/person/health/illness/SituationSummary';
import { IllnessCompanyInformationPage as HICompanyInformationPage } from '../pages/person/health/illness/CompanyInformation';
import { IllnessClaimantInformationPage as HIClaimantInformationPage } from '../pages/person/health/illness/ClaimantInformation';
import { IllnessReporterInformationPage as HIReporterInformationPage } from '../pages/person/health/illness/ReporterInformation';
import { IllnessBankAccountInformationPage as HIBankAccountInformationPage } from '../pages/person/health/illness/BankAccountInformation';
import { IllnessBankAccountContactInformationPage as HIBankAccountContactInformationPage } from '../pages/person/health/illness/BankAccountContactInformation';

// Group Accident
import { GroupAccidentOnboardingPage } from '../pages/person/groupAccident/Onboarding';
import { GroupAccidentPrivacyPage } from '../pages/person/groupAccident/Privacy';
import { GroupAccidentClaimDatePage } from '../pages/person/groupAccident/ClaimDate';
import { GroupAccidentNatureTypeIdPage } from '../pages/person/groupAccident/NatureTypeId';
import { GroupAccidentClaimCausePage } from '../pages/person/groupAccident/ClaimCause';
import { GroupAccidentBodyPartsListPage } from '../pages/person/groupAccident/BodyPartsList';
import { GroupAccidentUploadReceiptPage } from '../pages/person/groupAccident/UploadReceipt';
import { GroupAccidentOtherInsuranceCompanyPage } from '../pages/person/groupAccident/OtherInsuranceCompany';
import { GroupAccidentSituationSummaryPage } from '../pages/person/groupAccident/SituationSummary';
import { GroupAccidentCompanyInformationPage } from '../pages/person/groupAccident/CompanyInformation';
import { GroupAccidentClaimantInformationPage } from '../pages/person/groupAccident/ClaimantInformation';
import { GroupAccidentReporterInformationPage } from '../pages/person/groupAccident/ReporterInformation';
import { GroupAccidentBankAccountInformationPage } from '../pages/person/groupAccident/BankAccountInformation';
import { GroupAccidentBankAccountContactInformationPage } from '../pages/person/groupAccident/BankAccountContactInformation';

// Workers Comp / Illness Imports
import { WorkersCompIllnessOnboardingPage } from '../pages/person/workersComp/illness/Onboarding';
import { WorkersCompIllnessClaimDatePage } from '../pages/person/workersComp/illness/ClaimDate';
import { WorkersCompIllnessReportedToEmployerDatePage } from '../pages/person/workersComp/illness/ReportedToEmployerDate';
import { WorkersCompIllnessDamageAddressPage } from '../pages/person/workersComp/illness/DamageAddress';
import { WorkersCompIllnessChemicalFactorPage } from '../pages/person/workersComp/illness/ChemicalFactor';
import { WorkersCompIllnessIncapacityPeriodsPage } from '../pages/person/workersComp/illness/IncapacityPeriods';
import { WorkersCompIllnessDateOfAppliedMedicalCarePage } from '../pages/person/workersComp/illness/DateOfAppliedMedicalCare';
import { WorkersCompIllnessMedicalInstitution } from '../pages/person/workersComp/illness/MedicalInstitution';
import { WorkersCompIllnessUploadReceiptPage } from '../pages/person/workersComp/illness/UploadReceipt';
import { WorkersCompIllnessSituationSummaryPage } from '../pages/person/workersComp/illness/SituationSummary';
import { WorkersCompIllnessCompanyInformationPage } from '../pages/person/workersComp/illness/CompanyInformation';
import { WorkersCompIllnessClaimantInformationPage } from '../pages/person/workersComp/illness/ClaimantInformation';
import { WorkersCompIllnessWorkplacePage } from '../pages/person/workersComp/illness/Workplace';
import { WorkersCompIllnessInjuredProfessionPage } from '../pages/person/workersComp/illness/InjuredProfession';
import { WorkersCompIllnessReporterInformationPage } from '../pages/person/workersComp/illness/ReporterInformation';
import { WorkersCompIllnessEntrepreneurOwnershipPage } from '../pages/person/workersComp/illness/EntrepreneurOwnership';
import { WorkersCompIllnessClaimantPositionPage } from '../pages/person/workersComp/illness/ClaimantPosition';
import { WorkersCompIllnessPolicyHoldersContactPage } from '../pages/person/workersComp/illness/PolicyHoldersContact';
import { WorkersCompIllnessBankAccountInformationPage } from '../pages/person/workersComp/illness/BankAccountInformation';
import { WorkersCompIllnessBankAccountContactInformationPage } from 'pages/person/workersComp/illness/BankAccountContactInformation';

// Workers Comp / Accident Imports
import { WorkersCompAccidentOnboardingPage } from '../pages/person/workersComp/accident/Onboarding';
import { WorkersCompAccidentDamageAddressPage } from '../pages/person/workersComp/accident/DamageAddress';
import { WorkersCompAccidentWorkplacePage } from '../pages/person/workersComp/accident/Workplace';
import { WorkersCompAccidentClaimDatePage } from '../pages/person/workersComp/accident/ClaimDate';
import { WorkersCompAccidentReportedToEmployerDatePage } from '../pages/person/workersComp/accident/ReportedToEmployerDate';
import { WorkersCompAccidentClaimantWorkDayPage } from '../pages/person/workersComp/accident/ClaimantWorkDay';
import { WorkersCompAccidentNatureTypeIdPage } from '../pages/person/workersComp/accident/NatureTypeId';
import { WorkersCompAccidentBodyPartsListPage } from '../pages/person/workersComp/accident/BodyPartsList';
import { WorkersCompAccidentVehicleInformationPage } from '../pages/person/workersComp/accident/VehicleInformation';
import { WorkersCompAccidentPoliceDistrictPage } from '../pages/person/workersComp/accident/PoliceDistrict';
import { WorkersCompAccidentUnderInfluenceClaimDescriptionPage } from '../pages/person/workersComp/accident/UnderInfluenceClaimDescription';
import { WorkersCompAccidentIncapacityPeriodsPage } from '../pages/person/workersComp/accident/IncapacityPeriods';
import { WorkersCompAccidentDateOfAppliedMedicalCarePage } from '../pages/person/workersComp/accident/DateOfAppliedMedicalCare';
import { WorkersCompAccidentMedicalInstitution } from '../pages/person/workersComp/accident/MedicalInstitution';
import { WorkersCompAccidentUploadReceiptPage } from '../pages/person/workersComp/accident/UploadReceipt';
import { WorkersCompAccidentOtherInsuranceCompanyPage } from '../pages/person/workersComp/accident/OtherInsuranceCompany';
import { WorkersCompAccidentSituationSummaryPage } from '../pages/person/workersComp/accident/SituationSummary';
import { WorkersCompAccidentCompanyInformationPage } from '../pages/person/workersComp/accident/CompanyInformation';
import { WorkersCompAccidentClaimantInformationPage } from '../pages/person/workersComp/accident/ClaimantInformation';
import { WorkersCompAccidentInjuredProfessionPage } from 'pages/person/workersComp/accident/InjuredProfession';
import { WorkersCompAccidentEntrepreneurOwnershipPage } from '../pages/person/workersComp/accident/EntrepreneurOwnership';
import { WorkersCompAccidentClaimantPositionPage } from 'pages/person/workersComp/accident/ClaimantPosition';
import { WorkersCompAccidentReporterInformationPage } from '../pages/person/workersComp/accident/ReporterInformation';
import { WorkersCompAccidentPolicyHoldersContactPage } from '../pages/person/workersComp/accident/PolicyHoldersContact';
import { WorkersCompAccidentBankAccountInformationPage } from '../pages/person/workersComp/accident/BankAccountInformation';
import { WorkersCompAccidentBankAccountContactInformationPage } from '../pages/person/workersComp/accident/BankAccountContactInformation';

export const Person: FC = () => {
    return (
        <Switch>
            {/* DYN */}
            <Route exact path={PersonRoutePaths.DYN_CAROUSEL} component={DynCarouselPage} />

            {/* START */}
            <Route exact path={PersonRoutePaths.INDEX} component={WelcomePage} />
            <Route exact path={PersonRoutePaths.START_WELCOME} component={WelcomePage} />
            <Route
                exact
                path={PersonRoutePaths.START_CLAIM_NUMBER_INFORMATION}
                component={ClaimNumberInformationPage}
            />

            {/* STOP */}
            <Route exact path={PersonRoutePaths.STOP} component={StopPage} />

            {/* HEALTH / ACCIDENT */}
            <Route exact path={PersonRoutePaths.H_A_ONBOARDING} component={HAOnboardingPage} />
            <Route exact path={PersonRoutePaths.H_A_PRIVACY} component={HAPrivacyPage} />
            <Route exact path={PersonRoutePaths.H_A_CLAIM_DATE} component={HAClaimDatePage} />
            <Route exact path={PersonRoutePaths.H_A_NATURE_TYPE_ID} component={HANatureTypeIdPage} />
            <Route exact path={PersonRoutePaths.H_A_BODY_PARTS} component={HABodyPartsPage} />
            <Route exact path={PersonRoutePaths.H_A_OTHER_INSURANCE_COMPANY} component={HAOtherInsuranceCompanyPage} />
            <Route exact path={PersonRoutePaths.H_A_UPLOAD_RECEIPT} component={HAUploadReceiptPage} />
            <Route exact path={PersonRoutePaths.H_A_UPLOAD_ATTACHMENT} component={HAUploadAttachmentPage} />
            <Route exact path={PersonRoutePaths.H_A_SITUATION_SUMMARY} component={HASituationSummaryPage} />
            <Route exact path={PersonRoutePaths.H_A_COMPANY_INFORMATION} component={HACompanyInformationPage} />
            <Route exact path={PersonRoutePaths.H_A_CLAIMANT_INFORMATION} component={HAClaimantInformationPage} />
            <Route exact path={PersonRoutePaths.H_A_REPORTER_INFORMATION} component={HAReporterInformationPage} />
            <Route
                exact
                path={PersonRoutePaths.H_A_BANK_ACCOUNT_INFORMATION}
                component={HABankAccountInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.H_A_BANK_ACCOUNT_CONTACT_INFORMATION}
                component={HABankAccountContactInformationPage}
            />
            <Route exact path={PersonRoutePaths.H_A_FINAL_SUMMARY} component={DynFinalSummaryPage} />
            <Route exact path={PersonRoutePaths.H_A_REPORT_COMPLETED} component={DynReportCompletedPage} />

            {/* HEALTH / ILLNESS */}
            <Route exact path={PersonRoutePaths.H_I_ONBOARDING} component={HIOnboardingPage} />
            <Route exact path={PersonRoutePaths.H_I_PRIVACY} component={HIPrivacyPage} />
            <Route exact path={PersonRoutePaths.H_I_CLAIM_DATE} component={HIClaimDatePage} />
            <Route
                exact
                path={PersonRoutePaths.H_I_DIAGNOSIS_CODE_INFORMATION}
                component={HIDiagnosisCodeInformationPage}
            />
            <Route exact path={PersonRoutePaths.H_I_OTHER_INSURANCE_COMPANY} component={HIOtherInsuranceCompanyPage} />
            <Route exact path={PersonRoutePaths.H_I_UPLOAD_RECEIPT} component={HIUploadReceiptPage} />
            <Route exact path={PersonRoutePaths.H_I_UPLOAD_ATTACHMENT} component={HIUploadAttachmentPage} />
            <Route exact path={PersonRoutePaths.H_I_SITUATION_SUMMARY} component={HISituationSummaryPage} />
            <Route exact path={PersonRoutePaths.H_I_COMPANY_INFORMATION} component={HICompanyInformationPage} />
            <Route exact path={PersonRoutePaths.H_I_CLAIMANT_INFORMATION} component={HIClaimantInformationPage} />
            <Route exact path={PersonRoutePaths.H_I_REPORTER_INFORMATION} component={HIReporterInformationPage} />
            <Route
                exact
                path={PersonRoutePaths.H_I_BANK_ACCOUNT_INFORMATION}
                component={HIBankAccountInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.H_I_BANK_ACCOUNT_CONTACT_INFORMATION}
                component={HIBankAccountContactInformationPage}
            />
            <Route exact path={PersonRoutePaths.H_I_FINAL_SUMMARY} component={DynFinalSummaryPage} />
            <Route exact path={PersonRoutePaths.H_I_REPORT_COMPLETED} component={DynReportCompletedPage} />

            {/* GROUP ACCIDENT */}
            <Route exact path={PersonRoutePaths.GROUP_ACCIDENT_ONBOARDING} component={GroupAccidentOnboardingPage} />
            <Route exact path={PersonRoutePaths.GROUP_ACCIDENT_PRIVACY} component={GroupAccidentPrivacyPage} />
            <Route exact path={PersonRoutePaths.GROUP_ACCIDENT_CLAIM_DATE} component={GroupAccidentClaimDatePage} />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_NATURE_TYPE_ID}
                component={GroupAccidentNatureTypeIdPage}
            />
            <Route exact path={PersonRoutePaths.GROUP_ACCIDENT_CLAIM_CAUSE} component={GroupAccidentClaimCausePage} />
            <Route exact path={PersonRoutePaths.GROUP_ACCIDENT_BODY_PARTS} component={GroupAccidentBodyPartsListPage} />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_UPLOAD_RECEIPT}
                component={GroupAccidentUploadReceiptPage}
            />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_OTHER_INSURANCE_COMPANY}
                component={GroupAccidentOtherInsuranceCompanyPage}
            />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_SITUATION_SUMMARY}
                component={GroupAccidentSituationSummaryPage}
            />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_COMPANY_INFORMATION}
                component={GroupAccidentCompanyInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_CLAIMANT_INFORMATION}
                component={GroupAccidentClaimantInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_REPORTER_INFORMATION}
                component={GroupAccidentReporterInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_BANK_ACCOUNT_INFORMATION}
                component={GroupAccidentBankAccountInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.GROUP_ACCIDENT_BANK_ACCOUNT_CONTACT_INFORMATION}
                component={GroupAccidentBankAccountContactInformationPage}
            />
            <Route exact path={PersonRoutePaths.GROUP_ACCIDENT_FINAL_SUMMARY} component={DynFinalSummaryPage} />
            <Route exact path={PersonRoutePaths.GROUP_ACCIDENT_REPORT_COMPLETED} component={DynReportCompletedPage} />

            {/* WORKERS COMP / ILLNESS */}
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_ONBOARDING}
                component={WorkersCompIllnessOnboardingPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIM_DATE}
                component={WorkersCompIllnessClaimDatePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTED_TO_EMPLOYER_DATE}
                component={WorkersCompIllnessReportedToEmployerDatePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_WORKPLACE}
                component={WorkersCompIllnessWorkplacePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_DAMAGE_ADDRESS}
                component={WorkersCompIllnessDamageAddressPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_TYPE_OF_CHEMICAL_FACTOR}
                component={WorkersCompIllnessChemicalFactorPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_INCAPACITY_PERIODS}
                component={WorkersCompIllnessIncapacityPeriodsPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_DATE_OF_APPLIED_MEDICAL_CARE}
                component={WorkersCompIllnessDateOfAppliedMedicalCarePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION}
                component={WorkersCompIllnessMedicalInstitution}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_UPLOAD_RECEIPT}
                component={WorkersCompIllnessUploadReceiptPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_SITUATION_SUMMARY}
                component={WorkersCompIllnessSituationSummaryPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_COMPANY_INFORMATION}
                component={WorkersCompIllnessCompanyInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIMANT_INFORMATION}
                component={WorkersCompIllnessClaimantInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_INJURED_PROFESSION}
                component={WorkersCompIllnessInjuredProfessionPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_ENTREPRENEUR_OWNERSHIP}
                component={WorkersCompIllnessEntrepreneurOwnershipPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIMANT_POSITION}
                component={WorkersCompIllnessClaimantPositionPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTER_INFORMATION}
                component={WorkersCompIllnessReporterInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_POLICY_HOLDERS_CONTACT}
                component={WorkersCompIllnessPolicyHoldersContactPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_BANK_ACCOUNT_INFORMATION}
                component={WorkersCompIllnessBankAccountInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_BANK_ACCOUNT_CONTACT_INFORMATION}
                component={WorkersCompIllnessBankAccountContactInformationPage}
            />
            <Route exact path={PersonRoutePaths.WORKERS_COMP_ILLNESS_FINAL_SUMMARY} component={DynFinalSummaryPage} />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORT_COMPLETED}
                component={DynReportCompletedPage}
            />

            {/* WORKERS COMP / ACCIDENT */}
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_ONBOARDING}
                component={WorkersCompAccidentOnboardingPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS}
                component={WorkersCompAccidentDamageAddressPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_WORKPLACE}
                component={WorkersCompAccidentWorkplacePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIM_DATE}
                component={WorkersCompAccidentClaimDatePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE}
                component={WorkersCompAccidentReportedToEmployerDatePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_WORK_DAY}
                component={WorkersCompAccidentClaimantWorkDayPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_NATURE_TYPE_ID}
                component={WorkersCompAccidentNatureTypeIdPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_BODY_PARTS_LIST}
                component={WorkersCompAccidentBodyPartsListPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER}
                component={WorkersCompAccidentVehicleInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICE_DISTRICT}
                component={WorkersCompAccidentPoliceDistrictPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE_CLAIM_DESCRIPTION}
                component={WorkersCompAccidentUnderInfluenceClaimDescriptionPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS}
                component={WorkersCompAccidentIncapacityPeriodsPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_DATE_OF_APPLIED_MEDICAL_CARE}
                component={WorkersCompAccidentDateOfAppliedMedicalCarePage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_MEDICAL_INSTITUTION}
                component={WorkersCompAccidentMedicalInstitution}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_UPLOAD_RECEIPT}
                component={WorkersCompAccidentUploadReceiptPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_OTHER_INSURANCE_COMPANY}
                component={WorkersCompAccidentOtherInsuranceCompanyPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_SITUATION_SUMMARY}
                component={WorkersCompAccidentSituationSummaryPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_COMPANY_INFORMATION}
                component={WorkersCompAccidentCompanyInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_INFORMATION}
                component={WorkersCompAccidentClaimantInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_INJURED_PROFESSION}
                component={WorkersCompAccidentInjuredProfessionPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_ENTREPRENEUR_OWNERSHIP}
                component={WorkersCompAccidentEntrepreneurOwnershipPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_POSITION}
                component={WorkersCompAccidentClaimantPositionPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTER_INFORMATION}
                component={WorkersCompAccidentReporterInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICY_HOLDERS_CONTACT}
                component={WorkersCompAccidentPolicyHoldersContactPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_INFORMATION}
                component={WorkersCompAccidentBankAccountInformationPage}
            />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_CONTACT_INFORMATION}
                component={WorkersCompAccidentBankAccountContactInformationPage}
            />
            <Route exact path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_FINAL_SUMMARY} component={DynFinalSummaryPage} />
            <Route
                exact
                path={PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORT_COMPLETED}
                component={DynReportCompletedPage}
            />

            {/* NOT FOUND */}
            <Route path={`${PersonRoutePaths.INDEX}*`} component={WelcomePage} />
        </Switch>
    );
};
