export enum TEST_ID_PREFIX {
    acceptedPoliceContact = 'acceptedPoliceContact',
    acceptedPrivacy = 'accepted-privacy',
    acceptedPrivacyCheck = 'acceptedPrivacy',
    accidentLocation = 'accidentLocation',
    accountNumber = 'accountNumber',
    bankAccountNumber = 'bankAccountNumber',
    bankName = 'bankName',
    bodyParts = 'body-parts',
    bodyPartList = 'bodyPartList',
    businessNumber = 'businessNumber',
    category = 'category',
    causedIncapacity = 'causedIncapacity',
    chassisNumber = 'chassisNumber',
    city = 'city',
    claimantInformation = 'claimantInformation',
    claimantRole = 'claimantRole',
    claimantRoundaboutPositioning = 'claimantRoundaboutPositioning',
    claimCause = 'claimCause',
    claimCauses = 'claimCause-',
    claimCauseDetails = 'claimCauseDetails',
    claimDate = 'claim-date',
    claimDescription = 'claimDescription',
    claimDiscovererInformation = 'claimDiscovererInformation',
    claimLocation = 'claimLocation',
    claimLocationType = 'claimLocationType',
    claimNumber = 'claimNumber',
    claimType = 'claimType',
    claimantAgeCategory = 'claimantAgeCategory',
    claimantWorkDay = 'claimantWorkDay',
    clearingNumber = 'clearingNumber',
    collisionSituation = 'collisionSituation',
    companyInformation = 'companyInformation',
    companyName = 'companyName',
    counterpartyRoundaboutPositioning = 'counterpartyRoundaboutPositioning',
    country = 'country',
    damageAddress = 'damageAddress',
    damagedItem = 'damagedItem',
    dateOfAppliedMedicalCare = 'dateOfAppliedMedicalCare',
    dateOfEmployment = 'dateOfEmployment',
    dateOfTermination = 'dateOfTermination',
    diagnosisCode = 'diagnosisCode',
    departmentName = 'departmentName',
    distanceFromRoadSide = 'distanceFromRoadSide',
    ditchClaimDescription = 'ditchClaimDescription',
    dontKnowBankInformation = 'dontKnowBankInformation-checkbox-input',
    driverInformation = 'driverInformation',
    drivingSpeed = 'drivingSpeed',
    email = 'email',
    employmentRelationship = 'employmentRelationship',
    engineClaimDescription = 'engineClaimDescription',
    externalReference = 'externalReference',
    fireClaimDescription = 'fireClaimDescription',
    firstName = 'firstName',
    freightWeight = 'freightWeight',
    hasAllKeys = 'hasAllKeys',
    hasAnimalEscaped = 'hasAnimalEscaped',
    hasAppliedKela = 'has-applied-kela',
    hasAppliedMedicalCare = 'hasAppliedMedicalCare',
    hasAttachments = 'has-attachments',
    hasDiagnosisCode = 'has-diagnosis-code',
    hasDigitalServiceBook = 'hasDigitalServiceBook',
    hasExistingClaim = 'has-existing-claim',
    hasExpenses = 'has-expenses',
    hasLicense = 'hasLicense',
    hasNonVehicleDamages = 'hasNonVehicleDamages',
    hasOtherInsurance = 'hasOtherInsurance',
    hasPersonInjuries = 'hasPersonInjuries',
    hasVehicleDamages = 'hasVehicleDamages',
    hasWitnesses = 'hasWitnesses',
    incapacityMoreThanSevenDays = 'incapacityMoreThanSevenDays',
    incapacityPeriod = 'incapacityPeriod',
    incapacityPeriodList = 'incapacityPeriodList',
    injuredPersonInformationList = 'injuredPersonInformationList',
    injuredProfession = 'injuredProfession',
    insuranceNumber = 'insuranceNumber',
    insuranceType = 'insurance-type',
    insuredByProtector = 'insuredByProtector',
    isCompany = 'isCompany-checkbox-input',
    isCounterpartyKnown = 'isCounterpartyKnown',
    isCounterpartyStationary = 'isCounterpartyStationary',
    isDriver = 'isDriver-checkbox-input',
    isDriving = 'isDriving',
    isItemMissing = 'isItemMissing',
    isLocked = 'isLocked',
    isLaneChange = 'isLaneChange',
    isOtherVehicleInvolved = 'isOtherVehicleInvolved',
    isPoliceContacted = 'isPoliceContacted',
    isPolicyHolderReporter = 'isPolicyHolderReporter',
    isReversing = 'isReversing',
    isSelfDiscoveredClaim = 'isSelfDiscoveredClaim',
    isTrafficLights = 'isTrafficLights',
    isUrbanArea = 'isUrbanArea',
    isVehicleInvolved = 'isVehicleInvolved',
    isVehicleRecovered = 'isVehicleRecovered',
    isVehicleStolen = 'isVehicleStolen',
    language = 'language',
    lastName = 'lastName',
    lifeDamageMunicipality = 'lifeDamageMunicipality',
    lightOfDay = 'lightOfDay',
    lob = 'lob',
    locationType = 'locationType',
    make = 'make',
    medicalInstitution = 'medicalInstitution',
    mileage = 'mileage',
    missingItems = 'missingItems',
    missingSSN = 'missingSSN',
    model = 'model',
    name = 'name',
    nationality = 'nationality',
    nationalIdentity = 'nationalIdentity',
    occupationType = 'occupationType',
    occupationalCondition = 'occupationalCondition',
    odCause = 'odCause',
    otherId = 'otherId',
    otherInsurance = 'other-insurance',
    otherInsuranceCompany = 'otherInsuranceCompany',
    otherMissingItemsClaimDescription = 'otherMissingItemsClaimDescription',
    ownerGivenName = 'ownerGivenName',
    ownerFamilyName = 'ownerFamilyName',
    ownership = 'ownership',
    parkedVehicleInformation = 'parkedVehicleInformation',
    parkingDate = 'parkingDate',
    phone = 'phone',
    placeOfAccident = 'placeOfAccident',
    policeCaseNumber = 'policeCaseNumber',
    policeDistrict = 'policeDistrict',
    policyHolder = 'policyHolder',
    policyHoldersContact = 'policyHoldersContact',
    policyNumber = 'policyNumber',
    position = 'position',
    propertyAddress = 'propertyAddress',
    propertyUnit = 'unit',
    recovererInformation = 'recovererInformation',
    recoveryDate = 'recoveryDate',
    registrationNumber = 'registrationNumber',
    reportedToEmployerDate = 'reportedToEmployerDate',
    reporterInformation = 'reporterInformation',
    reversingClaimDescription = 'reversingClaimDescription',
    roadCondition = 'roadCondition',
    roadConditionClaimDescription = 'roadConditionClaimDescription',
    roadWidth = 'roadWidth',
    role = 'role',
    salary = 'salary',
    speedLimit = 'speedLimit',
    speedOnImpact = 'speedOnImpact',
    street = 'street',
    studyLocation = 'studyLocation',
    natureTypeId = 'natureTypeId',
    theftAndDamagesClaimDescription = 'theftAndDamagesClaimDescription',
    timeSinceAction = 'timeSinceAction',
    travelDates = 'travelDates',
    travelInterruption = 'travelInterruption',
    type = 'type',
    typeOfAnimal = 'typeOfAnimal',
    typeOfCause = 'typeOfCause',
    typeOfDoctor = 'type-of-doctor',
    typeOfIntersection = 'typeOfIntersection',
    typeOfRoad = 'typeOfRoad',
    typeOfStudies = 'typeOfStudies',
    typeOfVehicle = 'typeOfVehicle',
    underInfluence = 'underInfluence',
    underInfluenceClaimDescription = 'underInfluenceClaimDescription',
    unidentifiedType = 'unidentifiedType',
    uploadAttachments = 'upload-attachments-',
    uploadPoliceReport = 'upload-police-report-',
    uploadReceipt = 'upload-receipt-',
    vehicleList = 'vehicleList-',
    vehicleType = 'vehicleType',
    VINNumber = 'VINNumber',
    whoAtFault = 'whoAtFault',
    whoPaidExpenses = 'whoPaidExpenses',
    whoReport = 'who-report',
    whoResponsibleClaimDescription = 'whoResponsibleClaimDescription',
    whoUnderInfluence = 'whoUnderInfluence',
    witnesses = 'witnesses',
    workshopInformation = 'workshopInformation',
    zip = 'zip',
}
