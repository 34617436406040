import React from 'react';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { Clickable, SummaryItem } from '@protectorinsurance/ds-can';

interface DisplayStartSituationSummaryProps {
    hasExistingClaim: {
        showExistingClaim: boolean;
        buttonText: string;
        path: string;
        textKey?: string;
        handleClick?: (e: Clickable, path: string) => void;
    };
    claimNumber: {
        buttonText: string;
        path: string;
        textKey?: string;
        handleClick?: (e: Clickable, path: string) => void;
    };
    insuranceType: {
        buttonText: string;
        path: string;
        textKey?: string;
        handleClick?: (e: Clickable, path: string) => void;
    };
    category: {
        buttonText: string;
        path: string;
        textKey?: string;
        handleClick?: (e: Clickable, path: string) => void;
    };
    claimReporterRole: {
        buttonText: string;
        path: string;
        textKey?: string;
        handleClick?: (e: Clickable, path: string) => void;
    };
}

export const DisplayStartSituationSummary = ({
    hasExistingClaim,
    claimNumber,
    insuranceType,
    category,
    claimReporterRole,
}: DisplayStartSituationSummaryProps) => {
    return (
        <>
            {/* Has Existing Claim */}
            <SummaryItem
                inputWrapperClass={`${
                    hasExistingClaim.showExistingClaim ? 'col-4' : 'col-12'
                } divider align-button-start text-align-left`}
                textKey={hasExistingClaim.textKey}
                dataTestId={TEST_ID_PREFIX.hasExistingClaim}
                buttonText={hasExistingClaim.buttonText}
                path={hasExistingClaim.path}
                handleClick={hasExistingClaim.handleClick}
            />

            {/* Claim Number */}
            {hasExistingClaim.showExistingClaim && (
                <SummaryItem
                    inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                    textKey={claimNumber.textKey}
                    dataTestId={TEST_ID_PREFIX.claimNumber}
                    buttonText={claimNumber.buttonText}
                    path={claimNumber.path}
                    handleClick={claimNumber.handleClick}
                />
            )}

            {/* Insurance Type */}
            <SummaryItem
                inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                textKey={insuranceType.textKey}
                dataTestId={TEST_ID_PREFIX.insuranceType}
                buttonText={insuranceType.buttonText}
                path={insuranceType.path}
                handleClick={insuranceType.handleClick}
            />

            {/* Category */}
            <SummaryItem
                inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                textKey={category.textKey}
                dataTestId={TEST_ID_PREFIX.category}
                buttonText={category.buttonText}
                path={category.path}
                handleClick={category.handleClick}
            />

            {/* Claim Reporter Role */}
            <SummaryItem
                inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                textKey={claimReporterRole.textKey}
                dataTestId={TEST_ID_PREFIX.whoReport}
                buttonText={claimReporterRole.buttonText}
                path={claimReporterRole.path}
                handleClick={claimReporterRole.handleClick}
            />
        </>
    );
};
