import {
    InsuredByProtectorKeys,
    MotorClaimCauseKeys,
    NeedVehicleTowingKeys,
    ObjectWithDynamicKey,
    TypeOfAnimalCollisionKeys,
    TypeOfAutoClaimKeys,
    WhoAtFaultKeys,
    YesNoKeys,
} from '@protectorinsurance/ds-can';

export enum MotorRoutePaths {
    INDEX = '/fin/:language/4',
    ERROR = '/error',
    SESSION_TIMEOUT = '/session-timeout',

    // Dyn
    DYN_BASE = '/fin/:language/4/dyn/',
    DYN_CAROUSEL = '/fin/:language/4/dyn/:flow?/:slug',
    // Start
    DYN_CAROUSEL_START_WELCOME = '/fin/:language/4/dyn/start/welcome',
    DYN_CAROUSEL_START_INSURED_BY_PROTECTOR = '/fin/:language/4/dyn/start/insured-by-protector',
    DYN_CAROUSEL_START_IS_DRIVING = '/fin/:language/4/dyn/start/is-driving',
    // Parked
    DYN_CAROUSEL_PARKED_CLAIM_CAUSE = '/fin/:language/4/dyn/parked/claim-cause',
    // Parked / Theft And Damages
    DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM = '/fin/:language/4/dyn/parked/is-self-discovered-claim',
    DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_LOCKED = '/fin/:language/4/dyn/parked/is-locked',
    DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN = '/fin/:language/4/dyn/parked/is-vehicle-stolen',
    DYN_CAROUSEL_THEFT_AND_DAMAGES_HAS_ALL_KEYS = '/fin/:language/4/dyn/parked/has-all-keys',
    DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED = '/fin/:language/4/dyn/parked/is-vehicle-recovered',
    DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_ITEM_MISSING = '/fin/:language/4/dyn/parked/is-item-missing',
    // Parked / Parking
    DYN_CAROUSEL_PARKING_IS_COUNTERPARTY_KNOWN = '/fin/:language/4/dyn/parked/is-counterparty-known',
    DYN_CAROUSEL_PARKING_UNIDENTIFIED_TYPE = '/fin/:language/4/dyn/parked/unidentified-type',
    // Parked / Other
    DYN_CAROUSEL_OTHER_CLAIM_CAUSE_DETAILS = '/fin/:language/4/dyn/parked/claim-cause-details',
    // Driving
    DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED = '/fin/:language/4/dyn/driving/is-other-vehicle-involved',
    DYN_CAROUSEL_DRIVING_UNIDENTIFIED_TYPE = '/fin/:language/4/dyn/driving/unidentified-type',
    DYN_CAROUSEL_DRIVING_CLAIM_LOCATION_TYPE = '/fin/:language/4/dyn/driving/claim-location-type',
    DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE = '/fin/:language/4/dyn/driving/is-lane-change',
    DYN_CAROUSEL_DRIVING_ROAD_CONDITION = '/fin/:language/4/dyn/driving/road-condition',
    DYN_CAROUSEL_DRIVING_LIGHT_OF_DAY = '/fin/:language/4/dyn/driving/light-of-day',
    DYN_CAROUSEL_DRIVING_IS_URBAN_AREA = '/fin/:language/4/dyn/driving/is-urban-area',
    // Driving / Vehicles / Road
    DYN_CAROUSEL_ROAD_TYPE_OF_ROAD = '/fin/:language/4/dyn/driving/type-of-road',
    DYN_CAROUSEL_ROAD_CLAIM_CAUSE = '/fin/:language/4/dyn/driving/road-claim-cause',
    // Driving / Vehicles / Roundabout
    DYN_CAROUSEL_ROUNDABOUT_TYPE_OF_ROAD = '/fin/:language/4/dyn/driving/roundabout-type-of-road',
    DYN_CAROUSEL_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING = '/fin/:language/4/dyn/driving/claimant-roundabout-positioning',
    DYN_CAROUSEL_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING = '/fin/:language/4/dyn/driving/counterparty-roundabout-positioning',
    DYN_CAROUSEL_ROUNDABOUT_CLAIM_CAUSE = '/fin/:language/4/dyn/driving/roundabout-claim-cause',
    // Driving / Vehicles / Intersection
    DYN_CAROUSEL_INTERSECTION_TYPE_OF_ROAD = '/fin/:language/4/dyn/driving/intersection-type-of-road',
    DYN_CAROUSEL_INTERSECTION_TYPE_OF_INTERSECTION = '/fin/:language/4/dyn/driving/type-of-intersection',
    DYN_CAROUSEL_INTERSECTION_IS_TRAFFIC_LIGHTS = '/fin/:language/4/dyn/driving/is-traffic-lights',
    DYN_CAROUSEL_INTERSECTION_CLAIM_CAUSE = '/fin/:language/4/dyn/driving/intersection-claim-cause',
    // Driving / Vehicles / Parking Other
    DYN_CAROUSEL_PARKING_OTHER_CLAIM_CAUSE = '/fin/:language/4/dyn/driving/parking-other-claim-cause',
    // Driving / Vehicles / Reversing
    DYN_CAROUSEL_REVERSING_WHO_AT_FAULT = '/fin/:language/4/dyn/driving/reversing-who-at-fault',
    // Driving / Vehicles / Lane Change
    DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT = '/fin/:language/4/dyn/driving/lane-change-who-at-fault',
    // Driving / Vehicles / Overtaking
    DYN_CAROUSEL_OVERTAKING_WHO_AT_FAULT = '/fin/:language/4/dyn/driving/overtaking-who-at-fault',
    // Driving / Vehicles / Head On
    DYN_CAROUSEL_HEAD_ON_IS_COUNTERPARTY_STATIONARY = '/fin/:language/4/dyn/driving/is-counterparty-stationary',
    // Driving / Vehicles / Rear Ended
    DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT = '/fin/:language/4/dyn/driving/rear-ended-who-at-fault',
    // Driving / Vehicles / Parked
    DYN_CAROUSEL_PARKED_WHO_AT_FAULT = '/fin/:language/4/dyn/driving/parked-who-at-fault',
    DYN_CAROUSEL_PARKED_IS_REVERSING = '/fin/:language/4/dyn/driving/is-reversing',
    // Driving / No Vehicles
    DYN_CAROUSEL_NO_VEHICLE_CLAIM_CAUSE = '/fin/:language/4/dyn/driving/no-vehicle-claim-cause',
    DYN_CAROUSEL_NO_VEHICLE_TYPE_OF_ROAD = '/fin/:language/4/dyn/driving/no-vehicle-type-of-road',
    // Driving / No Vehicles / Animal
    DYN_CAROUSEL_ANIMAL_TYPE_OF_ANIMAL = '/fin/:language/4/dyn/driving/type-of-animal',
    DYN_CAROUSEL_ANIMAL_HAS_ANIMAL_ESCAPED = '/fin/:language/4/dyn/driving/has-animal-escaped',
    // End
    DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES = '/fin/:language/4/dyn/end/has-vehicle-damages',
    DYN_CAROUSEL_END_NEED_VEHICLE_TOWING = '/fin/:language/4/dyn/end/need-vehicle-towing',
    DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES = '/fin/:language/4/dyn/end/has-non-vehicle-damages',
    DYN_CAROUSEL_END_HAS_PERSON_INJURIES = '/fin/:language/4/dyn/end/has-person-injuries',
    DYN_CAROUSEL_END_IS_POLICE_CONTACTED = '/fin/:language/4/dyn/end/is-police-contacted',
    DYN_CAROUSEL_END_IS_UNDER_INFLUENCE = '/fin/:language/4/dyn/end/is-under-influence',
    DYN_CAROUSEL_END_HAS_WITNESSES = '/fin/:language/4/dyn/end/has-witnesses',
    DYN_CAROUSEL_END_HAS_LICENSE = '/fin/:language/4/dyn/end/has-license',

    // Start
    START_WINDSHIELD_DAMAGE = '/fin/:language/4/start/windshield-damage',
    START_START_SUMMARY = '/fin/:language/4/start/start-summary',
    START_LOGIN = '/fin/:language/4/start/login',
    START_CONTINUE_CLAIM = '/fin/:language/4/start/continue-claim',
    START_CLAIM_DATE = '/fin/:language/4/start/claim-date',
    START_VEHICLE_REGISTRATION_NUMBER = '/fin/:language/4/start/vehicle-registration-number',
    START_VEHICLE_INFORMATION = '/fin/:language/4/start/vehicle-information',
    START_ADD_EXTRAS = '/fin/:language/4/start/add-extras',
    START_STOP = '/fin/:language/4/start/stop',
    START_COMPANY_INFORMATION = '/fin/:language/4/start/policy-holder',

    // Parked / Theft And Damages
    PARKED_CLAIM_DISCOVERER_INFORMATION = '/fin/:language/4/parked/claim-discoverer-information',
    PARKED_PARKING_DATE = '/fin/:language/4/parked/parking-date',
    PARKED_PARKING_INFORMATION = '/fin/:language/4/parked/parking-information',
    PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION = '/fin/:language/4/parked/theft-and-damages-claim-description',
    PARKED_RECOVERY_DATE = '/fin/:language/4/parked/recovery-date',
    PARKED_RECOVERER_INFORMATION = '/fin/:language/4/parked/recoverer-information',
    PARKED_MISSING_ITEMS = '/fin/:language/4/parked/missing-items',
    PARKED_OTHER_MISSING_ITEMS = '/fin/:language/4/parked/other-missing-items',
    PARKED_UPLOAD_RECEIPT = '/fin/:language/4/parked/upload-receipts',

    // Parked / Engine
    PARKED_ENGINE_CLAIM_DESCRIPTION = '/fin/:language/4/parked/engine-claim-description',

    // Parked/Fire
    PARKED_FIRE_CLAIM_DESCRIPTION = '/fin/:language/4/parked/fire-claim-description',

    // Parked / Parking
    PARKED_COUNTERPARTY_REGISTRATION_NUMBER = '/fin/:language/4/parked/counterparty-registration-number',
    PARKED_VEHICLE_INFORMATION = '/fin/:language/4/parked/vehicle-information',
    PARKED_COUNTERPARTY_INFORMATION = '/fin/:language/4/parked/counterparty-information',

    // Driving / No Vehicle
    DRIVING_NO_VEHICLE_SPEED_INFORMATION = '/fin/:language/4/driving/no-vehicle-speed-information',
    DRIVING_NO_VEHICLE_PLACE_OF_ACCIDENT = '/fin/:language/4/driving/no-vehicle-place-of-accident',

    // Driving / No vehicle / Animal
    DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST = '/fin/:language/4/driving/animal-owner-information',

    // Driving / No Vehicle / Engine
    DRIVING_NO_VEHICLE_ENGINE_CLAIM_DESCRIPTION = '/fin/:language/4/driving/engine-claim-description',

    // Driving / Vehicle
    DRIVING_VEHICLE_DAMAGES = '/fin/:language/4/driving/damages',
    DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER = '/fin/:language/4/driving/vehicle-registration-number',
    DRIVING_VEHICLE_VEHICLE_INFORMATION = '/fin/:language/4/driving/vehicle-information',
    DRIVING_VEHICLE_OWNER_INFORMATION = '/fin/:language/4/driving/owner-information',
    DRIVING_ADD_EXTRAS = '/fin/:language/4/driving/add-extras',
    DRIVING_VEHICLE_SPEED_INFORMATION = '/fin/:language/4/driving/speed-information',
    DRIVING_VEHICLE_PLACE_OF_ACCIDENT = '/fin/:language/4/driving/place-of-accident',
    DRIVING_VEHICLE_COLLISION = '/fin/:language/4/driving/collision',

    // Driving / Vehicle / Reversing
    DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION = '/fin/:language/4/driving/reversing-claim-description',

    // Driving / Vehicle / Head On
    DRIVING_VEHICLE_ROAD_INFORMATION = '/fin/:language/4/driving/road-information',

    // End
    END_ACCIDENT_LOCATION = '/fin/:language/4/end/accident-location',
    END_ACCIDENT_DESCRIPTION = '/fin/:language/4/end/accident-description',
    END_VEHICLE_WHO_RESPONSIBLE = '/fin/:language/4/end/who-responsible',
    END_DAMAGE_OVERVIEW = '/fin/:language/4/end/damage-overview',
    END_VEHICLE_REGISTRATION_NUMBER = '/fin/:language/4/end/vehicle-registration-number',
    END_VEHICLE_INFORMATION = '/fin/:language/4/end/vehicle-information',
    END_BODY = '/fin/:language/4/end/body-damages',
    END_CONTACT_TOWING_SERVICE = '/fin/:language/4/end/contact-towing-service',
    END_FIND_WORKSHOP = '/fin/:language/4/end/find-workshop',
    END_OWNER_INFORMATION_LIST = '/fin/:language/4/end/owner-information',
    END_INJURED_PERSON_INFORMATION = '/fin/:language/4/end/injured-person-information',
    END_POLICE_CASE_NUMBER = '/fin/:language/4/end/police-case-number',
    END_UPLOAD_POLICE_REPORT = '/fin/:language/4/end/upload-police-report',
    END_POLICE_DISTRICT = '/fin/:language/4/end/police-district',
    END_WHO_UNDER_INFLUENCE = '/fin/:language/4/end/who-under-influence',
    END_REPORTER_INFORMATION = '/fin/:language/4/end/reporter-information',
    END_DRIVER_INFORMATION = '/fin/:language/4/end/driver-information',
    END_WITNESS_INFORMATION = '/fin/:language/4/end/witness-information',
    END_UPLOAD_ATTACHMENT = '/fin/:language/4/end/upload-attachment',
    END_POLICY_HOLDERS_CONTACT = '/fin/:language/4/end/policy-holders-contact',
    END_FINAL_SUMMARY = '/fin/:language/4/end/final-summary',
    END_REPORT_COMPLETED = '/fin/:language/4/end/report-completed',
}

export const motorWizardRoutes: ObjectWithDynamicKey = {
    //Start routes
    [MotorRoutePaths.INDEX]: {
        [TypeOfAutoClaimKeys.WINDSHIELD]: MotorRoutePaths.START_WINDSHIELD_DAMAGE,
        [TypeOfAutoClaimKeys.STANDARD]: MotorRoutePaths.START_START_SUMMARY,
    },
    [MotorRoutePaths.START_START_SUMMARY]: MotorRoutePaths.START_CLAIM_DATE,
    [MotorRoutePaths.START_LOGIN]: MotorRoutePaths.START_CONTINUE_CLAIM,
    [MotorRoutePaths.START_CONTINUE_CLAIM]: MotorRoutePaths.START_CLAIM_DATE,
    [MotorRoutePaths.START_CLAIM_DATE]: MotorRoutePaths.START_VEHICLE_REGISTRATION_NUMBER,
    [MotorRoutePaths.START_VEHICLE_REGISTRATION_NUMBER]: MotorRoutePaths.START_VEHICLE_INFORMATION,
    [MotorRoutePaths.START_VEHICLE_INFORMATION]: MotorRoutePaths.START_ADD_EXTRAS,
    [MotorRoutePaths.DYN_CAROUSEL_START_INSURED_BY_PROTECTOR]: {
        [InsuredByProtectorKeys.YES_RECENTLY_INSURED_BY_PROTECTOR]: MotorRoutePaths.START_COMPANY_INFORMATION,
        [InsuredByProtectorKeys.YES]: MotorRoutePaths.START_COMPANY_INFORMATION,
        [InsuredByProtectorKeys.NO]: MotorRoutePaths.START_STOP,
    },
    [MotorRoutePaths.START_ADD_EXTRAS]: MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING,
    [MotorRoutePaths.START_STOP]: MotorRoutePaths.START_COMPANY_INFORMATION,
    [MotorRoutePaths.START_COMPANY_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING,
    [MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE,
    },

    //Parked flow routes
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.THEFT_AND_DAMAGE]: MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM,
        [MotorClaimCauseKeys.ENGINE]: MotorRoutePaths.PARKED_ENGINE_CLAIM_DESCRIPTION,
        [MotorClaimCauseKeys.PARKING]: MotorRoutePaths.DYN_CAROUSEL_PARKING_IS_COUNTERPARTY_KNOWN,
        [MotorClaimCauseKeys.FIRE]: MotorRoutePaths.PARKED_FIRE_CLAIM_DESCRIPTION,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.DYN_CAROUSEL_OTHER_CLAIM_CAUSE_DETAILS,
    },

    //Parked/Theft And Damages
    [MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_LOCKED,
        [YesNoKeys.NO]: MotorRoutePaths.PARKED_CLAIM_DISCOVERER_INFORMATION,
    },
    [MotorRoutePaths.PARKED_CLAIM_DISCOVERER_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_LOCKED,
    [MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_LOCKED]:
        MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN,
    [MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_PARKING_DATE,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_ITEM_MISSING,
    },
    [MotorRoutePaths.PARKED_PARKING_DATE]: MotorRoutePaths.PARKED_PARKING_INFORMATION,
    [MotorRoutePaths.PARKED_PARKING_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_HAS_ALL_KEYS,
    [MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_HAS_ALL_KEYS]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED,
        [YesNoKeys.NO]: MotorRoutePaths.PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION,
    },
    [MotorRoutePaths.PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION]:
        MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED,
    [MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_RECOVERY_DATE,
        [YesNoKeys.NO]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },
    [MotorRoutePaths.PARKED_RECOVERY_DATE]: MotorRoutePaths.PARKED_RECOVERER_INFORMATION,
    [MotorRoutePaths.PARKED_RECOVERER_INFORMATION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    [MotorRoutePaths.DYN_CAROUSEL_THEFT_AND_DAMAGES_IS_ITEM_MISSING]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_MISSING_ITEMS,
        [YesNoKeys.NO]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },
    [MotorRoutePaths.PARKED_MISSING_ITEMS]: MotorRoutePaths.PARKED_UPLOAD_RECEIPT,
    [MotorRoutePaths.PARKED_OTHER_MISSING_ITEMS]: MotorRoutePaths.PARKED_UPLOAD_RECEIPT,
    [MotorRoutePaths.PARKED_UPLOAD_RECEIPT]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Engine
    [MotorRoutePaths.PARKED_ENGINE_CLAIM_DESCRIPTION]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Parking
    [MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Fire
    [MotorRoutePaths.PARKED_FIRE_CLAIM_DESCRIPTION]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Parking
    [MotorRoutePaths.DYN_CAROUSEL_PARKING_IS_COUNTERPARTY_KNOWN]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER,
        [YesNoKeys.NO]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },
    [MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER]: MotorRoutePaths.DYN_CAROUSEL_PARKING_UNIDENTIFIED_TYPE,
    [MotorRoutePaths.DYN_CAROUSEL_PARKING_UNIDENTIFIED_TYPE]: MotorRoutePaths.PARKED_VEHICLE_INFORMATION,
    [MotorRoutePaths.PARKED_VEHICLE_INFORMATION]: MotorRoutePaths.PARKED_COUNTERPARTY_INFORMATION,
    [MotorRoutePaths.PARKED_COUNTERPARTY_INFORMATION]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Other
    [MotorRoutePaths.DYN_CAROUSEL_OTHER_CLAIM_CAUSE_DETAILS]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Driving flow routes
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED]: {
        [YesNoKeys.YES]: MotorRoutePaths.DRIVING_VEHICLE_DAMAGES,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_NO_VEHICLE_CLAIM_CAUSE,
    },

    //Driving/No Vehicle
    [MotorRoutePaths.DYN_CAROUSEL_NO_VEHICLE_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.ANIMAL_COLLISION]: MotorRoutePaths.DYN_CAROUSEL_ANIMAL_TYPE_OF_ANIMAL,
        [MotorClaimCauseKeys.STATIONARY_OBJECT_COLLISION]: MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION,
        [MotorClaimCauseKeys.CYCLIST_COLLISION]: MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION,
        [MotorClaimCauseKeys.DITCH_COLLISION]: MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION,
        [MotorClaimCauseKeys.PEDESTRIAN_COLLISION]: MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION,
        [MotorClaimCauseKeys.ENGINE]: MotorRoutePaths.DRIVING_NO_VEHICLE_ENGINE_CLAIM_DESCRIPTION,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION,
    },
    [MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_NO_VEHICLE_TYPE_OF_ROAD,
    [MotorRoutePaths.DYN_CAROUSEL_NO_VEHICLE_TYPE_OF_ROAD]: MotorRoutePaths.DRIVING_NO_VEHICLE_PLACE_OF_ACCIDENT,
    [MotorRoutePaths.DRIVING_NO_VEHICLE_PLACE_OF_ACCIDENT]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION,

    //Driving/No Vehicle/Animal
    [MotorRoutePaths.DYN_CAROUSEL_ANIMAL_TYPE_OF_ANIMAL]: {
        [TypeOfAnimalCollisionKeys.PET]: MotorRoutePaths.DYN_CAROUSEL_ANIMAL_HAS_ANIMAL_ESCAPED,
        [TypeOfAnimalCollisionKeys.LIVESTOCK]: MotorRoutePaths.DYN_CAROUSEL_ANIMAL_HAS_ANIMAL_ESCAPED,
        [TypeOfAnimalCollisionKeys.GAME]: MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION,
    },
    [MotorRoutePaths.DYN_CAROUSEL_ANIMAL_HAS_ANIMAL_ESCAPED]: MotorRoutePaths.DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST,
    [MotorRoutePaths.DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST]: MotorRoutePaths.DRIVING_NO_VEHICLE_SPEED_INFORMATION,

    //Driving/No Vehicle/Engine
    [MotorRoutePaths.DRIVING_NO_VEHICLE_ENGINE_CLAIM_DESCRIPTION]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Driving/Vehicle
    [MotorRoutePaths.DRIVING_VEHICLE_DAMAGES]: MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION,
    [MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_UNIDENTIFIED_TYPE,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_UNIDENTIFIED_TYPE]: MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_INFORMATION,
    [MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_INFORMATION]: MotorRoutePaths.DRIVING_VEHICLE_OWNER_INFORMATION,
    [MotorRoutePaths.DRIVING_ADD_EXTRAS]: MotorRoutePaths.DRIVING_VEHICLE_OWNER_INFORMATION,
    [MotorRoutePaths.DRIVING_VEHICLE_OWNER_INFORMATION]: MotorRoutePaths.DRIVING_VEHICLE_DAMAGES,
    [MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_CLAIM_LOCATION_TYPE,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_CLAIM_LOCATION_TYPE]: MotorRoutePaths.DRIVING_VEHICLE_PLACE_OF_ACCIDENT,

    //Driving/Vehicle/Road
    [MotorRoutePaths.DYN_CAROUSEL_ROAD_TYPE_OF_ROAD]: MotorRoutePaths.DYN_CAROUSEL_ROAD_CLAIM_CAUSE,
    [MotorRoutePaths.DYN_CAROUSEL_ROAD_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.HEAD_ON]: MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION,
        [MotorClaimCauseKeys.OVERTAKING]: MotorRoutePaths.DYN_CAROUSEL_OVERTAKING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.PARKED]: MotorRoutePaths.DYN_CAROUSEL_PARKED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_REVERSING_WHO_AT_FAULT,
    },

    //Driving/Vehicle/Roundabout
    [MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_TYPE_OF_ROAD]:
        MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING,
    [MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING]:
        MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING,
    [MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING]:
        MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_CLAIM_CAUSE,
    [MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_REVERSING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },
    //Driving/Vehicle/Intersection
    [MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_TYPE_OF_ROAD]:
        MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_TYPE_OF_INTERSECTION,
    [MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_TYPE_OF_INTERSECTION]:
        MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_CLAIM_CAUSE,
    [MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_IS_TRAFFIC_LIGHTS]:
        MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_CLAIM_CAUSE,
    [MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.HEAD_ON]: MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION,
        [MotorClaimCauseKeys.OVERTAKING]: MotorRoutePaths.DYN_CAROUSEL_OVERTAKING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.PARKED]: MotorRoutePaths.DYN_CAROUSEL_PARKED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_REVERSING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION,
    },

    //Driving/Vehicle/Parking Other
    [MotorRoutePaths.DYN_CAROUSEL_PARKING_OTHER_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.HEAD_ON]: MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION,
        [MotorClaimCauseKeys.PARKED]: MotorRoutePaths.DYN_CAROUSEL_PARKED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_REVERSING_WHO_AT_FAULT,
    },

    //Driving/Vehicle/Reversing
    [MotorRoutePaths.DYN_CAROUSEL_REVERSING_WHO_AT_FAULT]: MotorRoutePaths.DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION,
    [MotorRoutePaths.DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION]: MotorRoutePaths.DRIVING_VEHICLE_COLLISION,

    //Driving/Vehicle/Lane Change
    [MotorRoutePaths.DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT]: MotorRoutePaths.DRIVING_VEHICLE_COLLISION,

    //Driving/Vehicle/Overtaking
    [MotorRoutePaths.DYN_CAROUSEL_OVERTAKING_WHO_AT_FAULT]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE,

    //Driving/Vehicle/Head On
    [MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_HEAD_ON_IS_COUNTERPARTY_STATIONARY,
    [MotorRoutePaths.DYN_CAROUSEL_HEAD_ON_IS_COUNTERPARTY_STATIONARY]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE,

    //Driving/Vehicle/Rear Ended
    [MotorRoutePaths.DYN_CAROUSEL_REAR_ENDED_WHO_AT_FAULT]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE,

    //Driving/Vehicle/Parked
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_WHO_AT_FAULT]: {
        [WhoAtFaultKeys.CLAIMANT]: MotorRoutePaths.DYN_CAROUSEL_PARKED_IS_REVERSING,
        [WhoAtFaultKeys.COUNTERPARTY]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION,
    },
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_IS_REVERSING]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION,

    //Driving/Vehicle
    [MotorRoutePaths.DRIVING_VEHICLE_COLLISION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_LANE_CHANGE]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_LANE_CHANGE_WHO_AT_FAULT,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION,
    },
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_ROAD_CONDITION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_LIGHT_OF_DAY,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_LIGHT_OF_DAY]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_URBAN_AREA,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_URBAN_AREA]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //End routes
    [MotorRoutePaths.END_ACCIDENT_LOCATION]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES,
    [MotorRoutePaths.END_ACCIDENT_DESCRIPTION]: MotorRoutePaths.END_VEHICLE_WHO_RESPONSIBLE,
    [MotorRoutePaths.END_VEHICLE_WHO_RESPONSIBLE]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_DAMAGE_OVERVIEW,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    },
    [MotorRoutePaths.END_DAMAGE_OVERVIEW]: MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING,
    [MotorRoutePaths.END_VEHICLE_REGISTRATION_NUMBER]: MotorRoutePaths.END_VEHICLE_INFORMATION,
    [MotorRoutePaths.END_VEHICLE_INFORMATION]: MotorRoutePaths.END_DAMAGE_OVERVIEW,
    [MotorRoutePaths.END_BODY]: MotorRoutePaths.END_DAMAGE_OVERVIEW,
    [MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING]: {
        [NeedVehicleTowingKeys.YES]: MotorRoutePaths.END_CONTACT_TOWING_SERVICE,
        [NeedVehicleTowingKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
        [NeedVehicleTowingKeys.ALREADY_TOWED]: MotorRoutePaths.END_FIND_WORKSHOP,
    },
    [MotorRoutePaths.END_CONTACT_TOWING_SERVICE]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    [MotorRoutePaths.END_FIND_WORKSHOP]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_OWNER_INFORMATION_LIST,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_PERSON_INJURIES,
    },
    [MotorRoutePaths.END_OWNER_INFORMATION_LIST]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_PERSON_INJURIES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_PERSON_INJURIES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_INJURED_PERSON_INFORMATION,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED,
    },
    [MotorRoutePaths.END_INJURED_PERSON_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED,
    [MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_POLICE_CASE_NUMBER,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_IS_UNDER_INFLUENCE,
    },
    [MotorRoutePaths.END_POLICE_CASE_NUMBER]: MotorRoutePaths.END_UPLOAD_POLICE_REPORT,
    [MotorRoutePaths.END_UPLOAD_POLICE_REPORT]: MotorRoutePaths.END_POLICE_DISTRICT,
    [MotorRoutePaths.END_POLICE_DISTRICT]: MotorRoutePaths.DYN_CAROUSEL_END_IS_UNDER_INFLUENCE,
    [MotorRoutePaths.DYN_CAROUSEL_END_IS_UNDER_INFLUENCE]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_WHO_UNDER_INFLUENCE,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_WITNESSES,
    },
    [MotorRoutePaths.END_WHO_UNDER_INFLUENCE]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_WITNESSES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_WITNESSES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_WITNESS_INFORMATION,
        [YesNoKeys.NO]: MotorRoutePaths.END_UPLOAD_ATTACHMENT,
    },
    [MotorRoutePaths.END_WITNESS_INFORMATION]: MotorRoutePaths.END_UPLOAD_ATTACHMENT,
    [MotorRoutePaths.END_UPLOAD_ATTACHMENT]: MotorRoutePaths.END_REPORTER_INFORMATION,
    [MotorRoutePaths.END_REPORTER_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_LICENSE,
    [MotorRoutePaths.END_DRIVER_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_LICENSE,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_LICENSE]: MotorRoutePaths.END_POLICY_HOLDERS_CONTACT,
    [MotorRoutePaths.END_POLICY_HOLDERS_CONTACT]: MotorRoutePaths.END_FINAL_SUMMARY,
    [MotorRoutePaths.END_FINAL_SUMMARY]: MotorRoutePaths.END_REPORT_COMPLETED,
};
