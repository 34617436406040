import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { DATE_OF_EMPLOYMENT_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const dateOfEmploymentSchema = (t: TFunction) => {
    return {
        dateOfEmployment: Yup.date()
            .label(t(DATE_OF_EMPLOYMENT_LABEL))
            .nullable()
            .transform((current, original) => (original === '' ? null : current))
            .required(),
    };
};
