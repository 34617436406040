import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    BiologicalFactorIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    OdCauseEnums,
    OtherInjuriesIcon,
    TuberculosisIcon,
    VirusIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectOdCause } from '../../../../../sagas/selectors/personSelectors';
import { createOdCauseKey, getOdCauseOption } from '../../../../../utils/person/odCauseUtils';

/**
 * Destructure necessary imports
 */
const { BACTERIA, OTHER_BIOLOGICAL_FACTOR, TUBERCULOSIS, VIRUS } = OdCauseEnums;

/**
 * Page view and page logic
 */
export const workersCompIllnessBiologicalFactorPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: BACTERIA,
            name: t(createOdCauseKey(BACTERIA)),
            icon: <BiologicalFactorIcon />,
        },
        {
            id: TUBERCULOSIS,
            name: t(createOdCauseKey(TUBERCULOSIS)),
            icon: <TuberculosisIcon />,
        },
        {
            id: VIRUS,
            name: t(createOdCauseKey(VIRUS)),
            icon: <VirusIcon />,
        },
        {
            id: OTHER_BIOLOGICAL_FACTOR,
            name: t(createOdCauseKey(OTHER_BIOLOGICAL_FACTOR)),
            icon: <OtherInjuriesIcon />,
        },
    ];

    // Numbers are set based on what Backend needs for mapping
    const bacteriaOption = getOdCauseOption(t, BACTERIA, '548133');
    const otherOption = getOdCauseOption(t, OTHER_BIOLOGICAL_FACTOR, '548136');
    const tuberculosisOption = getOdCauseOption(t, TUBERCULOSIS, '548134');
    const virusOption = getOdCauseOption(t, VIRUS, '548135');

    const values = [bacteriaOption, tuberculosisOption, virusOption, otherOption];

    const getValues = (option: string) => {
        const selected = values.find((x) => x.label === option);
        if (selected) {
            return { id: Number(selected.value), propertyId: 46, key: selected.label };
        }
        return null;
    };

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_BIOLOGICAL_FACTOR,
        stateKey: 'odCause',
        i18n: {
            ns: 'lob.person.workersComp.illness.biologicalFactor',
        },
        selectorValue: selectOdCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, personActions.update, { odCause: getValues(option.name) })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
