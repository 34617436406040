import React from 'react';
import {
    CarouselConfigLookupResponse,
    IAmInjuredIcon,
    OnBehalfOfAnInjuredIcon,
    WhoReportKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { selectClaimReporterRole } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { MYSELF, ON_BEHALF } = WhoReportKeys;

/**
 * Page view and page logic
 */
export const healthAccidentClaimReporterRolePage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: MYSELF,
            name: t(`lob.person.health.accident.claimReporterRole.select.${MYSELF}`),
            icon: <IAmInjuredIcon />,
        },
        {
            id: ON_BEHALF,
            name: t(`lob.person.health.accident.claimReporterRole.select.${ON_BEHALF}`),
            icon: <OnBehalfOfAnInjuredIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_CLAIM_REPORTER_ROLE,
        stateKey: 'claimReporterRole',
        i18n: {
            ns: 'lob.person.health.accident.claimReporterRole',
        },
        selectorValue: selectClaimReporterRole(state),
        options,
    };
};
