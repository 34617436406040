import React from 'react';
import { LobKeys, Nullable, PrivacyTypeModel, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { BOAT, CHANGE_OF_OWNERSHIP_NOR, LIABILITY, PROPERTY, TRANSPORT } = LobKeys;

/**
 * Component view and component logic
 */
export const renderPrivacy = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>,
    acceptedPrivacy: PrivacyTypeModel
) => {
    const lobs = [BOAT, CHANGE_OF_OWNERSHIP_NOR, LIABILITY, PROPERTY, TRANSPORT];
    if (lob && lobs.includes(lob) && acceptedPrivacy) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
