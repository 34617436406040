import React from 'react';
import { CarouselConfigLookupResponse, DoctorIcon, SpesialistIcon, TypeOfDoctorKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { selectTypeOfDoctor } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { GENERAL_PRACTITIONER, SPECIALIST } = TypeOfDoctorKeys;

/**
 * Page view and page logic
 */
export const healthAccidentTypeOfDoctorPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: GENERAL_PRACTITIONER,
            name: t(`lob.person.health.accident.typeOfDoctor.select.${GENERAL_PRACTITIONER}`),
            icon: <DoctorIcon />,
        },
        {
            id: SPECIALIST,
            name: t(`lob.person.health.accident.typeOfDoctor.select.${SPECIALIST}`),
            icon: <SpesialistIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_HEALTH_ACCIDENT_TYPE_OF_DOCTOR,
        stateKey: 'typeOfDoctor',
        i18n: {
            ns: 'lob.person.health.accident.typeOfDoctor',
        },
        selectorValue: selectTypeOfDoctor(state),
        options,
    };
};
