import React, { MouseEvent } from 'react';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { useDispatch, useSelector } from 'react-redux';
import { selectIncapacityPeriods } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import { Controller, useForm } from 'react-hook-form';
import {
    AddIcon,
    CircleIconButton,
    FormChangeable,
    getLocaleFromUrl,
    Grid,
    HiddenInputSubmit,
    IncapacityPeriodsModel,
    is,
    MuiTextInput,
    Nullable,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { incapacityPeriodSchema } from '../../../../validations/schemas/incapacityPeriodSchema';
import {
    IncapacityPeriodsFormModel,
    isFormEmpty,
    isOverlappingPeriod,
    removeIncapacityPeriod,
} from 'utils/person/incapacityPeriodUtils';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { wizardRouterActions } from '../../../../sagas/wizardRouter';
import { personActions } from '../../../../sagas/person';
import { IncapacityPeriodList } from '../../../../components/incapacityPeriod/IncapacityPeriodList';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { getMomentLocale } from '../../../../utils/locale/getMomentLocale';
import { DatePickerInput } from '../../../../components/date/DatePickerInput';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    FROM_DATE_LABEL,
    FROM_DATE_TEXT,
    HELP_TEXT,
    PAGE_NAME,
    RATE_LABEL,
    RATE_PLACEHOLDER,
    REMOVE_BUTTON,
    SUB_TITLE,
    TITLE,
    TO_DATE_LABEL,
    TO_DATE_TEXT,
} = PhraseKeys;
const { FROM_DATE, TO_DATE, RATE } = FormFieldNames;
const { OVERLAPPING_PERIODS } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const WorkersCompIllnessIncapacityPeriodsPage = () => {
    const dispatch = useDispatch();
    const incapacityPeriods = useSelector(selectIncapacityPeriods);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.illness.incapacityPeriods');
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        reset,
        setError,
        setValue,
        trigger,
        watch,
    } = useForm<IncapacityPeriodsFormModel>({
        resolver: yupResolver(incapacityPeriodSchema(t)),
        defaultValues: {
            from: null,
            to: null,
            rate: 100,
        },
    });
    const values = watch();

    const handleBackButton = useGoBack();

    const handleContinueButton = () => {
        const values = getValues();

        if (!isFormEmpty(values)) {
            incapacityPeriodSchema(t)
                .isValid(values)
                .then((valid) => {
                    if (valid) {
                        handleDispatch(values, true);
                    } else {
                        trigger();
                    }
                });
        } else if (isFormEmpty(values) && is(incapacityPeriods.length, 0)) {
            trigger();
        } else {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    const isOverlapping = (period: IncapacityPeriodsFormModel): boolean => {
        const overlappingPeriods = isOverlappingPeriod(period, incapacityPeriods);
        if (overlappingPeriods) {
            setError(FROM_DATE, {
                type: 'OverlappingPeriods',
                message: t(OVERLAPPING_PERIODS),
            });
        }

        return overlappingPeriods;
    };

    const handleDispatch = (period: IncapacityPeriodsFormModel, isNext: boolean = false) => {
        if (isOverlapping(period)) {
            return;
        }
        const incapacityPeriod: IncapacityPeriodsModel = {
            from: period.from ? moment(period.from).utc() : null,
            rate: period.rate,
            to: period.to ? moment(period.to).utc() : null,
        };

        dispatch(personActions.update({ incapacityPeriods: [...incapacityPeriods, incapacityPeriod] }));
        reset({ from: null, to: null, rate: 100 });

        if (isNext) {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    const handleOnSubmit = (period: IncapacityPeriodsFormModel) => {
        handleDispatch(period);
    };

    const handleDelete = (e: MouseEvent, period: IncapacityPeriodsModel) => {
        e.preventDefault();
        const updatedPeriods = removeIncapacityPeriod(period, incapacityPeriods);
        dispatch(personActions.update({ incapacityPeriods: updatedPeriods }));
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        setValue(id, value, { shouldValidate: true });
    };

    const handleFromDateChange = (newValue: Nullable<Date>) => {
        setValue('from', newValue, { shouldValidate: true });
    };

    const handleToDateChange = (newValue: Nullable<Date>) => {
        setValue('to', newValue, { shouldValidate: true });
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <DatePickerInput
                        error={!!errors.from}
                        errorMessage={errors.from?.message}
                        id={FROM_DATE}
                        inputFieldWrapper={'col-4'}
                        label={t(FROM_DATE_LABEL)}
                        locale={getMomentLocale(getLocaleFromUrl())}
                        onChange={handleFromDateChange}
                        value={values.from}
                        {...{ customCAN }}
                    />
                    <DatePickerInput
                        error={!!errors.to}
                        errorMessage={errors.to?.message}
                        id={TO_DATE}
                        inputFieldWrapper={'col-4'}
                        label={t(TO_DATE_LABEL)}
                        locale={getMomentLocale(getLocaleFromUrl())}
                        onChange={handleToDateChange}
                        value={values.to}
                        {...{ customCAN }}
                    />
                    <Controller
                        control={control}
                        name={RATE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                append={'%'}
                                error={!!errors.rate}
                                errorMessage={errors.rate?.message}
                                id={RATE}
                                inputFieldWrapper={'col-5'}
                                label={t(RATE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(RATE_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                value={field.value?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <CircleIconButton
                        ariaLabel={t(ADD_BUTTON)}
                        className={'single-4-center'}
                        dataTestId={'btn-add-sick-leave-period'}
                        icon={<AddIcon />}
                        label={t(ADD_BUTTON)}
                    />
                </Grid>
            </form>

            <IncapacityPeriodList
                fromText={t(FROM_DATE_TEXT)}
                label={t(REMOVE_BUTTON)}
                toText={t(TO_DATE_TEXT)}
                {...{ handleDelete, incapacityPeriods }}
            />
        </PageLayout>
    );
};
