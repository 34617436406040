import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { fromDateSchema } from '../fieldSchemas/fromDateSchema';
import { toDateSchema } from '../fieldSchemas/toDateSchema';

/**
 * Validation logic
 */
export const travelDatesSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...fromDateSchema(t),
        ...toDateSchema(t),
    });
};
