import { is, LanguageCodeEnums } from '@protectorinsurance/ds-can';
import { countriesFI } from '../../config/country/countriesFI';
import { countriesSE } from '../../config/country/countriesSE';
import { countriesEN } from '../../config/country/countriesEN';

/**
 * Destructure necessary imports
 */
const { FI, SE } = LanguageCodeEnums;

/**
 * Utility logic
 */
export const getCountry = (language: string) => {
    if (is(language, FI)) {
        return countriesFI;
    } else if (is(language, SE)) {
        return countriesSE;
    } else {
        return countriesEN;
    }
};
