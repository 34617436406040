import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectEntrepreneurOwnership } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    AddIcon,
    CircleIconButton,
    Clickable,
    EntrepreneurOwnershipModel,
    EntrepreneurOwnershipTypeModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { entrepreneurOwnershipSchema } from '../../../../validations/schemas/entrepreneurOwnershipSchema';
import { DisplayEntrepreneurOwnershipList } from '../../../../components/entrepreneurOwnership/DisplayEntrepreneurOwnershipList';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { OWNERSHIP, POSITION } = FormFieldNames;
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DELETE_BUTTON,
    ENTREPRENEUR_POSITION_LABEL,
    ENTREPRENEUR_POSITION_PLACEHOLDER,
    HELP_TEXT,
    OWNERSHIP_LABEL,
    OWNERSHIP_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const WorkersCompAccidentEntrepreneurOwnershipPage = () => {
    const dispatch = useDispatch();
    const entrepreneurOwnership = useSelector(selectEntrepreneurOwnership);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.accident.entrepreneurOwnership');
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        reset,
        setValue,
        trigger,
    } = useForm<EntrepreneurOwnershipModel>({
        resolver: yupResolver(entrepreneurOwnershipSchema(t)),
    });

    const handleBackButton = useGoBack();

    const isEmptyForm = (values: EntrepreneurOwnershipModel) => {
        const { ownership, position } = values;
        return !ownership && (!position || is(position.length, 0));
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        setValue(id, value, { shouldValidate: true });
    };

    const handleContinueButton = () => {
        const values = getValues();

        if (!isEmptyForm(values)) {
            entrepreneurOwnershipSchema(t)
                .isValid(values)
                .then((valid) => {
                    if (valid) {
                        handleDispatch(values, true);
                    } else {
                        trigger();
                    }
                });
        } else if (isEmptyForm(values) && is(entrepreneurOwnership.length, 0)) {
            trigger();
        } else {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleDispatch = (values: EntrepreneurOwnershipModel, isNext = false) => {
        dispatcherWithPromise(dispatch, personActions.update, {
            entrepreneurOwnership: [...entrepreneurOwnership, values],
        })
            .then(() => isNext && dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => {
                reset({
                    ownership: null,
                    position: null,
                });
                if (isNext) {
                    dispatch(wizardActions.goToNext());
                }
            });
    };

    const onSubmit = (values: EntrepreneurOwnershipModel) => handleDispatch(values);

    const removeEntrepreneur = (
        entrepreneurOwnership: EntrepreneurOwnershipModel,
        existingEntrepreneurOwnership: EntrepreneurOwnershipTypeModel
    ): EntrepreneurOwnershipTypeModel => {
        return existingEntrepreneurOwnership.filter((eo: EntrepreneurOwnershipModel) => {
            return eo.ownership !== entrepreneurOwnership.ownership || eo.position !== entrepreneurOwnership.position;
        });
    };

    const handleDelete = (e: Clickable, values: EntrepreneurOwnershipModel) => {
        e.preventDefault();
        const updatedEntrepreneurOwnership = removeEntrepreneur(values, entrepreneurOwnership);
        dispatch(personActions.update({ entrepreneurOwnership: updatedEntrepreneurOwnership }));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={POSITION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.position}
                                errorMessage={errors.position?.message}
                                id={POSITION}
                                inputFieldWrapper={'col-6'}
                                label={t(ENTREPRENEUR_POSITION_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ENTREPRENEUR_POSITION_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={OWNERSHIP}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.ownership}
                                errorMessage={errors.ownership?.message}
                                id={OWNERSHIP}
                                inputFieldWrapper={'col-6'}
                                inputMode={'numeric'}
                                label={t(OWNERSHIP_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(OWNERSHIP_PLACEHOLDER)}
                                reference={ref}
                                value={field.value?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <CircleIconButton
                        ariaLabel={t(ADD_BUTTON)}
                        className={'flex flex-col single-4-center'}
                        dataTestId={'btn-add-entrepreneur'}
                        handleClick={handleSubmit(onSubmit)}
                        icon={<AddIcon />}
                        label={t(ADD_BUTTON)}
                    />
                </Grid>
            </form>
            <DisplayEntrepreneurOwnershipList
                buttonText={t(DELETE_BUTTON)}
                entrepreneurs={entrepreneurOwnership}
                {...{ handleDelete }}
            />
        </PageLayout>
    );
};
