import { CompanyModel } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const formatCompanyInformation = (company: CompanyModel) => {
    return [
        company.name || '',
        company.businessNumber ? ` (${company.businessNumber})` : '',
        company.policyNumber ? ` (${company.policyNumber})` : '',
    ].join('');
};
