import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { FROM_DATE_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const fromDateSchema = (t: TFunction) => {
    return {
        from: Yup.mixed().label(t(FROM_DATE_LABEL)).required().nullable(),
    };
};
