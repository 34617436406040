import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import { selectClaimNumber, selectInsuranceType } from '../../../sagas/selectors/personSelectors';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { personActions } from '../../../sagas/person';
import { claimNumberInformationSchema } from '../../../validations/schemas/claimNumberInformationSchema';
import { commonActions } from '../../../sagas/common';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    ClaimNumberModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    InsuranceTypeKeys,
    is,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { PersonRoutePaths } from '../../../config/wizardRouter/personWizardRoutes';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { CLAIM_NUMBER } = FormFieldNames;
const { CLAIM_NUMBER_LABEL, CLAIM_NUMBER_PLACEHOLDER } = PhraseKeys;
const { DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY, DYN_CAROUSEL_WORKERS_COMP_CATEGORY } = PersonRoutePaths;
const { GROUP_ACCIDENT, WORK_COMP } = InsuranceTypeKeys;

/**
 * Page view and page logic
 */
export const ClaimNumberInformationPage = () => {
    const dispatch = useDispatch();
    const claimNumber = useSelector(selectClaimNumber);
    const insuranceType = useSelector(selectInsuranceType);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.start.claimNumberInformation');
    const prefix = 'PRO-';
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimNumberModel>({
        resolver: yupResolver(claimNumberInformationSchema(t)),
        defaultValues: {
            claimNumber: claimNumber ? claimNumber : prefix,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ claimNumber }: ClaimNumberModel) => {
        let nextAction = wizardActions.goToNext();
        if (is(insuranceType, GROUP_ACCIDENT)) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_GROUP_ACCIDENT_CLAIMANT_AGE_CATEGORY);
        }

        if (is(insuranceType, WORK_COMP)) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_WORKERS_COMP_CATEGORY);
        }
        dispatcherWithPromise(dispatch, personActions.update, { claimNumber: claimNumber?.toUpperCase() })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={CLAIM_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.claimNumber}
                                errorMessage={errors.claimNumber?.message}
                                id={CLAIM_NUMBER}
                                inputFieldWrapper={'col-12'}
                                label={t(CLAIM_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CLAIM_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
