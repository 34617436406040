import { TFunction } from 'i18next';

export enum EmploymentRelationshipEnums {
    PERMANENT = 'PERMANENT',
    TEMPORARY = 'TEMPORARY',
}

const createEmploymentRelationshipKey = (id: EmploymentRelationshipEnums) => {
    return `lob.person.employmentRelationship.select.${id}`;
};

export const getEmploymentRelationshipOption = (key: EmploymentRelationshipEnums, t: TFunction) => {
    return {
        value: key,
        label: t(createEmploymentRelationshipKey(key)),
    };
};
