import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ownershipSchema } from '../fieldSchemas/ownershipSchema';
import { positionSchema } from '../fieldSchemas/positionSchema';

export const claimantPositionSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...ownershipSchema(t),
        ...positionSchema(t),
    });
};
