import { UnitTestPaths } from './unitTestWizardRoutes';
import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';

export enum BaseRoutePaths {
    DEFAULT_INDEX = '/fin/fi',
    INDEX = '/fin/:language',
    CAPTCHA = '/fin/:language/captcha',
    ERROR = '/error',
    SESSION_TIMEOUT = '/session-timeout',
}

export type BaseRoutePathsType = BaseRoutePaths | UnitTestPaths;
export const baseWizardRoutes: ObjectWithDynamicKey = {};
