import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { professionSchema } from '../fieldSchemas/professionSchema';
import { dateOfEmploymentSchema } from '../fieldSchemas/dateOfEmploymentSchema';
import { dateOfTerminationSchema } from '../fieldSchemas/dateOfTerminationSchema';

/**
 * Validation logic
 */
export const injuredOccupationSchema = (t: TFunction, required: boolean) => {
    return Yup.object().shape({
        ...professionSchema(t),
        ...dateOfEmploymentSchema(t),
        ...dateOfTerminationSchema(t, required),
    });
};
