import { addMethod, number, string } from 'yup';
import { requiredIf } from '../validations/methods/requiredIf';
import { digits } from '../validations/methods/digits';
import { claimNumber } from '../validations/methods/claimNumber';
import { businessNumber } from '../validations/methods/businessNumber';
import { numberOfDigits } from '../validations/methods/numberOfDigits';
import { noSpecialChars } from '../validations/methods/noSpecialChars';
import ssn from '../validations/methods/ssn';
import { accountNumber } from '../validations/methods/accountNumber';
import { requiredIfChecked } from '../validations/methods/requiredIfChecked';
import { icd } from '../validations/methods/icd';
import { minNumber } from '../validations/methods/minNumber';
import { city } from '../validations/methods/city';
import { onlyAscii } from '../validations/methods/onlyAscii';
import { registrationNumber } from '../validations/methods/registrationNumber';
import { PIAPolicyNumberYupMethod } from '@protectorinsurance/ds-can';
import { bankAccountNumber } from '../validations/methods/bankAccountNumber';
import { secureEndingEmail } from '../validations/methods/secureEndingEmail';

addMethod(string, 'requiredIf', requiredIf);
addMethod(string, 'requiredIfChecked', requiredIfChecked);
addMethod(string, 'digits', digits);
addMethod(string, 'claimNumber', claimNumber);
addMethod(string, 'businessNumber', businessNumber);
addMethod(number, 'numberOfDigits', numberOfDigits);
addMethod(number, 'minNumber', minNumber);
addMethod(string, 'noSpecialChars', noSpecialChars);
addMethod(string, 'ssn', ssn);
addMethod(string, 'accountNumber', accountNumber);
addMethod(string, 'icd', icd);
addMethod(string, 'city', city);
addMethod(string, 'onlyAscii', onlyAscii);
addMethod(string, 'registrationNumber', registrationNumber);
// @ts-ignore
addMethod(string, 'PIAPolicyNumber', PIAPolicyNumberYupMethod);
addMethod(string, 'bankAccountNumber', bankAccountNumber);
addMethod(string, 'secureEndingEmail', secureEndingEmail);
