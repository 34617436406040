import React from 'react';
import { DisplayHealthAccidentFinalSummary } from '../../components/summary/final/person/DisplayHealthAccidentFinalSummary';
import { DisplayHealthIllnessFinalSummary } from '../../components/summary/final/person/DisplayHealthIllnessFinalSummary';
import { FlowKeys, InsuranceTypeKeys, InsuranceTypeModel, is } from '@protectorinsurance/ds-can';
import { FlowState } from '../../sagas/flow';
import { DisplayGroupAccidentFinalSummary } from '../../components/summary/final/person/DisplayGroupAccidentFinalSummary';
import { DisplayWorkersCompIllnessFinalSummary } from '../../components/summary/final/person/DisplayWorkersCompIllnessFinalSummary';
import { DisplayWorkersCompAccidentFinalSummary } from '../../components/summary/final/person/DisplayWorkersCompAccidentFinalSummary';

/**
 * Destructure necessary imports
 */
const { ILLNESS } = FlowKeys;
const { GROUP_ACCIDENT, WORK_COMP } = InsuranceTypeKeys;

/**
 * Utility logic
 */
export const getFinalSummary = (
    flow: FlowState,
    insuranceType: InsuranceTypeModel,
    showSummary = true,
    editDescription = true
) => {
    if (is(insuranceType, GROUP_ACCIDENT)) {
        return <DisplayGroupAccidentFinalSummary editDescription={editDescription} showSummary={showSummary} />;
    } else if (is(insuranceType, WORK_COMP)) {
        if (is(flow, ILLNESS)) {
            return (
                <DisplayWorkersCompIllnessFinalSummary editDescription={editDescription} showSummary={showSummary} />
            );
        } else {
            return (
                <DisplayWorkersCompAccidentFinalSummary editDescription={editDescription} showSummary={showSummary} />
            );
        }
    } else if (is(flow, ILLNESS)) {
        return <DisplayHealthIllnessFinalSummary editDescription={editDescription} showSummary={showSummary} />;
    } else {
        return <DisplayHealthAccidentFinalSummary editDescription={editDescription} showSummary={showSummary} />;
    }
};
