import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

const { DIAGNOSIS_CODE_LABEL } = PhraseKeys;

export const diagnosisSchema = (t: TFunction) => {
    return {
        diagnosisCode: Yup.string().label(t(DIAGNOSIS_CODE_LABEL)).required().icd(),
    };
};
