import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    isYes,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { PersonRoutePaths } from '../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../sagas/person';
import { selectOtherInsurance } from '../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const groupAccidentHasOtherInsurancePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: YES, name: t(`lob.person.groupAccident.hasOtherInsurance.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lob.person.groupAccident.hasOtherInsurance.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_HAS_OTHER_INSURANCE,
        stateKey: 'otherInsurance',
        i18n: {
            ns: 'lob.person.groupAccident.hasOtherInsurance',
        },
        selectorValue: selectOtherInsurance(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (isYes(option.id)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }
                dispatcherWithPromise(dispatch, personActions.update, { otherInsurance: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
