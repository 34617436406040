import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { selectPlaceOfAccident, selectTypeOfRoad } from '../../../../sagas/selectors/motorSelectors';
import {
    Clickable,
    FormChangeable,
    Grid,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    TypeOfRoadKeys,
} from '@protectorinsurance/ds-can';
import { motorActions } from '../../../../sagas/motor';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { getPlaceOfAccidentOption, PlaceOfAccidentKeys } from '../../../../utils/flow/placeOfAccidentUtil';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    PLACE_OF_ACCIDENT_LABEL,
    PLACE_OF_ACCIDENT_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { PLACE_OF_ACCIDENT } = FormFieldNames;
const { FREEWAY, HIGHWAY, PRIVATE, ROAD, STREET } = TypeOfRoadKeys;
const {
    BEND,
    BRIDGE,
    EQUAL_PRIORITY_JUNCTION,
    LEVEL_CROSSING,
    OTHER,
    PARKING_AREA,
    PRIVATE_ROAD_JUNCTION,
    RIGHT_OF_WAY_JUNCTION,
    STRAIGHT_ROAD,
} = PlaceOfAccidentKeys;

/**
 * Page view and page logic
 */
export const DrivingNoVehiclePlaceOfAccidentPage = () => {
    const dispatch = useDispatch();
    const [place, setPlace] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const placeOfAccident = useSelector(selectPlaceOfAccident);
    const typeOfRoad = useSelector(selectTypeOfRoad);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.noVehiclePlaceOfAccident');

    // Options
    const bendOption = getPlaceOfAccidentOption(t, BEND);
    const bridgeOption = getPlaceOfAccidentOption(t, BRIDGE);
    const equalPriorityJunctionOption = getPlaceOfAccidentOption(t, EQUAL_PRIORITY_JUNCTION);
    const levelCrossingOption = getPlaceOfAccidentOption(t, LEVEL_CROSSING);
    const otherOption = getPlaceOfAccidentOption(t, OTHER);
    const parkingAreaOption = getPlaceOfAccidentOption(t, PARKING_AREA);
    const privateRoadJunctionOption = getPlaceOfAccidentOption(t, PRIVATE_ROAD_JUNCTION);
    const rightOfWayJunctionOption = getPlaceOfAccidentOption(t, RIGHT_OF_WAY_JUNCTION);
    const straightRoadOption = getPlaceOfAccidentOption(t, STRAIGHT_ROAD);

    let options = [parkingAreaOption, otherOption];
    if (typeOfRoad === FREEWAY || typeOfRoad === PRIVATE || typeOfRoad === ROAD || typeOfRoad === STREET) {
        options = [
            levelCrossingOption,
            privateRoadJunctionOption,
            rightOfWayJunctionOption,
            equalPriorityJunctionOption,
            bridgeOption,
            bendOption,
            straightRoadOption,
        ];
    } else if (typeOfRoad === HIGHWAY) {
        options = [bridgeOption, bendOption, straightRoadOption];
    }

    useEffect(() => {
        const selected = options.find((x) => x.value === placeOfAccident);
        if (selected) {
            setPlace(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeOfAccident, setPlace]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (placeOfAccident === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setPlace(object);
        dispatch(motorActions.update({ placeOfAccident: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (placeOfAccident === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={PLACE_OF_ACCIDENT}
                    inputFieldWrapper={'col-12'}
                    label={t(PLACE_OF_ACCIDENT_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(PLACE_OF_ACCIDENT_PLACEHOLDER)}
                    value={place}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
