import { IncapacityPeriodsModel, IncapacityPeriodsTypeModel, Nullable } from '@protectorinsurance/ds-can';
import moment from 'moment';

export interface IncapacityPeriodsFormModel extends Omit<IncapacityPeriodsModel, 'from' | 'to'> {
    from: Nullable<Date>;
    to: Nullable<Date>;
}

export const isFormEmpty = (values: IncapacityPeriodsFormModel) => {
    const { from, to } = values;
    return !from && !to;
};

export const periodIsBefore = (period: IncapacityPeriodsFormModel, existingPeriod: IncapacityPeriodsModel) => {
    return (
        !!period.to &&
        !!period.from &&
        !!existingPeriod.from &&
        moment(period.from).isBefore(existingPeriod.from) &&
        moment(period.to).isBefore(existingPeriod.from)
    );
};

export const periodIsAfter = (period: IncapacityPeriodsFormModel, existingPeriod: IncapacityPeriodsModel) => {
    return (
        !!period.from &&
        !!period.to &&
        !!existingPeriod.to &&
        moment(period.from).isAfter(existingPeriod.to) &&
        moment(period.to).isAfter(existingPeriod.to)
    );
};

export const isOverlappingPeriod = (
    period: IncapacityPeriodsFormModel,
    existingPeriods: IncapacityPeriodsTypeModel = []
): boolean => {
    const samePeriods = existingPeriods.filter((p: IncapacityPeriodsModel) => {
        return period.from && period.to && p.from && p.to && !periodIsBefore(period, p) && !periodIsAfter(period, p);
    });
    return samePeriods.length !== 0;
};

export const removeIncapacityPeriod = (
    period: IncapacityPeriodsModel,
    existingPeriods: IncapacityPeriodsTypeModel
): IncapacityPeriodsTypeModel => {
    return existingPeriods.filter((p: IncapacityPeriodsModel) => {
        return p.from !== period.from || p.to !== period.to || p.rate !== period.rate;
    });
};
