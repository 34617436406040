import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { accidentDescriptionFieldSchema } from '../fieldSchemas/accidentDescriptionFieldSchema';

/**
 * Validation logic
 */
export const accidentDescriptionSchema = (t: TFunction, label: string): Yup.ObjectSchema => {
    return Yup.object().shape({
        ...accidentDescriptionFieldSchema(t, label),
    });
};
