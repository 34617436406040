import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectAttachmentFiles } from '../../../../sagas/selectors/uploadSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { uploadAttachmentActions } from '../../../../sagas/upload/attachment/uploadAttachment';
import { deleteAttachmentActions } from '../../../../sagas/upload/attachment/deleteAttachment';
import { useGoBack } from '../../../../hooks/useGoBack';
import { Clickable, FileModel, FileUploader, PageLayout } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DROPZONE_DESCRIPTION,
    DROPZONE_TITLE,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    UPLOAD_LABEL,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const IllnessUploadAttachmentPage = () => {
    const dispatch = useDispatch();
    const fileStore = useSelector(selectAttachmentFiles);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.health.illness.uploadAttachment');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleOnSuccess = (files: FileModel[]) => {
        files.forEach((file) => dispatch(uploadAttachmentActions.request(file)));
    };

    const handleDelete = (e: Clickable, file: FileModel) => {
        e.preventDefault();
        dispatch(deleteAttachmentActions.request(file));
    };

    const checkFileErrors: boolean = fileStore.some((file: FileModel) => {
        return file.errors.length > 0;
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={checkFileErrors}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <FileUploader
                label={tWithNS.t(DROPZONE_DESCRIPTION)}
                onDelete={handleDelete}
                onSuccess={handleOnSuccess}
                text={tWithNS.t(UPLOAD_LABEL)}
                title={tWithNS.t(DROPZONE_TITLE)}
                {...{ fileStore }}
            />
        </PageLayout>
    );
};
