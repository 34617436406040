import { takeEvery, takeLatest } from 'redux-saga/effects';
import { wizardRouterActions } from './wizardRouter';
import { api } from '../utils/api';
import { BaseRoutePaths } from '../config/wizardRouter/baseWizardRoutes';
import { selectClaimReport, selectClaimReportId, selectLoggedInClaimReport } from './selectors/reportSelectors';
import {
    actionWithPromise,
    CountryCodeISOEnums,
    CountryEnums,
    customCANProps,
    emptyFn,
    FlowKeys,
    InsuranceTypeKeys,
    is,
    LanguageCodeEnums,
    LobKeys,
    LobModel,
    LocaleModel,
    LocaleServiceInstance,
    LogServiceModel,
    Nullable,
    PFError,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { selectJWT, selectLob, selectRequestId } from './selectors/commonSelectors';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { selectFlow } from './selectors/flowSelectors';
import { PersonRoutePaths } from '../config/wizardRouter/personWizardRoutes';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import { call, put, select } from 'typed-redux-saga';
import { uuidUtil } from '../utils/uuidUtils';
import { logServiceActions } from './services/logService';
import { NODE_API_BASE_URL } from '../config/api';
import { selectInsuranceType } from './selectors/personSelectors';

/**
 * Constants
 */
export enum CommonActionTypes {
    ERROR = '@common/ERROR',
    INIT = '@common/INIT',
    UPDATE = '@common/UPDATE',
    POST = '@common/POST',
    PUT = '@common/PUT',
    SEND = '@common/SEND',
    SUBMIT = '@common/SUBMIT',
    AUTH_POST = '@common/AUTH_POST',
}

/**
 * Interfaces
 */
export interface ICommonAction {
    type: CommonActionTypes;
    data?: Partial<CommonState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface CommonState extends LobModel {
    country: CountryEnums;
    customCAN?: customCANProps;
    errors: PFError[];
    id: Nullable<string>;
    jwt: Nullable<string>;
    loading: boolean;
    locale: LocaleModel;
    loggedIn: boolean;
    requestId: Nullable<string>;
    submitted: boolean;
    timeoutWarning: number;
}

/**
 * Initial state
 */
export const commonInitState: CommonState = {
    country: CountryEnums.FINLAND,
    errors: [],
    id: null,
    jwt: null,
    loading: true,
    lob: null,
    locale: {
        country: CountryCodeISOEnums.FINLAND,
        language: LanguageCodeEnums.FI,
    },
    loggedIn: false,
    requestId: null,
    submitted: false,
    timeoutWarning: 0,
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = commonInitState, { type, data }: ICommonAction) {
    switch (type) {
        case CommonActionTypes.ERROR:
            return { ...state, errors: [data] };
        case CommonActionTypes.SEND:
            return { ...state, errors: [] };
        case CommonActionTypes.UPDATE:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const commonActions = {
    init: actionWithPromise(CommonActionTypes.INIT),
    error: actionWithPromise<CommonActionTypes, Partial<PFError>>(CommonActionTypes.ERROR),
    send: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.SEND),
    put: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.PUT),
    post: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.POST),
    update: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.UPDATE),
    submit: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.SUBMIT),
    authPost: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.AUTH_POST),
};

/**
 * Saga watchers
 */
export const commonWatcher = function* () {
    yield takeLatest(CommonActionTypes.INIT, commonSagas.init);
    yield takeEvery(CommonActionTypes.POST, commonSagas.post);
    yield takeEvery(CommonActionTypes.PUT, commonSagas.put);
    yield takeEvery(CommonActionTypes.SEND, commonSagas.send);
    yield takeEvery(CommonActionTypes.SUBMIT, commonSagas.submit);
    yield takeEvery(CommonActionTypes.UPDATE, commonSagas.update);
    yield takeEvery(CommonActionTypes.AUTH_POST, commonSagas.authPost);
};

/**
 * Saga functions
 */
let sendingCounter = 0;

export const commonSagas = {
    *init() {
        const { country, language } = LocaleServiceInstance.getLocaleFromUrl();
        const requestId = yield* select(selectRequestId);
        const uuid = uuidUtil();
        const urlParams = new URLSearchParams(window.location.search);
        const company = urlParams.get('company');
        const partyId = company ? company.split('_').pop() : null;
        let customCAN;
        if (partyId) {
            const resSettings: any = yield* call(api.get, `metadata/settings/${partyId}`, {
                baseURL: NODE_API_BASE_URL,
                headers: { 'X-Request-Id': `${requestId}` },
            });
            // @ts-ignore
            const resLogo: any = yield* call(api.get, `metadata/image/${partyId}`, {
                baseURL: NODE_API_BASE_URL,
                responseType: 'blob',
                headers: { 'X-Request-Id': `${requestId}` },
            });
            customCAN = { logo: URL.createObjectURL(resLogo.data), ...resSettings.data.data };
        }

        yield* put(commonActions.update({ customCAN, loading: false, locale: { country, language }, requestId: uuid }));
    },

    // eslint-disable-next-line
    *update({ resolve = emptyFn }: ICommonAction) {
        resolve();
    },

    *post({ resolve = emptyFn, reject = emptyFn }: ICommonAction) {
        const requestId = yield* select(selectRequestId);
        const claimReportData = yield* select(selectClaimReport);
        const loggedIn = yield* select(selectLoggedInClaimReport);
        const jwt = yield* select(selectJWT);
        try {
            let res: any;
            if (loggedIn) {
                res = yield* call(
                    api.post,
                    'auth/report',
                    { ...claimReportData, jwt },
                    { headers: { 'X-Request-Id': `${requestId}` } }
                );
            } else {
                res = yield* call(api.post, `claims/report`, claimReportData, {
                    baseURL: NODE_API_BASE_URL,
                    headers: { 'X-Request-Id': `${requestId}` },
                });
            }
            yield* put(commonActions.update({ id: res.data.data.uuid }));
            resolve();
        } catch (e) {
            reject(e);
            yield* put(commonActions.error({ type: CommonActionTypes.POST, error: e as any }));
            yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
        }
    },

    *authPost({ resolve = emptyFn, reject = emptyFn }: ICommonAction) {
        yield* put(commonActions.post(undefined, resolve, reject));
    },

    *submit({ resolve = emptyFn, reject = emptyFn }: ICommonAction) {
        const requestId = yield* select(selectRequestId);
        const claimReportData = yield* select(selectClaimReport);
        const uuid = yield* select(selectClaimReportId);
        const loggedIn = yield* select(selectLoggedInClaimReport);
        const jwt = yield* select(selectJWT);
        const lob = yield* select(selectLob);
        const flow = yield* select(selectFlow);
        const insuranceType = yield* select(selectInsuranceType);
        let goToLink = wizardRouterActions.goTo(LpoRoutePaths.END_REPORT_COMPLETED);

        try {
            sendingCounter++;
            if (loggedIn) {
                yield* call(api.post, `auth/submission/${uuid}`, { ...claimReportData, jwt });
            } else {
                yield* call(api.post, `claims/submission/${uuid}/${Date.now()}/${sendingCounter++}`, claimReportData, {
                    baseURL: NODE_API_BASE_URL,
                    headers: { 'X-Request-Id': `${requestId}` },
                });
            }
        } catch (e) {
            if ((e as any).url?.includes('null')) {
                const requestId = yield* select(selectRequestId);
                const errorPayload: LogServiceModel = {
                    level: 'error',
                    message: `Missing UUID: Tried to submit claim (FIN). X-Request-Id=${requestId}`,
                };
                yield* put(logServiceActions.request(errorPayload));
            }
            /**
             * TODO Temporary fix until BE fixes the error on their hand
             *
             * See task CLAIMS-11422
             */

            if ((e as any)?.message !== 'Error deserializing Avro message for id -1') {
                reject(e);
                yield* put(commonActions.error({ type: CommonActionTypes.SUBMIT, error: e as any }));
                yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
            }
        }

        try {
            if (is(lob, LobKeys.PERSON)) {
                if (is(insuranceType, InsuranceTypeKeys.GROUP_ACCIDENT)) {
                    goToLink = wizardRouterActions.goTo(PersonRoutePaths.GROUP_ACCIDENT_REPORT_COMPLETED);
                } else if (is(insuranceType, InsuranceTypeKeys.WORK_COMP)) {
                    if (is(flow, FlowKeys.ACCIDENT)) {
                        goToLink = wizardRouterActions.goTo(PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORT_COMPLETED);
                    } else {
                        goToLink = wizardRouterActions.goTo(PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORT_COMPLETED);
                    }
                } else {
                    if (is(flow, FlowKeys.ACCIDENT)) {
                        goToLink = wizardRouterActions.goTo(PersonRoutePaths.H_A_REPORT_COMPLETED);
                    } else {
                        goToLink = wizardRouterActions.goTo(PersonRoutePaths.H_I_REPORT_COMPLETED);
                    }
                }
            }

            if (is(lob, LobKeys.AUTO)) {
                goToLink = wizardRouterActions.goTo(MotorRoutePaths.END_REPORT_COMPLETED);
            }

            yield* put(goToLink);
            resolve();
        } catch (e) {
            reject(e);
            yield* put(commonActions.error({ type: CommonActionTypes.SUBMIT, error: e as any }));
            yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
        }
    },

    *put({ data, resolve = emptyFn, reject = emptyFn }: ICommonAction) {
        const requestId = yield* select(selectRequestId);
        const claimReportData = yield* select(selectClaimReport);
        const loggedIn = yield* select(selectLoggedInClaimReport);
        const jwt = yield* select(selectJWT);
        try {
            if (loggedIn) {
                yield* call(
                    api.put,
                    `auth/store/${data && data.id}`,
                    { ...claimReportData, jwt },
                    { headers: { 'X-Request-Id': `${requestId}` } }
                );
            } else {
                yield* call(api.put, `claims/store/${data && data.id}`, claimReportData, {
                    baseURL: NODE_API_BASE_URL,
                    headers: { 'X-Request-Id': `${requestId}` },
                });
            }
            resolve();
        } catch (e) {
            reject(e);
            yield* put(commonActions.error({ type: CommonActionTypes.PUT, error: e as any }));
            yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
        }
    },

    *send({ resolve, reject }: ICommonAction) {
        const id = yield* select(selectClaimReportId);
        if (id === null) {
            yield* put(commonActions.post(undefined, resolve, reject));
        } else {
            yield* put(commonActions.put({ id }, resolve, reject));
        }
    },
};
