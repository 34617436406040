import React from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { useForm } from 'react-hook-form';
import {
    ClaimTypeEnums,
    getLocaleFromUrl,
    Grid,
    HiddenInputSubmit,
    is,
    Nullable,
    PageLayout,
    updateDateByDate,
} from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { getMomentLocale } from '../../../utils/locale/getMomentLocale';
import { selectClaimType, selectTravelDates } from '../../../sagas/selectors/lpoSelectors';
import { TravelDatesModel } from '../../../interfaces/models/TravelDates.model';
import { travelDatesSchema } from '../../../validations/schemas/travelDatesSchema';
import { lpoActions } from '../../../sagas/lpo';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { DatePickerInput } from '../../../components/date/DatePickerInput';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, FROM_DATE_LABEL, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE, TO_DATE_LABEL } =
    PhraseKeys;
const { FROM_DATE, TO_DATE } = FormFieldNames;
const { INVALID_DATE_ORDER } = FormFieldErrors;
const { INTERRUPTION } = ClaimTypeEnums;
const { TRAVEL_TRAVEL_INTERRUPTION } = LpoRoutePaths;

/**
 * Interfaces
 */
interface TravelDatesFormModel extends Omit<TravelDatesModel, 'from' | 'to'> {
    from: Nullable<Date>;
    to: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const TravelTravelDatesPage = () => {
    const dispatch = useDispatch();
    const travelDates = useSelector(selectTravelDates);
    const claimType = useSelector(selectClaimType);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.travel.travelDates');
    const {
        clearErrors,
        formState: { errors },
        handleSubmit,
        setError,
        setValue,
        watch,
    } = useForm<TravelDatesFormModel>({
        resolver: yupResolver(travelDatesSchema(t)),
        defaultValues: {
            from: travelDates.from?.toDate() || null,
            to: travelDates.to?.toDate() || null,
        },
    });
    const values = watch();

    const handleBackButton = useGoBack();

    const onSubmit = (dates: TravelDatesFormModel) => {
        const datesInOrder = moment(dates.from).isBefore(moment(dates.to));
        if (datesInOrder) {
            let nextAction = wizardActions.goToNext();
            if (is(claimType, INTERRUPTION)) {
                nextAction = wizardActions.goTo(TRAVEL_TRAVEL_INTERRUPTION);
            }

            dispatcherWithPromise(dispatch, lpoActions.update, {
                travelDates: {
                    from: dates.from ? updateDateByDate(moment(dates.from), moment(dates.from)) : null,
                    to: dates.to ? updateDateByDate(moment(dates.to), moment(dates.to)) : null,
                },
            }).then(() => {
                dispatch(nextAction);
            });
        } else {
            setError('from', { message: t(INVALID_DATE_ORDER), type: 'dateFormat' });
        }
    };

    const handleFromDateChange = (newValue: any) => {
        clearErrors('from');
        setValue('from', newValue, { shouldValidate: true });
    };

    const handleToDateChange = (newValue: Nullable<Date>) => {
        setValue('to', newValue, { shouldValidate: true });
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <DatePickerInput
                        error={!!errors.from}
                        errorMessage={errors.from?.message}
                        id={FROM_DATE}
                        inputFieldWrapper={'col-6'}
                        label={t(FROM_DATE_LABEL)}
                        locale={getMomentLocale(getLocaleFromUrl())}
                        onChange={handleFromDateChange}
                        value={values.from}
                        {...{ customCAN }}
                    />
                    <DatePickerInput
                        error={!!errors.to}
                        errorMessage={errors.to?.message}
                        id={TO_DATE}
                        inputFieldWrapper={'col-6'}
                        label={t(TO_DATE_LABEL)}
                        locale={getMomentLocale(getLocaleFromUrl())}
                        onChange={handleToDateChange}
                        value={values.to}
                        {...{ customCAN }}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
