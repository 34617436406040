import React from 'react';
import classNames from 'classnames';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { is, isYes, LobKeys } from '@protectorinsurance/ds-can';
import { BaseRoutePaths } from '../../../config/wizardRouter/baseWizardRoutes';
import { LpoRoutePaths } from 'config/wizardRouter/lpoWizardRoutes';
import { renderLob } from './components/start/RenderLob';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { renderClaimDate } from './components/start/RenderClaimDate';
import { renderClaimType } from './components/start/RenderClaimType';
import { renderLocation } from './components/start/RenderLocation';
import { renderUnit } from './components/start/RenderUnit';
import { renderIsPoliceContacted } from './components/start/RenderIsPoliceContacted';
import { renderClaimDescription } from './components/start/RenderClaimDescription';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { renderPoliceCaseNumber } from './components/start/RenderPoliceCaseNumber';
import { LpoSummaryProps } from 'interfaces/types/SummaryTypes';
import { renderTravelInterruption } from './components/start/RenderTravelInterruption';
import { renderTravelDates } from './components/start/RenderTravelDates';

/**
 * Destructure necessary imports
 */
const { INDEX } = BaseRoutePaths;
const {
    DYN_CAROUSEL_START_IS_POLICE_CONTACTED,
    DYN_CAROUSEL_TRAVEL_CLAIM_TYPE,
    PROPERTY_PROPERTY_ADDRESS,
    START_ACCIDENT_LOCATION,
    START_CLAIM_DATE,
    START_CLAIM_DESCRIPTION,
    START_POLICE_CASE_NUMBER,
    TRAVEL_TRAVEL_DATES,
    TRAVEL_TRAVEL_INTERRUPTION,
} = LpoRoutePaths;
const { PROPERTY } = LobKeys;

/**
 * Component view and component logic
 */
export const DisplayStartSummary = ({ model, t, tWithNS, handleClick, upload, common }: LpoSummaryProps) => {
    const {
        accidentLocation,
        claimDate,
        claimDescription,
        claimType,
        isPoliceContacted,
        policeCaseNumber,
        travelDates,
        travelInterruption,
    } = model;
    const { lob } = common;

    // Classes
    const locationClass = classNames(
        {
            'col-6': is(lob, PROPERTY),
            'col-12': !is(lob, PROPERTY),
        },
        'divider text-align-left'
    );
    const policeClass = classNames(
        {
            'col-6': isYes(isPoliceContacted),
            'col-12': !isYes(isPoliceContacted),
        },
        'divider text-align-left'
    );

    return (
        <>
            {renderLob({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('lob.heading.title'),
                dataTestId: TEST_ID_PREFIX.lob,
                buttonText: t(`lob.select.${lob}`),
                path: INDEX,
                handleClick,
            })}

            {renderClaimType(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimType.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimType,
                    buttonText: claimType ? t(`lpo.claimType.select.${claimType}`) : '-',
                    path: DYN_CAROUSEL_TRAVEL_CLAIM_TYPE,
                    handleClick,
                },
                lob
            )}

            {renderClaimDate({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtParseAndFormat(claimDate) : '-',
                path: START_CLAIM_DATE,
                handleClick,
            })}

            {renderTravelDates(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('travelDates.heading.title'),
                    dataTestId: TEST_ID_PREFIX.travelDates,
                    buttonText: `${travelDates.from ? dtParseAndFormat(travelDates.from) : '-'} - ${
                        travelDates.to ? dtParseAndFormat(travelDates.to) : '-'
                    }`,
                    path: TRAVEL_TRAVEL_DATES,
                    handleClick,
                },
                lob
            )}

            {renderLocation(
                {
                    inputWrapperClass: locationClass,
                    textKey: tWithNS('accidentLocation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.accidentLocation,
                    buttonText: accidentLocation.note
                        ? accidentLocation.note
                        : `${accidentLocation.latitude}, ${accidentLocation.longitude}`,
                    path: START_ACCIDENT_LOCATION,
                    handleClick,
                },
                accidentLocation
            )}

            {renderUnit(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('propertyAddress.heading.title'),
                    dataTestId: TEST_ID_PREFIX.propertyAddress,
                    buttonText: accidentLocation.unit ? accidentLocation.unit : '-',
                    path: PROPERTY_PROPERTY_ADDRESS,
                    handleClick,
                },
                accidentLocation,
                lob
            )}
            {renderTravelInterruption(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('travelInterruption.heading.title'),
                    dataTestId: TEST_ID_PREFIX.travelInterruption,
                    buttonText: travelInterruption ? travelInterruption : '-',
                    path: TRAVEL_TRAVEL_INTERRUPTION,
                    handleClick,
                },
                lob
            )}

            {renderIsPoliceContacted(
                {
                    inputWrapperClass: policeClass,
                    textKey: tWithNS('isPoliceContacted.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isPoliceContacted,
                    buttonText: t(createTextYesNoDontKnow(isPoliceContacted)),
                    path: DYN_CAROUSEL_START_IS_POLICE_CONTACTED,
                    handleClick,
                },
                isPoliceContacted
            )}

            {renderPoliceCaseNumber(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('policeCaseNumber.heading.title'),
                    dataTestId: TEST_ID_PREFIX.policeCaseNumber,
                    buttonText: policeCaseNumber ? policeCaseNumber : '-',
                    path: START_POLICE_CASE_NUMBER,
                    handleClick,
                },
                isPoliceContacted
            )}

            {renderClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimDescription,
                    buttonText: claimDescription ? claimDescription : '-',
                    path: START_CLAIM_DESCRIPTION,
                    handleClick,
                },
                claimDescription
            )}
        </>
    );
};
