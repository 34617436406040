import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { companyNameSchema } from '../fieldSchemas/companyNameSchema';
import { businessNumberSchema } from '../fieldSchemas/businessNumberSchema';
import { policyNumberSchema } from '../fieldSchemas/policyNumberSchema';

/**
 * Validation logic
 */
export const policyHolderSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...companyNameSchema(t),
        ...businessNumberSchema(t, true),
        ...policyNumberSchema(t, false),
    });
};
