import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    AtARecreationalEventIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    DuringAHealthCareAppointmentIcon,
    InOtherActivitiesIcon,
    InTheCourseOfActivitiesToMaintainTheAbilityToWorkIcon,
    InTheCourseOfTravelToEventIcon,
    is,
    OccupationalConditionEnums,
    TrainingEventIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectOccupationalCondition } from '../../../../../sagas/selectors/personSelectors';
import {
    createOccupationalConditionKey,
    getOccupationalConditionOption,
} from '../../../../../utils/person/occupationalConditionUtils';

/**
 * Destructure necessary imports
 */
const {
    AT_RECREATIONAL_EVENT,
    AT_TRAINING_EVENT,
    EMPLOYER_PROVIDED_ACTIVITY,
    HEALTH_CARE_APPOINTMENT,
    OTHER_ACTIVITIES,
    TRAVEL_TO_ACTIVITY,
} = OccupationalConditionEnums;
const { WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS } = PersonRoutePaths;

/**
 * Page view and page logic
 */
export const workersCompAccidentOtherOccupationalConditionPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        {
            id: AT_TRAINING_EVENT,
            name: t(createOccupationalConditionKey(AT_TRAINING_EVENT)),
            icon: <TrainingEventIcon />,
        },
        {
            id: EMPLOYER_PROVIDED_ACTIVITY,
            name: t(createOccupationalConditionKey(EMPLOYER_PROVIDED_ACTIVITY)),
            icon: <InTheCourseOfActivitiesToMaintainTheAbilityToWorkIcon />,
        },
        {
            id: AT_RECREATIONAL_EVENT,
            name: t(createOccupationalConditionKey(AT_RECREATIONAL_EVENT)),
            icon: <AtARecreationalEventIcon />,
        },
        {
            id: TRAVEL_TO_ACTIVITY,
            name: t(createOccupationalConditionKey(TRAVEL_TO_ACTIVITY)),
            icon: <InTheCourseOfTravelToEventIcon />,
        },
        {
            id: HEALTH_CARE_APPOINTMENT,
            name: t(createOccupationalConditionKey(HEALTH_CARE_APPOINTMENT)),
            icon: <DuringAHealthCareAppointmentIcon />,
        },
        {
            id: OTHER_ACTIVITIES,
            name: t(createOccupationalConditionKey(OTHER_ACTIVITIES)),
            icon: <InOtherActivitiesIcon />,
        },
    ];

    // Numbers are set based on what Backend needs for mapping
    const atRecreationalEventOption = getOccupationalConditionOption(t, AT_RECREATIONAL_EVENT, '824');
    const atTrainingEventOption = getOccupationalConditionOption(t, AT_TRAINING_EVENT, '823');
    const employerProvidedActivityOption = getOccupationalConditionOption(t, EMPLOYER_PROVIDED_ACTIVITY, '825');
    const healthCareAppointmentOption = getOccupationalConditionOption(t, HEALTH_CARE_APPOINTMENT, '826');
    const otherActivitiesOption = getOccupationalConditionOption(t, OTHER_ACTIVITIES, '');
    const travelToActivityOption = getOccupationalConditionOption(t, TRAVEL_TO_ACTIVITY, '829');

    const values = [
        atTrainingEventOption,
        employerProvidedActivityOption,
        atRecreationalEventOption,
        travelToActivityOption,
        healthCareAppointmentOption,
        otherActivitiesOption,
    ];

    const getValues = (option: string) => {
        const selected = values.find((x) => x.label === option);
        if (selected) {
            return { id: Number(selected.value), propertyId: 32, key: selected.label };
        }
        return null;
    };

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OTHER_OCCUPATIONAL_CONDITION,
        stateKey: 'occupationalCondition',
        i18n: {
            ns: 'lob.person.workersComp.accident.occupationalCondition',
        },
        selectorValue: selectOccupationalCondition(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext();
                if (is(option.id, OTHER_ACTIVITIES)) {
                    nextAction = wizardActions.goTo(WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS);
                }
                dispatcherWithPromise(dispatch, personActions.update, { occupationalCondition: getValues(option.name) })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
