import React from 'react';
import { SummaryProps } from '../../../../interfaces/types/SummaryTypes';
import { PersonState } from '../../../../sagas/person';
import { PersonRoutePaths } from '../../../../config/wizardRouter/personWizardRoutes';
import { createTextYesNoDontKnow, trueFalseToYesNo } from '../../../../utils/displayUtils';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { DisplayStartSituationSummary } from '../../start/DisplayStartSituationSummary';
import { FileList, FileModel, isYes, SummaryItem } from '@protectorinsurance/ds-can';
import { dtParseAndFormat } from '../../../../utils/date/dateUtils';

interface DisplayHealthIllnessSituationSummaryProps extends SummaryProps {
    model: PersonState;
    receipts: FileModel[];
    attachments: FileModel[];
}

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_HEALTH_CATEGORY,
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_APPLIED_KELA,
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_ATTACHMENTS,
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_DIAGNOSIS_CODE,
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_EXPENSES,
    DYN_CAROUSEL_HEALTH_ILLNESS_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_HEALTH_ILLNESS_TYPE_OF_DOCTOR,
    DYN_CAROUSEL_HEALTH_ILLNESS_WHO_PAID_EXPENSES,
    DYN_CAROUSEL_HEALTH_ILLNESS_CLAIM_REPORTER_ROLE,
    DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
    INDEX,
    START_CLAIM_NUMBER_INFORMATION,
    H_I_PRIVACY,
    H_I_CLAIM_DATE,
    H_I_DIAGNOSIS_CODE_INFORMATION,
    H_I_OTHER_INSURANCE_COMPANY,
    H_I_UPLOAD_RECEIPT,
    H_I_UPLOAD_ATTACHMENT,
} = PersonRoutePaths;

export const DisplayHealthIllnessSituationSummary = ({
    model,
    receipts,
    attachments,
    t,
    handleClick,
}: DisplayHealthIllnessSituationSummaryProps) => {
    /**
     * Helpers
     */
    const {
        hasExistingClaim,
        claimNumber,
        insuranceType,
        category,
        claimReporterRole,
        acceptedPrivacy,
        claimDate,
        hasDiagnosisCode,
        diagnosisCode,
        hasAppliedKela,
        otherInsurance,
        otherInsuranceCompany,
        hasExpenses,
        whoPaidExpenses,
        typeOfDoctor,
        hasAttachment,
    } = model;

    const renderClaimReport = () => {
        return (
            <DisplayStartSituationSummary
                hasExistingClaim={{
                    showExistingClaim: isYes(hasExistingClaim),
                    textKey: t('lob.person.health.illness.situationSummary.hasExistingClaim.heading.title'),
                    buttonText: t(createTextYesNoDontKnow(hasExistingClaim)),
                    path: DYN_CAROUSEL_START_HAS_EXISTING_CLAIM,
                    handleClick,
                }}
                claimNumber={{
                    textKey: t('lob.person.health.illness.situationSummary.claimNumberInformation.heading.title'),
                    buttonText: claimNumber ? claimNumber : '',
                    path: START_CLAIM_NUMBER_INFORMATION,
                    handleClick,
                }}
                insuranceType={{
                    textKey: t('lob.person.health.illness.situationSummary.insuranceType.heading.title'),
                    buttonText: t(`lob.person.start.insuranceType.select.${insuranceType}`),
                    path: INDEX,
                    handleClick,
                }}
                category={{
                    textKey: t('lob.person.health.illness.situationSummary.category.heading.title'),
                    buttonText: t(`lob.person.health.category.select.${category}`),
                    path: DYN_CAROUSEL_HEALTH_CATEGORY,
                    handleClick,
                }}
                claimReporterRole={{
                    textKey: t('lob.person.health.illness.situationSummary.claimReporterRole.heading.title'),
                    buttonText: t(`lob.person.health.illness.claimReporterRole.select.${claimReporterRole}`),
                    path: DYN_CAROUSEL_HEALTH_ILLNESS_CLAIM_REPORTER_ROLE,
                    handleClick,
                }}
            />
        );
    };

    const renderPrivacy = () => {
        return (
            <>
                {/* Privacy */}
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.health.illness.situationSummary.privacy.heading.title')}
                    dataTestId={TEST_ID_PREFIX.acceptedPrivacy}
                    buttonText={t(trueFalseToYesNo(acceptedPrivacy))}
                    path={H_I_PRIVACY}
                    handleClick={handleClick}
                />
            </>
        );
    };

    const renderClaim = () => {
        return (
            <>
                {/* Claim Date */}
                <SummaryItem
                    inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                    textKey={t('lob.person.health.illness.situationSummary.claimDate.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimDate}
                    buttonText={claimDate ? dtParseAndFormat(claimDate) : ''}
                    path={H_I_CLAIM_DATE}
                    handleClick={handleClick}
                />

                {/* Has Diagnosis Code */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasDiagnosisCode) ? 'col-4' : 'col-8'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.health.illness.situationSummary.hasDiagnosisCode.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasDiagnosisCode}
                    buttonText={t(createTextYesNoDontKnow(hasDiagnosisCode))}
                    path={DYN_CAROUSEL_HEALTH_ILLNESS_HAS_DIAGNOSIS_CODE}
                    handleClick={handleClick}
                />

                {/* Diagnosis Code Information */}
                {isYes(hasDiagnosisCode) && (
                    <SummaryItem
                        inputWrapperClass={'col-4 divider align-button-start text-align-left'}
                        textKey={t('lob.person.health.illness.situationSummary.diagnosisCode.heading.title')}
                        dataTestId={TEST_ID_PREFIX.diagnosisCode}
                        buttonText={diagnosisCode ? diagnosisCode : ''}
                        path={H_I_DIAGNOSIS_CODE_INFORMATION}
                        handleClick={handleClick}
                    />
                )}
            </>
        );
    };

    const renderKela = () => {
        return (
            <>
                {/* Has Applied Kela */}
                <SummaryItem
                    inputWrapperClass={'col-12 divider align-button-start text-align-left'}
                    textKey={t('lob.person.health.illness.situationSummary.hasAppliedKela.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasAppliedKela}
                    buttonText={t(createTextYesNoDontKnow(hasAppliedKela))}
                    path={DYN_CAROUSEL_HEALTH_ILLNESS_HAS_APPLIED_KELA}
                    handleClick={handleClick}
                />
            </>
        );
    };

    const renderOtherInsurance = () => {
        return (
            <>
                <>
                    {/* Has Other Insurance */}
                    <SummaryItem
                        inputWrapperClass={`${
                            isYes(otherInsurance) ? 'col-4' : 'col-12'
                        } divider align-button-start text-align-left`}
                        textKey={t('lob.person.health.illness.situationSummary.hasOtherInsurance.heading.title')}
                        dataTestId={TEST_ID_PREFIX.otherInsurance}
                        buttonText={t(createTextYesNoDontKnow(otherInsurance))}
                        path={DYN_CAROUSEL_HEALTH_ILLNESS_HAS_OTHER_INSURANCE}
                        handleClick={handleClick}
                    />

                    {/* Other Insurance Company */}
                    {isYes(otherInsurance) && (
                        <SummaryItem
                            inputWrapperClass={'col-8 divider align-button-start text-align-left'}
                            textKey={t(
                                'lob.person.health.illness.situationSummary.otherInsuranceCompany.heading.title'
                            )}
                            dataTestId={TEST_ID_PREFIX.otherInsuranceCompany}
                            buttonText={otherInsuranceCompany ? otherInsuranceCompany : ''}
                            path={H_I_OTHER_INSURANCE_COMPANY}
                            handleClick={handleClick}
                        />
                    )}
                </>
            </>
        );
    };

    const renderExpenses = () => {
        return (
            <>
                {/* Has Expenses */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasExpenses) ? 'col-4 spacing' : 'col-12 divider'
                    } align-button-start text-align-left`}
                    textKey={t('lob.person.health.illness.situationSummary.hasExpenses.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasExpenses}
                    buttonText={t(createTextYesNoDontKnow(hasExpenses))}
                    path={DYN_CAROUSEL_HEALTH_ILLNESS_HAS_EXPENSES}
                    handleClick={handleClick}
                />

                {isYes(hasExpenses) && (
                    <>
                        {/* Type Of Doctor */}
                        <SummaryItem
                            inputWrapperClass={'col-4 spacing align-button-start text-align-left'}
                            textKey={t('lob.person.health.illness.situationSummary.typeOfDoctor.heading.title')}
                            dataTestId={TEST_ID_PREFIX.typeOfDoctor}
                            buttonText={t(`lob.person.health.illness.typeOfDoctor.select.${typeOfDoctor}`)}
                            path={DYN_CAROUSEL_HEALTH_ILLNESS_TYPE_OF_DOCTOR}
                            handleClick={handleClick}
                        />

                        {/* Who Paid Expenses */}
                        <SummaryItem
                            inputWrapperClass={'col-4 spacing align-button-start text-align-left'}
                            textKey={t('lob.person.health.illness.situationSummary.whoPaidExpenses.heading.title')}
                            dataTestId={TEST_ID_PREFIX.whoPaidExpenses}
                            buttonText={t(`lob.person.health.illness.whoPaidExpenses.select.${whoPaidExpenses}`)}
                            path={DYN_CAROUSEL_HEALTH_ILLNESS_WHO_PAID_EXPENSES}
                            handleClick={handleClick}
                        />

                        {/* List of Receipts */}
                        <div className={'col-12 divider align-button-start text-align-left'}>
                            <FileList
                                list={receipts}
                                path={H_I_UPLOAD_RECEIPT}
                                show={receipts.length > 0}
                                dataTestId={TEST_ID_PREFIX.uploadReceipt}
                                textKey={`${t(
                                    'lob.person.health.illness.situationSummary.uploadReceipt.heading.title'
                                )}`}
                                handleClick={handleClick}
                            />{' '}
                        </div>
                    </>
                )}
            </>
        );
    };

    const renderAttachments = () => {
        return (
            <>
                {/* Has Attachments */}
                <SummaryItem
                    inputWrapperClass={`${
                        isYes(hasAttachment) ? 'col-4' : 'col-12'
                    } divider align-button-start text-align-left`}
                    textKey={t('lob.person.health.illness.situationSummary.hasAttachments.heading.title')}
                    dataTestId={TEST_ID_PREFIX.hasAttachments}
                    buttonText={t(createTextYesNoDontKnow(hasAttachment))}
                    path={DYN_CAROUSEL_HEALTH_ILLNESS_HAS_ATTACHMENTS}
                    handleClick={handleClick}
                />

                {/* List of Attachments */}
                {isYes(hasAttachment) && (
                    <div className={'col-8 divider align-button-start text-align-left'}>
                        <FileList
                            list={attachments}
                            path={H_I_UPLOAD_ATTACHMENT}
                            show={attachments.length > 0}
                            dataTestId={TEST_ID_PREFIX.uploadAttachments}
                            textKey={`${t(
                                'lob.person.health.illness.situationSummary.uploadAttachment.heading.title'
                            )}`}
                            handleClick={handleClick}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {renderClaimReport()}

            {renderPrivacy()}

            {renderClaim()}

            {renderKela()}

            {renderOtherInsurance()}

            {renderExpenses()}

            {renderAttachments()}
        </>
    );
};
