import { AppState } from '../../store';

export const selectLpoState = (state: AppState) => state.lpo;

// Start selectors
export const selectClaimType = (state: AppState) => state.lpo.claimType;
export const selectClaimCause = (state: AppState) => state.lpo.claimCause;
export const selectAcceptedPrivacy = (state: AppState) => state.lpo.acceptedPrivacy;
export const selectClaimDate = (state: AppState) => state.lpo.claimDate;
export const selectAccidentLocation = (state: AppState) => state.lpo.accidentLocation;
export const selectIsPoliceContacted = (state: AppState) => state.lpo.isPoliceContacted;
export const selectPoliceCaseNumber = (state: AppState) => state.lpo.policeCaseNumber;
export const selectClaimDescription = (state: AppState) => state.lpo.claimDescription;

// Travel selectors
export const selectTravelDates = (state: AppState) => state.lpo.travelDates;
export const selectTravelInterruption = (state: AppState) => state.lpo.travelInterruption;
export const selectClaimantInformation = (state: AppState) => state.lpo.claimantInformation;

// End selectors
export const selectHasOtherInsurance = (state: AppState) => state.lpo.otherInsurance;
export const selectOtherInsuranceCompany = (state: AppState) => state.lpo.otherInsuranceCompany;
export const selectCompanyInformation = (state: AppState) => state.lpo.companyInformation;
export const selectReporterInformation = (state: AppState) => state.lpo.reporterInformation;
export const selectIsPolicyHolderReporter = (state: AppState) => state.lpo.isPolicyHolderReporter;
export const selectPolicyHoldersContact = (state: AppState) => state.lpo.policyHoldersContact;
export const selectExternalReference = (state: AppState) => state.lpo.externalReference;
export const selectAcceptedPoliceContact = (state: AppState) => state.lpo.acceptedPoliceContact;
export const selectBankAccountInformation = (state: AppState) => state.lpo.bankAccountInformation;
