import { OptionType } from '@protectorinsurance/ds-can';

export const countriesSE: OptionType[] = [
    {
        value: '1',
        label: 'Afghanistan',
    },
    {
        value: '2',
        label: 'Albanien',
    },
    {
        value: '3',
        label: 'Antarktis',
    },
    {
        value: '4',
        label: 'Algeriet',
    },
    {
        value: '5',
        label: 'Amerikanska Samoa',
    },
    {
        value: '6',
        label: 'Andorra',
    },
    {
        value: '7',
        label: 'Angola',
    },
    {
        value: '8',
        label: 'Antigua och Barbuda',
    },
    {
        value: '9',
        label: 'Azerbajdzjan',
    },
    {
        value: '10',
        label: 'Argentina',
    },
    {
        value: '11',
        label: 'Australien',
    },
    {
        value: '12',
        label: 'Österrike',
    },
    {
        value: '13',
        label: 'Bahamas',
    },
    {
        value: '14',
        label: 'Bahrain',
    },
    {
        value: '15',
        label: 'Bangladesh',
    },
    {
        value: '16',
        label: 'Armenien',
    },
    {
        value: '17',
        label: 'Barbados',
    },
    {
        value: '18',
        label: 'Belgien',
    },
    {
        value: '19',
        label: 'Bermuda',
    },
    {
        value: '20',
        label: 'Bhutan',
    },
    {
        value: '21',
        label: 'Bolivia',
    },
    {
        value: '22',
        label: 'Bosnien och Hercegovina',
    },
    {
        value: '23',
        label: 'Botswana',
    },
    {
        value: '24',
        label: 'Bouvetön',
    },
    {
        value: '25',
        label: 'Brasilien',
    },
    {
        value: '26',
        label: 'Belize',
    },
    {
        value: '27',
        label: 'Brittiska territoriet i Indiska oceanen',
    },
    {
        value: '28',
        label: 'Salomonöarna',
    },
    {
        value: '29',
        label: 'Brittiska Jungfruöarna',
    },
    {
        value: '30',
        label: 'Brunei',
    },
    {
        value: '31',
        label: 'Bulgarien',
    },
    {
        value: '32',
        label: 'Myanmar',
    },
    {
        value: '33',
        label: 'Burundi',
    },
    {
        value: '34',
        label: 'Vitryssland',
    },
    {
        value: '35',
        label: 'Kambodja',
    },
    {
        value: '36',
        label: 'Kamerun',
    },
    {
        value: '37',
        label: 'Kanada',
    },
    {
        value: '38',
        label: 'Cap Verde',
    },
    {
        value: '39',
        label: 'Caymanöarna',
    },
    {
        value: '40',
        label: 'Centralafrikanska republiken',
    },
    {
        value: '41',
        label: 'Sri Lanka',
    },
    {
        value: '42',
        label: 'Tchad',
    },
    {
        value: '43',
        label: 'Chile',
    },
    {
        value: '44',
        label: 'Kina',
    },
    {
        value: '45',
        label: 'Taiwan',
    },
    {
        value: '46',
        label: 'Julön',
    },
    {
        value: '47',
        label: 'Cocosöarna',
    },
    {
        value: '48',
        label: 'Colombia',
    },
    {
        value: '49',
        label: 'Komorerna',
    },
    {
        value: '50',
        label: 'Mayotte',
    },
    {
        value: '51',
        label: 'Kongo (Kongo-Brazzaville)',
    },
    {
        value: '52',
        label: 'Kongo (Kongo-Kinshasa)',
    },
    {
        value: '53',
        label: 'Cooköarna',
    },
    {
        value: '54',
        label: 'Costa Rica',
    },
    {
        value: '55',
        label: 'Kroatien',
    },
    {
        value: '56',
        label: 'Kuba',
    },
    {
        value: '57',
        label: 'Cypern',
    },
    {
        value: '58',
        label: 'Tjeckien',
    },
    {
        value: '59',
        label: 'Benin',
    },
    {
        value: '60',
        label: 'Danmark',
    },
    {
        value: '61',
        label: 'Dominica',
    },
    {
        value: '62',
        label: 'Dominikanska republiken',
    },
    {
        value: '63',
        label: 'Ecuador',
    },
    {
        value: '64',
        label: 'El Salvador',
    },
    {
        value: '65',
        label: 'Ekvatorialguinea',
    },
    {
        value: '66',
        label: 'Etiopien',
    },
    {
        value: '67',
        label: 'Eritrea',
    },
    {
        value: '68',
        label: 'Estland',
    },
    {
        value: '69',
        label: 'Färöarna',
    },
    {
        value: '70',
        label: 'Falklandsöarna',
    },
    {
        value: '71',
        label: 'South Georgia och South Sandwich Islands',
    },
    {
        value: '72',
        label: 'Fvaluez',
    },
    {
        value: '73',
        label: 'Finland',
    },
    {
        value: '74',
        label: 'Ett land',
    },
    {
        value: '75',
        label: 'Frankrike',
    },
    {
        value: '76',
        label: 'Franska Guyana',
    },
    {
        value: '77',
        label: 'Franska Polynesien',
    },
    {
        value: '78',
        label: 'Södra Frankrike',
    },
    {
        value: '79',
        label: 'Djibouti',
    },
    {
        value: '80',
        label: 'Gabon',
    },
    {
        value: '81',
        label: 'Georgien',
    },
    {
        value: '82',
        label: 'Gambia',
    },
    {
        value: '83',
        label: 'Staten Palestina',
    },
    {
        value: '84',
        label: 'Tyskland',
    },
    {
        value: '85',
        label: 'Ghana',
    },
    {
        value: '86',
        label: 'Stenen',
    },
    {
        value: '87',
        label: 'Kiribati',
    },
    {
        value: '88',
        label: 'Grekland',
    },
    {
        value: '89',
        label: 'Grönland',
    },
    {
        value: '90',
        label: 'Grenada',
    },
    {
        value: '91',
        label: 'Guadeloupe',
    },
    {
        value: '92',
        label: 'Guam',
    },
    {
        value: '93',
        label: 'Guatemala',
    },
    {
        value: '94',
        label: 'Guinea',
    },
    {
        value: '95',
        label: 'Guyana',
    },
    {
        value: '96',
        label: 'Haiti',
    },
    {
        value: '97',
        label: 'Heard och McDonaldöarna',
    },
    {
        value: '98',
        label: 'Vatikanen',
    },
    {
        value: '99',
        label: 'Honduras',
    },
    {
        value: '100',
        label: 'Hong Kong',
    },
    {
        value: '101',
        label: 'Ungern',
    },
    {
        value: '102',
        label: 'Island',
    },
    {
        value: '103',
        label: 'Indien',
    },
    {
        value: '104',
        label: 'Indonesien',
    },
    {
        value: '105',
        label: 'Iran',
    },
    {
        value: '106',
        label: 'Irak',
    },
    {
        value: '107',
        label: 'Irland',
    },
    {
        value: '108',
        label: 'Israel',
    },
    {
        value: '109',
        label: 'Italien',
    },
    {
        value: '110',
        label: 'Elfenbenskusten',
    },
    {
        value: '111',
        label: 'Jamaica',
    },
    {
        value: '112',
        label: 'Japan',
    },
    {
        value: '113',
        label: 'Kazakstan',
    },
    {
        value: '114',
        label: 'Jordanien',
    },
    {
        value: '115',
        label: 'Kenya',
    },
    {
        value: '116',
        label: 'Demokratiska folkrepubliken Korea (Nordkorea)',
    },
    {
        value: '117',
        label: 'Republiken Korea (Sydkorea)',
    },
    {
        value: '118',
        label: 'Kuwait',
    },
    {
        value: '119',
        label: 'Kirgizistan',
    },
    {
        value: '120',
        label: 'Laos',
    },
    {
        value: '121',
        label: 'Libanon',
    },
    {
        value: '122',
        label: 'Lesotho',
    },
    {
        value: '123',
        label: 'Lettland',
    },
    {
        value: '124',
        label: 'Liberia',
    },
    {
        value: '125',
        label: 'Libyen',
    },
    {
        value: '126',
        label: 'Liechtenstein',
    },
    {
        value: '127',
        label: 'Litauen',
    },
    {
        value: '128',
        label: 'Luxemburg',
    },
    {
        value: '129',
        label: 'Macao',
    },
    {
        value: '130',
        label: 'Madagaskar',
    },
    {
        value: '131',
        label: 'Malawi',
    },
    {
        value: '132',
        label: 'Malaysia',
    },
    {
        value: '133',
        label: 'Maldiverna',
    },
    {
        value: '134',
        label: 'Malört',
    },
    {
        value: '135',
        label: 'Malta',
    },
    {
        value: '136',
        label: 'Martinique',
    },
    {
        value: '137',
        label: 'Mauretanien',
    },
    {
        value: '138',
        label: 'Mauritius',
    },
    {
        value: '139',
        label: 'Mexiko',
    },
    {
        value: '140',
        label: 'Monaco',
    },
    {
        value: '141',
        label: 'Mongoliet',
    },
    {
        value: '142',
        label: 'Moldavien',
    },
    {
        value: '143',
        label: 'Montenegro',
    },
    {
        value: '144',
        label: 'Montserrat',
    },
    {
        value: '145',
        label: 'Marocko',
    },
    {
        value: '146',
        label: 'Moçambique',
    },
    {
        value: '147',
        label: 'Oman',
    },
    {
        value: '148',
        label: 'Namibia',
    },
    {
        value: '149',
        label: 'Nauru',
    },
    {
        value: '150',
        label: 'Nepal',
    },
    {
        value: '151',
        label: 'Nederländerna',
    },
    {
        value: '152',
        label: 'Curaçao',
    },
    {
        value: '153',
        label: 'Aruba',
    },
    {
        value: '154',
        label: 'Sint Maarten (del av Nederländerna)',
    },
    {
        value: '155',
        label: 'Bonaire, Sint Eustatius och Saba',
    },
    {
        value: '156',
        label: 'Nya Kaledonien',
    },
    {
        value: '157',
        label: 'Vanuatu',
    },
    {
        value: '158',
        label: 'Nya Zeeland',
    },
    {
        value: '159',
        label: 'Nicaragua',
    },
    {
        value: '160',
        label: 'Niger',
    },
    {
        value: '161',
        label: 'Nigeria',
    },
    {
        value: '162',
        label: 'Niue',
    },
    {
        value: '163',
        label: 'Norfolkön',
    },
    {
        value: '164',
        label: 'Norge',
    },
    {
        value: '165',
        label: 'Nordmarianerna',
    },
    {
        value: '166',
        label: 'Förenta staternas mindre avlägsna öar',
    },
    {
        value: '167',
        label: 'Mikronesien',
    },
    {
        value: '168',
        label: 'Marshallöarna',
    },
    {
        value: '169',
        label: 'Palau',
    },
    {
        value: '170',
        label: 'Pakistan',
    },
    {
        value: '171',
        label: 'Panama',
    },
    {
        value: '172',
        label: 'Papua Nya Guinea',
    },
    {
        value: '173',
        label: 'Paraguay',
    },
    {
        value: '174',
        label: 'Peru',
    },
    {
        value: '175',
        label: 'Filippinska öarna',
    },
    {
        value: '176',
        label: 'Pitcairn',
    },
    {
        value: '177',
        label: 'Polen',
    },
    {
        value: '178',
        label: 'Portugal',
    },
    {
        value: '179',
        label: 'Guinea-Bissau',
    },
    {
        value: '180',
        label: 'Östtimor',
    },
    {
        value: '181',
        label: 'Puerto Rico',
    },
    {
        value: '182',
        label: 'Qatar',
    },
    {
        value: '183',
        label: 'Réunion',
    },
    {
        value: '184',
        label: 'Rumänien',
    },
    {
        value: '185',
        label: 'Ryssland',
    },
    {
        value: '186',
        label: 'Rwanda',
    },
    {
        value: '187',
        label: 'Saint Barthélemy',
    },
    {
        value: '188',
        label: 'Saint Helena, Ascension och Tristan da Cunha',
    },
    {
        value: '189',
        label: 'Saint Kitts och Nevis',
    },
    {
        value: '190',
        label: 'Anguilla',
    },
    {
        value: '191',
        label: 'Saint Lucia',
    },
    {
        value: '192',
        label: 'Saint Martin (franska delen)',
    },
    {
        value: '193',
        label: 'Saint Pierre och Miquelon',
    },
    {
        value: '194',
        label: 'Saint Vincent och Grenadinerna',
    },
    {
        value: '195',
        label: 'San Marino',
    },
    {
        value: '196',
        label: 'São Tomé och Príncipe',
    },
    {
        value: '197',
        label: 'Saudiarabien',
    },
    {
        value: '198',
        label: 'Senegal',
    },
    {
        value: '199',
        label: 'Serbien',
    },
    {
        value: '200',
        label: 'Seychellerna',
    },
    {
        value: '201',
        label: 'Sierra Leone',
    },
    {
        value: '202',
        label: 'Singapore',
    },
    {
        value: '203',
        label: 'Slovakien',
    },
    {
        value: '204',
        label: 'Vietnam',
    },
    {
        value: '205',
        label: 'Slovenien',
    },
    {
        value: '206',
        label: 'Somalia',
    },
    {
        value: '207',
        label: 'Sydafrika',
    },
    {
        value: '208',
        label: 'Zimbabwe',
    },
    {
        value: '209',
        label: 'Spanien',
    },
    {
        value: '210',
        label: 'Södra Sudan',
    },
    {
        value: '211',
        label: 'Sudan',
    },
    {
        value: '212',
        label: 'Västra Sahara',
    },
    {
        value: '213',
        label: 'Surilabel',
    },
    {
        value: '214',
        label: 'Svalbard och Jan Mayen',
    },
    {
        value: '215',
        label: 'Swaziland',
    },
    {
        value: '216',
        label: 'Svenska',
    },
    {
        value: '217',
        label: 'Schweiz',
    },
    {
        value: '218',
        label: 'Syrien',
    },
    {
        value: '219',
        label: 'Tadzjikistan',
    },
    {
        value: '220',
        label: 'Thailand',
    },
    {
        value: '221',
        label: 'Togo',
    },
    {
        value: '222',
        label: 'Tokelau',
    },
    {
        value: '223',
        label: 'Tonga',
    },
    {
        value: '224',
        label: 'Trinvaluead och Tobago',
    },
    {
        value: '225',
        label: 'Förenade arabemiraten',
    },
    {
        value: '226',
        label: 'Tunisien',
    },
    {
        value: '227',
        label: 'Kalkon',
    },
    {
        value: '228',
        label: 'Turkmenistan',
    },
    {
        value: '229',
        label: 'Turks- och Caicosöarna',
    },
    {
        value: '230',
        label: 'Tuvalu',
    },
    {
        value: '231',
        label: 'Uganda',
    },
    {
        value: '232',
        label: 'Ukraina',
    },
    {
        value: '233',
        label: 'Makedonien',
    },
    {
        value: '234',
        label: 'Egypten',
    },
    {
        value: '235',
        label: 'Storbritannien',
    },
    {
        value: '236',
        label: 'Guernsey',
    },
    {
        value: '237',
        label: 'Jersey',
    },
    {
        value: '238',
        label: 'Man',
    },
    {
        value: '239',
        label: 'Tanzania',
    },
    {
        value: '240',
        label: 'USA (USA)',
    },
    {
        value: '241',
        label: 'Amerikanska Jungfruöarna',
    },
    {
        value: '242',
        label: 'Burkina Faso',
    },
    {
        value: '243',
        label: 'Uruguay',
    },
    {
        value: '244',
        label: 'Uzbekistan',
    },
    {
        value: '245',
        label: 'Venezuela',
    },
    {
        value: '246',
        label: 'Wallis och Futunaöarna',
    },
    {
        value: '247',
        label: 'Samoa',
    },
    {
        value: '248',
        label: 'Jemen',
    },
    {
        value: '249',
        label: 'Zambia',
    },
];
