import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    FlowKeys,
    LivestockIcon,
    PetIcon,
    TypeOfAnimalCollisionKeys,
    WildAnimalIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { selectTypeOfAnimalCollision } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../../sagas/common';
import { flowActions, FlowState } from '../../../../../../sagas/flow';
import { selectFlow } from '../../../../../../sagas/selectors/flowSelectors';

/**
 * Destructure necessary imports
 */
const { GAME, LIVESTOCK, PET } = TypeOfAnimalCollisionKeys;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const typeOfAnimalPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: PET, name: t(`motor.driving.typeOfAnimal.select.${PET}`), icon: <PetIcon /> },
        { id: LIVESTOCK, name: t(`motor.driving.typeOfAnimal.select.${LIVESTOCK}`), icon: <LivestockIcon /> },
        { id: GAME, name: t(`motor.driving.typeOfAnimal.select.${GAME}`), icon: <WildAnimalIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_ANIMAL_TYPE_OF_ANIMAL,
        stateKey: 'typeOfAnimalCollision',
        i18n: {
            ns: 'motor.driving.typeOfAnimal',
        },
        selectorValue: selectTypeOfAnimalCollision(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let flow: FlowState | FlowKeys = END;
                if (option.id === LIVESTOCK || option.id === PET) {
                    flow = selectFlow(state);
                    dispatch(wizardActions.skipBackToPrev(true));
                }
                dispatcherWithPromise(dispatch, motorActions.update, { typeOfAnimalCollision: option.id, flow })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, flow))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
