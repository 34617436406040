import React from 'react';
import { useI18n } from '../../../../../hooks/useI18n';
import './FinalSummary.scss';
import { ClaimantModel, I18NOptions, SummaryItemText } from '@protectorinsurance/ds-can';

interface DisplayClaimantInformationProps {
    model: Partial<ClaimantModel>;
    i18n?: I18NOptions;
}

export const DisplayClaimantInformation = ({ model, i18n }: DisplayClaimantInformationProps) => {
    const namespace = i18n && i18n.namespace ? i18n.namespace : undefined;
    const prefix = i18n && i18n.prefix ? i18n.prefix : undefined;
    const { t } = useI18n(namespace);

    /**
     * Helper functions
     */
    const trans = (value: string) => (i18n && i18n.t ? i18n.t(value) : t(value));
    const tWithPrefix = (value: string) => (prefix ? trans(`${prefix}.${value}`) : trans(value));
    const { city, email, firstName, language, lastName, nationalIdentity, nationalityId, phone, street, zip } = model;

    /**
     * Renders
     */
    return (
        <>
            <div className={'col-12 header-title-col text-align-left'}>
                <p className={'summary-header-title'} tabIndex={0}>
                    {tWithPrefix('text.claimantInformation')}
                </p>
            </div>
            <SummaryItemText
                label={tWithPrefix('text.fullName')}
                text={`${firstName} ${lastName}`}
                className={'col-4 spacing text-align-left'}
            />
            {nationalIdentity && (
                <SummaryItemText
                    label={tWithPrefix('text.nationalIdentity')}
                    text={`${nationalIdentity}`}
                    className={'col-8 spacing text-align-left'}
                />
            )}
            <SummaryItemText
                label={tWithPrefix('text.street')}
                text={`${street}`}
                className={'col-4 spacing text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.zip')}
                text={`${zip}`}
                className={'col-4 spacing text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.city')}
                text={`${city}`}
                className={'col-4 spacing text-align-left'}
            />
            <SummaryItemText
                label={tWithPrefix('text.nationality')}
                text={`${nationalityId?.key}`}
                className={`col-4 ${language ? 'spacing' : 'divider'} text-align-left`}
            />
            {email && (
                <SummaryItemText
                    label={tWithPrefix('text.email')}
                    text={`${email}`}
                    className={`${phone ? 'col-4' : 'col-8'} ${language ? 'spacing' : 'divider'} text-align-left`}
                />
            )}
            {phone && (
                <SummaryItemText
                    label={tWithPrefix('text.phone')}
                    text={`${phone}`}
                    className={`${email ? 'col-4' : 'col-8'} ${language ? 'spacing' : 'divider'} text-align-left`}
                />
            )}
            {language && (
                <SummaryItemText
                    label={tWithPrefix('text.language')}
                    text={t(`lob.person.language.select.${language}`)}
                    className={'col-12 divider text-align-left'}
                />
            )}
        </>
    );
};
