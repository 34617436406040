import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { DATE_OF_TERMINATION_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const dateOfTerminationSchema = (t: TFunction, required: boolean) => {
    if (required) {
        return {
            dateOfTermination: Yup.date()
                .label(t(DATE_OF_TERMINATION_LABEL))
                .nullable()
                .transform((current, original) => (original === '' ? null : current))
                .required(),
        };
    } else {
        return {
            dateOfTermination: Yup.date()
                .label(t(DATE_OF_TERMINATION_LABEL))
                .nullable()
                .transform((current, original) => (original === '' ? null : current)),
        };
    }
};
