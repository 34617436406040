import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useGoBack } from '../../../hooks/useGoBack';
import { useI18n } from '../../../hooks/useI18n';
import { Clickable, DoubleButton, Grid, PageLayout, YesNoKeys } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useVehicles } from '../../../hooks/useVehicles';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { NO, YES } = YesNoKeys;
const { START_COMPANY_INFORMATION } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const StartAddExtrasPage = () => {
    const dispatch = useDispatch();
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.start.addExtras');
    const { claimVehicle } = useVehicles();

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let nextAction = wizardActions.goToNext();
        if (!claimVehicle?.lookupSuccessful) {
            nextAction = wizardActions.goTo(START_COMPANY_INFORMATION);
        }

        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
    };

    const handleCompanyVehicleSelect = (companyVehicle: YesNoKeys, e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.updateClaimVehicle, { ...claimVehicle, companyVehicle }).then(() =>
            dispatch(commonActions.send())
        );
    };

    const handleLeasingSelect = (leasing: YesNoKeys, e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.updateClaimVehicle, { ...claimVehicle, leasing }).then(() =>
            dispatch(commonActions.send())
        );
    };

    const handleVATSelect = (VAT: YesNoKeys, e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.updateClaimVehicle, { ...claimVehicle, VAT }).then(() =>
            dispatch(commonActions.send())
        );
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={!claimVehicle?.companyVehicle || !claimVehicle?.leasing || !claimVehicle?.VAT}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid>
                <DoubleButton
                    primaryButtonClassName={claimVehicle?.companyVehicle === YES ? 'active' : ''}
                    primaryButtonText={tWithNS.t(`companyVehicle.select.${YES}`)}
                    primaryOnClick={() => handleCompanyVehicleSelect(YES)}
                    primaryTestId={'primaryCompanyVehicleButton'}
                    secondaryButtonClassName={claimVehicle?.companyVehicle === NO ? 'active' : ''}
                    secondaryButtonText={tWithNS.t(`companyVehicle.select.${NO}`)}
                    secondaryOnClick={() => handleCompanyVehicleSelect(NO)}
                    secondaryTestId={'secondaryCompanyVehicleButton'}
                    title={tWithNS.t('companyVehicle.title')}
                    wrapperClassName={'col-4'}
                    {...{ customCAN }}
                />

                <DoubleButton
                    primaryButtonClassName={claimVehicle?.leasing === YES ? 'active' : ''}
                    primaryButtonText={tWithNS.t(`leasing.select.${YES}`)}
                    primaryOnClick={() => handleLeasingSelect(YES)}
                    primaryTestId={'primaryLeasingButton'}
                    secondaryButtonClassName={claimVehicle?.leasing === NO ? 'active' : ''}
                    secondaryButtonText={tWithNS.t(`leasing.select.${NO}`)}
                    secondaryOnClick={() => handleLeasingSelect(NO)}
                    secondaryTestId={'secondaryLeasingButton'}
                    title={tWithNS.t('leasing.title')}
                    wrapperClassName={'col-4'}
                    {...{ customCAN }}
                />

                <DoubleButton
                    primaryButtonClassName={claimVehicle?.VAT === YES ? 'active' : ''}
                    primaryButtonText={tWithNS.t(`VAT.select.${YES}`)}
                    primaryOnClick={() => handleVATSelect(YES)}
                    primaryTestId={'primaryVATButton'}
                    secondaryButtonClassName={claimVehicle?.VAT === NO ? 'active' : ''}
                    secondaryButtonText={tWithNS.t(`VAT.select.${NO}`)}
                    secondaryOnClick={() => handleVATSelect(NO)}
                    secondaryTestId={'secondaryVATButton'}
                    title={tWithNS.t('VAT.title')}
                    wrapperClassName={'col-4'}
                    {...{ customCAN }}
                />
            </Grid>
        </PageLayout>
    );
};
