import { personHealthProgressBarRoutes } from '../../config/wizardRouter/personHealthProgressBarRoutes';
import { InsuranceTypeKeys, InsuranceTypeModel, is, LobKeys, Nullable } from '@protectorinsurance/ds-can';
import { motorProgressBarRoutes } from '../../config/wizardRouter/motorProgressBarRoutes';
import { lpoProgressBarRoutes } from '../../config/wizardRouter/lpoProgressBarRoutes';
import { personGroupAccidentProgressBarRoutes } from '../../config/wizardRouter/personGroupAccidentProgressBarRoutes';
import { personWorkersCompProgressBarRoutes } from '../../config/wizardRouter/personWorkersCompProgressBarRoutes';

/**
 * Destructure necessary imports
 */
const { AUTO, PERSON } = LobKeys;
const { GROUP_ACCIDENT, WORK_COMP } = InsuranceTypeKeys;

/**
 * Utility logic
 */
export const getLobProgressBarRoutes = (lob: Nullable<LobKeys>, insuranceType?: InsuranceTypeModel) => {
    if (is(lob, PERSON)) {
        if (is(insuranceType, GROUP_ACCIDENT)) {
            return personGroupAccidentProgressBarRoutes;
        } else if (is(insuranceType, WORK_COMP)) {
            return personWorkersCompProgressBarRoutes;
        } else {
            return personHealthProgressBarRoutes;
        }
    } else if (is(lob, AUTO)) {
        return motorProgressBarRoutes;
    } else {
        return lpoProgressBarRoutes;
    }
};
