import { put, takeEvery } from 'redux-saga/effects';
import {
    actionWithPromise,
    BankAccountContactInformation,
    BankAccountInformationModel,
    BodyPartListTypeModel,
    CategoryModel,
    ClaimantAgeCategoryModel,
    ClaimantModel,
    ClaimantPositionModel,
    ClaimantRoleTypeModel,
    ClaimantWorkDayModel,
    ClaimCompanyModel,
    ClaimDescriptionTypeModel,
    ClaimLocationTypeModel,
    ClaimNumberTypeModel,
    ClaimReporterInformationModel,
    DamageAddressTypeModel,
    DamageLocationTypeTypeModel,
    Datable,
    DiagnosisCodeTypeModel,
    emptyFn,
    EntrepreneurOwnershipTypeModel,
    ExternalReferenceTypeModel,
    IncapacityPeriodsTypeModel,
    IncapacityPeriodTypeModel,
    initClaimantModel,
    initClaimantPosition,
    initOccupation,
    initPolicyHoldersContact,
    InjuredProfessionTypeModel,
    InsuranceTypeModel,
    LifeDamageMunicipalityTypeModel,
    MedicalInstitutionTypeModel,
    NatureTypeIdModel,
    OccupationalConditionTypeModel,
    OccupationModel,
    OdCauseTypeModel,
    OtherInsuranceCompanyTypeModel,
    PersonClaimCauseTypeModel,
    PoliceDistrictTypeModel,
    PolicyHoldersContactModel,
    PrivacyTypeModel,
    RegistrationNumberTypeModel,
    Rejectable,
    Resolvable,
    StudyLocationTypeModel,
    TypeOfCauseTypeModel,
    TypeOfDoctorTypeModel,
    TypeOfStudiesTypeModel,
    TypeOfVehicleTypeModel,
    WhoPaidExpensesTypeModel,
    WhoReportTypeModel,
    YesNoModel,
} from '@protectorinsurance/ds-can';

/**
 * Constants
 */
export enum PersonActionTypes {
    UPDATE = '@person/UPDATE',
    UPDATED = '@person/UPDATED',
    RESET_FROM_CATEGORY = '@person/RESET_FROM_CATEGORY',
}

/**
 * Interfaces
 */

export interface IPersonAction {
    type: PersonActionTypes;
    data?: Partial<PersonState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface PersonState {
    acceptedPrivacy: PrivacyTypeModel;
    bankAccountContactInformation: BankAccountContactInformation;
    bankAccountInformation: BankAccountInformationModel;
    bodyPartList: BodyPartListTypeModel;
    category: CategoryModel;
    causedIncapacity: YesNoModel;
    claimCause: PersonClaimCauseTypeModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimLocation: ClaimLocationTypeModel;
    claimNumber: ClaimNumberTypeModel;
    claimReporterRole: WhoReportTypeModel;
    claimantAgeCategory: ClaimantAgeCategoryModel;
    claimantInformation: ClaimantModel;
    claimantPosition: ClaimantPositionModel;
    claimantRole: ClaimantRoleTypeModel;
    claimantWorkDay: ClaimantWorkDayModel;
    companyInformation: ClaimCompanyModel;
    damageAddress: DamageAddressTypeModel;
    dateOfAppliedMedicalCare: Datable;
    diagnosisCode: DiagnosisCodeTypeModel;
    entrepreneurOwnership: EntrepreneurOwnershipTypeModel;
    externalReference: ExternalReferenceTypeModel;
    hasAppliedKela: YesNoModel;
    hasAppliedMedicalCare: YesNoModel;
    hasAttachment: YesNoModel;
    hasCompanyShares: YesNoModel;
    hasDiagnosisCode: YesNoModel;
    hasExistingClaim: YesNoModel;
    hasExpenses: YesNoModel;
    incapacityMoreThanSevenDays: YesNoModel;
    incapacityPeriod: IncapacityPeriodTypeModel;
    incapacityPeriods: IncapacityPeriodsTypeModel;
    injuredProfession: InjuredProfessionTypeModel;
    insuranceType: InsuranceTypeModel;
    isPoliceContacted: YesNoModel;
    isVehicleInvolved: YesNoModel;
    lifeDamageMunicipality: LifeDamageMunicipalityTypeModel;
    locationType: DamageLocationTypeTypeModel;
    medicalInstitution: MedicalInstitutionTypeModel;
    natureTypeId: NatureTypeIdModel;
    occupation: OccupationModel;
    occupationalCondition: OccupationalConditionTypeModel;
    odCause: OdCauseTypeModel;
    otherInsurance: YesNoModel;
    otherInsuranceCompany: OtherInsuranceCompanyTypeModel;
    policeDistrict: PoliceDistrictTypeModel;
    policyHoldersContact: PolicyHoldersContactModel;
    registrationNumber: RegistrationNumberTypeModel;
    reportedToEmployerDate: Datable;
    reporterInformation: ClaimReporterInformationModel;
    studyLocation: StudyLocationTypeModel;
    typeOfCause: TypeOfCauseTypeModel;
    typeOfDoctor: TypeOfDoctorTypeModel;
    typeOfStudies: TypeOfStudiesTypeModel;
    typeOfVehicle: TypeOfVehicleTypeModel;
    underInfluence: YesNoModel;
    underInfluenceClaimDescription: ClaimDescriptionTypeModel;
    whoPaidExpenses: WhoPaidExpensesTypeModel;
}

/**
 * Initial state
 */
export const personInitState: PersonState = {
    acceptedPrivacy: false,
    bankAccountContactInformation: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        role: null,
    },
    bankAccountInformation: {
        accountNumber: null,
        companyName: null,
        dontKnowBankInformation: false,
        isCompany: false,
        ownerFamilyName: null,
        ownerGivenName: null,
    },
    bodyPartList: [],
    category: null,
    causedIncapacity: null,
    claimCause: null,
    claimDate: null,
    claimDescription: '',
    claimLocation: null,
    claimNumber: null,
    claimReporterRole: null,
    claimantAgeCategory: null,
    claimantInformation: initClaimantModel,
    claimantPosition: initClaimantPosition,
    claimantRole: null,
    claimantWorkDay: {
        from: null,
        to: null,
    },
    companyInformation: {
        businessNumber: null,
        city: null,
        departmentName: null,
        insuranceNumber: null,
        name: null,
        street: null,
        zip: null,
    },
    damageAddress: null,
    dateOfAppliedMedicalCare: null,
    diagnosisCode: null,
    entrepreneurOwnership: [],
    externalReference: null,
    hasAppliedKela: null,
    hasAppliedMedicalCare: null,
    hasAttachment: null,
    hasCompanyShares: null,
    hasDiagnosisCode: null,
    hasExistingClaim: null,
    hasExpenses: null,
    incapacityMoreThanSevenDays: null,
    incapacityPeriod: null,
    incapacityPeriods: [],
    injuredProfession: null,
    insuranceType: null,
    isPoliceContacted: null,
    isVehicleInvolved: null,
    lifeDamageMunicipality: null,
    locationType: {
        id: null,
        key: null,
    },
    medicalInstitution: null,
    natureTypeId: null,
    occupation: initOccupation,
    occupationalCondition: null,
    odCause: null,
    otherInsurance: null,
    otherInsuranceCompany: null,
    policeDistrict: null,
    policyHoldersContact: initPolicyHoldersContact,
    registrationNumber: null,
    reportedToEmployerDate: null,
    reporterInformation: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        role: null,
    },
    studyLocation: null,
    typeOfCause: null,
    typeOfDoctor: null,
    typeOfStudies: null,
    typeOfVehicle: null,
    underInfluence: null,
    underInfluenceClaimDescription: '',
    whoPaidExpenses: null,
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = personInitState, { type, data }: IPersonAction) {
    switch (type) {
        case PersonActionTypes.UPDATED:
            return { ...state, ...data };
        case PersonActionTypes.RESET_FROM_CATEGORY:
            return {
                ...personInitState,
                hasExistingClaim: state.hasExistingClaim,
                claimNumber: state.claimNumber,
                insuranceType: state.insuranceType,
                category: data?.category,
            };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const personActions = {
    update: actionWithPromise<PersonActionTypes, Partial<PersonState>>(PersonActionTypes.UPDATE),
    resetFromCategory: actionWithPromise<PersonActionTypes, Partial<PersonState>>(
        PersonActionTypes.RESET_FROM_CATEGORY
    ),
    updated: actionWithPromise<PersonActionTypes, Partial<PersonState>>(PersonActionTypes.UPDATED),
};

/**
 * Saga watchers
 */
export const personWatcher = function* () {
    yield takeEvery(PersonActionTypes.UPDATE, sagas.update);
};

/**
 * Saga functions
 */
export const sagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: IPersonAction) {
        try {
            yield put(personActions.updated(data));
            resolve();
        } catch (e) {
            reject();
        }
    },
};
