import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import { Clickable, Datable, getLocaleFromUrl, MuiDatePicker, PageLayout } from '@protectorinsurance/ds-can';
import { motorActions } from '../../../../sagas/motor';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { selectRecoveryDate } from '../../../../sagas/selectors/motorSelectors';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import { getMomentLocale } from '../../../../utils/locale/getMomentLocale';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { RECOVERY_DATE } = FormFieldNames;
const { MANDATORY_DATE_FIELD } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const PTRecoveryDatePage = () => {
    const dispatch = useDispatch();
    const recoveryDate = useSelector(selectRecoveryDate);
    const customCAN = useSelector(selectCustomCAN);
    const [date, setDate] = useState<Datable>(recoveryDate || null);
    const [error, setError] = useState<string>();
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.recoveryDate');
    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (date !== null) {
            dispatcherWithPromise(dispatch, motorActions.update, { recoveryDate: date })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        } else {
            setError(t(MANDATORY_DATE_FIELD));
        }
    };

    const selectedDate = (date: Datable) => {
        if (date) {
            setDate(date);
            setError('');
            dispatch(motorActions.update({ recoveryDate: date }));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <MuiDatePicker
                disableFuture={true}
                locale={getMomentLocale(getLocaleFromUrl())}
                name={RECOVERY_DATE}
                onChange={selectedDate}
                {...{ customCAN, date, error }}
            />
        </PageLayout>
    );
};
