import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    InputLabel,
    LocationModel,
    MuiTextInput,
    PageLayout,
    PencilIcon,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { lpoActions } from '../../../sagas/lpo';
import { selectAccidentLocation } from '../../../sagas/selectors/lpoSelectors';
import { propertyUnitSchema } from '../../../validations/schemas/propertyUnitSchema';
import { propertyAddressSchema } from '../../../validations/schemas/propertyAddressSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    PROPERTY_ADDRESS_LABEL,
    PROPERTY_ADDRESS_PLACEHOLDER,
    PROPERTY_UNIT_LABEL,
    PROPERTY_UNIT_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { PROPERTY_ADDRESS, PROPERTY_UNIT } = FormFieldNames;

/**
 * Page view and page logic
 */
export const PropertyPropertyAddressPage = () => {
    const dispatch = useDispatch();
    const accidentLocation = useSelector(selectAccidentLocation);
    const customCAN = useSelector(selectCustomCAN);
    const [editing, setEditing] = useState<boolean>(false);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.propertyAddress');
    const schema = editing ? propertyAddressSchema(t) : propertyUnitSchema(t);
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        register,
        setValue,
        trigger,
    } = useForm<LocationModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...accidentLocation,
        },
    });

    useEffect(() => {
        setValue('note', accidentLocation.note);
        register(PROPERTY_ADDRESS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleEditing = async (e: FormChangeable) => {
        e.preventDefault();
        const { value, id } = e.currentTarget;
        if (editing) {
            await trigger(id);
            await setValue(id, value, { shouldValidate: true });
            const { note } = getValues();
            dispatcherWithPromise(dispatch, lpoActions.update, {
                accidentLocation: { ...accidentLocation, note },
            }).then(() => dispatch(commonActions.send()));
            if (!errors.note) {
                setEditing(!editing);
            }
        } else {
            setEditing(!editing);
        }
    };

    const onSubmit = (values: LocationModel) => {
        dispatcherWithPromise(dispatch, lpoActions.update, { accidentLocation: { ...accidentLocation, ...values } })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <button className={'col-12'} onClick={handleEditing}>
                        <PencilIcon wh={20} />
                    </button>
                    {editing ? (
                        <Controller
                            control={control}
                            name={PROPERTY_ADDRESS}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    error={!!errors.note}
                                    errorMessage={errors.note?.message}
                                    id={PROPERTY_ADDRESS}
                                    inputFieldWrapper={'col-6'}
                                    label={t(PROPERTY_ADDRESS_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(PROPERTY_ADDRESS_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    ) : (
                        <div className={'col-6 text-align-left'}>
                            <InputLabel label={t(PROPERTY_ADDRESS_LABEL)} name={PROPERTY_ADDRESS} />
                            {accidentLocation.note &&
                                accidentLocation.note.split(',').map((item: string, idx: number) => {
                                    return <p key={idx}>{item.trim()}</p>;
                                })}
                        </div>
                    )}
                    <Controller
                        control={control}
                        name={PROPERTY_UNIT}
                        render={({ field: { ref, value, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.unit}
                                errorMessage={errors.unit?.message}
                                id={PROPERTY_UNIT}
                                inputFieldWrapper={'col-6'}
                                label={t(PROPERTY_UNIT_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PROPERTY_UNIT_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
