import { AppState } from '../../store';

export const selectMotorState = (state: AppState) => state.motor;

//Motor selectors
export const selectTypeOfAutoClaim = (state: AppState) => state.motor.typeOfAutoClaim;
export const selectLogin = (state: AppState) => state.motor.login;
export const selectVehicles = (state: AppState) => state.motor.vehicles;
export const selectClaimDate = (state: AppState) => state.motor.claimDate;
export const selectCompanyInformation = (state: AppState) => state.motor.companyInformation;
export const selectIsDriving = (state: AppState) => state.motor.isDriving;
export const selectClaimCause = (state: AppState) => state.motor.claimCause;
export const selectClaimCauseList = (state: AppState) => state.motor.claimCauseList;
export const selectHasDigitalServiceBook = (state: AppState) => state.motor.hasDigitalServiceBook;
export const selectClaimDescription = (state: AppState) => state.motor.claimDescription;
export const selectAccidentDescription = (state: AppState) => state.motor.accidentDescription;
export const selectAccidentLocation = (state: AppState) => state.motor.accidentLocation;
export const selectAccidentSketch = (state: AppState) => state.motor.accidentSketch;
export const selectHasVehicleDamages = (state: AppState) => state.motor.hasVehicleDamages;
export const selectNeedVehicleTowing = (state: AppState) => state.motor.needVehicleTowing;
export const selectHasNonVehicleDamages = (state: AppState) => state.motor.hasNonVehicleDamages;
export const selectOwnerInformationList = (state: AppState) => state.motor.ownerInformationList;
export const selectHasPersonInjuries = (state: AppState) => state.motor.hasPersonInjuries;
export const selectInjuredPersonInformationList = (state: AppState) => state.motor.injuredPersonInformationList;
export const selectIsPoliceContacted = (state: AppState) => state.motor.isPoliceContacted;
export const selectPoliceCaseNumber = (state: AppState) => state.motor.policeCaseNumber;
export const selectPoliceDistrict = (state: AppState) => state.motor.policeDistrict;
export const selectReporterInformation = (state: AppState) => state.motor.reporterInformation;
export const selectDriverInformation = (state: AppState) => state.motor.driverInformation;
export const selectHasWitnesses = (state: AppState) => state.motor.hasWitnesses;
export const selectWitnesses = (state: AppState) => state.motor.witnesses;
export const selectPolicyHoldersContact = (state: AppState) => state.motor.policyHoldersContact;
export const selectExternalReference = (state: AppState) => state.motor.externalReference;
export const selectSelectedVehicleId = (state: AppState) => state.motor.selectedVehicleId;
export const selectSelectedVehicle = (state: AppState) => state.motor.selectedVehicle;
export const selectSearchedVehicles = (state: AppState) => state.motor.searchedVehicles;

// Parked
export const selectIsSelfDiscoveredClaim = (state: AppState) => state.motor.isSelfDiscoveredClaim;
export const selectClaimDiscovererInformation = (state: AppState) => state.motor.claimDiscovererInformation;
export const selectIsVehicleStolen = (state: AppState) => state.motor.isVehicleStolen;
export const selectParkingDate = (state: AppState) => state.motor.parkingDate;
export const selectHasAllKeys = (state: AppState) => state.motor.hasAllKeys;
export const selectIsVehicleRecovered = (state: AppState) => state.motor.isVehicleRecovered;
export const selectRecoveryDate = (state: AppState) => state.motor.recoveryDate;
export const selectRecovererInformation = (state: AppState) => state.motor.recovererInformation;
export const selectIsItemMissing = (state: AppState) => state.motor.isItemMissing;
export const selectMissingItems = (state: AppState) => state.motor.missingItems;
export const selectTheftAndDamagesClaimDescription = (state: AppState) => state.motor.theftAndDamagesClaimDescription;
export const selectEngineClaimDescription = (state: AppState) => state.motor.engineClaimDescription;
export const selectOtherMissingItemsClaimDescription = (state: AppState) =>
    state.motor.otherMissingItemsClaimDescription;
export const selectFireClaimDescription = (state: AppState) => state.motor.fireClaimDescription;
export const selectIsCounterpartyKnown = (state: AppState) => state.motor.isCounterpartyKnown;
export const selectHasParkedWitnesses = (state: AppState) => state.motor.hasParkedWitnesses;
export const selectIsLocked = (state: AppState) => state.motor.isLocked;

// Driving
export const selectIsOtherVehicleInvolved = (state: AppState) => state.motor.isOtherVehicleInvolved;
export const selectTypeOfAnimalCollision = (state: AppState) => state.motor.typeOfAnimalCollision;
export const selectHasAnimalEscaped = (state: AppState) => state.motor.hasAnimalEscaped;
export const selectIsReversing = (state: AppState) => state.motor.isReversing;
export const selectSpeedLimit = (state: AppState) => state.motor.speedLimit;
export const selectDrivingSpeed = (state: AppState) => state.motor.drivingSpeed;
export const selectSpeedOnImpact = (state: AppState) => state.motor.speedOnImpact;
export const selectClaimLocationType = (state: AppState) => state.motor.claimLocationType;
export const selectTypeOfIntersection = (state: AppState) => state.motor.typeOfIntersection;
export const selectIsTrafficLights = (state: AppState) => state.motor.isTrafficLights;
export const selectClaimantRoundaboutPositioning = (state: AppState) => state.motor.claimantRoundaboutPositioning;
export const selectCounterpartyRoundaboutPositioning = (state: AppState) =>
    state.motor.counterpartyRoundaboutPositioning;
export const selectTypeOfRoad = (state: AppState) => state.motor.typeOfRoad;
export const selectWhoAtFault = (state: AppState) => state.motor.whoAtFault;
export const selectRoadWidth = (state: AppState) => state.motor.roadWidth;
export const selectDistanceFromRoadSide = (state: AppState) => state.motor.distanceFromRoadSide;
export const selectIsCounterpartyStationary = (state: AppState) => state.motor.isCounterpartyStationary;
export const selectIsLaneChange = (state: AppState) => state.motor.isLaneChange;
export const selectCollisionSituation = (state: AppState) => state.motor.collisionSituation;
export const selectTimeSinceAction = (state: AppState) => state.motor.timeSinceAction;
export const selectRoadConditionClaimDescription = (state: AppState) => state.motor.roadConditionClaimDescription;
export const selectWhoResponsibleClaimDescription = (state: AppState) => state.motor.whoResponsibleClaimDescription;
export const selectDitchClaimDescription = (state: AppState) => state.motor.ditchClaimDescription;
export const selectReversingClaimDescription = (state: AppState) => state.motor.reversingClaimDescription;
export const selectIsCyclistResponsible = (state: AppState) => state.motor.isCyclistResponsible;
export const selectPlaceOfAccident = (state: AppState) => state.motor.placeOfAccident;
export const selectRoadCondition = (state: AppState) => state.motor.roadCondition;
export const selectLightOfDay = (state: AppState) => state.motor.lightOfDay;
export const selectIsUrbanArea = (state: AppState) => state.motor.isUrbanArea;

// End
export const selectAcceptedPrivacy = (state: AppState) => state.motor.acceptedPrivacy;
export const selectWorkshopInformation = (state: AppState) => state.motor.workshopInformation;
export const selectIsUnderInfluence = (state: AppState) => state.motor.underInfluence;
export const selectWhoUnderInfluence = (state: AppState) => state.motor.whoUnderInfluence;
export const selectHasLicense = (state: AppState) => state.motor.hasLicense;
