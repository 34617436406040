import React from 'react';
import { CarouselConfigLookupResponse, NoIcon, YesIcon, YesNoKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { selectIsCounterpartyStationary } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const isCounterpartyStationaryPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.driving.isCounterpartyStationary.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.isCounterpartyStationary.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_HEAD_ON_IS_COUNTERPARTY_STATIONARY,
        stateKey: 'isCounterpartyStationary',
        i18n: {
            ns: 'motor.driving.isCounterpartyStationary',
        },
        selectorValue: selectIsCounterpartyStationary(state),
        options,
    };
};
