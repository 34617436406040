import React, { useRef } from 'react';
import { DamageMarkers } from '../DamageMarker';
import { useMarkers } from '../../../hooks/useMarkers';
import { createOnClickAddDamagePoint } from '../../../utils/svgUtils';
import { BodyKeys, SvgImg } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { BACK_LEFT, CENTER_LEFT, FRONT_LEFT } = BodyKeys;

/**
 * Component view and component logic
 */
export const BusLeftImage = ({
    addDamagePoint,
    removeDamagePoint,
    selectedDamagePoints,
    title,
    id = 'bus-left-image-svg',
}: SvgImg) => {
    const refSVG = useRef<SVGSVGElement>(null);
    const thisMarkers = useMarkers(selectedDamagePoints, id);
    const onClick = createOnClickAddDamagePoint(refSVG, addDamagePoint);
    return (
        <>
            <svg
                id={id}
                data-testid={id}
                className={'vehicle-img-svg left-image-svg'}
                ref={refSVG}
                width="1617"
                height="564"
                viewBox="0 0 1617 564"
                aria-labelledby={`${id}-title`}
                role="img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title id={`${id}-title`}>{title}</title>
                <g className={'left-image-svg__selectable'}>
                    <path
                        id={FRONT_LEFT}
                        onClick={onClick}
                        d="M5 563.5C2.51472 563.5 0.5 561.485 0.5 559L0.500007 0.5L470.183 0.500006L470.183 563.5L5 563.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <path
                        id={BACK_LEFT}
                        onClick={onClick}
                        d="M1612 563.5C1614.49 563.5 1616.5 561.485 1616.5 559L1616.5 0.5L1146.82 0.499994L1146.82 563.5L1612 563.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />

                    <path
                        id={CENTER_LEFT}
                        onClick={onClick}
                        d="M475.683 563.5C473.198 563.5 471.183 561.485 471.183 559L471.183 0.5L1145.82 0.500008L1145.82 563.5L475.683 563.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                </g>
                <g className={'left-image-svg__content'}>
                    <path
                        d="M1471.26 70.5001H1545.26C1581.26 70.5001 1595.76 90.0001 1595.76 114.5V436C1595.76 446 1592.26 452 1583.26 456L1494.76 476H1329.76C1329.76 476 1329.76 398 1249.76 398C1173.76 398 1172.26 476 1172.26 476H463.26C463.26 476 463.26 398 384.76 398C306.26 398 304.26 476 304.26 476H43.76C31.76 473 28.26 459 30.76 444.5L39.9277 400.591C40.1213 399.664 40.9385 399 41.8855 399H47.76C51.76 399 51.76 399 51.76 391.5L54.6013 336.5M1471.26 70.5001V46H994.26V70.5001M1471.26 70.5001H994.26M994.26 70.5001H694.26M694.26 70.5001V52H553.26V70.5001M694.26 70.5001H553.26M553.26 70.5001H294.26M294.26 70.5001V52H156.26V70.5001M294.26 70.5001H156.26M156.26 70.5001H86.76C76.6921 70.3531 71.5743 71.7829 63.76 78.0001L55.26 102.5C52.5586 113.305 52.9429 117.942 61.26 120.5H65.76L65.1143 133M54.6013 336.5L89.76 314V133H65.1143M54.6013 336.5L65.1143 133M1181.26 474.5C1181.26 512.884 1212.38 544 1250.76 544C1289.14 544 1320.26 512.884 1320.26 474.5C1320.26 436.116 1289.14 405 1250.76 405C1212.38 405 1181.26 436.116 1181.26 474.5ZM314.26 474.5C314.26 512.884 345.376 544 383.76 544C422.144 544 453.26 512.884 453.26 474.5C453.26 436.116 422.144 405 383.76 405C345.376 405 314.26 436.116 314.26 474.5ZM853.5 336V133H1144.5V336H853.5ZM1159.5 336V133H1343.5V336H1159.5ZM1357.5 336V133H1541.5V336H1357.5ZM641.5 133V336H457.5V133H641.5ZM839.5 133V336H655.5V133H839.5ZM441.5 133V336H257.5V133H441.5ZM126.76 367V134C126.76 133.448 127.208 133 127.76 133H240.26C240.812 133 241.26 133.448 241.26 134V367C241.26 367.552 240.812 368 240.26 368H127.76C127.208 368 126.76 367.552 126.76 367Z"
                        stroke="black"
                        strokeWidth="3.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <DamageMarkers removeDamagePoint={removeDamagePoint} selectedDamagePoints={thisMarkers} />
            </svg>
        </>
    );
};
