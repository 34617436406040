import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { vehicleSchema } from '../fieldSchemas/vehicleSchema';
import { vehicleMakeSchema } from '../fieldSchemas/vehicleMakeSchema';

/**
 * Validation logic
 */
export const vehicleInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...vehicleMakeSchema(t),
        ...vehicleSchema(t),
    });
};
