import { MotorState } from '../../sagas/motor';
import { TFunction } from 'i18next';
import { ClaimDescriptionTypeModel, YesNoKeys } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Utility logic
 */
export const getClaimDescriptionHistory = (
    motorState: MotorState,
    t: TFunction,
    excludedClaimDescription?: boolean,
    claimDescription?: ClaimDescriptionTypeModel
): ClaimDescriptionTypeModel => {
    const {
        accidentDescription,
        theftAndDamagesClaimDescription,
        engineClaimDescription,
        otherMissingItemsClaimDescription,
        fireClaimDescription,
        roadConditionClaimDescription,
        whoResponsibleClaimDescription,
        ditchClaimDescription,
        reversingClaimDescription,
        isDriving,
    } = motorState;

    if (excludedClaimDescription && isDriving === YES) {
        return `${
            ditchClaimDescription && !claimDescription?.includes(ditchClaimDescription)
                ? `${t('motor.driving.ditchClaimDescription.heading.title')}: ${ditchClaimDescription}  |     `
                : ''
        }${
            roadConditionClaimDescription && !claimDescription?.includes(roadConditionClaimDescription)
                ? `${t(
                      'motor.driving.roadConditionDescription.heading.title'
                  )}: ${roadConditionClaimDescription}  |     `
                : ''
        }${
            whoResponsibleClaimDescription && !claimDescription?.includes(whoResponsibleClaimDescription)
                ? `${t('motor.driving.whoResponsible.heading.title')}: ${whoResponsibleClaimDescription}  |     `
                : ''
        }${
            reversingClaimDescription && !claimDescription?.includes(reversingClaimDescription)
                ? `${t('motor.driving.reversingClaimDescription.heading.title')}: ${reversingClaimDescription}  |     `
                : ''
        }${
            accidentDescription && !claimDescription?.includes(accidentDescription)
                ? `${t('motor.end.accidentDescription.heading.title')}: ${accidentDescription}  |     `
                : ''
        }`;
    } else if (excludedClaimDescription && isDriving === NO) {
        return `${
            theftAndDamagesClaimDescription && !claimDescription?.includes(theftAndDamagesClaimDescription)
                ? `${t(
                      'motor.parked.theftAndDamagesClaimDescription.heading.title'
                  )}: ${theftAndDamagesClaimDescription}  |     `
                : ''
        }${
            engineClaimDescription && !claimDescription?.includes(engineClaimDescription)
                ? `${t('motor.parked.claimDescription.heading.title')}: ${engineClaimDescription}  |     `
                : ''
        }${
            otherMissingItemsClaimDescription && !claimDescription?.includes(otherMissingItemsClaimDescription)
                ? `${otherMissingItemsClaimDescription} `
                : ''
        }${
            fireClaimDescription && !claimDescription?.includes(fireClaimDescription)
                ? `${t('motor.parked.fireClaimDescription.heading.title')}: ${fireClaimDescription}  |     `
                : ''
        }`;
    } else if (isDriving === YES) {
        return `${
            ditchClaimDescription
                ? `${t('motor.driving.ditchClaimDescription.heading.title')}: ${ditchClaimDescription}  |     `
                : ''
        }${
            roadConditionClaimDescription
                ? `${t('motor.driving.roadConditionClaimDescription')}: ${roadConditionClaimDescription}  |     `
                : ''
        }${
            whoResponsibleClaimDescription
                ? `${t('motor.driving.whoResponsible.heading.title')}: ${whoResponsibleClaimDescription}  |     `
                : ''
        }${
            reversingClaimDescription
                ? `${t('motor.driving.reversingClaimDescription.heading.title')}: ${reversingClaimDescription}  |     `
                : ''
        }${accidentDescription ? `${t('motor.end.accidentDescription.heading.title')}: ${accidentDescription}` : ''}`;
    } else {
        return `${
            theftAndDamagesClaimDescription
                ? `${t(
                      'motor.parked.theftAndDamagesClaimDescription.heading.title'
                  )}: ${theftAndDamagesClaimDescription}  |     `
                : ''
        }${
            engineClaimDescription
                ? `${t('motor.parked.claimDescription.heading.title')}: ${engineClaimDescription}  |     `
                : ''
        }${otherMissingItemsClaimDescription ? `${otherMissingItemsClaimDescription}  |     ` : ''}${
            fireClaimDescription
                ? `${t('motor.parked.fireClaimDescription.heading.title')}: ${fireClaimDescription}  |     `
                : ''
        }`;
    }
};
