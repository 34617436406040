import { LpoRoutePaths } from './lpoWizardRoutes';
import { FlowKeys, getLobFromUrl, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { strReplace } from 'utils/strings/strReplace';

const lpoProgressBar = (path: LpoRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const lob = getLobFromUrl();
    const find = [':language', ':lob'];
    const replace = [language ? language : '', lob.lob ? lob.lob.toString() : ''];
    return strReplace(path, find, replace);
};

export const lpoProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.START]: [
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_TRAVEL_CLAIM_TYPE),
        lpoProgressBar(LpoRoutePaths.START_ONBOARDING),
        lpoProgressBar(LpoRoutePaths.START_PRIVACY),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_DATE),
        lpoProgressBar(LpoRoutePaths.TRAVEL_TRAVEL_DATES),
        lpoProgressBar(LpoRoutePaths.START_ACCIDENT_LOCATION),
        lpoProgressBar(LpoRoutePaths.PROPERTY_PROPERTY_ADDRESS),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_START_IS_POLICE_CONTACTED),
        lpoProgressBar(LpoRoutePaths.START_POLICE_CASE_NUMBER),
        lpoProgressBar(LpoRoutePaths.TRAVEL_TRAVEL_INTERRUPTION),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_DESCRIPTION),
    ],

    [FlowKeys.END]: [
        lpoProgressBar(LpoRoutePaths.END_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE),
        lpoProgressBar(LpoRoutePaths.END_OTHER_INSURANCE_COMPANY),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDER),
        lpoProgressBar(LpoRoutePaths.END_REPORTER_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDERS_CONTACT),
        lpoProgressBar(LpoRoutePaths.END_BANK_ACCOUNT_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_FINAL_SUMMARY),
        lpoProgressBar(LpoRoutePaths.END_REPORT_COMPLETED),
    ],
};
