import { produce } from 'immer';
import { AttachmentModel } from '../../../interfaces/models/Attachment.model';
import { actionWithPromise, FileModel, Rejectable, Resolvable, YesNoKeys } from '@protectorinsurance/ds-can';

/**
 * Action Types
 */
export enum ReceiptActionTypes {
    REQUEST = '@app/upload/receipt/REQUEST',
    SUCCESS = '@app/upload/receipt/SUCCESS',
    ADD = '@app/upload/receipt/ADD',
    UPDATE = '@app/upload/receipt/UPDATE',
    FAILURE = '@app/upload/receipt/FAILURE',
    REMOVE = '@app/upload/receipt/REMOVE',
}

/**
 * Action Definitions
 */
export interface IReceiptAction {
    type: ReceiptActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 *  Init state
 */
export const receiptInitState: AttachmentModel = {
    files: [],
    hasAttachment: null,
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = receiptInitState, { type, data }: IReceiptAction) {
    return produce(state, (draft: AttachmentModel) => {
        switch (type) {
            case ReceiptActionTypes.ADD:
                draft.files.push(data);
                draft.hasAttachment = YesNoKeys.YES;
                break;
            case ReceiptActionTypes.UPDATE:
                const {
                    amount,
                    category,
                    definition,
                    errors,
                    externalId,
                    id,
                    requireAmount,
                    requireDefinition,
                    status,
                } = data;
                const index = state.files.findIndex((x: FileModel) => x.id === id);
                draft.files[index].status = status;
                draft.files[index].errors = errors;
                if (externalId) {
                    draft.files[index].externalId = externalId;
                }
                if (category) {
                    draft.files[index].category = category;
                }
                if (definition || requireDefinition) {
                    draft.files[index].definition = definition;
                }
                if (amount || requireAmount) {
                    draft.files[index].amount = amount;
                }
                break;
            case ReceiptActionTypes.REMOVE:
                draft.files = state.files.filter((x) => x.id !== data.id);
                break;
            default:
                break;
        }
    });
}

/**
 * Redux Actions
 */
export const receiptActions = {
    add: actionWithPromise<ReceiptActionTypes, FileModel>(ReceiptActionTypes.ADD),
    failure: actionWithPromise(ReceiptActionTypes.FAILURE),
    success: actionWithPromise(ReceiptActionTypes.SUCCESS),
    remove: actionWithPromise(ReceiptActionTypes.REMOVE),
    request: actionWithPromise(ReceiptActionTypes.REQUEST),
    update: actionWithPromise(ReceiptActionTypes.UPDATE),
};
