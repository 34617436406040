import { put, takeLatest } from 'redux-saga/effects';
import { certificateActions } from './certificate';
import { pollCertificateActions } from './pollCertificate';
import { actionWithPromise, api, emptyFn, FileModel, Rejectable, Resolvable } from '@protectorinsurance/ds-can';
import { select } from 'typed-redux-saga';
import { selectRequestId } from '../../selectors/commonSelectors';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum DeleteCertificateActionTypes {
    REQUEST = '@app/upload/certificate/delete/REQUEST',
    SUCCESS = '@app/upload/certificate/delete/SUCCESS',
    FAILURE = '@app/upload/certificate/delete/FAILURE',
}

/**
 * Action Definitions
 */
export interface IDeleteCertificateAction {
    type: DeleteCertificateActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const deleteCertificateActions = {
    request: actionWithPromise(DeleteCertificateActionTypes.REQUEST),
    success: actionWithPromise(DeleteCertificateActionTypes.SUCCESS),
    failure: actionWithPromise(DeleteCertificateActionTypes.FAILURE),
};

/**
 * Saga functions
 */
export const deleteCertificateSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: IDeleteCertificateAction) {
        try {
            const requestId = yield* select(selectRequestId);
            if (data.externalId) {
                yield api.delete(`upload/${data.externalId}`, {
                    baseURL: NODE_API_BASE_URL,
                    headers: { 'X-Request-Id': `${requestId}` },
                });
            }
            yield put(deleteCertificateActions.success());
            yield put(certificateActions.remove(data));
            resolve();
        } catch (e) {
            yield put(pollCertificateActions.failure((e as any).response.data));
            reject();
        }
    },
};

/**
 * Saga watchers
 */
export const deleteCertificateWatchers = function* () {
    yield takeLatest(DeleteCertificateActionTypes.REQUEST, deleteCertificateSagas.request);
};
