import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    IDontKnowIcon,
    isYes,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../sagas/common';
import { PersonRoutePaths } from '../../../../wizardRouter/personWizardRoutes';
import { personActions } from '../../../../../sagas/person';
import { selectHasAppliedMedicalCare } from '../../../../../sagas/selectors/personSelectors';

/**
 * Destructure necessary imports
 */
const { DONT_KNOW, NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const workersCompIllnessHasAppliedMedicalCarePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<PersonRoutePaths> => {
    const options = [
        { id: YES, name: t(`lob.person.workersComp.illness.hasAppliedMedicalCare.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lob.person.workersComp.illness.hasAppliedMedicalCare.select.${NO}`), icon: <NoIcon /> },
        {
            id: DONT_KNOW,
            name: t(`lob.person.workersComp.illness.hasAppliedMedicalCare.select.${DONT_KNOW}`),
            icon: <IDontKnowIcon />,
        },
    ];

    return {
        slug: PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE,
        stateKey: 'hasAppliedMedicalCare',
        i18n: {
            ns: 'lob.person.workersComp.illness.hasAppliedMedicalCare',
        },
        selectorValue: selectHasAppliedMedicalCare(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (isYes(option.id)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }
                dispatcherWithPromise(dispatch, personActions.update, { hasAppliedMedicalCare: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
