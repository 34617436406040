import React from 'react';
import { CarouselConfigLookupResponse, RoadIcon, TypeOfRoadKeys } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { selectTypeOfRoad } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { FREEWAY, PRIVATE, ROAD, STREET } = TypeOfRoadKeys;

/**
 * Page view and page logic
 */
export const roundaboutTypeOfRoadPage = ({
    t,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: STREET, name: t(`motor.driving.roundaboutTypeOfRoad.select.${STREET}`), icon: <RoadIcon /> },
        { id: FREEWAY, name: t(`motor.driving.roundaboutTypeOfRoad.select.${FREEWAY}`), icon: <RoadIcon /> },
        { id: ROAD, name: t(`motor.driving.roundaboutTypeOfRoad.select.${ROAD}`), icon: <RoadIcon /> },
        { id: PRIVATE, name: t(`motor.driving.roundaboutTypeOfRoad.select.${PRIVATE}`), icon: <RoadIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_ROUNDABOUT_TYPE_OF_ROAD,
        stateKey: 'typeOfRoad',
        i18n: {
            ns: 'motor.driving.roundaboutTypeOfRoad',
        },
        selectorValue: selectTypeOfRoad(state),
        options,
    };
};
