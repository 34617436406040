import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { isLockedPage } from './parked/theftAndDamages/isLocked';
import { welcomePage } from './start/welcome';
import { isDrivingPage } from './start/isDriving';
import { hasAllKeysPage } from './parked/theftAndDamages/hasAllKeys';
import { isItemMissingPage } from './parked/theftAndDamages/isItemMissing';
import { isSelfDiscoveredClaimPage } from './parked/theftAndDamages/isSelfDiscoveredClaim';
import { isVehicleRecoveredPage } from './parked/theftAndDamages/isVehicleRecovered';
import { isVehicleStolenPage } from './parked/theftAndDamages/isVehicleStolen';
import { isCounterpartyKnownPage } from './parked/parking/isCounterpartyKnown';
import { isOtherVehicleInvolvedPage } from './driving/isOtherVehicleInvolved';
import { typeOfAnimalPage } from './driving/noVehicle/animal/typeOfAnimal';
import { hasAnimalEscapedPage } from './driving/noVehicle/animal/hasAnimalEscaped';
import { parkedClaimCausePage } from './parked/claimCause';
import { insuredByProtectorPage } from './start/insuredByProtector';
import { parkingUnidentifiedTypePage } from './parked/parking/unidentifiedType';
import { claimCauseDetailsPage } from './parked/other/claimCauseDetails';
import { noVehicleClaimCausePage } from './driving/noVehicle/claimCause';
import { drivingUnidentifiedTypePage } from './driving/vehicles/unidentifiedType';
import { isLaneChangePage } from './driving/vehicles/isLaneChange';
import { claimLocationTypePage } from './driving/vehicles/claimLocationType';
import { roundaboutClaimCausePage } from './driving/vehicles/roundabout/roundaboutClaimCause';
import { counterpartyRoundaboutPositioningPage } from './driving/vehicles/roundabout/counterpartyRoundaboutPositioning';
import { claimantRoundaboutPositioningPage } from './driving/vehicles/roundabout/claimantRoundaboutPositioning';
import { roadClaimCausePage } from './driving/vehicles/road/roadClaimCause';
import { typeOfRoadPage } from './driving/vehicles/road/typeOfRoad';
import { reversingWhoAtFaultPage } from './driving/vehicles/reversing/reversingWhoAtFault';
import { rearEndedWhoAtFaultPage } from './driving/vehicles/rearEnded/rearEndedWhoAtFault';
import { parkingOtherClaimCausePage } from './driving/vehicles/parkingOther/parkingOtherClaimCause';
import { isReversingPage } from './driving/vehicles/parked/isReversing';
import { parkedWhoAtFaultPage } from './driving/vehicles/parked/parkedWhoAtFault';
import { overtakingWhoAtFaultPage } from './driving/vehicles/overtaking/overtakingWhoAtFault';
import { laneChangeWhoAtFaultPage } from './driving/vehicles/laneChange/laneChangeWhoAtFault';
import { isCounterpartyStationaryPage } from './driving/vehicles/headOn/isCounterpartyStationary';
import { intersectionClaimCausePage } from './driving/vehicles/intersection/intersectionClaimCause';
import { typeOfIntersectionPage } from './driving/vehicles/intersection/typeOfIntersection';
import { hasNonVehicleDamagesPage } from './end/hasNonVehicleDamages';
import { hasPersonInjuriesPage } from './end/hasPersonInjuries';
import { hasVehicleDamagesPage } from './end/hasVehicleDamages';
import { hasWitnessesPage } from './end/hasWitnesses';
import { isPoliceContactedPage } from './end/isPoliceContacted';
import { needVehicleTowingPage } from './end/needVehicleTowing';
import { noVehicleTypeOfRoadPage } from './driving/noVehicle/typeOfRoad';
import { intersectionTypeOfRoadPage } from './driving/vehicles/intersection/typeOfRoad';
import { roundaboutTypeOfRoadPage } from './driving/vehicles/roundabout/typeOfRoad';
import { roadConditionPage } from './driving/roadCondition';
import { lightOfDayPage } from './driving/lightOfDay';
import { isUnderInfluencePage } from './end/isUnderInfluence';
import { isTrafficLightsPage } from './driving/vehicles/intersection/isTrafficLights';
import { hasLicensePage } from './end/hasLicense';
import { isUrbanAreaPage } from './driving/isUrbanArea';

/**
 * Config logic
 */
export const motorCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    welcomePage(props),
    insuredByProtectorPage(props),
    isDrivingPage(props),

    // Parked
    parkedClaimCausePage(props),

    // Parked / Theft And Damages
    isSelfDiscoveredClaimPage(props),
    isLockedPage(props),
    isVehicleStolenPage(props),
    hasAllKeysPage(props),
    isVehicleRecoveredPage(props),
    isItemMissingPage(props),

    // Parked / Parking
    isCounterpartyKnownPage(props),
    parkingUnidentifiedTypePage(props),

    // Parked / Other
    claimCauseDetailsPage(props),

    // Driving
    isOtherVehicleInvolvedPage(props),
    roadConditionPage(props),
    lightOfDayPage(props),
    isUrbanAreaPage(props),

    // Driving / Vehicles
    drivingUnidentifiedTypePage(props),
    claimLocationTypePage(props),
    isLaneChangePage(props),

    // Driving / Vehicles / Road
    typeOfRoadPage(props),
    roadClaimCausePage(props),

    // Driving / Vehicles / Roundabout
    roundaboutTypeOfRoadPage(props),
    claimantRoundaboutPositioningPage(props),
    counterpartyRoundaboutPositioningPage(props),
    roundaboutClaimCausePage(props),

    // Driving / Vehicles / Intersection
    intersectionTypeOfRoadPage(props),
    typeOfIntersectionPage(props),
    isTrafficLightsPage(props),
    intersectionClaimCausePage(props),

    // Driving / Vehicles / Parking Other
    parkingOtherClaimCausePage(props),

    // Driving / Vehicles / Reversing
    reversingWhoAtFaultPage(props),

    // Driving / Vehicles / Lane Change
    laneChangeWhoAtFaultPage(props),

    // Driving / Vehicles / Overtaking
    overtakingWhoAtFaultPage(props),

    // Driving / Vehicles / Head On
    isCounterpartyStationaryPage(props),

    // Driving / Vehicles / Rear Ended
    rearEndedWhoAtFaultPage(props),

    // Driving / Vehicles / Parked
    parkedWhoAtFaultPage(props),
    isReversingPage(props),

    // Driving / No Vehicle
    noVehicleClaimCausePage(props),
    noVehicleTypeOfRoadPage(props),

    // Driving / No Vehicle / Animal
    typeOfAnimalPage(props),
    hasAnimalEscapedPage(props),

    // End
    hasVehicleDamagesPage(props),
    needVehicleTowingPage(props),
    hasNonVehicleDamagesPage(props),
    hasPersonInjuriesPage(props),
    isPoliceContactedPage(props),
    isUnderInfluencePage(props),
    hasWitnessesPage(props),
    hasLicensePage(props),
];
