import React from 'react';
import { LobKeys, Nullable, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { TRAVEL } = LobKeys;

/**
 * Component view and component logic
 */
export const renderClaimType = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>
) => {
    const lobs = [TRAVEL];
    if (lob && lobs.includes(lob)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
