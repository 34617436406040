import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    ClaimListItem,
    Clickable,
    getLocaleFromUrl,
    Grid,
    PageLayout,
    ProgressBar,
    StandardModal,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { bankIDJWTServiceActions } from '../../../sagas/services/bankIDJWTService';
import { selectHasExistingClaim, selectStoredClaim } from '../../../sagas/selectors/bankIDSelectors';
import { getLobProgressBarRoutes } from '../../../utils/flow/getLobProgressBarRoutes';
import { progressBarUtil } from '../../../utils/flow/progressBarUtil';
import { motorActions } from '../../../sagas/motor';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { MotorRoutePaths } from 'config/wizardRouter/motorWizardRoutes';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SELECT_NO, SELECT_YES, SUB_TITLE, TITLE } = PhraseKeys;
const { END_REPORT_COMPLETED } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const StartContinueClaimPage = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const lob = useSelector(selectLob);
    const hasExistingClaim = useSelector(selectHasExistingClaim);
    const storedClaim = useSelector(selectStoredClaim);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.start.continueClaim');
    const progressBarRoutes = getLobProgressBarRoutes(lob);
    const motorPath = (path: MotorRoutePaths) => {
        const { language } = getLocaleFromUrl();
        const url = path.replace(':language', language);
        return url;
    };

    useEffect(() => {
        dispatcherWithPromise(dispatch, bankIDJWTServiceActions.submissionId).then(() =>
            dispatch(bankIDJWTServiceActions.fetch())
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (storedClaim) {
            setProgress(progressBarUtil(storedClaim.flow, storedClaim.path, progressBarRoutes));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedClaim]);

    const handleBackButton = useGoBack();

    const handleModalClose = () => {
        setIsModalOpen(() => false);
    };

    const handleModalConfirm = () => {
        setIsModalOpen(() => false);
        dispatcherWithPromise(dispatch, commonActions.authPost).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (hasExistingClaim && storedClaim.path !== motorPath(END_REPORT_COMPLETED)) {
            setIsModalOpen(() => true);
        } else {
            dispatcherWithPromise(dispatch, commonActions.authPost)
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleContinueClaim = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, storedClaim).then(() =>
            dispatch(wizardActions.goTo(storedClaim.path))
        );
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={
                hasExistingClaim && storedClaim.path !== motorPath(END_REPORT_COMPLETED) ? tWithNS.t(HELP_TEXT) : ''
            }
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            {hasExistingClaim && storedClaim.path !== motorPath(END_REPORT_COMPLETED) ? (
                <Grid>
                    <ClaimListItem
                        claimsTitle={tWithNS.t('claim.title')}
                        handleClick={handleContinueClaim}
                        {...{ customCAN }}
                    >
                        <ProgressBar width={progress} {...{ customCAN }} />
                    </ClaimListItem>
                </Grid>
            ) : (
                <p>{tWithNS.t('noClaim')}</p>
            )}
            {isModalOpen && (
                <StandardModal
                    closeButtonText={t(SELECT_NO)}
                    confirmButtonText={t(SELECT_YES)}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={tWithNS.t('modal.title')}
                >
                    <div className={'dialog__content text-center'}>{tWithNS.t('modal.question')}</div>
                </StandardModal>
            )}
        </PageLayout>
    );
};
