import {
    CountryCodeISOEnums,
    LanguageCodeEnums,
    LocaleServiceInstance,
    MomentLocaleEnums,
} from '@protectorinsurance/ds-can';

LocaleServiceInstance.setCountry(CountryCodeISOEnums.FINLAND)
    .setLanguage(LanguageCodeEnums.FI)
    .setMoment(MomentLocaleEnums.FI)
    .initialize();
