import { PersonRoutePaths } from './personWizardRoutes';
import { FlowKeys, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { strReplace } from '../../utils/strings/strReplace';

const personProgressBar = (path: PersonRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const find = [':language'];
    const replace = [language ? language : ''];
    return strReplace(path, find, replace);
};

export const personWorkersCompProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.ILLNESS]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_CATEGORY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CLAIMANT_ROLE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_ONBOARDING),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIM_DATE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTED_TO_EMPLOYER_DATE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_CAUSE),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_PHYSICAL_FACTOR),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_TYPE_OF_BIOLOGICAL_FACTOR),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_TYPE_OF_CHEMICAL_FACTOR),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_CAUSED_INCAPACITY),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_INCAPACITY_PERIODS),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_APPLIED_MEDICAL_CARE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_DATE_OF_APPLIED_MEDICAL_CARE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_MEDICAL_INSTITUTION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_WHO_PAID_EXPENSES),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_UPLOAD_RECEIPT),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_COMPANY_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_INJURED_PROFESSION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_ENTREPRENEUR_OWNERSHIP),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ILLNESS_HAS_COMPANY_SHARES),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_CLAIMANT_POSITION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORTER_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_POLICY_HOLDERS_CONTACT),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_BANK_ACCOUNT_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_BANK_ACCOUNT_CONTACT_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_FINAL_SUMMARY),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ILLNESS_REPORT_COMPLETED),
    ],
    [FlowKeys.ACCIDENT]: [
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_CATEGORY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CLAIMANT_ROLE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_ONBOARDING),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_STUDY_LOCATION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_STUDIES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OCCUPATIONAL_CONDITION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OUTSIDE_OCCUPATIONAL_CONDITION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_OTHER_OCCUPATIONAL_CONDITION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_DAMAGE_ADDRESS),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_WORKPLACE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIM_DATE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTED_TO_EMPLOYER_DATE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_WORK_DAY),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_NATURE_TYPE_ID),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_BODY_PARTS_LIST),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_VEHICLE_INVOLVED),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_TYPE_OF_VEHICLE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_VEHICLE_REGISTRATION_NUMBER),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_IS_POLICE_CONTACTED),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICE_DISTRICT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_UNDER_INFLUENCE_CLAIM_DESCRIPTION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_CAUSED_INCAPACITY),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_MORE_THAN_SEVEN_DAYS),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_INCAPACITY_PERIOD),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_INCAPACITY_PERIODS),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_APPLIED_MEDICAL_CARE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_DATE_OF_APPLIED_MEDICAL_CARE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_MEDICAL_INSTITUTION),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_EXPENSES),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_WHO_PAID_EXPENSES),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_UPLOAD_RECEIPT),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_OTHER_INSURANCE),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_OTHER_INSURANCE_COMPANY),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_SITUATION_SUMMARY),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_COMPANY_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_INJURED_PROFESSION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_ENTREPRENEUR_OWNERSHIP),
        personProgressBar(PersonRoutePaths.DYN_CAROUSEL_WORKERS_COMP_ACCIDENT_HAS_COMPANY_SHARES),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_CLAIMANT_POSITION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORTER_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_POLICY_HOLDERS_CONTACT),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_BANK_ACCOUNT_CONTACT_INFORMATION),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_FINAL_SUMMARY),
        personProgressBar(PersonRoutePaths.WORKERS_COMP_ACCIDENT_REPORT_COMPLETED),
    ],
};
