import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    selectExternalReference,
    selectPolicyHoldersContact,
    selectReporterInformation,
} from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import {
    AutocompleteEnums,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    isEmpty,
    MuiTextInput,
    Nullable,
    PageLayout,
    PolicyHoldersContactModel,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { policyHoldersContactSchema } from '../../../validations/schemas/policyHoldersContactSchema';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { EMAIL, EXTERNAL_REFERENCE, FIRST_NAME, IS_REPORTER, LAST_NAME, PHONE } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    EXTERNAL_REFERENCE_LABEL,
    EXTERNAL_REFERENCE_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    IS_REPORTER_LABEL,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteEnums;

/**
 * Interfaces
 */
interface PolicyHoldersContactForm extends PolicyHoldersContactModel {
    externalReference: Nullable<string>;
}

/**
 * Page view and page logic
 */
export const PolicyHoldersContactPage = () => {
    const dispatch = useDispatch();
    const policyHoldersContact = useSelector(selectPolicyHoldersContact);
    const externalReference = useSelector(selectExternalReference);
    const isReporter = policyHoldersContact.isReporter;
    const reporterInformation = useSelector(selectReporterInformation);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.policyHoldersContact');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<PolicyHoldersContactForm>({
        resolver: yupResolver(policyHoldersContactSchema(t)),
        defaultValues: {
            email: policyHoldersContact.email ? policyHoldersContact.email : '',
            externalReference: externalReference ? externalReference : '',
            firstName: policyHoldersContact.firstName ? policyHoldersContact.firstName : '',
            lastName: policyHoldersContact.lastName ? policyHoldersContact.lastName : '',
            phone: policyHoldersContact.phone ? policyHoldersContact.phone : '',
        },
    });
    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const fillWithReporterInformation = () => {
        const { email, firstName, lastName, phone } = reporterInformation;
        setValue('firstName', firstName, { shouldValidate: true });
        setValue('lastName', lastName, { shouldValidate: true });
        setValue('email', email, { shouldValidate: true });
        setValue('phone', phone, { shouldValidate: true });
    };

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setValue('isReporter', checked);
        dispatch(motorActions.update({ policyHoldersContact: { ...policyHoldersContact, isReporter: checked } }));
        checked && fillWithReporterInformation();
    };

    const onSubmit = (values: PolicyHoldersContactForm) => {
        const { email, externalReference, firstName, lastName, phone } = values;
        dispatcherWithPromise(dispatch, motorActions.update, {
            policyHoldersContact: { email, firstName, isReporter, lastName, phone },
            externalReference,
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    const isReporterFilled = () => {
        const { email, firstName, lastName, phone } = reporterInformation;
        return !isEmpty(firstName) && !isEmpty(lastName) && (!isEmpty(phone) || !isEmpty(email));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    {isReporterFilled() && (
                        <SingleCheckbox
                            checked={isReporter}
                            handleChange={handleChecked}
                            name={IS_REPORTER}
                            wrapperClass={'col-12 multiple'}
                            {...{ customCAN }}
                        >
                            {t(IS_REPORTER_LABEL)}
                        </SingleCheckbox>
                    )}
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                disabled={isReporter}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                disabled={isReporter}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EXTERNAL_REFERENCE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.externalReference}
                                errorMessage={errors.externalReference?.message}
                                id={EXTERNAL_REFERENCE}
                                inputFieldWrapper={'col-4'}
                                label={t(EXTERNAL_REFERENCE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EXTERNAL_REFERENCE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                disabled={isReporter}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                inputMode={'email'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                disabled={isReporter}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
