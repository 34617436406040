import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';
import { addressSchema } from '../fieldSchemas/addressSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { bankAccountNumberSchema } from '../fieldSchemas/bankAccountNumberSchema';

/**
 * Validation logic
 */
export const claimantInformationWorkersCompSchema = (t: TFunction, missingSSN: boolean = false) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...(!missingSSN && nationalIdentitySchema(t, false)),
        ...contactDetailsSchema(t),
        ...addressSchema(t),
        ...bankAccountNumberSchema(t, false),
    });
};
