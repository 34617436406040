import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';

/**
 * Destructure necessary imports
 */
const { ACCOUNT_NUMBER_LABEL } = PhraseKeys;
const { ACCOUNT_NUMBER_ERROR } = FormFieldErrors;

/**
 * Validation logic
 */
export const accountNumberSchema = (t: TFunction, optional: boolean) => {
    if (optional) {
        return {
            accountNumber: Yup.string().accountNumber(t(ACCOUNT_NUMBER_ERROR)).label(t(ACCOUNT_NUMBER_LABEL)),
        };
    } else {
        return {
            accountNumber: Yup.string()
                .accountNumber(t(ACCOUNT_NUMBER_ERROR))
                .label(t(ACCOUNT_NUMBER_LABEL))
                .required(),
        };
    }
};
