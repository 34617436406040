import { ReporterInformationModel } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const formatReporterInformation = (reporter: ReporterInformationModel) => {
    return [
        reporter.firstName ? `${reporter.firstName}` : '',
        reporter.lastName ? ` ${reporter.lastName}` : '',
        reporter.role ? ` (${reporter.role})` : '',
        reporter.email ? `, ${reporter.email}` : '',
        reporter.phone ? `, ${reporter.phone}` : '',
    ].join('');
};
