import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldNames } from '../../config/formFieldNames';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { MAKE_LABEL } = PhraseKeys;
const { CHASSIS_NUMBER, TYPE, VEHICLE_TYPE, VIN_NUMBER, MODEL } = FormFieldNames;

/**
 * Validation logic
 */
export const vehicleMakeSchema = (t: TFunction) => {
    return {
        make: Yup.string()
            .label(t(MAKE_LABEL))
            .noSpecialChars()
            .requiredIf([TYPE, VEHICLE_TYPE, MODEL, VIN_NUMBER, CHASSIS_NUMBER])
            .nullable(),
    };
};
