import React, { ReactElement } from 'react';
import { customCANProps, Nullable } from '@protectorinsurance/ds-can';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

/**
 * Interfaces
 */
interface DatePickerInputProps {
    customCAN?: customCANProps;
    disableFuture?: boolean;
    error?: boolean;
    errorMessage?: string | ReactElement;
    id: string;
    inputFieldWrapper?: string;
    label: string;
    locale?: string;
    onChange: (newValue: Nullable<Date>) => void;
    placeholder?: string;
    value?: Nullable<Date>;
}

/**
 * Component view and component logic
 */
export const DatePickerInput = ({
    customCAN,
    disableFuture,
    error,
    errorMessage,
    id,
    inputFieldWrapper,
    label,
    locale,
    onChange,
    placeholder,
    value,
}: DatePickerInputProps) => {
    return (
        <div className={inputFieldWrapper}>
            <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterMoment}>
                <DatePicker
                    data-testid={id}
                    slotProps={{
                        layout: {
                            sx: {
                                '& .MuiPickersDay-root, & .MuiPickersYear-root': {
                                    '&.Mui-selected': {
                                        backgroundColor: `${customCAN ? customCAN.color : '#ef6e0b'} !important`,
                                        '&:hover': {
                                            backgroundColor: `${
                                                customCAN ? customCAN.colorFaded : '#c35c0f'
                                            } !important`,
                                        },
                                    },
                                },
                                '& .MuiPickersYear-root': {
                                    '& button.Mui-selected': {
                                        backgroundColor: `${customCAN ? customCAN.color : '#ef6e0b'} !important`,
                                        '&:hover': {
                                            backgroundColor: `${
                                                customCAN ? customCAN.colorFaded : '#c35c0f'
                                            } !important`,
                                        },
                                    },
                                },
                            },
                        },
                        textField: {
                            error,
                            helperText: errorMessage,
                            id,
                            placeholder,
                        },
                    }}
                    sx={{
                        '& label.Mui-focused': {
                            color: customCAN ? customCAN.color : '#ef6e0b',
                        },

                        '& .MuiOutlinedInput-root': {
                            '& .MuiInputAdornment-root': {
                                width: 'unset !important',
                            },

                            '&.Mui-disabled:hover': {
                                '& fieldset': {
                                    borderColor: 'rgba(0,0,0,0.26)',
                                },
                            },

                            '&:hover': {
                                '& fieldset': {
                                    borderColor: customCAN ? customCAN.color : '#ef6e0b',
                                },
                            },

                            '&.Mui-focused': {
                                '& legend': {
                                    backgroundColor: '#ffffff',
                                },

                                '& fieldset': {
                                    borderColor: customCAN ? customCAN.color : '#ef6e0b',
                                },
                            },
                        },
                    }}
                    {...{ disableFuture, label, onChange, value }}
                />
            </LocalizationProvider>
        </div>
    );
};
