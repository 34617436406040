import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    LightControlledIntersectionIcon,
    OtherInjuriesIcon,
    RighOfWayIntersectionIcon,
    TSectionIcon,
    TypeOfIntersectionKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { selectTypeOfIntersection } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { commonActions } from '../../../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { LIGHT_CONTROLLED, OTHER, RIGHT_OF_WAY, T_SECTION } = TypeOfIntersectionKeys;
const { DYN_CAROUSEL_INTERSECTION_IS_TRAFFIC_LIGHTS } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const typeOfIntersectionPage = ({
    t,
    dispatch,
    state,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        {
            id: LIGHT_CONTROLLED,
            name: t(`motor.driving.typeOfIntersection.select.${LIGHT_CONTROLLED}`),
            icon: <LightControlledIntersectionIcon />,
        },
        {
            id: RIGHT_OF_WAY,
            name: t(`motor.driving.typeOfIntersection.select.${RIGHT_OF_WAY}`),
            icon: <RighOfWayIntersectionIcon />,
        },
        { id: T_SECTION, name: t(`motor.driving.typeOfIntersection.select.${T_SECTION}`), icon: <TSectionIcon /> },
        { id: OTHER, name: t(`motor.driving.typeOfIntersection.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_INTERSECTION_TYPE_OF_INTERSECTION,
        stateKey: 'typeOfIntersection',
        i18n: {
            ns: 'motor.driving.typeOfIntersection',
        },
        selectorValue: selectTypeOfIntersection(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext();
                if (option.id === LIGHT_CONTROLLED) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_INTERSECTION_IS_TRAFFIC_LIGHTS);
                }

                dispatcherWithPromise(dispatch, motorActions.update, { typeOfIntersection: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
