import React from 'react';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { Link, PageLayout } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useSelector } from 'react-redux';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const WindshieldDamagePage = () => {
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.start.windshieldDamage');

    const handleBackButton = useGoBack();

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <p className={'text-left mb-5'} tabIndex={0}>
                {tWithNS.t('text.firstSection')}
            </p>
            <p className={'text-left mb-5'} tabIndex={0}>
                {tWithNS.t('text.secondSection')}
            </p>
            <p className={'text-left'} tabIndex={0}>
                <span>{tWithNS.t('text.carAndLightTruck')}</span>
                <Link linkText={tWithNS.t('linkText.ryds')} url={tWithNS.t('url.ryds')} {...{ customCAN }} />
            </p>
        </PageLayout>
    );
};
