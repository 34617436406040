import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { selectClaimantPosition } from '../../../../sagas/selectors/personSelectors';
import { useI18n } from '../../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { personActions } from '../../../../sagas/person';
import { commonActions } from '../../../../sagas/common';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    ClaimantPositionModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { claimantPositionSchema } from 'validations/schemas/claimantPositionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { OWNERSHIP, POSITION } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    OWNERSHIP_LABEL,
    OWNERSHIP_PLACEHOLDER,
    PAGE_NAME,
    POSITION_LABEL,
    POSITION_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const WorkersCompAccidentClaimantPositionPage = () => {
    const dispatch = useDispatch();
    const claimantPosition = useSelector(selectClaimantPosition);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob.person.workersComp.accident.claimantPosition');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimantPositionModel>({
        resolver: yupResolver(claimantPositionSchema(t)),
        defaultValues: {
            ...claimantPosition,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = (values: ClaimantPositionModel) => {
        dispatcherWithPromise(dispatch, personActions.update, { claimantPosition: values })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={POSITION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.position}
                                errorMessage={errors.position?.message}
                                id={POSITION}
                                inputFieldWrapper={'col-6'}
                                label={t(POSITION_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(POSITION_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={OWNERSHIP}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.ownership}
                                errorMessage={errors.ownership?.message}
                                id={OWNERSHIP}
                                inputFieldWrapper={'col-6'}
                                inputMode={'numeric'}
                                label={t(OWNERSHIP_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(OWNERSHIP_PLACEHOLDER)}
                                reference={ref}
                                value={field.value?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
