import React from 'react';
import {
    ClaimantRoleEnums,
    ClaimantRoleTypeModel,
    is,
    isYes,
    SummaryItem,
    SummaryItemProps,
    YesNoModel,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { STUDENT } = ClaimantRoleEnums;

/**
 * Component view and component logic
 */
export const renderIncapacityPeriod = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    causedIncapacity: YesNoModel,
    claimantRole: ClaimantRoleTypeModel
) => {
    if (isYes(causedIncapacity) && !is(claimantRole, STUDENT)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
